
import { useState, useEffect, useContext } from 'react';
import { OnboardContext } from '../../context/OnboardProvider';
import NameErrorModal from './NameErrorModal';

const SetOrganizationName = () => {
    const [orgName, setOrgName] = useState("")
    const {
        createOrganization,
        nameErrorModalVisible,
    } = useContext(OnboardContext)

    const createOrg = () => {
        // console.log('create!')
        createOrganization(orgName)
    }

    useEffect(() => {
        if (nameErrorModalVisible) {
            setOrgName("")
        }
    }, [nameErrorModalVisible])

    const onSubmit = (e) => {
        if (e === 'Enter' && orgName != "" && /^[a-zA-Z0-9_\ ]+$/.test(orgName) && orgName.length > 2) {
            createOrg()
        }
        
    }


    return (
        <>
        <NameErrorModal/>
        <div className="flex flex-col justify-center">
            <div className="w-full text-4xl flex mb-8">
                <div className="flex border-b border-[#1F422A] py-2 w-1/3 text-center min-w-[500px] m-auto">
                        <input value={orgName} onKeyDownCapture={(e) => onSubmit(e.key)} onChange={(value) => setOrgName(value.target.value)} className={(orgName != "" && !/^[a-zA-Z0-9_\ ]+$/.test(orgName)) ? "bg-transparent rounded outline-red-300 apperance-none text-center w-full py-1 px-2 leading-tight focus:outline-red-300 ml-[20px]" : "ml-[20px] appearance-none bg-transparent border-none text-center w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"} type="text" placeholder="Set an Organization Name" aria-label="Full name" />
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" onClick={() => createOrg() } visibility={(orgName.length < 3 || orgName === "" || orgName.length > 40 || !/^[a-zA-Z0-9_\ ]+$/.test(orgName)) ? "hidden" : "visible"} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-9 h-9 mt-[15px] cursor-pointer p-1 hover:bg-gray-200 rounded-lg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                </div>
            </div>
            <div className="w-full text-2xl text-gray-600 text-center mb-24">Only use letters, numbers, spaces, and underscores</div>
        </div>
        </>
    )
}

export default SetOrganizationName;
