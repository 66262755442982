import './style.scss';
import { CURRENT_ENVIRONMENT } from '../..';
import Page from '../../components/Page';

const AccountPage = () => {
  return (
    <Page>
      <div>
        <div className="page-content Account-page">
          {/* <snippyly-comments/>
            <snippyly-cursor/> */}
          <iframe
            title="AccountPageIframe"
            id="account"
            seamless
            frameBorder="0"
            scrolling="yes"
            src={CURRENT_ENVIRONMENT + '/account'}
            sandbox
          ></iframe>
        </div>
      </div>
    </Page>
  );
};

export default AccountPage;
