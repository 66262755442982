import { TableGraphContext } from '../../context/TableGraphProvider';
import React, { useState, useContext } from 'react';
import { getBezierPath,  BaseEdge, EdgeLabelRenderer } from 'reactflow';

const CustomEdge = ({
  id,
  style,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,

}) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const { onDeleteEdge, setForceDagSave, hoveredEdges} = useContext(TableGraphContext);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    
  });

  const onEdgeClick = () => {
    onDeleteEdge({id: id});
    setForceDagSave(true)
  };

  return (

    <>
     <BaseEdge path={edgePath} markerEnd={markerEnd} style={hoveredEdges?.has(id) ? {...style,  stroke: '#4CB043'} : style} />
      <EdgeLabelRenderer >
      {hoveredEdges?.has(id) ? 
      <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <button className="w-5 h-5 bg-gray-200 border border-white cursor-pointer rounded-full text-xs leading-none hover:shadow-md" onClick={onEdgeClick}>
            ×
          </button>
        </div>
        : 
        <></>
        }
       </EdgeLabelRenderer>
      </>
  );
};

export default CustomEdge;

