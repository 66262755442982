import React, { useContext, useState, useEffect, useRef } from 'react';
import { ModelEditorContext } from '../../context/ModelEditorProvider';
import { UserContext } from '../../context/UserProvider';
import DbtMenu from './Sidebar/dbt/dbtMenu';
import { EditorContext } from '../../context/EditorProvider';
import GitMenu from './Sidebar/dbt/gitMenu';
import SqlRecentMenu from './Sidebar/sql/sqlRecentMenu';
import SqlSavedMenu from './Sidebar/sql/sqlSavedMenu';
import SqlManageRecentsMenu from './Sidebar/sql/sqlManageRecentsMenu';
import TableMenu from './Sidebar/Database/TableMenu';
import DatabaseMenu from './Sidebar/Database/DatabaseMenu';

const ContextMenu = () => {
    const {
        onItemClicked,
        handleEdit,
    } = useContext(ModelEditorContext);

    const {
        editorMode,
        contextMenuType,
        setContextMenuType,
        menuPos,
        setMenuPos,
        menuItem,
        setMenuItem,
        sidebarRef,
    } = useContext(EditorContext)

    const [YPos, setYPos] = useState()

    const menuRef = useRef(null)

    const getContextMenu = () => {
        if (!menuItem) {
            return <></>
        }
        if (contextMenuType == 0) {
            return <DbtMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        if (contextMenuType == 1) {
            return <GitMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        if (contextMenuType == 2) {
            return <SqlSavedMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        if (contextMenuType == 3) {
            return <SqlRecentMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        if (contextMenuType == 4) {
            return <SqlManageRecentsMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        if (contextMenuType == 5) {
            return <TableMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} /> 
        }
        if (contextMenuType == 6) {
            return <DatabaseMenu menuItem={menuItem} setMenuItem={setMenuItem} setMenuPos={setMenuPos} />
        }
        return <></>
    }

    useEffect(() => {
        if (menuPos && menuRef.current) {
            if (menuPos[1] + menuRef.current.clientHeight >= sidebarRef.current.getBoundingClientRect().bottom) {
                setYPos(menuPos[1] - menuRef.current.clientHeight)
            }
            else {
                setYPos(menuPos[1])
            }
        }
    }, [menuPos, menuRef.current?.clientHeight])

    const handleClose = () => {
        setMenuPos()
        setMenuItem()
        setContextMenuType()
        setYPos()
    }

    return ( 
        <div className="absolute z-[99] top-[-55px] w-[100vw] h-[100vh]" hidden={!(menuPos) || !(menuItem)} onClick={handleClose} >
            <div ref={menuRef} className='relative w-[130px]' style={menuItem && menuPos && YPos ? {top: `${YPos}px`, left: `${String(menuPos[0])}px`} : {visibility: 'hidden'}}>
                <div className="bg-white border border-gray-300 rounded-md flex flex-col text-sm py-[5px] px-[5px] text-gray-500 shadow-sm">
                    {getContextMenu()}
                </div>
            </div>
        </div>
    )
}

export default ContextMenu
