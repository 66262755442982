import { Card, Row, Col, Spin, Button } from 'antd'
import axios from 'axios';
import { useState, useEffect, useContext, useCallback } from 'react';
import { OnboardContext } from '../../context/OnboardProvider';
import ReactMarkdown from 'react-markdown';
import ConnectFail from '../Onboarding/ConnectWarehouseFail';
import { useAuthInfo } from '@propelauth/react';
import { trackOnboardingFeature } from '../../utils/SegmentTracker';
import ApproveCertificateModal from './CertificateApproval';
import {useDropzone} from 'react-dropzone'


const WarehouseInput = () => {
    const authInfo = useAuthInfo();

    const [file, setFile] = useState()

    const {
        warehouseType,
        requiredFields,
        setRequiredFields,
        guideText,
        setGuideText,
        warehouseRequiredFields,
        connectWarehouse,
        previousPage,
        requestWarehouse,
        sqlPath,
        setSqlPath,
        host,
        setHost,
        username,
        setUsername,
        password,
        setPassword,
        nextPage,
        port,
        setPort,
        database,
        setDatabase,
        pat,
        setPat,
        connectArtemisManaged,
        connectionLoading,
        setConnectionLoading,
        sharedAvailable,
        accessCodeError,
        setAccessCodeError,
        setWarehouseType
    } = useContext(OnboardContext)

    const [loadWarehouse, setLoadWarehouse] = useState(true)
    const [requested, setRequested] = useState(false)
    const [accessCode, setAccessCode] = useState("")


    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
              setPat(reader.result)
              console.log(reader.result)

          }
          reader.readAsText(file)
            
        })
        
    }, [])
    
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        onDrop,
        accept: {
            'application/json': ['.json'],
        },
        multiple: false
    })

    const goBack = () => {
        setRequiredFields()
        setGuideText()
        previousPage()
        setDatabase()
        setHost()
        setPassword()
        setUsername()
        setPat()
        setSqlPath()
        setPort()
        setWarehouseType()
    }

    useEffect(() => {
        setAccessCodeError(false)
    }, [])


    const submitWarehouseInfo = () => {
        trackOnboardingFeature("Connect to " + warehouseType, authInfo.user.userId, authInfo.orgHelper.getOrgIds()[0])
        const response = connectWarehouse()
    }

    const confirmArtemisManaged = () => {
        trackOnboardingFeature("Chose Artemis Managed", authInfo.user.userId, authInfo.orgHelper.getOrgIds()[0])

        const response = connectArtemisManaged(accessCode)
        // if (response.data == true) {
        //     nextPage()
        // }
    }

    const onRequestWarehouse = (special_type = null) => {
        trackOnboardingFeature("Requested Warehouse " + warehouseType, authInfo.user.userId, authInfo.orgHelper.getOrgIds()[0])
        var response = null
        if (special_type) {
            response = requestWarehouse(special_type)
        }
        else {
            response = requestWarehouse(warehouseType)
        }
        console.log(response)
        setRequested(true)
    }

    const onOutOfManaged = () => {
        const response = requestWarehouse(warehouseType)
        setRequested(true)
        console.log(response)
    }

    useEffect(() => {
        if (loadWarehouse) {
            setRequiredFields()
            setGuideText()
            warehouseRequiredFields()
            setLoadWarehouse(false)
        }
    }, [])

    return (
        <div className="divide-x divide-gray-300 h-full flex overflow-hidden">
                    <ApproveCertificateModal/>
            <ConnectFail />
            <Col span={12} className="bg-white h-full">
                <div className="cursor-pointer absolute top-[40px] left-[50px]" onClick={() => goBack()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </div>

                <Row className='mt-[50px] mx-[40px] h-full text-center'>
                    <Col offset={1} span={22} className="mt-[20px]">
                        <h1 className='text-[25px] font-[500]'> Connection Details</h1>
                        <hr className="mt-4 mb-8 h-px bg-gray-200 border-0 dark:bg-gray-700" />
                        {requiredFields && requiredFields.length == 0 ?
                            <div className="md:flex md:items-center w-full">
                                <div className="w-full">
                                    {warehouseType === "artemis" ?

                                        sharedAvailable ?
                                            <>
                                                <div className="flex flex-col justify-center mt-[8vh]">
                                                    <div className="w-full text-l flex">
                                                        <div className="flex border-b border-[#1F422A] py-2 w-1/2 text-center m-auto">
                                                            <input value={accessCode} onChange={(value) => setAccessCode(value.target.value)} className=" appearance-none bg-transparent border-none text-center w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Access code" aria-label="Full name" />
                                                        </div>
                                                    </div>
                                                    { accessCodeError && 
                                                        <div className="w-full text-l text-red-600 text-center mt-[10px]">Invalid access code</div>
                                                    }
                                                </div>
                                                <button disabled={!accessCode.length} onClick={() => confirmArtemisManaged()} className={!accessCode.length ? "shadow bg-gray-300 text-white text-[18px] py-2 px-6 rounded mt-[15px]" : "shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[18px] py-2 px-6 rounded mt-[15px]"} type="button">
                                                    Continue
                                                </button>
                                                <div className='mt-[30vh]'>
                                                    Don't have an access code? Request one below and we will contact you shortly.
                                                </div>
                                                <button onClick={() => onRequestWarehouse("artemis_request_code")} className="shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[15px] mt-[10px] py-2 px-6 rounded" type="button">
                                                    Request code
                                                </button>
                                            </>
                                            :
                                            requested ? <h3>Team has been notified. We will get back to you ASAP.</h3>
                                                :
                                                <button onClick={() => onRequestWarehouse("artemis_no_slots")} className="shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[20px] mt-[10vh] py-2 px-6 rounded" type="button">
                                                    Request Lakehouse
                                                </button>


                                        :
                                        requested ? <h3>Team has been notified. We will get back to you ASAP.</h3> :
                                            <button onClick={() => onRequestWarehouse()} className="shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[20px] mt-[10vh] py-2 px-6 rounded" type="button">
                                                Request Now
                                            </button>

                                    }
                                </div>
                            </div> :
                            <>
                                {requiredFields &&
                                    <form className="w-full h-full">
                                        {(requiredFields.includes("host")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="host">
                                                        Host
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input
                                                        value={host}
                                                        onChange={(value) => setHost(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="host" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("sql_path")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="sql_path">
                                                        SQL path
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={sqlPath} onChange={(value) => setSqlPath(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="sql_path" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("pat")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="pat">
                                                        Personal Access Token
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={pat} onChange={(value) => setPat(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="pat" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("port")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="port">
                                                        Port
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={port} onChange={(value) => setPort(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="port" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("database")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="database">
                                                        Database
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={database} onChange={(value) => setDatabase(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="database" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("user")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="username">
                                                        Username
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={username} onChange={(value) => setUsername(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="username" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("password")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="password">
                                                        Password
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={password} onChange={(value) => setPassword(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="password" type="password" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("project_id")) &&
                                            <div className="md:flex md:items-center mb-6">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="port">
                                                        Project ID
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3">
                                                    <input value={host} onChange={(value) => setHost(value.target.value)} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight" id="port" type="text" />
                                                </div>
                                            </div>
                                        }
                                        {(requiredFields.includes("service_account")) &&
                                            <>
                                            <div className="md:flex md:items-center">
                                                <div className="md:w-1/3">
                                                    <label className="block md:text-right mb-1 md:mb-0 pr-4" for="port">
                                                        Service Account
                                                    </label>
                                                    
                                                </div>
                                                <div className="md:w-2/3">
                                                    <div>
                                                        <div className="mb-[5px]" {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {
                                                                // acceptedFileItems && acceptedFileItems.length ?
                                                                <Button>
                                                                    Select File
                                                                </Button> 
                                                                // :
                                                                // <aside>
                                                                // <ul>{acceptedFileItems}</ul>
                                                                // </aside>
                                                            }
                                                        </div>
                                                        <h5 className="mb-[5px]">
                                                            or paste below
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <textarea style={{maxHeight: '50vh'}} value={pat} onChange={(value) => {
                                                console.log(value.target.value)
                                                setPat(value.target.value)
                                            }} className="bg-white appearance-none border-[1px] border-gray-400 focus:outline-[#1F422A] rounded w-full py-2 px-4 leading-tight mb-[10px]" id="port" type="text" />
                                            </>
                                        }
                                        <div className="md:flex md:items-center absolute bottom-[100px] w-full">
                                            <div className="w-full">
                                                <Spin spinning={connectionLoading}>
                                                    <button onClick={() => submitWarehouseInfo()} className="shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[20px] py-2 px-6 rounded" type="button">
                                                        Save & Test
                                                    </button>
                                                </Spin>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </>
                        }

                    </Col>
                </Row>
            </Col>
            <hr></hr>
            <Col span={12} className="bg-gray-200 overflow-auto">
                {/* <Row className='mt-[50px] mx-[50px]'> */}
                <div className='mb-[30px] mt-[70px] mx-[50px]'>
                    {guideText &&
                        <ReactMarkdown children={guideText} />
                    }
                </div>
                </Col>
            </div>
    )
}

export default WarehouseInput;
