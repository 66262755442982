// CreateWorkflowSchedulesModal.js
import React, { useState, useContext, useEffect } from 'react';
import { WorkflowSchedulesContext } from '../../context/WorkflowSchedulesProvider';
import { TableGraphContext } from '../../context/TableGraphProvider';
import { Spin, Slider } from "antd";
import { AirflowIcon } from "./icons/Airflow";
import { CronIcon } from "./icons/Cron";
import { AirbyteIcon } from "./icons/Airbyte";
import { FivetranIcon } from "./icons/Fivetran";

const marks = {
  0: '1h',
  1: '2h',
  2: '3h',
  3: '6h',
  4: '12h',
  5: '1d',
  6: '3d',
  7: '7d'
};


const CreateScreen = ({ selectedProvider, onCreateWorkflow, dags }) => {
  const [interval, setInterval] = useState(0);
  const [selectedDAGs, setSelectedDAGs] = useState([]);
  const [unselectedDAGs, setUnselectedDAGs] = useState([]);

  useEffect(() => {
    // Extract only the _id and name fields from the dags prop
    const simplifiedDags = dags.map(dag => ({ _id: dag._id, name: dag.name }));
    setUnselectedDAGs(simplifiedDags);
  }, [dags]);

  const convertToMinutes = key => {
    const mark = marks[key];
    let minutes = 0;
    if (mark.includes('h')) {
      minutes = parseInt(mark, 10) * 60;
    } else if (mark.includes('d')) {
      minutes = parseInt(mark, 10) * 1440; // 1440 minutes in a day
    }
    return minutes;
  };

  const handleSubmit = () => {
    const intervalInMinutes = convertToMinutes(interval);
    let data = {
      type: 'cron',
      data: {
        interval: intervalInMinutes, // Use the converted minutes here
        workflows: selectedDAGs,
      },
    };
    onCreateWorkflow(data);
  };

  const toggleDAGSelection = (selectedDag) => {
    if (selectedDAGs.find(dag => dag._id === selectedDag._id)) {
      setSelectedDAGs(selectedDAGs.filter(dag => dag._id !== selectedDag._id));
      setUnselectedDAGs(prevUnselectedDAGs => [...prevUnselectedDAGs, selectedDag]);
    } else {
      setUnselectedDAGs(unselectedDAGs.filter(dag => dag._id !== selectedDag._id));
      setSelectedDAGs(prevSelectedDAGs => [...prevSelectedDAGs, selectedDag]);
    }
  };

  return (
    <div className="p-8">
      <div className="mt-4">
        {selectedProvider.key === 'cron' && (
          <div className='p-2 mx-10'>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Interval
            </label>
            <div className='mx-10 p-5'>
            <Slider
              marks={marks}
              tipFormatter={null}
              step={null}
              value={interval}
              onChange={setInterval}
              max={Object.keys(marks).length - 1}
            />
            </div>
            <p className="mt-5 mb-1 text-sm font-medium">Selected Workflows:</p>
            <div className="overflow-x-auto whitespace-nowrap">
             {selectedDAGs.length > 0 ? (
                selectedDAGs.map(dag => (
                  <div key={dag._id} className="inline-block p-2 m-1 border rounded bg-blue-100 w-40 overflow-hidden text-ellipsis whitespace-nowrap hover:bg-gray-100" onClick={() => toggleDAGSelection(dag)}>
                    {dag.name}
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No selected workflows</p>
              )}
            </div>

            <p className="mt-4 mb-1 col-span-2 text-sm font-medium">Workflows:</p>
            <div className="h-64 overflow-y-auto grid grid-cols-2 gap-4">
              {unselectedDAGs.length > 0 ? (
                unselectedDAGs.map(dag => (
                  <div key={dag._id} className="p-4 border rounded cursor-pointer hover:bg-gray-100" onClick={() => toggleDAGSelection(dag)}>
                    {dag.name}
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No workflows</p>
              )}
            </div>
            <div className='inline-flex items-center justify-center mt-5 w-full'>
            <button
              className={`mt-4 inline-flex items-center justify-center py-2 px-4 border border-1 text-sm font-medium rounded-md text-gray-800 ${
                selectedDAGs.length === 0 ? "opacity-25 cursor-not-allowed" : "hover:bg-gray-100 transition"
              }`}
              onClick={handleSubmit}>
                Schedule Workflows
            </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ConfigScreen = ({ workflowSchedulesConfig, onSaveAndTest, testLoading }) => {
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  
    useEffect(() => {
    if (workflowSchedulesConfig && workflowSchedulesConfig.fivetran) {
      const { API_KEY, API_SECRET } = workflowSchedulesConfig.fivetran;
      setApiKey(API_KEY || '');
      setApiSecret(API_SECRET || '');
    }
  }, [workflowSchedulesConfig]); 

  return (
      <Spin className='w-auto' spinning={testLoading}>
    <div className="flex h-full divide-x divide-gray-200">
      <div className="w-1/2 p-8 mr-5">
        <div className="mb-6">
          <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">API Key</label>
          <input
            type="text"
            id="apiKey"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-1"
            placeholder="Enter your API key"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="apiSecret" className="block text-sm font-medium text-gray-700">API Secret</label>
          <input
            type="text"
            id="apiSecret"
            value={apiSecret}
            onChange={(e) => setApiSecret(e.target.value)}
            className="mt-1 focus:ring-gray-500 focus:border-gray-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md p-1"
            placeholder="Enter your API secret"
          />
        </div>
        <button
          className="inline-flex items-center justify-center py-2 px-4 border border-1 shadow-sm text-sm font-medium rounded-md text-black bg-indigo-600 hover:bg-indigo-700 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onSaveAndTest(apiKey, apiSecret)}
        >
          Save & Test
        </button>
      </div>
      {/* Right Side - Documentation */}
      <div className="w-1/2 p-8">
        <h2 className="text-lg font-medium text-gray-900 ml-5">Retrieving Fivetran Credentials</h2>
     <p className="mt-2 text-sm text-gray-700 ml-5">
      We use Fivetrans REST API to schedule workflows with webhooks. Fivetran connects via webhook, and pushes whenever a connector is synced so we can execute the downstream pipelines</p>   
      <p className="mt-2 text-sm text-gray-700 ml-5">
To generate an API key and secret in Fivetran, navigate to your dashboard, select "API Key" under your user name, and click "Generate API key." Note down the key and secret as they won't be shown again.
      </p>
      </div>
    </div>
    </Spin>

  );
};


const CreateWorkflowSchedulesModal = ({ isOpen, onClose }) => {
    const { workflowSchedules, getWorkflowSchedulesConfig, saveTestFivetranSchedulesConfig, workflowSchedulesConfig, addWorkflowSchedule, getWorkflowSchedulesFivetranConnectors, getWorkflowSchedulesFivetranGroups  } = useContext(WorkflowSchedulesContext);

    const {
        dags,
        getDags,
    } = useContext(TableGraphContext);
    
    const [hoveredProvider, setHoveredProvider] = useState(null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [action, setAction] = useState(null);
    const [testLoading, setTestLoading] = useState(false);

    const [workflows, setWorkflows] = useState([]);

    useEffect(() => {
        if (isOpen && Object.keys(workflowSchedulesConfig).length === 0) {
            getWorkflowSchedulesConfig();
        }
            setTestLoading(false)
    }, [isOpen, workflowSchedulesConfig]);
      console.log(workflowSchedulesConfig)

  const providers = [
    { name: 'Fivetran Web Hook', logo: <FivetranWebHookLogo />, key: 'fivetran', disabled: false },
    { name: 'Artemis Cron', logo: <CronIcon />, key: 'cron', disabled: false },
    { name: 'Fivetran', logo: <FivetranIcon />, key: 'fivetran_deployment', disabled: true },
    { name: 'Airbyte', logo: <AirbyteIcon />, key: 'airbyte', disabled: true },
    { name: 'Airflow', logo: <AirflowIcon />, key: 'airflow', disabled: true },
  ];

    const handleConfigClick = (provider) => {
        setSelectedProvider(provider);
        setAction('config');
    };

    const handleCreateClick = async (provider) => {
        setSelectedProvider(provider);

        await getDags();

        setAction('create');
        console.log(provider)
    };    
    
    const scheduleWorkflow = async (data) => {
        await addWorkflowSchedule(data);
        console.log(data)
        onClose();
    }

    const isUnconfigured = (providerKey) => {
        if (!workflowSchedulesConfig) return true;
        const config = workflowSchedulesConfig[providerKey];
        return typeof config === 'undefined' || config === null || config.validated === false;
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-[80%] h-[80%] overflow-auto p-5 rounded-lg">
                <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">Create New Workflow Schedule</h2>
                            <div>
                                <button className="px-4 py-2 rounded text-black" onClick={()=>{
                                    setSelectedProvider(null);
                                    onClose();
                                }}>Cancel</button>
                            </div>
                </div>


                {selectedProvider && action === 'config' && selectedProvider.key === 'fivetran' ? (
    <ConfigScreen  
    workflowSchedulesConfig = {workflowSchedulesConfig}
    onSaveAndTest={async (apiKey, apiSecret) => {
        setTestLoading(true);
        await saveTestFivetranSchedulesConfig(apiKey, apiSecret)
        setTestLoading(false);
        setAction(null)
    }} testLoading={testLoading}/>
) : selectedProvider && action === 'create' ? (
        <CreateScreen
            getWorkflowSchedulesFivetranConnectors={getWorkflowSchedulesFivetranConnectors}
            getWorkflowSchedulesFivetranGroups={getWorkflowSchedulesFivetranGroups}
            selectedProvider={selectedProvider}
            dags={dags}
            onCreateWorkflow={scheduleWorkflow}/>
                ) : (                    <>
                                                <div className="grid grid-cols-3 gap-4">
                           {providers.map((provider, index) => (
                                <div
                                    key={index}
                                    className="border border-gray-300 rounded-lg p-4 flex flex-col items-center relative"
                                    onMouseEnter={() => setHoveredProvider(provider.name)}
                                    onMouseLeave={() => setHoveredProvider(null)}
                                >
                                    <div className="mb-2">{provider.logo}</div>
                                    <h3 className="text-md font-semibold">{provider.name}</h3>
            <div className="absolute top-2 right-2 flex flex-col items-center">
                {provider.disabled && (
                    <div className="flex container items-center mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                        </svg>
                        <span className="ml-1 text-xs">Coming Soon</span>
                    </div>
                )}
                {workflowSchedulesConfig && isUnconfigured(provider.key) && (
                    <div className="flex container items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                            />
                        </svg>
                        <span className="ml-1 text-xs">Unconfigured</span>
                    </div>
                )}
            </div>
                                                {hoveredProvider === provider.name ? (
  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="flex">
      {isUnconfigured(provider.key) ? (
        <button
          className={`btn border border-1 rounded p-1 w-full text-white py-2 ${
            provider.disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 transition"
          }`}
          onClick={() => handleConfigClick(provider)}
          disabled={provider.disabled}
        >
          Config
        </button>
      ) : (
        <button
          className={`btn border border-1 rounded p-1 w-full text-white py-2 ${
            provider.disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700 transition"
          }`}
          onClick={() => handleCreateClick(provider)}
          disabled={provider.disabled}
        >
          Create
        </button>
      )}
    </div>
  </div>
) : null}
                                </div>
                            ))}                        
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};


const CronLogo = () => <CronIcon/>; 

const FivetranWebHookLogo = () => <FivetranIcon />;
const FivetranLogo = () => <FivetranIcon />;
const AirbyteLogo = () => <AirbyteIcon />;
const AirflowLogo = () => <AirflowIcon />;


export default CreateWorkflowSchedulesModal;

