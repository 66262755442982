import React, { useContext, useState, useEffect } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Checkbox, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "../Sidebars/styles.scss"
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorContext } from '../../../context/EditorProvider';
import { EditorView } from "@codemirror/view";
import CustomSchema from '../../../components/Workflows/CustomSchema';
import CustomMaterialization from '../../../components/Workflows/Materialization';
import ErrorModelSidebar from './ErrorModelSidebar';

export default () => {
  const {
    selectedNode,
    getSelectedNode,
    updateSelectedNode,
    handleNodeRename,
    setSelectedNodeEdited,
    editedNodes,
    setSelectedNode,
    getDbtModelFile,

  } = useContext(TableGraphContext);

  const {
    openDbtFileFromNode
  } = useContext(EditorContext)

  const [editedName, setEditedName] = useState()

  const [loading, setLoading] = useState(false);

  const [dbtModelContent, setDbtModelContent] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  

  useEffect(() => {
    const allSelected = selectedNode?.data?.columns?.every(col =>
      selectedNode.data.output_columns.includes(col)
    );
    const someSelected = selectedNode?.data?.columns?.some(col =>
      selectedNode.data.output_columns.includes(col)
    );
    setSelectAll(allSelected);
    setIndeterminate(!allSelected && someSelected);
  }, [selectedNode.data.columns, selectedNode.data.output_columns]);


  useEffect(() => {
    const fetchDBTModelFile = async () => {
      if (selectedNode && !selectedNode.dbt && selectedNode.data && selectedNode.data.type === 'model') {
        setLoading(true);
        try {
          let node = getSelectedNode()
          const response = await getDbtModelFile(node.data.model_id); 
          if (response) {
            setDbtModelContent(response.data); 
          }
        } catch (error) {
          console.error("Failed to fetch DBT model file:", error);
          setDbtModelContent(''); 
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDBTModelFile();
  }, [selectedNode, selectedNode.data?.model_id]);




  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  
  if (loading) {
    return (
      <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }



  const handleSelectAllChange = (e) => {
    let updatedOutputColumns;
    if (e.target.checked) {
      updatedOutputColumns = [...selectedNode.data.columns]; 
    } else {
      updatedOutputColumns = [];
    }
    setSelectedNode({
      ...selectedNode,
      data: {
        ...selectedNode.data,
        output_columns: updatedOutputColumns,
      },
    });
    setSelectedNodeEdited(true);
  };

  const editName = e => {
    setEditedName(e.target.value);
  };

  const saveNameEdit = async () => {
    const old_name = selectedNode.data.model_name
    let response = await handleNodeRename(editedName, old_name)
    if (response && response.status === 'error') {
      setEditedName(old_name)
    } else {
      setEditedName(editedName)
    }
  }

  const cancelNameEdit = () => {
    setEditedName()
  }

  const openModel = () => {
    openDbtFileFromNode(selectedNode)
  }


  const filteredColumns = selectedNode && selectedNode.data && selectedNode.data.columns
  ? selectedNode.data.columns.filter(column => 
      column.toLowerCase().includes(searchInput.toLowerCase()))
  : [];

  const Title = () => {
      return <div className="flex flex-col mb-[20px]">
      <div className="flex justify-center">
        <div className='w-full relative mb-[0px]'>
          <div className='mb-[0px] font-[500]'>
            Model Name
          </div>
          <div className='flex mt-[0px] relative'>
            <input className="border-[1px] text-ellipsis border-gray-200 rounded-lg py-[2px] px-[5px] flex-1 h-[40px] w-[full] pr-[50px] font-[400] text-[14px] pl-[20px] relative top-[8px] overflow-hidden" value={editedName != null ? editedName : selectedNode.data.model_name ?? ''} onChange={(e) => editName(e)}/>
            <div className='relative'>
            <div className={editedName != null ? 'absolute right-[5px] top-[17px] flex transition-all transition-[100ms] ease-in bg-white' : 'absolute right-[5px] top-[17px] flex hidden'}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={editedName ? "w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" : "w-[22px] h-[22px] p-[1px] text-white"} onClick={() => { if (editedName) saveNameEdit() }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" onClick={() => cancelNameEdit()}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  };



  if (selectedNode && selectedNode.data && selectedNode.data.type == 'seed') {
    return (
      <div className="tablegraphs-sider-style relative flex flex-col">
        <div>{Title()}</div>
      </div>
    )
  }


  if (selectedNode && selectedNode.data && selectedNode.data.status && selectedNode.data.status.code == 'error') {
    return(
      <div>
        <ErrorModelSidebar/>
      </div>
  )
  }


  if (selectedNode && selectedNode.data && selectedNode.data.type == 'model') {
    return (
      <div className="tablegraphs-sider-style relative flex flex-col">
        <div>{Title()}</div>
        {
          <div className="mt-[10px] rounded-md grow overflow-y-auto mb-[80px] pb-[0px] bg-[#f0f0f0]">
          <CodeMirror
                  id="sql-code"
                  className='relative'
                  width="100%"
                  extensions={[
                    sql(),
                    EditorView.lineWrapping,
                    EditorView.theme({
                      ".cm-activeLineGutter": {
                        color: 'black',
                        fontWeight: '500',
                        backgroundColor: 'transparent',
                      },
                      ".cm-content": {
                        paddingRight: '0px !important'
                      },
                      ".cm-scroller": {
                        paddingBottom: '30px !important',
                        backgroundColor: '#f0f0f0'
                      },
                      ".cm-line.cm-activeLine": {
                        backgroundColor: '#e8e8e8',
                        borderRadius: '4px',
                      },
                      ".cm-gutters": {
                        backgroundColor: "#f0f0f0",
                      },
                    }),
                  ]}
                  value={selectedNode.dbt ? selectedNode.dbt : dbtModelContent}
                  editable={false}
                >
              </CodeMirror>
              <button onClick={() => openModel()} className='absolute bottom-[87px] right-[10px] z-50 bg-white rounded-md px-[10px] py-[3px] border-gray-300 border-[1px] rounded-md text-[12px] hover:bg-gray-100'> Edit </button>
            </div>
          }
      </div>
    )
  }


  return (
    selectedNode &&
    selectedNode.data && (
      <div className="tablegraphs-sider-style relative ">

        <div>{Title()}</div>
        <CustomSchema node={selectedNode}/>
        <CustomMaterialization node={selectedNode}/>
        <div className="font-[500] mt-[20px] text-black">Selected Columns</div>
        <div>
          <input
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
              className="w-full my-2 border border-gray-300 rounded-md p-2"
            />
        </div>
        <div className="test pb-10">
        {!searchInput &&
        <Checkbox
          style={{ transition: 'none' }}
          indeterminate={indeterminate}
          motion={null}
          onChange={handleSelectAllChange}
          className="no-animation-checkbox"
          checked={selectAll}
        >
          Select All
        </Checkbox>}
        {filteredColumns&&
          filteredColumns.map((column, i) => (
              <div key={i}> 
                  <Checkbox
                      style={{ transition: 'none' }}
                      className='no-animation-checkbox'
                      checked={selectedNode.data.output_columns.includes(column)}
                      onChange={e => {
                          const isChecked = e.target.checked;
                          let updatedOutputColumns;
                          if (isChecked) {
                              updatedOutputColumns = selectedNode.data.output_columns.includes(column)
                                  ? [...selectedNode.data.output_columns]
                                  : [...selectedNode.data.output_columns, column];
                          } else {
                              updatedOutputColumns = selectedNode.data.output_columns.filter(c => c !== column);
                          }

                          setSelectedNode({
                              ...selectedNode,
                              data: {
                                  ...selectedNode.data,
                                  output_columns: updatedOutputColumns,
                              },
                          });
                          setSelectedNodeEdited(true); 
                      }}
                  >
                      {column}
                  </Checkbox>
              </div>
          ))
      }
        </div>
      </div>
    )
  )
};
