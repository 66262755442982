import { React } from 'react';

const EditorResultTabs = ({ type, results, activeResultTab, setActiveResultTab }) => {

    const renderTabContent = (resultTab) => {

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            const strHours = hours < 10 ? `0${hours}` : hours;
            const strMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const strSeconds = seconds < 10 ? `0${seconds}` : seconds;

            return `${strHours}:${strMinutes}:${strSeconds} ${ampm}`;
        }

        switch(resultTab.type) {
            case 'sql_execute':
                if (!resultTab.log) {
                    return (
                        <div className='flex p-1'>
                            <span className='pl-1'>Executing query...</span>
                        </div>
                    )
                }

                const sql_created_at = new Date(resultTab.log.created_at).toLocaleString().replace(',', '');

                return (
                    <div className='flex p-1 justify-between'>
                        <span className='pl-1'>{formatDate(sql_created_at)}</span>
                    </div>
                );
            case 'dbt_execute':
                if (!resultTab.log) {
                    return (
                        <div className='flex justify-between p-1'>
                            <span className='pl-2'>Executing model...</span>
                        </div>
                    )
                }

                const dbt_created_at = new Date(resultTab.log.created_at).toLocaleString().replace(',', '')

                return (
                    <div className='flex p-1 justify-between'>
                        <span className='pl-1'>{formatDate(dbt_created_at)}</span>
                    </div>
                )
            case 'dbt_preview':
                if (!resultTab.result && !resultTab.error) {
                    console.log(resultTab)
                    return (
                        <div className='flex justify-between p-1'>
                            <span className='pl-2'>Fetching Preview...</span>
                        </div>
                    )
                }

                return (
                    <div className='flex p-1 justify-between'>
                        <span className='pl-1'>Preview</span>
                    </div>
                )
            case 'copilot':
                return (
                    <div className='flex p-1'>
                        <span className='pl-1'>Copilot</span>
                    </div>
                )
            default:
                return (
                    <div className='flex justify-between p-1'>
                        <span className='pl-2'>default tab</span>
                    </div>
                );
        }
    }

    return (
        <div className={`flex h-8 ${ Object.keys(results).length === 0 ? 'bg-white' : 'border-b bg-[#f7f7f7] ' }`}>
            <div className='no-scrollbar flex w-full overflow-y-hidden overflow-x-auto'>
            {Object.entries(results).map(([resultId, resultTab]) => (
                <div 
                    key={resultId} 
                    className={`border-r box-border border-l-2 border-r-2 max-w-[250px] min-w-[100px] w-auto flex-shrink flex-grow text-center ${
                        activeResultTab === resultId
                            ? 'bg-white border-l-2 border-r-2'
                            : 'border-transparent'
                    }`}
                    onClick={() => {
                        setActiveResultTab(resultId);
                    }}
                >
                    {renderTabContent(resultTab)}
                </div>
            ))}
            </div>
        </div>
    );
};

export default EditorResultTabs;
