import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LetoClient from './LetoClient';

import { TableGraphContext } from './TableGraphProvider';
import { ArtyAIContext } from './ArtyAIProvider';

export const ActivityTracking = ({ children }) => {
    const { pathname } = useLocation();
    const { dags } = useContext(TableGraphContext)
    const { chartBoards } = useContext(ArtyAIContext)



    useEffect(() => {
        console.log(pathname)
        if (/tablegraphs\//.test(pathname) && dags) {
            let id = pathname.split("tablegraphs/")[1]
            for (let dag of dags) {
                if (dag._id === id) {
                    LetoClient.trackUserActivity(pathname, 'workflows', dag.name)
                    break
                }
            }

        }
        else if (/analytics\/dashboard\//.test(pathname) && chartBoards) {
            let id = pathname.split("analytics/dashboard/")[1]
            for (let chart of chartBoards) {
                if (chart.chart_board_id === id) {
                    LetoClient.trackUserActivity(pathname, 'analytics', chart.name)
                    break
                }
            }
        }
        // else if (/)
        // LetoClient.trackUserActivity()
      
    }, [pathname])
    return (
        <>
            {children}
        </>
  );
};
