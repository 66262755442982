import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@propelauth/react';

// const TESTING_ENVIRONMENT = 'https://8009503.propelauthtest.com';
// const STAGING_ENVIRENTMENT = "https://auth.app-staging.artemisdata.io";
// const PRODUCTION_ENVIRONMENT = 'https://auth.artemisdata.io';
// const TESTING_SERVER_URL = 'http://127.0.0.1:8000';
// const PRODUCTION_SERVER_URL = 'https://api.artemisdata.io';

function getAuthUrl() {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging')
    return 'https://auth.app-staging.artemisdata.io';
  else if (process.env.REACT_APP_ENVIRONMENT === 'production')
    return 'https://auth.artemisdata.io';

  return 'https://8009503.propelauthtest.com';
}

function getServerUrl() {
  // return 'https://api.artemisdata.io/api/v0';

  if (process.env.REACT_APP_ENVIRONMENT === 'staging')
    return 'https://api-staging.artemisdata.io/api/v0';

  else if (process.env.REACT_APP_ENVIRONMENT === 'production')
    return 'https://api.artemisdata.io/api/v0'; 
  
  return 'http://127.0.0.1:8000/api/v0';
}

export const CURRENT_SERVER_URL = getServerUrl();

export const CURRENT_ENVIRONMENT = getAuthUrl();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider authUrl={CURRENT_ENVIRONMENT}>
    {/* <React.StrictMode> */}
    <Router>
      <App />
    </Router>
    {/* </React.StrictMode> */}
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
