
import React, { useContext, useState, useEffect } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import {Spin } from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { LoadingOutlined} from '@ant-design/icons';
import { EditorView } from "@codemirror/view";
import { EditorContext } from '../../../context/EditorProvider';

export default () => {
  const {
    nodes,
    selectedNode,
    getDbtModelFile,
    retryNodeParse,
    retryParseLoading
  } = useContext(TableGraphContext);

  const {
    openDbtFileFromNode
  } = useContext(EditorContext)


  const [loading, setLoading] = useState(false);
  const [dbtModelContent, setDbtModelContent] = useState('');

  useEffect(() => {
    const fetchDBTModelFile = async () => {
      if (selectedNode && !selectedNode.dbt && selectedNode.data) {
        setLoading(true);
        try {
          const response = await getDbtModelFile(selectedNode.data.model_id); // Assuming `selectedNode.id` is needed for the API call
          if (response?.data) {
            setDbtModelContent(response.data); // Assuming the response is the content you need
          }
        } catch (error) {
          console.error("Failed to fetch DBT model file:", error);
          setDbtModelContent(''); // Reset content on error
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDBTModelFile();
  }, [selectedNode, selectedNode.data?.model_id]); 


  const openModel = () => {
    openDbtFileFromNode(nodes.find(item => item.id === selectedNode.id))
  }

  const retry = () => {
    retryNodeParse(selectedNode)
  }


  useEffect(() => {
    setLoading(retryParseLoading)
  }, [retryParseLoading])


  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  
  if (loading) {
    return (
      <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  if (!selectedNode.data.status.errors) {
    return (
        <div className="bg-[#FFEFEE] border-2 border-[#FFA09C] rounded-sm p-2 mt-2 overflow-hidden">
          <strong className="text-[#C8141F]">Unknown Error</strong><br/>
        </div>
    )
  }

  return (
     <div>
      {Object.entries(selectedNode.data.status.errors).map(([error_type, error]) => (
        <div className="bg-[#FFEFEE] border-2 border-[#FFA09C] rounded-sm p-2 mt-2 overflow-hidden">
          <strong className="text-[#C8141F]">{error.error_title}</strong><br/>
          <div className="text-[#C8141F]">{error.error_message}</div>
          {error_type === 'generic_parse' && (
            <div className="flex justify-center mt-2">
              <button onClick={() => retry()} className='bg-white mx-[5px] px-[10px] py-[3px] border-gray-300 border-[1px] rounded-md text-[12px] hover:bg-gray-100'> Recompile </button>
            </div>
          )}
        </div>
      ))}
      {Object.keys(selectedNode.data.status.errors).every(error_type => error_type !== 'file_not_found') && (
         <div className="mt-[10px] rounded-md grow overflow-y-auto mb-[80px] pb-[0px] bg-[#f0f0f0]">
          <CodeMirror
                  id="sql-code"
                  className='relative'
                  width="100%"
                  extensions={[
                    sql(),
                    EditorView.lineWrapping,
                    EditorView.theme({
                      ".cm-activeLineGutter": {
                        color: 'black',
                        fontWeight: '500',
                        backgroundColor: 'transparent',
                      },
                      ".cm-content": {
                        paddingRight: '0px !important'
                      },
                      ".cm-scroller": {
                        paddingBottom: '30px !important',
                        backgroundColor: '#f0f0f0'
                      },
                      ".cm-line.cm-activeLine": {
                        backgroundColor: '#e8e8e8',
                        borderRadius: '4px',
                      },
                      ".cm-gutters": {
                        backgroundColor: "#f0f0f0",
                      },
                    }),
                  ]}
                  value={selectedNode.dbt ? selectedNode.dbt : dbtModelContent}
                  editable={false}
                >
              </CodeMirror>
              <button onClick={() => openModel()} className='absolute bottom-[10px] right-[10px] z-50 bg-white rounded-md px-[10px] py-[3px] border-gray-300 border-[1px] rounded-md text-[12px] hover:bg-gray-100'> Edit </button>
            </div>
      )}
     </div>
  );
};
