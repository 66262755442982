import { React, useEffect, useState, useContext, useMemo } from 'react';
import { EditorContext } from '../../../context/EditorProvider';
import { ModelEditorContext } from '../../../context/ModelEditorProvider';
import { SqlEditorContext } from '../../../context/SqlEditorProvider';
import EditorResultTabs from './EditorResultTabs';
import SqlResults from './SqlResults'; 
import DbtResults from './DbtResults'; 
import CopilotResults from './CopilotResults';

const EditorResults = ({ tab }) => {
    const { getEditorResults } = useContext(EditorContext)
    const { sqlResultAdded } = useContext(SqlEditorContext)
    const { dbtResultAdded } = useContext(ModelEditorContext)
    const [activeResultTab, setActiveResultTab] = useState()

    const results = useMemo(() => getEditorResults(tab), [tab, getEditorResults]);

    useEffect(() => {
        if (results && Object.keys(results).length) {
            setActiveResultTab(Object.keys(results)[0])
        }
    }, [tab, sqlResultAdded, dbtResultAdded])
    
    const renderResults = () => {
        if ( results[activeResultTab] && results[activeResultTab].type === 'copilot') {
            return <CopilotResults tab={tab} results={results[activeResultTab]} />;
        } else if (tab.type === 'sql') {
            return <SqlResults tab={tab} results={results[activeResultTab]} />;
        } else if (tab.type === 'dbt') {
            return <DbtResults tab={tab} results={results[activeResultTab]} />;
        } else {
            return <pre>{JSON.stringify(results[activeResultTab], null, 2)}</pre>;
        }
    }
    return (
        results && Object.keys(results).length ?
            <div className='h-full'>
                <EditorResultTabs type={tab.type} results={results} activeResultTab={activeResultTab} setActiveResultTab={setActiveResultTab}/>
                <div className='editor-page-height'>
                    {renderResults()} 
                </div>
            </div>
        :
        
            <div className='w-full h-full text-center items-center pt-[10vh]'>
            </div>
    );
};

export default EditorResults;

