import React, { useState, useMemo, useRef, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  Background,
  MarkerType,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { TableGraphContext } from '../../context/TableGraphProvider';
import GraphSidebar from './Sidebar';
import Sidebar from '../../components/Sidebar';
import Console from '../../components/Console';
import Page from '../../components/Page';
import Node from './Node';
import Toolbox from './Toolbox';
import DirectedEdge from './DirectedEdge';
import ChatPage from './ChatPage';
import './index.css';
import WorkflowNavBar from './WorkflowNavbar';
import './styles.scss'
import TableGraphModal from './TableGraphModal';
import {DownOutlined} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import CopilotNode from './CopilotNode';

const DnDFlow = () => {
  const { dag_id } = useParams();
  const [fetched, setFetched] = useState(false);
  const [draggingNode, setDraggingNode] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ parents: [], n: {}, edgeList: [], params: {}, showModelLogicControls: false});
  const [loadingText, setLoadingText] = useState("");
  const [sidebarWidth, setSidebarWidth] = useState('330px'); 
  const {
    setSelectedEdge,
    setSelectedNode,
    selectedNode,
    setEditedNodes,
    editedNodes,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    onDeleteEdge,
    getSelectedNode,
    isChat,
    getParents,
    getSuggestedJoinFromNodes,
    setSelectedDag,
    interfaceLog,
    saveDAGSpecific,
    setInterfaceLog,
    openSidebar,
    setOpenSidebar,
    openConsole,
    setOpenConsole,
    setForceDagSave,
    initializeAggregateNode,
    setReactFlowInstance,
    setReactFlowSize,
    showNodeOutline,
    setShowNodeOutline,
    setDraggingNodeOutline,
    hoveredEdges,
    setHoveredEdges,
    copilotNodes,
    copilotEdges,
    onCopilotNodesChange,
    deleteNode
  } = useContext(TableGraphContext);
  const [sidebarData, setSidebarData] = useState({})
  const reactFlowWrapper = useRef(null)
  const connectingNodeId = useRef(null); 
  const { screenToFlowPosition, getZoom } = useReactFlow();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  useEffect(() => { // make it so that if you press the esc key while shownodeoutline is true, it will set it to false
    
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowNodeOutline(false);
        setDraggingNodeOutline(false);
        connectingNodeId.current = null;
      }
    };

    const handleMouseMove = (event) => {
        setCursorPosition({ x: event.clientX, y: event.clientY });
    };

    if(showNodeOutline)
    {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('keydown', handleKeyDown);

    }
    else 
    {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);

    }

    return () => { 
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);

    };
}, [showNodeOutline]);

  const onConnectStart = useCallback((event, { nodeId }) => {
    connectingNodeId.current = nodeId;
    setShowNodeOutline(true);
    setDraggingNodeOutline(true)
  }, []);
  
  const handleConnectEnd = (event) => {
      setShowNodeOutline(false);
      setDraggingNodeOutline(false)
      if (!connectingNodeId.current) return;
      const targetIsPane = event.target.classList.contains('react-flow__pane');
      let node_id = Date.now().toString()

      if (targetIsPane) {
        const id = node_id 
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }), 
          type: 'logic',
          data: { type:'logic', columns: [], model_id: null, last_executed : null},
          origin: [0.5, 0.0],
        };

        const edge_id  = "reactflow__edge-"+connectingNodeId.current+"b-"+id+"a"
        const newEdge = { id: edge_id, source: connectingNodeId.current, target: id, type: "smoothstep"}
       
        const nodeList = [...nodes, newNode];
        setNodes((nds) => [...nds, newNode]);
      
        const edgeList = [...edges, newEdge];
        setEdges((eds) => [...eds, newEdge]);
        
        const saveAndInit = async () => {
          const response = await saveDAGSpecific(nodeList, edgeList);
          if(response.status === "success"){
            const parent = nodes.find(node => node.id === connectingNodeId.current);  
            if(parent){
              initializeAggregateNode(parent, id);
            }
          }
        }
        
        saveAndInit();
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      if (!fetched) {
        await setSelectedDag(dag_id);
        setFetched(true);
      }
    };
    setOpenSidebar(false);
    fetchData();
    setSelectedNode(null);
  }, [dag_id, fetched]);

  useEffect(() => {
    const messages = ["Parsing Model Files", "Compiling DBT"];
    let currentMessageIndex = -1;
    const timeoutId = setTimeout(() => {
      setLoadingText("Loading Workflow")
      const intervalId = setInterval(() => {
        if(currentMessageIndex == messages.length - 1){
        } else{
          currentMessageIndex++;
        }
      
        if (currentMessageIndex >= messages.length) {
          currentMessageIndex = 0;
        }

        setLoadingText(messages[currentMessageIndex]);
      }, 2000);
      return () => clearInterval(intervalId);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const calculateDivSize = () => {
      if (reactFlowWrapper.current) {
        const { clientWidth, clientHeight } = reactFlowWrapper.current;
        setReactFlowSize({ width: clientWidth, height: clientHeight });
      }
    };
    calculateDivSize(); // Calculate initial size
    const handleResize = () => {
      calculateDivSize(); // Recalculate size on window resize
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener
    };
  }, [reactFlowWrapper.current]);

  const handleNodeDelete = () => {
    setForceDagSave(true)
    deleteNode(selectedNode?.id)
    setSelectedNode()
  }

  

  const onConnect = async (params) => {
      params['type'] = 'smoothstep';  // smoo
      params['markerEnd'] = {
        type: MarkerType.ArrowClosed,
        height: '20px',
        width: '20px',
      };
      connectingNodeId.current = null;
      let _id = uuidv4()
      let sourceNode = nodes.find(node => node.id === params.source);
      if ((sourceNode && sourceNode?.data?.status?.code === 'error') || !sourceNode.data.model_id) {
          setInterfaceLog(prevInterfaceLog => [
              ...prevInterfaceLog,
              { id: _id, message: 'Initializing node', status: 'Error', console_log: sourceNode?.data?.status?.error_message || 'Uninitialized source node' }
          ]);
          return;
      }
      let parents = getParents(params.target);
      parents.push(getSelectedNode(params.source));
      let seenId = new Set();
      for(const parent of parents){
        if(seenId.has(parent.data.model_id)){
          setInterfaceLog(prevInterfaceLog => [
            ...prevInterfaceLog,
            { id: _id, message: 'Retrieving suggested join', status: 'Error', console_log: 'Cannot join identical nodes' }
           ]);
           return
        }
        seenId.add(parent.data.model_id)
      }

      let edgeList = addEdge(params, edges)
      setEdges(edgeList)

      const targetNode = nodes.find(node => node.id === params.target);
      if(targetNode && targetNode.type === 'logic'){
        const targetParents = getParents(targetNode.id);
        const sourceExistsInParents = targetParents.some(parent => parent.id === params.source);
        if(!sourceExistsInParents){
          targetParents.push(getSelectedNode(params.source));
        }

        if(sourceExistsInParents){
          setInterfaceLog(prevInterfaceLog => [
            ...prevInterfaceLog,
            { id: _id, message: 'Retrieving suggested join', status: 'Error', console_log: 'Cannot join identical nodes' }
          ]);
          return;
        }
        if (targetParents.length > 1 || targetNode.data.type === "model_logic") {
          setModalData({ parents: targetParents, n: targetNode, edgeList, params, showModelLogicControls: targetNode.data.type === "model_logic" });
          openModal();
        } else {
            await initializeAggregateNode(targetParents[0], targetNode.id, edgeList);
        }

      }
  }

  const openModal = () => setIsModalOpen(true);

  const handleCancel = () => {
    const { parents, n, edgeList, params } = modalData;
    const edge_to_delete_id = "reactflow__edge-" + params.source + "b-" + n.id +"a";
    onDeleteEdge({id: edge_to_delete_id});
    setIsModalOpen(false); 
  };


  const handleAccept = async (overwrite) => {
    const { parents, n, edgeList } = modalData;
    setEditedNodes(prev => {
      const newState = { ...prev };
      delete newState[n.id];
      return newState;
    });   
    await getSuggestedJoinFromNodes(parents, n.id, edgeList, overwrite);
    setIsModalOpen(false); 

  };

  const handleNodeDragEnd = () => {
    if (draggingNode) {
      setDraggingNode(false)
      setForceDagSave(true)
    }
  }
 
  const edgeTypes = useMemo(
    () => ({
      smoothstep: DirectedEdge, // all existing dags have edgetype 'smoothstep'. We keep it like this so that all dags will have the custom edge type without needing to migrate mongo data
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      custom: Node,
      source: Node,
      logic: Node,
      suggested: CopilotNode,
    }),
    []
  );

  const onEdgeMouseLeave = (event, edge) => {
    const edgeId = edge.id;
    setHoveredEdges((prevElements) => {
      const newElements = new Set(prevElements);
      newElements.delete(edgeId);
      return newElements;
    });
  };

  const onEdgeMouseEnter = (event, edge) => {
    const edgeId = edge.id;
    setHoveredEdges((prevElements) => {
      const newElements = new Set(prevElements);
      newElements.add(edgeId);
      return newElements;
    });
  };

  //TODO: FIX
  const onEdgeClick = useCallback((event, edge) => {
    setSelectedEdge(edge);
  }, []);


  const onNodeClick = async (event, node) => {
    if(!openSidebar){
      setOpenSidebar(true);
    }
     selectedNode && selectedNode.edited && selectedNode.id !== node.id && await setEditedNodes(prev => ({...prev, [selectedNode.id]: selectedNode}));
    if(node.id in editedNodes){
      setSelectedNode({...editedNodes[node.id]})
    } else if (!selectedNode) {
      setSelectedNode(node);
    } else if (selectedNode.id !== node.id) {
      setSelectedNode(node);
    }
  };

  const handleNodesChange = (e) => {
    if (nodes && nodes.find(item => item.id == e[0].id)) {
      onNodesChange(e)
    }
    else {
      onCopilotNodesChange(e)
    }
  }

  const handleEdgesChange = (e) => {
    if (edges && edges.find(item => item.id == e[0].id)) {
      onEdgesChange(e)
    }
  }

  //TODO: move chat to different route
  if (isChat) {
    return <ChatPage />;
  }

  return (
    <Page>
      {!fetched && <div className='w-full h-full flex justify-center items-center'>
      <div className='flex flex-col items-center'>
          <div className='w-[50px] h-[50px]'>
            <div className="loading-container">
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
            </div> 
          </div>
          {loadingText ? <div className="absolute loading-text text-xl font-bold ">{loadingText}</div> : ""}
        </div>
      </div>}
      <TableGraphModal  
        isOpen={isModalOpen} 
        onCancel={handleCancel} 
        onAccept={handleAccept} 
        targetNodeModelName={modalData.n.data ? modalData.n.data.model_name : ''}
        showModelLogicControls={modalData.showModelLogicControls}
      />
      <div className='select-none'>
        <WorkflowNavBar dag_id={dag_id} page={"workflow"}/>
      </div>
      {fetched && 
        <Sidebar
          setSidebarData={setSidebarData}
          sidebarContent={<GraphSidebar />}
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          hideButtonWhenClosed={true}
          removePadding={true}
          onSidebarCollapse={() => setSelectedNode()}
          sidebarWidth={sidebarWidth || '330px' }
          setSidebarWidth={setSidebarWidth}
        >
          <div className='flex h-full w-full flex-col'>
            <div className="flex-grow w-full" ref={reactFlowWrapper}>
              <ReactFlow 
                nodes={copilotNodes ? [...nodes, ...copilotNodes] : nodes}
                edges={copilotEdges ? [...edges, ...copilotEdges] : edges}
                onNodesChange={handleNodesChange}
                onEdgesChange={handleEdgesChange}
                onNodesDelete={() => handleNodeDelete()}
                onEdgesDelete={() => setForceDagSave(true)}
                onConnect={(e) => onConnect(e)}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onEdgeClick={onEdgeClick}
                onNodeClick={onNodeClick}
                fitView
                fitViewOptions={{ padding: 0.6 }}
                proOptions={{ hideAttribution: true }}
                onNodeDrag={() => setDraggingNode(true)}
                onNodeDragStop={() => { handleNodeDragEnd() }}
                onConnectStart={onConnectStart}
                onConnectEnd={(e) => handleConnectEnd(e)}
                onEdgeMouseEnter={onEdgeMouseEnter}
                onEdgeMouseLeave={onEdgeMouseLeave}
                onInit={setReactFlowInstance}
              >
                <Controls position='bottom-right' />
                <Background variant="dots" gap={12} size={1} />
                {showNodeOutline && (
                    <div className=' border border-1 rounded bg-gray-50 shadow-xl opacity-50'
                        style={{
                            position: 'absolute',
                            height: 125 * getZoom(),
                            width: 150 * getZoom(),
                            top: cursorPosition.y,
                            left: cursorPosition.x - (openSidebar ? parseInt(sidebarWidth) : 0),
                            pointerEvents: 'none',
                            transform: 'translate(0, -' + (-46.67 * getZoom() +113.33)  +'%)', // This equation calculates the position of the node outline. Trust me bro.
                        }}
                    > </div>
                )}
              </ReactFlow>
            </div>
           {openConsole && <Console openConsole={openConsole} setOpenConsole={setOpenConsole} />}
          </div>
        </Sidebar>}
      </Page>
  );
};

export default DnDFlow;
