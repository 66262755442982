import React, { useState, useEffect } from 'react';
import {
    CheckCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import CodeMirror from '@uiw/react-codemirror';
import { autocompletion, CompletionSource } from '@codemirror/autocomplete';

import { python } from '@codemirror/lang-python';
import FunctionalEditorToolbar from './FunctionalEditorToolbar'

const clients = [
  {
    label: 'BigQueryClient',
    type: 'class',
    methods: [
      'BigQueryClient.query(sql_query)',
      'BigQueryClient(warehouse=warehouse_config)'
    ]
  },
  {
    label: 'SnowflakeClient',
    type: 'class',
    methods: [
      'SnowflakeClient.query(sql_query)',
      'SnowflakeClient(warehouse=warehouse_config)'
    ]
  },
  {
    label: 'DatabricksClient',
    type: 'class',
    methods: [
      'DatabricksClient.query(spark_sql_query)',
      'DatabricksClient(connection=connection_config)'
    ]
  },
  {
    label: 'PostgreSQLClient',
    type: 'class',
    methods: [
      'PostgreSQLClient.query(sql_query)',
      'PostgreSQLClient(db=db_config)'
    ]
  },
  {
    label: 'LLMClient',
    type: 'class',
    methods: [
      'LLMClient.set_system_prompt(prompt)',
      'LLMClient.set_user_prompt(prompt)',
      'LLMClient.get_response()',
      'LLMClient(api_key=api_key, model=model)'
    ]
  },
  {
    label: 'MetabaseClient',
    type: 'class',
    methods: [
      'MetabaseClient.query(sql_query)',
      'MetabaseClient.get_session_token()',
      'MetabaseClient.create_card(card_details)',
      'MetabaseClient.create_dashboard(dashboard_details)',
      'MetabaseClient.get_public_link(item_id)',
      'MetabaseClient(auth=auth_config)'
    ]
  },
  {
    label: 'LookerClient',
    type: 'class',
    methods: [
      'LookerClient.run_query(query_details)',
      'LookerClient.get_dashboard_link(dashboard_id)',
      'LookerClient(api=api_credentials)'
    ]
  },
{
    label: 'warehouse_config',
    type: 'object',
    properties: [
      "{ 'credentials': {...}, 'project_id': 'your_project_id' }",
      "{ 'account': 'your_account', 'username': 'your_username', 'password': 'your_password' }",
      "{ 'workspace_url': 'https://your-databricks-workspace-url', 'personal_access_token': 'your_personal_access_token' }",
      "{ 'host': 'your_host', 'dbname': 'your_database', 'user': 'your_username', 'password': 'your_password' }"
    ]
  },
  {
    label: 'auth_config',
    type: 'object',
    properties: [
      "{ 'api_key': 'your_api_key' }",
      "{ 'username': 'your_username', 'password': 'your_password' }",
      "{ 'client_id': 'your_client_id', 'client_secret': 'your_client_secret' }"
    ]
  },
  {
    label: 'connection_config',
    type: 'object',
    properties: [
      "{ 'workspace_url': 'https://your-databricks-workspace-url', 'personal_access_token': 'your_personal_access_token' }"
    ]
  },
  {
    label: 'db_config',
    type: 'object',
    properties: [
      "{ 'host': 'your_host', 'dbname': 'your_database', 'user': 'your_username', 'password': 'your_password' }"
    ]
  },
  {
    label: 'api_credentials',
    type: 'object',
    properties: [
      "{ 'client_id': 'your_client_id', 'client_secret': 'your_client_secret' }"
    ]
  },
  {
    label: 'card_details',
    type: 'object',
    properties: [
      "{ 'name': 'New Card Name', 'query': 'SELECT * FROM your_table' }"
    ]
  },
  // ... Add other clients here as they become available
];



const clientCompletionSource: CompletionSource = (context) => {
  let word = context.matchBefore(/\w*/);
  if (word.from === word.to) return null;

  let completions = [];

  // Check if the current word matches any client names or template objects
  clients.forEach(client => {
    if (client.label.toLowerCase().startsWith(word.text.toLowerCase())) {
      completions.push({ label: client.label, type: client.type });

      // Add methods or properties based on type
      if (client.type === 'class') {
        client.methods.forEach(method => {
          completions.push({ label: method, type: 'method', info: `Method of ${client.label}` });
        });
      } else if (client.type === 'object') {
        client.properties.forEach(property => {
          completions.push({ label: property, type: 'template', info: `Template for ${client.label}` });
        });
      }
    }
  });

  return {
    from: word.from,
    options: completions,
    span: /^[\w$]*$/
  };
};





const ParameterCapsule = ({ index, parameter, onParameterChange, onDelete}) => {
  const handleNameChange = (e) => {
    onParameterChange(index, 'value', e.target.value);
  };

  const handleDescriptionChange = (e) => {
    onParameterChange(index, 'description', e.target.value);
  };

  return (
    <div className="flex flex-col mr-2">
      <label className="font-medium flex">Name

     <div
        className="relative top-0 left-0 transform  hover:bg-gray-200 text-black font-bold rounded select-none cursor-pointer"
        onClick={() => onDelete(index)}
      >
<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-4 h-4'>
                                                    <path fill="currentColor" strokeWidth="1" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
                                                </svg>
      </div>

    </label>
      <input
        type="text"
        value={parameter.value}
        onChange={handleNameChange}
        className="mb-2 p-1 border border-gray-300 rounded"
        placeholder="Parameter Name"
      />
      <label className="font-medium">Description</label>
      <input
        type="text"
        value={parameter.description}
        onChange={handleDescriptionChange}
        className="p-1 border border-gray-300 rounded"
        placeholder="Description"
      />

    </div>
 
  );
};
const FunctionEditor = ({ selectedNode, updateFunctionInContext, handleUnsavedChanges, generateContextSuggestion }) => {
  const [name, setName] = useState('');
  const [newName, setNewName] = useState('');
  const [initialDagName, setInitialDagName] = useState("");

  const [isEditingName, setIsEditingName] = useState(false);

  const [description, setDescription] = useState('');
  const [parameters, setParameters] = useState([]);
  const [code, setCode] = useState('');
  const [consoleMessages, setConsoleMessages] = useState([]);

  const [loadingSuggestion, setLoadingSuggestion] = useState(false);

  useEffect(() => {
    if (selectedNode) {
      setName(selectedNode.name || 'placeholder');
      setDescription(selectedNode.description || '');
      setParameters(selectedNode.parameters || []);
      setCode(selectedNode.code || '');
    } else {
      setName('placeholder');
      setDescription('');
      setParameters([]);
      setCode('');
    }
  }, [selectedNode]);

  const handleDeleteParameter = (index) => {
    setParameters(parameters.filter((_, i) => i !== index));
  };

  const handleParameterChange = (index, field, newValue) => {
    let newParameters = [...parameters];
    newParameters[index] = { ...newParameters[index], [field]: newValue };
    setParameters(newParameters);
  };

  const addParameter = () => {
    setParameters([...parameters, { value: '', description: '' }]);
  };

  const saveFunctionData = () => {
    if (!name.trim() || !code.trim()) {
      // Handle error
      return;
    }

    const functionData = {
      id: selectedNode.id,
      name,
      description,
      parameters,
      code,
      type: selectedNode.type
    };
    updateFunctionInContext(functionData);
    handleUnsavedChanges();
  };
  const renderConsoleMessages = () => (
    <div className="border border-black p-2 m-5 mt-5 h-[250px] max-h-[250px] overflow-auto">
      {consoleMessages.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </div>
  );

  const fetchSuggestion = async (user_prompt) => {
    setLoadingSuggestion(true)
   let data = {
      'user_prompt': user_prompt,
     'function_data' : {
      name,
      description,
      parameters,
      code,
    } 
   }
    console.log(data)
   let response = await generateContextSuggestion(data)
    const functionData = {
      id: selectedNode.id,
      name: response.name,
      description: response.description,
      parameters: response.parameters,
      code: response.code,
      type: selectedNode.type
    };
    updateFunctionInContext(functionData);

    //TODO: clean this up so updates propogate down from context above, and this isnt required
    setName(response.name || '');
    setDescription(response.description || '');
    setParameters(response.parameters || []);
    setCode(response.code || '');
    setLoadingSuggestion(false)
    handleUnsavedChanges();

  }

    const handleEdit = () => {
            setNewName(name);
            setInitialDagName(name);
            setIsEditingName(true);
    };

    const handleKeyDown = async (e) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    const handleSave = async (e = null) => {
        if (isEditingName !== null) {
            
            // TODO implement the dag update name
            // await updateDagName(dag_id, newDagName);

            setIsEditingName(null);
            setName(newName)
        }
    };

    const handleCancelEdit = () => {
        setNewName(initialDagName);
        setIsEditingName(null);
    };


  return (
    <div className="flex flex-col h-full mx-5 mt-5">
    
    <div className="mb-5">
    {isEditingName ? (
                <div className="flex items-center space-x-2 justify-center">
                        <input 
                            className="border rounded-lg border-gray-900 focus:border-black p-1 text-xl font-bold"
                            type="text" 
                            value={newName} 
                            onChange={(e) => setNewName(e.target.value)} 
                            onKeyDown={(e) => handleKeyDown(e)}
                            autoFocus
                        />
                        <CheckCircleOutlined className='hover:text-[#4cb043]' onClick={(e) => handleSave(e)}/>
                        <CloseCircleOutlined className='hover:text-red-400' onClick={() => handleCancelEdit()}/>
                    </div>
                ) : (
                    <a 
                        className="text-xl font-bold flex justify-center"
                        onClick={() => handleEdit()}>
                        {name}
                        <div className="ml-[5px] relative top-[1px]">
                            
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 top-1 relative">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                        </div>
                    </a>
    )}
    </div>
      


    <textarea
      className="p-2 border border-gray-300 rounded mb-4"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder="Description"
    />      
    <label className="mb-2 font-medium">Parameters</label>
      <div className="flex overflow-x-auto mb-4">
{parameters.map((param, index) => (
  <ParameterCapsule
    key={index}
    index={index}
    parameter={param}
    onParameterChange={handleParameterChange}
    onDelete={handleDeleteParameter}
  />
))}
        <button 
          onClick={addParameter} 
          className="border border-dashed border-gray-300 rounded-full px-3 py-1"
        >
          +
        </button>
      </div>
      <label className="mb-2 font-medium">Function Definition</label>
      <CodeMirror
        value={code}
        height="400px"
        margin="5px"
        extensions={[python(), autocompletion({ override: [clientCompletionSource] }) ]}
        options={{
          theme: 'default',
          mode: 'python',
          lineNumbers: true,
          editable: (pos) => pos.line > 0
        }}
  onChange={(newValue, viewUpdate) => {
    setCode(newValue);
  }}
      />

          <button 
            onClick={saveFunctionData} 
                    className="mt-4 border border-1 border-gray-600 hover:scale-110 font-bold py-2 px-10 mx-15 rounded">

            Build
          </button>

     {/* Console Output Section
      <FunctionalEditorToolbar saveChanges={saveFunctionData} fetchSuggestion={fetchSuggestion} loadingSuggestion={loadingSuggestion} />

 {renderConsoleMessages()} 
       */}

    </div>
  );
};

export default FunctionEditor;

