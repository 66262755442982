import { createContext, useState, useEffect } from 'react';
import SqlEditorClient from './LetoClient';
import { useAuthInfo } from '@propelauth/react';

import FivetranApi from './FivetranAPI';
import LetoClient from './LetoClient';

export const ArtyAIContext = createContext();

export const ArtyAIProvider = ({ children, warehouse_type }) => {
  const [artyAITable, setArtyAITable] = useState([])
  const [artyAISchema, setArtyAISchema] = useState('')  
  const [showCharts, setShowCharts] = useState(true)
  const [artyAIPrompt, setArtyAIPrompt] = useState('')
  const [artyAISchemaList, setArtyAISchemaList] = useState([])
  const [artyAITableList, setArtyAITableList] = useState([])
  const [artyAISchemaLoading, setArtyAISchemaLoading] = useState(false)
  const [artyAITableLoading, setArtyAITableLoading] = useState(false)
  const [gptSQL, setGptSQL] = useState()
  const [tableInfo, setTableInfo] = useState({})
  const [chartData, setChartData] = useState({})
  const [view, setView] = useState(0)
  const [loading, setLoading] = useState(false)
  const [returnError, setReturnError] = useState(false)
  const [chartLimitError, setChartLimitError] = useState(false)
  const [savePromptLoading, setSavePromptLoading] = useState(false)
  const [savedPromptsList, setSavedPromptsList] = useState([])
  const [savedCharts, setSavedCharts] = useState({})
  const [viewMode, setViewMode] = useState('prompt')
  const [saved, setSaved] = useState(false)
  const [savedChartsLoading, setSavedChartsLoading] = useState(false)
  const [chartBoardsLoading, setChartBoardsLoading] = useState(false)
  const [warehouseType, setWarehouseType] = useState('')
  const [noSavedPrompts, setNoSavedPrompts] = useState(false)
  const [artySidebarOpen, setArtySidebarOpen] = useState(true)
  const [chartBoards, setChartboards] = useState()
  const [selectedChartBoard, setSelectedChartBoard] = useState()
  const [addboardModalVisible, setAddboardModalVisible] = useState(false)
  const [pinChartView, setPinChartView] = useState(false)
  const [pinTableView, setPinTableView] = useState(false)
  const [pinChart, setPinChart] = useState()
  const [pinTable, setPinTable] = useState()
  const [gettingChartBoards, setGettingChartBoards] = useState(true)
  const [dashboardId, setDashboardId] = useState()


  useEffect(() => {
    if (dashboardId) {
      getSavedCharts(dashboardId)
    }
  }, [dashboardId])

  useEffect(() => {
    if (warehouse_type) {
      setWarehouseType(warehouse_type)
    }
  }, [warehouse_type])

  useEffect(() => {
    getChartBoards()
  }, [])

  useEffect(() => {
    let interval = null;

    if (loading) {
      interval = setInterval(() => {
        pollInsights() 
      }, 1675);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [loading]);

  const authInfo = useAuthInfo();
    
  const deleteSavedPrompt = async (id) => {
    const response = await SqlEditorClient.deleteSavedPrompt(id)
  }

  const renameSavedPrompt = async (id, name) => {
    const response = await SqlEditorClient.renameSavedPrompt(id, name)
  }

  const deleteChartBoard = async (id) => {
    const response = await SqlEditorClient.deleteChartBoard(id)
    const updatedBoards = chartBoards.filter(board => board.chart_board_id != id)
    if (selectedChartBoard && (selectedChartBoard.chart_board_id === id)) {
      await setSelectedChartBoard()
    }
    await setChartboards(updatedBoards)
    console.log('response got!')
    return
  }

  const updateChartboardName = async (id, name) => {
    const body = { chart_board_id: id, name: name }
    console.log('update', id, name)
    try {
      const response = await SqlEditorClient.updateChartboardName(body)
      const updatedBoards = chartBoards.map(board => 
        board.chart_board_id === id
          ? { ...board, name: name }
          : board
        )
      setChartboards(updatedBoards)
      setSelectedChartBoard({ ...selectedChartBoard, name: name })
    }
    catch {
      console.log('error in naming chart')
    }
  }

  const applyPrompt = async (prompt) => {
    setArtyAIPrompt(prompt.prompt)
    setGptSQL(prompt.sql)
    setChartData(prompt.chart_data)
    const response = await SqlEditorClient.getTableForQuery(prompt.sql.replace(/(\r\n|\n|\r)/gm, " "))
    setTableInfo(response.table)
  }

  const savePrompt = async () => {
    setSavePromptLoading(true)
    if (warehouseType === 'postgres') {
      const response = await SqlEditorClient.savePrompt(artyAIPrompt, gptSQL, chartData, '', artyAITable[0].label, 'Untitled');
    }
    else {
      const response = await SqlEditorClient.savePrompt(artyAIPrompt, gptSQL, chartData, artyAISchema.label, artyAITable[0].label, 'Untitled');
    }
  }

  const saveChart = async (chart, chart_board_id) => {
    // setSavePromptLoading(true)
    const response = await SqlEditorClient.saveChart(chart, chart_board_id);
    if(response['error']){
      setChartLimitError(true)
    }
    else {
      setPinChartView(false)
    }
    console.log(response)
  }

  const saveTable = async (table) => {
    const response = await SqlEditorClient.saveTableToDashBoard(table); 

    if(response['error']){
      setChartLimitError(true)
    }
    else {
      setPinTableView(false)
    }
  }

  const deleteChart = async (chart_id) => {
    // setSavePromptLoading(true)
    await SqlEditorClient.deleteChart(chart_id);
  }

  const addChartBoard = async (name) => {
    const response = await SqlEditorClient.addChartBoard(name);
    if (response['error']) {
      console.log('error adding chart board')
    }
    else {
      console.log('board is:')
      console.log(response['board'])
      setChartboards([...chartBoards, response['board']])
      // const temp = chartBoards
      // temp.append(response['board'])
      // setChartboards(temp)
    }
    setAddboardModalVisible(false)
  }

  const editChartBoard = async (name, id) => {
    const response = await SqlEditorClient.editChartBoard(name, id);
    if (response['error']) {
      console.log('error editing chart board')
    }
    else {
      const temp = chartBoards
      for (const board of temp) {
        if (board.chart_board_id == response['board'].chart_board_id) {
          board.name = response['board'].name
        }
      }
      setChartboards(temp)
    }
  }

  const getChartBoards = async () => {
    const response = await SqlEditorClient.getChartBoards();
    if (response['error']) {
      console.log('error getting chart boards')
    }
    else {
      setChartboards(response['data'])
      setViewMode('saved_charts')
      setShowCharts(true)
      setGettingChartBoards(false)
    }
    setChartBoardsLoading(false)
  }

  const getSavedCharts = async (chart_board_id) => {
    // setSavePromptLoading(true)
    const response = await SqlEditorClient.getSavedCharts(chart_board_id);
    response['data'].forEach(element => {
      if (element['chart'] && element['chart']['x_axis']) {
        element['chart']['x_axis'] = element['chart']['x_axis'][0]
      }
      if (element['chart'] && element['chart']['y_axis']) {
        element['chart']['y_axis'] = element['chart']['y_axis'][0]
      }
    })
    setSelectedChartBoard(response.chart_board)
    setSavedCharts(response)
    setSavedChartsLoading(false)
    setShowCharts(true)
  }

  const getSavedPrompts = async () => {
    const response = await SqlEditorClient.getSavedPrompts()
    setSavedPromptsList(response)
    if (!response.length) {
      setNoSavedPrompts(true)
    }
    else {
      setNoSavedPrompts(false)
    }
  }

  const getSchemaTables = async schema => {
    setArtyAITableLoading(true);
    const response = await SqlEditorClient.getSchemaTables(schema);
    setArtyAITableList(response);
    setArtyAITableLoading(false);
  };

  // console.log(artyAISchemaList)

  const getDBSchemas = async () => {
    if (warehouseType === 'postgres') {
      setArtyAITableLoading(true)
    }
    setArtyAISchemaLoading(true);
    const response = await SqlEditorClient.getDBSchemas();
    if (warehouseType === 'postgres') {
      console.log('type is postgres')
      setArtyAITableList(response)
      setArtyAITableLoading(false)
    }
    else {
      setArtyAISchemaList(response);
    }
    setArtyAISchemaLoading(false);
  };
   
  const pollInsights = async () => {
    let response = await SqlEditorClient.pollArtyInsights()

    if (response.error != null || response.status == "failed") {
      setReturnError(true)
    setLoading(false)
    setSaved(false)
    }
    else { 
      setGptSQL(response.sql ? response.sql : null)
      setTableInfo(response.table ? response.table : null)
      setChartData(response.chart_data ? response.chart_data : null) 
      if (response.status == "complete"){
        setLoading(false)
        setSaved(false)
      } 
    }
    
  
      
    
  }


  const getInsights = async (prompt) => {
    const table_list = []
    for (var i in artyAITable) {
      table_list.push(artyAITable[i].value)
    }
    SqlEditorClient.getInsights(table_list, prompt)
    setLoading(true) 
  }

  const runCustomSQL = async (custom_sql) => {
    const response = await SqlEditorClient.customSQL(custom_sql.replace(/(\r\n|\n|\r)/gm, " "))
    if(response.error != null){
      setReturnError(true)
    }
    else {
      setTableInfo(response.table)
      setChartData(response.chart_data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (authInfo.isLoggedIn) {
      SqlEditorClient.setToken(authInfo.accessToken)
    }
  }, [authInfo])

  return (
    <ArtyAIContext.Provider
      value={{
        artyAITable, 
        setArtyAITable,
        artyAISchema, 
        setArtyAISchema,
        artyAIPrompt, 
        setArtyAIPrompt,
        artyAISchemaList, 
        setArtyAISchemaList,
        artyAITableList, 
        setArtyAITableList,
        getDBSchemas,
        getSchemaTables,
        artyAISchemaLoading, 
        setArtyAISchemaLoading,
        artyAITableLoading, 
        setArtyAITableLoading,
        getInsights,
        gptSQL,
        setGptSQL,
        tableInfo,
        setTableInfo,
        chartData,
        setChartData,
        saveChart,
        saveTable,
        getSavedCharts,
        savedCharts,
        setSavedCharts,
        chartLimitError,
        setChartLimitError,
        view,
        setView,
        loading,
        setLoading,
        setReturnError,
        returnError,
        runCustomSQL,
        savePrompt,
        getSavedPrompts,
        savedPromptsList,
        setSavedPromptsList,
        viewMode,
        setViewMode,
        applyPrompt,
        deleteSavedPrompt,
        renameSavedPrompt,
        saved,
        setSaved,
        deleteChart,
        savedChartsLoading,
        setSavedChartsLoading,
        warehouseType,
        setWarehouseType,
        noSavedPrompts,
        setNoSavedPrompts,
        artySidebarOpen,
        setArtySidebarOpen,
        showCharts,
        setShowCharts,
        chartBoards,
        setChartboards,
        chartBoardsLoading,
        setChartBoardsLoading,
        getChartBoards,
        selectedChartBoard,
        setSelectedChartBoard,
        addChartBoard,
        addboardModalVisible,
        setAddboardModalVisible,
        editChartBoard,
        pinChartView,
        setPinChartView,
        pinTableView,
        setPinTableView,
        pinChart,
        setPinChart,
        pinTable,
        setPinTable,
        updateChartboardName,
        deleteChartBoard,
        gettingChartBoards,
        dashboardId,
        setDashboardId,
      }}
    >
      {children}
    </ArtyAIContext.Provider>
  );
};
