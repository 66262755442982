import React, { useContext, useState, useEffect } from 'react';
import { TableManagementContext } from '../../../../context/TableManagementProvider';
import { DownOutlined, DatabaseOutlined, TableOutlined } from '@ant-design/icons';
import { Spin, Input } from 'antd';

import './DatabaseSidebar.css';
import Tree from './Tree';
import SearchTree from './SearchTree';

const createTreeData = (tables) => {
    const treeMap = {};

    tables?.forEach(table => {
        if (!treeMap[table.schema]) {
            treeMap[table.schema] = {
                name: table.schema,
                key: table.schema,
                type: 'schema',
                children: [],
                toggled: false
            };
        }
        treeMap[table.schema].children.push({
            name: table.table,
            key: `${table.schema}.${table.table}`,
            type: 'table',
            toggled: false
        });
    });

    return Object.values(treeMap);
}

const DatabaseSidebar = () => {
    const {
        tableList,
        tableListLoading,
        getTableSchema
    } = useContext(TableManagementContext);
    const [treeData, setTreeData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [filteredTree, setFilteredTree] = useState()

    useEffect(() => {
        const initialTreeData = createTreeData(tableList);
        setTreeData(initialTreeData);
        console.log('resetting file tree')
    }, [tableList]);

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
        if (!value) {
            setTreeData(createTreeData(tableList));
            return;
        }

        const newExpandedKeys = [];
        const filteredData = treeData.map(schemaNode => ({
            ...schemaNode,
            children: schemaNode.children.filter(tableNode => {
                const match = tableNode.name.toLowerCase().includes(value.toLowerCase());
                if (match) {
                    newExpandedKeys.push(schemaNode.key);
                }
                return match;
            })
        })).filter(schemaNode => schemaNode.children.length > 0);

        setTreeData(filteredData);
    };

    const handleDropdown = (value) => {
        // setShowDropdown(true);
        searchChange(value);
    };

    const searchChange = async (value) => {
        setSearchValue(value)
        if (value.length >= 2 ) {
            setFilteredTree(treeData.map(item => handleSearchFilter(item, value)).filter(x => x))
        }
        else {
            setFilteredTree()
        }
    }
    const handleSearchFilter = (item, value, force_include=false) => {
        if ((item.key.toLowerCase()).includes(value.toLowerCase())) {
            if (item.children) {
                const children = item.children.map(child => {
                    let val = handleSearchFilter(child, value, true)
                    if (val) {
                        return val
                    }
                    else if (force_include) {
                        return child   
                    }
                }).filter(x => x)
                
                if (children.length) {
                    return {...item, children: children, toggled: true}
                }
                return {...item, toggled: false}
            }
            return { ...item, toggled: false }
        }
        else {
            if (item.children) {
                const children = item.children.map(child => {
                    let val = handleSearchFilter(child, value, force_include)
                    if (val) {
                        return val
                    }
                    else if (force_include) {
                        return child   
                    }
                }).filter(x => x)
                if (children.length) {
                    return {...item, children: children, toggled: true}
                }
                else if (force_include) {
                    return { ...item, toggled: false }
                }
            }
            else if (force_include) {
                return { ...item, toggled: false }
            }
        }
    }

    return (
        <div className='h-full p-3 pt-6 overflow-y-auto overflow-x-hidden no-scrollbar'>
            <input 
                type="search" 
                className="bg-gray-50 bg-transparent border w-[240px] rounded-[5px] border-gray-300 text-gray-900 text-sm block px-2.5 py-1 mb-2" 
                placeholder={"Search database by name"}
                onClick={(e) => handleDropdown(e.target.value)}
                onChange={(e) => searchChange(e.target.value)} 
            />
            <div className='h-[calc(100vh-150px)] overflow-y-auto overflow-x-hidden'>
                {tableListLoading ?
                    (
                        <Spin size="large" />
                    )
                    :
                    (filteredTree ?
                        filteredTree.map(item => 
                            <SearchTree
                                className='bg-[#f7f7f7]'
                                data={item}
                                setFileTree={setTreeData}
                                setFilteredTree={setFilteredTree}
                                first={true}
                                searchValue={searchValue}
                            />
                        )
                        :
                        treeData && treeData.map(item => 
                            <Tree
                                className='bg-[#f7f7f7]'
                                data={item}
                                setFileTree={setTreeData}
                                first={true}
                            />
                        )
                    )}
            </div>
        </div>
    );
};

export default DatabaseSidebar;

