import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import AnalyticsDropdown from './AnalyticsDropdown';
import AnalyticsChartParameters from './AnalyticsChartParameters';
import AnalyticsGraph from './AnalyticsGraph';
import AnalyticsChartFilters from './AnalyticsChartFilters';
import { useAuthInfo } from '@propelauth/react';
import { Layout, Input, Divider, Button, Rate, Tooltip, Spin } from 'antd';
import { SaveOutlined, PushpinOutlined, LoadingOutlined } from '@ant-design/icons';
import Page from '../../components/Page';
import Sidebar from '../../components/Sidebar';
import { useHistory } from 'react-router-dom';
import PinModal from './PinModal';
import './style.scss'
const ChartTypes = [
    { "value": "Time Series BarChart", "label": "Time Series Bar Chart" },
    { "value": "Time Series LineChart", "label": "Time Series Line Chart" },
    {"value" : "BarChart", "label" : "Bar Chart"},
    {"value" : "LineChart", "label" : "Line Chart"},
    {"value" : "ScatterChart", "label" : "Scatter Chart"},
    {"value" : "AreaChart", "label" : "Area Chart"},
    { "value": "PieChart", "label": "Pie Chart" },
    

]

const TimeGrains = [
    { "value": "day", "label": "Day" },
    {"value" : "week", "label" : "Week"},
    {"value" : "month", "label" : "Month"},
    {"value" : "quarter", "label" : "Quarter"},
    {"value" : "year", "label" : "Year"},


]

const AnalyticsChart = () => {
    const { chart_id } = useParams();

    const history = useHistory()

    const {
        resetState,
        loadChart,
        chartName,
        setChartName,
        analyticsSchemaSelected,
        setAnalyticsSchemaSelected,
        analyticsSchemaList,
        analyticsTableSelected,
        setAnalyticsTableSelected,
        analyticsTableList,
        setAnalyticsTableList,
        chartTypeSelected,
        setChartTypeSelected,
        getSchemaTables,
        analyticsSchemaLoading,
        analyticsTableLoading,
        readyToGraph,
        setChartData,
        chartData,
        getChartData,
        metricsSelected,
        filtersSelected,
        saveChart,
        generateGraph,
        setGenerateGraph,
        favorited,
        setFavorited,
        groupsSelected,
        timeColumn,
        setTimeColumn,
        timeGrain,
        setTimeGrain,
        analyticsColumnList,
        currentChartID,
        setPinModalView,
        generatedChartType,
        chartLoading,
    } = useContext(AnalyticsContext)
    const authInfo = useAuthInfo();
    const [sidebarData, setSidebarData] = useState({})
    const [openSidebar, setOpenSidebar] = useState(true)

    const [saved, setSaved] = useState(false)

    useEffect(() => {
        if (chart_id !== undefined) {
            loadChart(chart_id)
            setSaved(true)
        }
    }, []);

    const graph = () => {
        setChartData()
        getChartData(chart_id, analyticsTableSelected.value, metricsSelected, filtersSelected, groupsSelected, {column: timeColumn, grain: timeGrain})
    }

    const favorite = () => {
        setFavorited(!favorited)
        saveChart() // TODO move to own endpoint
    }

    const sidebarContent = () => {
        return (
            <div className='w-full h-full text-[13px]'>
                
                <div className='analytics-sider relative overflow-auto border-b-[1px] border-gray-300 pb-[5px] mr-[10px]'>
                <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <div className='flex' style={{ paddingTop: 5, paddingBottom: 5 }}>
                            Schema
                            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[16px] h-[16px] relative ml-[5px] top-[2px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                            </svg> */}

                        </div>
                        <AnalyticsDropdown 
                            selected={analyticsSchemaSelected}
                            dropdownList={analyticsSchemaList}
                            setSelected={(val) => setAnalyticsSchemaSelected(val)}
                            loading={analyticsSchemaLoading}
                            disabled={false}
                        />
                        <div style={{paddingTop: 5, paddingBottom : 5}}>Table</div>
                        <AnalyticsDropdown 
                            selected={analyticsTableSelected}
                            dropdownList={analyticsTableList}
                            setSelected={(val) => setAnalyticsTableSelected(val)}
                            loading={analyticsTableLoading}
                            disabled={!analyticsSchemaSelected}
                        />
                    </div>
                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                    <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <div style={{paddingBottom : 5}}>Chart Type</div>
                        <AnalyticsDropdown
                            selected={chartTypeSelected}
                            dropdownList={ChartTypes}
                            setSelected={setChartTypeSelected}
                            loading={false}
                            disabled={false}
                        />
                    </div>
                    {
                    chartTypeSelected && chartTypeSelected.value && chartTypeSelected.value.includes('Time Series') &&
                    <> 
                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                        <div style={{paddingLeft : 15, paddingRight : 15}}>
                            
                                <div className='mb-[5px]'>Time Column</div>
                                <AnalyticsDropdown
                            selected={timeColumn}
                            dropdownList={analyticsColumnList}
                            setSelected={setTimeColumn}
                            loading={false}
                            disabled={false}
                        />
                                <div className='mt-[5px] mb-[5px]'> Time Grain</div>
                                <AnalyticsDropdown
                            selected={timeGrain}
                            dropdownList={TimeGrains}
                            setSelected={setTimeGrain}
                            loading={false}
                            disabled={false}
                        />

                        </div>
                    </>
                    
                    
                    }
                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                    <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <AnalyticsChartParameters/>
                    </div>
                    </div>
                <div className='w-full flex justify-center'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'fixed', bottom: '15px'}}>
                        <button className={readyToGraph ? 'bg-[#5e7452] text-white py-[5px] rounded-[4px] px-[20px] hover:bg-[#4b613d]' : 'rounded-[4px] px-[20px] bg-gray-100 border-[1px] py-[5px] border-gray-300 cursor-not-allowed hover:bg-gray-200'} disabled={!readyToGraph} onClick={() => graph()}><p>Generate Graph</p></button>
                    </div>
                </div>
                    
            </div>
        )
    }

    const pinChart = () => {
        console.log('pin!')
        console.log(currentChartID)
        setPinModalView(true)
    }
    

    return (
        <Page>
            <PinModal/>
            <Sidebar setSidebarData={setSidebarData} sidebarContent={sidebarContent()} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} hideButtonWhenClosed={false}>
                
                <div className='flex flex-col h-full overflow-hidden p-[15px]'>
                    <div>
                        <button className='text-gray-600 text-[12px] hover:bg-gray-200 p-[5px] rounded-md' onClick={() => history.push('/analytics')}>
                            Back
                        </button>
                    </div>
                        
                    <div className='flex align-center w-full justify-between'>
                        
                        <Input placeholder="Enter Chart Name Here" style={{ width: '50%', 'font-weight': 'bold', 'font-size': '1.8em', padding: 0 }} bordered={false} value={chartName} onChange={e => setChartName(e.target.value)}/>
                        <div>
                            <Tooltip placement="top" title="Pin to dashboard">
                                <PushpinOutlined data-tooltip-target="pin-chart" className='text-[24px] mr-[5px] hover:bg-gray-100 p-[5px] rounded-lg' onClick={() => pinChart()}/>
                            </Tooltip> 
                            {saved && (
                                <Rate
                                    count={1}
                                    value={favorited ? 1 : 0}
                                    onChange={() => favorite()}
                                    style={{ fontSize: '24px' }}
                                    className='align-middle mr-[5px]'
                                />
                            )}
                            <Tooltip title="Save Changes" placement='topRight'>
                                <button className='align-middle hover:bg-gray-100 p-[5px] rounded-lg' type="secondary" onClick={() => saveChart()}>
                                    <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px', width: '100%'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                                        </svg>

                                    </div>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='flex flex-grow overflow-hidden justify-center'>
                        {generatedChartType && chartData ?
                            <AnalyticsGraph chartData={chartData} generatedChartType={generatedChartType} />
                            :
                            ( chartLoading &&
                                <div className='mt-[20vh]'>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 30, }} spin />} spinning={chartLoading}></Spin>
                                </div>                                
                            )
                        }
                    </div>
                </div>
                

            </Sidebar>
            {/* <Layout.Header style={{width: '100%', backgroundColor:"white", border: '1px LightGrey', 'border-style': 'solid'}}>
                <div style={{ 'display': 'flex', 'align-items': 'center', justifyContent: 'space-between', height: '100%', width: '100%'}}>
                    <Input placeholder="Enter Chart Name Here" style={{ width: '50%', 'font-weight': 'bold', 'font-size': '1.8em', padding: 0 }} bordered={false} value={chartName} onChange={e => setChartName(e.target.value)}/>
                    <div>
                        {saved && (
                            <Rate
                                count={1}
                                value={favorited ? 1 : 0}
                                onChange={() => favorite()}
                                style={{ fontSize: '2.0em' }}
                            />
                        )}
                        <Button type="secondary" onClick={() => saveChart()}>
                            <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                                <SaveOutlined style={{ fontSize: '2.0em' }}/>
                            </div>
                        </Button>
                    </div>
                </div>
            </Layout.Header>
            <Layout>
                <Layout.Content >
                    <AnalyticsGraph chartData={chartData} chartTypeSelected={chartTypeSelected.value} dimensionSelected={dimensionSelected.value}/>
                </Layout.Content> */}
                {/* <Layout.Sider className="table-properties" trigger={null} width={300} style={{ backgroundColor:"white", overflowY: 'auto', height: '80vh' }} bodyStyle={{margin: 20, overflowY: 'auto'}}>
                    <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <p style={{paddingTop: 15, paddingBottom : 5}}>Schema</p>
                        <AnalyticsDropdown 
                            selected={analyticsSchemaSelected}
                            dropdownList={analyticsSchemaList}
                            setSelected={setAnalyticsSchemaSelected}
                            loading={analyticsSchemaLoading}
                            disabled={false}
                        />
                        <p style={{paddingTop: 10, paddingBottom : 5}}>Table</p>
                        <AnalyticsDropdown 
                            selected={analyticsTableSelected}
                            dropdownList={analyticsTableList}
                            setSelected={setAnalyticsTableSelected}
                            loading={analyticsTableLoading}
                            disabled={!analyticsSchemaSelected}
                        />
                        <p style={{paddingTop: 10, paddingBottom : 5}}>Filters</p>
                        <AnalyticsChartFilters/>
                    </div>
                    <Divider />
                    <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <p style={{paddingBottom : 5}}>Chart Type</p>
                        <AnalyticsDropdown
                            selected={chartTypeSelected}
                            dropdownList={ChartTypes}
                            setSelected={setChartTypeSelected}
                            loading={false}
                            disabled={false}
                        />
                    </div>
                    <Divider />
                    <div style={{paddingLeft : 15, paddingRight : 15}}>
                        <AnalyticsChartParameters/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', position: 'fixed', bottom: 0, height: '7vh', width: 300}}>
                        <Button type="primary" disabled={!readyToGraph} onClick={() => graph()}><p>Generate Graph</p></Button>
                    </div>
                </Layout.Sider> */}
            {/* </Layout> */}
        </Page>
    )
};

export default AnalyticsChart;
