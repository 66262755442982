import './style.scss';

const Card = ({ children, title, info, className, contentClass }) => {
  return (
    <div className={['card', className].join(' ')}>
      <h1>{title}</h1>
      {info && <p className="info">{info}</p>}

      <div className={['card-content', contentClass].join(' ')}>{children}</div>
    </div>
  );
};

export default Card;
