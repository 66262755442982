import {
    DownOutlined, SettingOutlined,
    BookOutlined, LogoutOutlined,
    CheckCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLogoutFunction } from '@propelauth/react';


const AgentsNavBar = ({updateContextName, name}) => {
    const logoutFn = useLogoutFunction();

    const [dropdown, setDropdown] = useState(false)
    const history = useHistory();

    const [hovering, setHovering] = useState(false)
    const [dagName, setDagName] = useState(name)
    const [newDagName, setNewDagName] = useState("");
    const [initialDagName, setInitialDagName] = useState("");
    const [isEditing, setIsEditing] = useState(null);
    const DOCUMENTATION_URL = 'https://artemisdata.notion.site/Artemis-Docs-05bbd7e73e144946bfd318d5c3315aae'

    const handleEdit = () => {
            setNewDagName(dagName);
            setInitialDagName(dagName);
            setIsEditing(true);
    };

    const handleKeyDown = async (e) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    const handleSave = async (e = null) => {
        if (isEditing !== null) {

            await updateContextName(newDagName);

            setIsEditing(null);
            setDagName(newDagName)
        }
    };

    const handleCancelEdit = () => {
        setNewDagName(initialDagName);
        setIsEditing(null);
    };

    const items = (
        <Menu
          style={{width: '15vw', minWidth: '200px', position: 'absolute', left: '-20px',}}
            items={[
            {
                label:
                    <div className='flex' onClick={() => { setDropdown(false); history.push('/tables') }}> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='relative top-[3px] mr-[5px] ml-[5px] h-[14px] w-[14px]' >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                        </svg>
                        Tables
                    </div>,
                key: '1',
                link: '/tables'
            },
            {
                label:
                    <div className='flex' onClick={() => { setDropdown(false); history.push('/workflows') }}> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='relative top-[3px] mr-[5px] ml-[5px] h-[14px] w-[14px]'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                        </svg>

                        Workflows
                    </div>,
                key: '2',
                link: '/workflows'
            },
            {
                label:
                    <div className='flex' onClick={() => { setDropdown(false); history.push('/models') }}> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='relative top-[3px] mr-[5px] ml-[5px] h-[14px] w-[14px]'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                        </svg>
                        Models
                    </div>,
                key: '12',
                link: '/models'
            },
            {
                label:
                    <div className='flex' onClick={() => { setDropdown(false); history.push('/analytics') }}> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className='relative top-[3px] mr-[5px] ml-[5px] h-[14px] w-[14px]'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                        </svg>
                        Analytics
                    </div>,
                key: '5',
                link: '/analytics'
            },
            {
                label:
                    <div className='flex' onClick={() => { setDropdown(false); history.push('/data') }}> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='relative top-[3px] mr-[5px] ml-[5px] h-[14px] w-[14px]'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                        </svg>

                        Data
                    </div>,
                key: '6',
                link: '/data'
            },
            {
                type: 'divider',
                link: ''
            },
            
            {
                  label: <div className='flex' onClick={() => { setDropdown(false); history.push('/account') }}> <SettingOutlined style={{position: 'relative', top: '3px', marginRight: '5px', marginLeft: '5px'}} /> Account settings</div>,
                key: '0',
                link: '/account'
              },
              {
                type: 'divider',
                link: ''
              },
              {
                  label: <div onClick={() => { setDropdown(false); window.open(DOCUMENTATION_URL, '_blank') }}> <BookOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}} /> Documentation </div>,
                key: '3',
              },
              {
                type: 'divider',
              },
              {
                label: <div onClick={() => logoutFn()}> <LogoutOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}}/> Sign out </div>,
                key: '7',
              },
          ]}
        />
      );




  return (
        <nav className="navbar" style={{height: '55px', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ccc'}} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
            <div style={{ width: "1rem" }} />
            <Dropdown overlay={items} trigger={['hover']} onVisibleChange={e => setDropdown(e)} className="ml-[10px] flex">
                <div>
                    <img src="https://s3-us-west-2.amazonaws.com/public.notion-static.com/e092fde8-6695-46b9-a66d-28bdec452eb5/twitter_logo.png" alt="Twitter Logo" style={{ height: '30px', width: '30px', borderRadius: '25%', cursor: 'pointer' }}/>
                    <DownOutlined className={ true ? 'text-black duration-[100ms] my-auto opacity-100 ml-[5px]' : ' opacity-0 my-auto duration-[100ms] ml-[5px]'} style={dropdown ? { transform: 'rotate(-180deg)', transition: '300ms ease-in' } : { transform: 'rotate(0)', transition: '300ms ease-in' }} />
                </div>
            </Dropdown>

            <div className="sm:flex-auto justify-center text-center items-center">
                <h1 className="grow text-center mr-[66px] ml-[20px]">
                {isEditing ? (
                    <div className="flex items-center space-x-2 justify-center">
                        <input 
                            className="border rounded-lg border-gray-900 focus:border-black p-1 text-xl font-bold"
                            type="text" 
                            value={newDagName} 
                            onChange={(e) => setNewDagName(e.target.value)} 
                            onKeyDown={(e) => handleKeyDown(e)}
                            autoFocus
                        />
                        <CheckCircleOutlined className='hover:text-[#4cb043]' onClick={(e) => handleSave(e)}/>
                        <CloseCircleOutlined className='hover:text-red-400' onClick={() => handleCancelEdit()}/>
                    </div>
                ) : (
                    <a 
                        className="text-xl font-bold flex justify-center"
                        onClick={() => handleEdit()}
                    >
                        {dagName}
                        <div className="ml-[5px] relative top-[1px]">
                            
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 top-1 relative">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                        </div>
                    </a>
                )}
                </h1>
            </div>
        <button className='mr-5' onClick={() => { 

 const currentPath = history.location.pathname;
    const newPath = currentPath + '/secrets';
    history.push(newPath);

        }}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>

      </button>
      
        </nav>
);
    
};

export default AgentsNavBar;

