import React , {useState, useContext, useRef, useEffect} from 'react';
import { ArtyChatContext, ArtyChatProvider } from './ArtyChatProvider';
import { Tooltip as AntdTooltip, List } from 'antd';
import ArtyAiErrorModal from './ArtyAiErrorModal';
import { PushpinFilled, DownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorView } from "@codemirror/view";
import ReactMarkdown from 'react-markdown';
// import WorkflowNavBar from './WorkflowNavbar';
import { Transition } from '@headlessui/react';

import Sidebar from '../../../components/Sidebar';

import { BsChatLeftText, BsTrashFill } from "react-icons/bs";
import {HiOutlineLightBulb} from "react-icons/hi"
import Page from '../../../components/Page';
import ColumnSelector from './ColumnSelectorComponent';
import { AgentContext } from '../../../context/AgentProvider';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const App= () => {
    const { pub_id } = useParams();
    const contexts = useContext(ArtyChatContext);
    const { updateFunctionInContext, updateSourceInContext, context  } = useContext(AgentContext);

    const { 
        chat, 
        setChat, 
        chatId,
        setChatId,
        createChat, 
        promptArty, 
        promptArty_SSE,
        contextMessage,
        getAllChats,
        setChatList,
        chatList,
        deleteChat,
        fetchChat, 
        fetchingChat,
        updateChatName,
        newChatName,
        setNewChatName,
        initialChatName,
        setInitialChatName,
        isEditing,
        setIsEditing,
        handleEdit,
        handleSave,
        handleCancel,
     } = useContext(ArtyChatContext);

    const [artySidebarOpen, setArtySidebarOpen] = useState(false)

    const [fetched, setFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const [showChats, setShowChats] = useState(true)
    const [hoveredChat, setHoveredChat] = useState(false)
    const [hoveredPinnedChat, setHoveredPinnedChat] = useState(false)
    const [expanded, setExpanded] = useState(-1)
    const [selectedX, setSelectedX] = useState('');
    const [selectedY, setSelectedY] = useState('');
    const [chartType, setChartType] = useState('bar'); // default chart type

    const [copied, setCopied] = useState(false)

    useEffect(() => {

        const fetchData = async () => {
            if (!fetched) {

                // await getAllChats(pub_id)
                await createChat(context._id)
                setFetched(true)
            }
        };
        fetchData();
    }, [pub_id, fetched]); 


    const itemDoubleClick = (item) => {
        fetchChat(pub_id, item._id)
    }

    const itemClick = (item) => {
        fetchChat(pub_id, item._id)
    }

    const accessPrompt = (prompt) => {
        console.log('prompt:', prompt);
        setInputMessage(prompt)
    };

    const onDelete = async (item, length, index) => {
        const response = await deleteChat(item._id, pub_id)
        if (index >= (length - 1)) {
            if (length === 1) {
                console.log(chatList)
                setChat(response[0])
                setChatId(response[0]._id)
            }
            else {
                setChat(response[index-1])
                setChatId(response[index-1]._id)
            }
        }
    }

    const pinnedChatExists = (chat) => {
        if (chat.pinned) {
            return true
        }
        return false
      };


    const handleKeyDown = async (e, chat_id) => {
        console.log("handle key down")
        if (e.key === "Enter") {
            handleSave(pub_id, chat_id);
        }
    };

    const getChatName = (item) => {
        if (isEditing === item._id) {
            return (
                <div className="flex w-[200px]">
                    <input 
                        className="border border-[#B0B0B0] h-[17px] bg-[#F0F0F0] text-[13px] w-[200px] rounded-sm p-[8px]"
                        type="text" 
                        value={newChatName} 
                        onChange={(e) => setNewChatName(e.target.value)} 
                        onBlur={handleCancel}
                        onKeyDown={(e) => handleKeyDown(e, item._id)}
                    />
                </div> 
            )
        }
        else {
            if (item.chat_name) {
                return <a style={{fontSize: '13px', marginLeft: "10px", marginBottom:'0px', position: 'relative', top: '-5px', userSelect: 'none'}} onClick={() => handleEdit(item)}>{item.chat_name}</a>
            }
            else {
                return <a style={{fontSize: '13px', marginLeft: "10px", marginBottom:'0px', position: 'relative', top: '-5px', userSelect: 'none'}}  onClick={() => handleEdit(item)}>Undefined Chat</a>
            }
        }
    }

 

    const togglePastChats = () => {
        setShowChats(!showChats);
        getAllChats(pub_id)
      }
    

    const adjustTextareaHeight = () => {
        const textarea = document.getElementById("textarea");
        textarea.style.height = "40px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };
    
    const changeDetailsMode = (index) => {
        
        if (expanded === index) {
            setExpanded(-1)

        }
        else {
            setExpanded(index)
        }
    }

    const handleCopy = (text) => {
        setCopied(true)
        navigator.clipboard.writeText(text)
        setTimeout(() => {
            setCopied(false)
        }, 1000 )
    }


const renderMessage = (message, index) => {
    console.log(message)
    const isUserMessage = message.type === 'user';
    const messageStyle = isUserMessage ? "bg-white" : "bg-[#F7F6F6]";
    const messageType = isUserMessage ? "User" : "Arty";

    const content = message.content.content;

    // Check if the message is of type 'table' and render table
    const contentType = message.content.message_type;

    const _output = message._output
    

const renderTable = (tableData) => {
  if (!tableData || tableData.length === 0) {
    return <div>No Data</div>;
  }

  // Get column headers from the first item's keys
  const columns = tableData[0] ? Object.keys(tableData[0]) : [];
  console.log(tableData);

  return (
    <div className="max-h-64 max-w-[500px] overflow-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            {columns.map((header, idx) => (
              <th
                key={idx}
                className="px-2 py-2 border-b border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, idx) => (
            <tr key={idx}>
              {columns.map((col, colIdx) => (
                <td
                  key={colIdx}
                  className="px-2 py-2 border-b border-gray-200 bg-white text-sm"
                >
                  {row[col]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const renderOutput = () => {
    if (!message._output) return null;

    // Extracting the data with conditional checks
    const functionCode = message._output.function_code ? message._output.function_code : "No code executed";
    const functionName = message._output.function_name ? message._output.function_name : "No function name";
    const parameters = message._output.parameters ? JSON.stringify(message._output.parameters, null, 2) : "No parameters";

    return (
        <div className='max-w-full mx-2 mt-2'>
            <div className='flex text-gray-500 cursor-pointer select-none' onClick={() => changeDetailsMode(index)}>
                Process Details
                <DownOutlined className='duration-[50ms]' style={expanded === index ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginLeft:'5px' ,top:'4px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'0px', position: 'relative', fontSize:'12px'}}/>
            </div>
            <div
        className="max-w-full overflow-auto"
        style={
          expanded === index
            ? { transition: '800ms ease', maxHeight: '200px' }
            : { transition: '500ms ease', maxHeight: '0px' }
        }
      >
                <div>Python code executed:</div>
                <CodeMirror value={functionCode} extensions={[sql(), EditorView.lineWrapping]} readOnly={true} />
                <div>Function Name: {functionName}</div>
                <div>Parameters: <pre>{parameters}</pre></div>
            </div>
        </div>
    );
};


    // Function to render an error message
    const renderErrorMessage = (errorMessage) => {
        return <div style={{ color: 'red' }}>{errorMessage}</div>;
    };

    // Function to render a URL as an iframe
    const renderURL = (url) => {
        return (
            <iframe src={url} width="300" height="400" frameBorder="0">
                Your browser does not support iframes.
            </iframe>
        );
    };
    const renderContent = () => {
        switch (contentType) {
            case 'table':
                return renderTable(content);
            case 'error':
                return renderErrorMessage(content);
            case 'url':
                return renderURL(content);
            default:
                return String(content); // default string content
        }
    };
return (
    <div key={index} className={`flex w-full ${messageStyle} py-4 border-b border-gray-300 px-6`}>
      <div className="text-gray-500 mr-2 select-none">{messageType}:</div>
      <div className="text-left flex-grow">
        {renderContent()}
        {renderOutput()}
      </div>
    </div>
);
};


    // Handle message submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        console.log(inputMessage)
        
        const response = await promptArty_SSE(context._id, inputMessage);
        
        setInputMessage('');
        setIsLoading(false);
    };
                   

            

    const handeKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            // event.stopPropagation()
            handleSubmit(event)
        }
    }

    const chatPrompts = (chat) => {
        return (
            <div className="">
                {chat.examples && <div>  
                    <div className = "flex items-center justify-center mt-[100px] text-[#7C7C7C] text-[25px]">Ask Arty!</div>
                    <div className = "flex flex-col items-center justify-center">
                        <HiOutlineLightBulb className = "flex items-center  self-center justify-center mt-[10px] h-7 w-7 stroke-[#7C7C7C]"></HiOutlineLightBulb>
                        <div className = "flex items-center justify-center mt-[5px] text-[#7C7C7C] text-[18px]">Examples</div>
                    </div>
                    <div className = "flex items-center justify-center mt-[10px]">
                        <div className = "w-1/2 flex flex-col justify-center self-center text-center rounded-lg bg-[#E3E3E3]">
                            <List
                            dataSource={chat.examples}
                            renderItem={(item) => (
                                <List.Item className = "hover:bg-[#E9E9E9] p-[10px]" onClick={() => accessPrompt(item.prompt)}>
                                    <List.Item.Meta 
                                        title = {item.prompt}
                                    />
                                </List.Item>
                            )}
                            >
                            </List>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }

    const handleInputChange = (event) => {
        // console.log('event:', event)
        setInputMessage(event.target.value);
    }



    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [chat]);
        // console.log(chat)

    
    const sidebarContent = () => {
        return (
          <div className='sider-style ml-[20px] mt-5 overflow-y-scroll'> 
            <div onClick={() => {togglePastChats()}} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
                <DownOutlined className='duration-[50ms]' style={showChats ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
                Chat History
             </div>
            <div className={showChats ? 'chat-sider' : 'chat-sider-hidden'}>
                {chatList && 
                <List
                dataSource={chatList}
                renderItem={(item, index) => (
                    <List.Item className = {chat._id === item._id? 'relative bg-[#f0f0f0] hover:bg-[#EaEaEa] rounded-lg focus:bg-[#E7E7E7] rounded-lg' : 'relative hover:bg-[#E7E7E7] focus:bg-[#E7E7E7] rounded-lg'} style={{height: '45px'}} onClick={() => itemClick(item)} onDoubleClick={() => itemDoubleClick(item)} onMouseEnter={() => setHoveredChat(item)} onMouseLeave={() => setHoveredChat([])}>
                        <List.Item.Meta
                            avatar = {<BsChatLeftText size = "0px"/>}
                            title = {getChatName(item)}
                        />
                        <a className='text-[11px] ml-10px mb-[0px] mt-[0px] absolute top-[22px] left-[35px] select-none' style={ (hoveredChat._id === item._id && isEditing === null) || (chat._id === item._id && isEditing === null)? {transition: '100ms ease-in-out', opacity: '1'} : {transition: '100ms ease-in-out', opacity: '0'}}>{"number of messages: " + (item.num_messages ?  item.num_messages : 0)} </a>
                        <button onClick={(event) => {onDelete(item, chatList.length, index); event.stopPropagation();}}>
                            <BsTrashFill className={ hoveredChat._id === item._id || chat._id === item._id? 'visible fill-[#B9B8B8] hover:fill-[#A4A4A4] mt-[5px] ml-[5px] mr-[10px]' : 'hidden'}></BsTrashFill>
                        </button>
                    </List.Item>
                )}
                >
                </List>}
            </div>
          </div>
        );
    }
    return (
      <div className='flex h-full overflow-y-auto'>
        <ArtyChatContext.Provider>

            <div className="bg-gray-200 w-full h-full p-8 rounded-lg">

        <Sidebar setSidebarData = {()=>{}} openSidebar={artySidebarOpen} setOpenSidebar = {setArtySidebarOpen} sidebarContent = {sidebarContent()} removePadding={true}>
            <div className="flex w-full relative flex-col justify-between h-full bg-white">

<Spin indicator={<LoadingOutlined style={{ fontSize: 24, top: '15vh', position: 'absolute' }} spin />} spinning={fetchingChat}>
  <div className="overflow-y-auto p-4 h-full" style={{ maxHeight: 'calc(100vh - 250px)' }}>  {/* Adjust maxHeight as per your layout */}
    {chat.data && chat.data.length > 0 ? (
      <div className="overflow-auto h-full">
        {chat.data.map(renderMessage)}
      </div>
    ) : (
      <div>No messages yet</div>
    )}
    <div ref={messagesEndRef} />
  </div>
</Spin>

                <div className="border-t-2 border-gray-200 p-4">
                    {isLoading ? (
                    <input className= "w-full rounded-md border-gray-300 shadow-sm p-2" disabled placeholder="Loading..." />
                    ) : (
                        <form onSubmit={(e) => handleSubmit(e)} className="mt-[8px] mb-[8px] flex justify-content">
                            <div className="input-field w-full">
                                <textarea
                                    id="textarea"
                                    className="w-full h-[40px] max-h-[100px] outline-gray-400 rounded-lg p-2 resize-none materialize-textarea"
                                    value={inputMessage}
                                    onKeyDown={handeKeyDown}
                                    onChange={handleInputChange}
                                    onInput={adjustTextareaHeight}
                                    placeholder="Type your message..."
                                    disabled={isLoading}
                                    autoFocus={true}
                                />
                            </div>
                            <input
                                className="h-[40px] ml-2 text-gray-500 p-2 rounded-lg cursor-pointer"
                                style={{ backgroundColor:'black' }}
                                type="submit"
                                value="Send"
                                disabled={isLoading}
                            />
                        </form>
                    )}
                </div>
            </div>
        </Sidebar>

        </div>
        </ArtyChatContext.Provider>

      </div>

    );
}

const CustomTooltip = ({active, payload, label, yAxisTitle}) => {
    const tooltipStyle = {
      border: "1px solid #CCCC",
      backgroundColor: "white",
      padding: "8px",
    };
  
    if (active && payload && payload.length) {
      const formattedValue = payload[0].value.toLocaleString(); 
      return (
        <div className="custom-tooltip"
          style={tooltipStyle}
          >
          <p className="label">{`${label}`}</p>
          <p className="value" style = {{fontSize: "13px", color: "#1f422a"}}>{yAxisTitle? `${yAxisTitle}: ${formattedValue}` : `${formattedValue}`} </p>
        </div>
      );
    }
  
    return null;
  };

const formatYAxisValue = (value) => {
    const stringValue = value.toString();
    const characters = stringValue.split('');
    
    const reversedCharacters = characters.reverse();
    
    const formattedValue = reversedCharacters.reduce((accumulator, character, index) => {
      if (index !== 0 && index % 3 === 0) {
        return `${accumulator},${character}`;
      }
      return `${accumulator}${character}`;
    }, '');
    
    return formattedValue.split('').reverse().join('');
};
  
export default App;


