
export const FivetranIcon = () => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"    
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-12 h-12"
  >

<g>
	<path className="st0" d="M40.8,32h6.4c0.5,0,1-0.4,1-1c0-0.1,0-0.3-0.1-0.4L37.1,0.6C36.9,0.3,36.6,0,36.2,0h-6.4c-0.5,0-1,0.4-1,1
		c0,0.1,0,0.2,0.1,0.3l11.1,30.2C40.1,31.8,40.4,32,40.8,32z"/>
	<path className="st0" d="M39.7,64h6.4c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.3L24.2,0.6C24.1,0.3,23.7,0,23.3,0h-6.4c-0.5,0-1,0.4-1,1
		c0,0.1,0,0.2,0.1,0.3l22.8,62.1C38.9,63.8,39.3,64,39.7,64z"/>
	<path className="st0" d="M27,64h6.4c0.5,0,0.9-0.4,1-0.9c0-0.1,0-0.3-0.1-0.4L23.2,32.6c-0.1-0.4-0.5-0.6-0.9-0.6h-6.5
		c-0.5,0-0.9,0.5-0.9,1c0,0.1,0,0.2,0.1,0.3l11,30.1C26.3,63.8,26.6,64,27,64z"/>
	<path className="st0" d="M41.6,1.3l5.2,14.1c0.1,0.4,0.5,0.6,0.9,0.6H54c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.3L49.7,0.6
		C49.6,0.3,49.3,0,48.9,0h-6.4c-0.5,0-1,0.4-1,1C41.5,1.1,41.5,1.2,41.6,1.3z"/>
	<path className="st0" d="M15.2,64h6.4c0.5,0,1-0.4,1-1c0-0.1,0-0.2-0.1-0.3l-5.2-14.1c-0.1-0.4-0.5-0.6-0.9-0.6H10c-0.5,0-1,0.4-1,1
		c0,0.1,0,0.2,0.1,0.3l5.2,14.1C14.4,63.8,14.8,64,15.2,64z"/>
</g>
</svg>);
