import './style.scss';
import { PlusOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { Card, Tooltip } from 'antd';
import { ConnectorContext } from '../../../context/ConnectorProvider';

const AddConnector = () => {
  const { setVisible } = useContext(ConnectorContext);

  return (
    <Tooltip title="Add New Connector">
      <Card className="add-connector-container hover:bg-gray-100 border-[1px] border-dashed border-gray-300"onClick={() => setVisible(true)}>
        <PlusOutlined style={{ fontSize: 32 }} className="add-connector-icon" />
      </Card>
    </Tooltip>
  );
};

export default AddConnector;
