

const TableGraphModal = ({ isOpen, onCancel, onAccept, targetNodeModelName, showModelLogicControls}) => {   
    if (!isOpen) return null;

    const handleBackdropClick = (e) => {
        if (e.currentTarget === e.target) {
            onCancel(); 
        }
      };

    return (
        <div onClick={handleBackdropClick} className="fixed inset-0 z-50 bg-gray-700 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-lg p-6 m-4">
                <h1 className="text-2xl font-semibold text-gray-800">Caution: model data overwrite</h1>
                <p className="mt-4 text-gray-600">
                    Connecting this node will lose all your settings on the target node (<span className="font-medium">{targetNodeModelName}</span>).
                </p>
                <p className="mt-2 text-gray-600">Would you like to continue?</p>
                <div className="flex justify-around mt-6">
                    <button
                        className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 transition duration-200 ease-in-out"
                        onClick={() => onAccept(true)}
                    >
                        {showModelLogicControls ? 'Overwrite' : 'Continue'}
                    </button>
                    {showModelLogicControls && (
                        <div>
                        <button
                        className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 transition duration-200 ease-in-out"
                        onClick={() => onAccept(false)}
                        >
                          {showModelLogicControls ? 'Continue Without Overwritting' : 'Continue'} 
                        </button> 
                        </div>)}
                    <button
                        className="bg-red-500 text-white py-2 px-6 rounded hover:bg-red-700 transition duration-200 ease-in-out"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    ) 
}

export default TableGraphModal;
