import { createContext, useState, useEffect, useRef } from 'react';
import { useAuthInfo } from '@propelauth/react';
import LetoClient from './LetoClient'
import { useLocation } from 'react-router-dom';




export const TableManagementContext = createContext();

export const TableManagementProvider = ({ children }) => {
  const [editCells, setEditCells] = useState([])
  const [rows, setRows] = useState({})
  const [columns, setColumns] = useState({})
  const [cellEditFailure, setCellEditFailure] = useState(false)
  const [cellEditSuccess, setCellEditSuccess] = useState(false)
  const [cellEditDetail, setCellEditDetail] = useState('')

  const [dataLoading, setDataLoading] = useState({})
  const [uselessState, setUselessState] = useState(false)
  const [retrieveError, setRetrieveError] = useState({})
  const [baseRows, setBaseRows] = useState({})
  const tabs = useState()
  const activeTab = useState(0)
  const [tabID, setTabID] = useState(0)
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [tabList, setTabList] = useState([{key: 0, table: 'Welcome', schema: ''}]); 
  const newTabIndex = useRef(1);
  const [tableList, setTableList] = useState()
  const [filteredTableList, setFilteredTableList] = useState()
  const [getTableLoading, setGetTableLoading] = useState({})
  const authInfo = useAuthInfo();
  const [tableListLoading, setTableListLoading] = useState(false)
  const [tableSidebarOpen, setTableSidebarOpen] = useState(true);
  const [screenType, setScreenType] = useState({});
  const [favTablesList, setGetFavTables] = useState([{}]);
  const [createFavTable, setCreateFavTable] = useState('');
  const [deleteFavTable, setDeleteFavTable] = useState('');

  const { pathname } = useLocation()

  useEffect(() => {
    setTimeout(() => {
      setCellEditFailure(false)
      setCellEditDetail('')
    }, 4000)
  }, [cellEditFailure])
  
  useEffect(() => {
    if (/\/tables/.test(pathname)) {
      getAllTables();
      getAllFavouriteTables();
    }
    else if (/\/contexts/.test(pathname)) {
      getAllTables();
    }
    else if (/\/editor/.test(pathname)) {
      getAllTables();
    }
  }, [pathname]);

  const getAllTables = async () => {
    if (!tableList || !tableList.length) {
      setTableListLoading(true)
    }
    if (!tableList ) {
      // Send cached true request if table list does not exist (not loaded yet)
      const cachedResponse = await LetoClient.getAllTables(true)
      if (cachedResponse) {
        setTableList(cachedResponse)
        if (!filteredTableList) {
          setFilteredTableList(cachedResponse)
        }
        setTableListLoading(false)
      }
    }
    // Send non-cached request 
    const nonCachedResponse = await LetoClient.getAllTables(false)
    if (nonCachedResponse) {
      setTableList(nonCachedResponse)
      if (!filteredTableList) {
        setFilteredTableList(nonCachedResponse)
      }
      setTableListLoading(false)
    }
  }

  const getTable = async (tab_id, table) => {
    setUselessState(true)
    const errorTemp = retrieveError;
    errorTemp[tab_id] = null;
    var loading = getTableLoading
    loading[tab_id] = true
    setGetTableLoading(loading)
    const response = await LetoClient.getTable(table)
    if (response.error) {
      errorTemp[tab_id] = response.error;
      setRetrieveError(errorTemp);
    }
    else {
      const tempColumns = columns;
      tempColumns[tab_id] = response.columns;
      setColumns(tempColumns);
      const tempRows = rows;
      tempRows[tab_id] = response.rows;
      setRows(tempRows);
      setBaseRows(tempRows)
    }
    loading = getTableLoading
    loading[tab_id] = false
    setGetTableLoading(loading)
    setUselessState(true)
    setUselessState(false)
  }

  const getTableSchema = async (table_name) => {
    const response = await LetoClient.getTableSchema(table_name);
    return response;
  };

  const updateTableData = async (tab_id, table_name, value, primary_key_value, column_name, primary_key_column) => {
    //TODO: prevent from leaving the cell until a response is recieved from the BE
    //TODO: test that cell was really updated before sending request to BE. If not, do nothing and exit cell
    //TODO: switch to finding primary key through BE to build query string. Have to pass the whole row in the api request

    const query_first = "UPDATE " + table_name + " SET " + table_name + "." + column_name + " = "
    const query_second = " WHERE " + primary_key_column + " = '" + primary_key_value + "'" 

    try {

      setUselessState(true)
      const response = await LetoClient.updateCell({value: value, query_first: query_first, query_second: query_second, table: table_name, column: column_name, pk_value: primary_key_value, pk_column: primary_key_column})
      console.log(response)
      if ( response.error ) {
          //update response error
          //TODO: handle errors & deal with value types
          setCellEditFailure(true)
          setCellEditDetail(response.error)
          //revert the data displayed back to original
      }
      else {
        setCellEditSuccess(true)
        setCellEditDetail(response.error)
        //TODO: update base table to reflect new table
      }
      setUselessState(false)
    }
    catch (err) {
      console.log('ERROR:')
      console.log(err)
    }
      
  }

  const getAllFavouriteTables = async () => {
    const response = await LetoClient.getAllFavouriteTables();
    setGetFavTables(response);
  }

  const createNewFavouriteTable = async (table) => {
    const response = await LetoClient.createFavouriteTable(table);
    setCreateFavTable(response);
  };

  const deleteFavouriteTable = async (table) => {
    const response = await LetoClient.deleteFavouriteTable(table);
    setDeleteFavTable(response);
  };


  // const updateTableData = async (tab_id, table_name, row, columns, updated_column) => {
  //   //TODO: prevent from leaving the cell until a response is recieved from the BE
  //   //TODO: test that cell was really updated before sending request to BE. If not, do nothing and exit cell
  //   //TODO: switch to finding primary key through BE to build query string. Have to pass the whole row in the api request
  //   console.log(row)
  //   console.log(columns)
  //   console.log(columns[updated_column])
  //   let body = {table: table_name, row: row, columns: columns, updated_column: updated_column}
  //   setUselessState(true)
  //   const response = await LetoClient.updateCell(body)
  //   console.log(response)
  //   if ( response.error ) {
  //       //update response error
  //       //TODO: handle errors & deal with value types
  //       console.log(response.error)
  //       setCellEditFailure(response.error)
  //       //revert the data displayed back to original
  //   }
  //   setUselessState(false)
  // }


  return(
    <TableManagementContext.Provider
      value={{
        rows,
        setRows,
        columns,
        setColumns,
        cellEditFailure,
        setCellEditFailure,
        dataLoading,
        setDataLoading,
        uselessState,
        setUselessState,
        getTable,
        getTableSchema,
        retrieveError,
        setRetrieveError,
        tabs,
        activeTab,
        baseRows,
        setBaseRows,
        updateTableData,
        editCells,
        setEditCells,
        activeTabKey, 
        setActiveTabKey,
        newTabIndex,
        getAllTables,
        tableList,
        setTableList,
        filteredTableList,
        setFilteredTableList,
        tabList,
        setTabList,
        tabID,
        setTabID,
        getTableLoading,
        setGetTableLoading,
        tableListLoading,
        setTableListLoading,
        tableSidebarOpen,
        setTableSidebarOpen,
        screenType,
        setScreenType,
        cellEditDetail,
        setCellEditDetail,
        getAllFavouriteTables,
        createNewFavouriteTable,
        deleteFavouriteTable,
        favTablesList,
        createFavTable,
        deleteFavTable, 
      }}>
        {children}
    </TableManagementContext.Provider>
  );
};

export default TableManagementProvider;    
