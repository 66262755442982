import React, { createContext, useContext, useState, useEffect } from 'react';
import GraphClient from './GraphClient.js';

export const GitContext = createContext();

export const GitProvider = ({ children }) => {
  const [branchData, setBranchData] = useState({});
  const [branchContext, setBranchContext] = useState({})
  
  const [commitStatus, setCommitStatus] = useState({})
  const [branchStatus, setBranchStatus] = useState({}) 
  const [branchDataLoading, setBranchDataLoading] = useState(false)
  const [noGitConnection, setNoGitConnection] = useState(null)

  useEffect(() => {
    const fetchBranchData = async () => {
      if (!branchData || Object.keys(branchData).length === 0) {
        setBranchDataLoading(true)
        try {
          const response = await GraphClient.getCurrentBranches();
          if (response.data) {
            setBranchData(response.data);
          }
          if (response.no_github_connection) {
            setNoGitConnection(response.no_github_connection)
          }
        } catch (error) {
          console.error('Failed to fetch branch data:', error);
        }
        setBranchDataLoading(false)
      }
    };
    fetchBranchData();
  }, [branchData]);  
   
  useEffect(() => {
    const fetchCurrentBranchContext = async () => { 
      if (Object.keys(branchData).length > 0) {
        setBranchDataLoading(true)
        try {

          let response = await GraphClient.getCurrentBranchContext();

          // let response = await GraphClient.getCommitStatus()

          if (response.data) {
            setCommitStatus(response.data.commit);
            setBranchStatus(response.data.branch)

            // setBranchContext(response.data);

          }
        } catch (error) {
          console.error('Failed to fetch branch context:', error);
        }
        setBranchDataLoading(false)
      }
    }
    fetchCurrentBranchContext();
  }, [branchData]); 


  const [commitHistory, setCommitHistory] = useState([]);
  const [activeBranch, setActiveBranch] = useState('main');

  const fetchCurrentBranchContext = async () => { 
      setBranchDataLoading(true)
        try {
          let response = await GraphClient.getCurrentBranchContext();
          if (response.data) {
            setCommitStatus(response.data.commit);
            setBranchStatus(response.data.branch)
          }
        } catch (error) {
          console.error('Failed to fetch branch context:', error);
        }
        setBranchDataLoading(false)
  }


    const fetchBranchData = async () => {
      if (!branchData || Object.keys(branchData).length === 0) {
        try {
          const response = await GraphClient.getCurrentBranches();
          if (response.data) {
            setBranchData(response.data);
          }
          if (response.no_github_connection) {
            setNoGitConnection(response.no_github_connection)
          }
        } catch (error) {
          console.error('Failed to fetch branch data:', error);
        }
      }
    };

  const switchBranch = async (branchName, remote) => {
    setBranchDataLoading(true)
    try {
      const response = await GraphClient.setCurrentBranch(branchName, remote);
      if (response.data){
        setBranchData(response.data)
      }
      setBranchDataLoading(false)
      if (response.error) {
        return response.error
      }
    }
    catch {
      setBranchDataLoading(false)
      return 'Server error, unable to switch branches.'
    }
  }
  const createBranch = async (branchName) => {
    setBranchDataLoading(true)
    try {
      let data = {
        'branch_name': branchName
      }
      const response = await GraphClient.createAndPushBranch(data);
      if (response.data){
        setBranchData(response.data)
      }
    }
    catch {

    }
    setBranchDataLoading(false)
   }  

  const stageUnstageFile = async (file, isStage) => {
    setBranchDataLoading(true)
    let data = {
      'flag' : isStage,
      'files' : [file]
    }
    try {
        let response = await GraphClient.setStageUnstageFiles(data);
        setCommitStatus(response.data)
    } catch (error) {
          console.error('Failed to fetch branch context:', error);
    } 
    setBranchDataLoading(false)
  }

  const commitStagedChanges = async (message) => {
    let data = {
      'message' : message,
    }
    try {
        let response = await GraphClient.commitStagedFiles(data);
        setCommitStatus(response.data)
    } catch (error) {
          console.error('Failed to fetch branch context:', error);
    } 
  }


  const handlePush = async () => {
      setBranchDataLoading(true)
      try {
          let response = await GraphClient.pushRemoteBranch();
          setBranchDataLoading(false)
          if (response.data)
            setBranchStatus(response.data)
          if (response.error) {
            return response.error
          }
        } catch (error) {
          setBranchDataLoading(false)
          return 'Failed to push to remote: ' + String(error)
      } 
  }

  const handlePull = async () => {
      setBranchDataLoading(true)
      try {
          let response = await GraphClient.pullRemoteBranch();
          setBranchDataLoading(false)
          if (response.error)
            return response.error
        } catch (error) {
          setBranchDataLoading(false)
          return 'Failed to pull remote: ' + String(error)
      } 
  }

  const handleFetch = async () => {
      setBranchDataLoading(true)
      try {
          let response = await GraphClient.fetchRemoteBranch();
          setBranchStatus(response.data)
        } catch (error) {
          console.error('Failed to fetch branch context:', error);
      } 
      setBranchDataLoading(false)
  }

  const getFileDiff = async (file) => { 
        try {
          let response = await GraphClient.getCurrentFileDiff(file);
          if (response.data) {
            setBranchContext(response.data);
          }
        } catch (error) {
          console.error('Failed to fetch branch context:', error);
        }
  }

  const getBranchData = async () => {
    setBranchDataLoading(true)
    try {
      const response = await GraphClient.getCurrentBranches();
      if (response.data) {
        setBranchData(response.data);
      }
      setNoGitConnection(response.no_github_connection)
    } catch (error) {
      console.error('Failed to fetch branch data:', error);
    }
    setBranchDataLoading(false)
  }

  const stashFleChanges = async (file_path) => {
    setBranchDataLoading(true)
    try {
      const response = await GraphClient.stashChanges(file_path);
      setBranchDataLoading(false)
      if (response.data) {
        setCommitStatus(response.data);
      }
      if (response.error) {
        return response.error
      }
    } catch (error) {
      setBranchDataLoading(false)
      console.error('Failed to fetch branch data:', error);
    }
  }

  return (
    <GitContext.Provider
      value={{
        branchData, switchBranch, createBranch,
        branchContext, fetchCurrentBranchContext,
        noGitConnection,
        setNoGitConnection,
        commitStatus, setCommitStatus,
        branchStatus, setBranchStatus,
        stageUnstageFile, getFileDiff,
        commitStagedChanges,
        handlePush, handlePull, handleFetch,
        getBranchData,
        branchDataLoading,
        stashFleChanges
      }}
    >
      {children}
    </GitContext.Provider>
  );
};

