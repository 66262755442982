import { Row, Col } from 'antd';
import { useContext, useEffect } from 'react';

import ConsumptionUnits from '../../components/ConsumptionUnits';
import PaymentMethod from '../../components/PaymentMethod';
import JobsTime from '../../components/JobsTime';
import StorageSpace from '../../components/StorageSpace';
import Page from '../../components/Page';
import { SubscriptionContext } from '../../context/SubscriptionProvider';
import LetoClient from '../../context/LetoClient';
import { useAuthInfo } from '@propelauth/react';
import PurchaseCreditModal from '../../components/Stripe/PurchaseCreditModal';

const Billings = () => {
  const authInfo = useAuthInfo();

  const {
    credits,
    subscriptionType,
    get_subscription_info,

  } = useContext(SubscriptionContext)

  //every 30 mins, call to BE to get credit info
  useEffect(() => {
    LetoClient.setToken(authInfo.accessToken)
    get_subscription_info()
    setInterval(function () {
      get_subscription_info()
    }, 1800000)
    // 1800000 is 30 mins
  }, [])
  
  return (
    <Page>
      <PurchaseCreditModal/>
      <div className="billings">
        <Row gutter={[32, 32]}>
          <Col md={24} xl={12}>
            <ConsumptionUnits />
          </Col>
          <Col md={24} xl={12}>
            <PaymentMethod />
          </Col>
          <Col md={24} xl={24}>
            <StorageSpace />
          </Col>
          {/* <Col md={24} xl={8}>
            <JobsTime />
          </Col> */}
        </Row>
      </div>
    </Page>
  );
};

export default Billings;
