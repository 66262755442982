import React, { PureComponent, useContext } from 'react';
import DataGrid from 'react-data-grid'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArtyAIContext } from '../../context/ArtyAIProvider';


export const SingleValue = ({chart_data, rows}) => {
    const {
        gptSQL,
        tableInfo,
        chartData,
        view,
        setView,
    } = useContext(ArtyAIContext)
    console.log(chart_data)
    console.log(rows)

    return (
      <div width="100%" style={{overflow:"hidden", background:'white', fontSize:"0.8vw", textAlign:'center'}}>
        {JSON.stringify(rows[0]).replace(/[{}]/g, '')}
           {/* (<div><pre>{JSON.stringify(rows[0], null, 2) }</pre></div>); */}
            
  
      </div>
      
      
      )


}
