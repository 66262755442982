import axios from 'axios';
export const API_URL = getApiUrl();

class _LetoClient {
  token = '';
  FTgroupId = '';

  constructor() {
    this.baseUrl = API_URL;
  }
  getGroupId() {
    return this.FTgroupId;
  }

  setGroupId(id) {
    this.FTgroupId = id;
  }

  setToken(token) {
    this.token = token;
  }

  async create_payment_intent(amount) {
    const responseData = await this.execute(`post`, `/stripe/create-payment-intent`, { "amount": amount });
    return responseData;
  }

  async getMe() {
    const responseData = await this.execute(`get`, `/users/me`, {});
    return responseData;
  }

  async connectArtemisManaged(accessCode) {
    const responseData = await this.execute(`post`, `/environment/onboard/artemis-managed`, {
      "access_code": accessCode,
    });

    console.log(responseData)
    return responseData
  }

  async getTable(table) {
    const response = await this.execute(`get`, `/table_manager/` + table.value)
    return response
  }

  async getAllTables(cached) {
    const response = await this.execute(`get`, `/table_manager/?cached=${cached}`)
    return response
  }

  async updateCell(body) {
    const response = await this.execute(`post`, `/table_manager/cell`, body)
    return response
  }


  async costHistory() {
    const response = await this.execute(`get`, `/subscriptions/credit_usage`); 
    console.log(response)
    return response
  }

  async connectWarehouse(body) {

    if (!("host" in body)) {
      body["host"] = ""
    }
    if (!("pat" in body)) {
      body["pat"] = ""
    }
    if (!("sql_path" in body)) {
      body["sql_path"] = ""
    }
    if (!("user" in body)) {
      body["user"] = ""
    }
    if (!("password" in body)) {
      body["password"] = ""
    }
    if (!("port" in body)) {
      body["port"] = ""
    }
    if (!("database" in body)) {
      body["database"] = ""
    }


    const responseData = await this.execute(`post`, `/environment/onboard/connect-warehouse`, body);

    console.log(responseData)
    return responseData
  }

  async warehouseRequiredFields(warehouseType) {
    const responseData = await this.execute(`get`, `/environment/onboard/config/${warehouseType}`, {});
    return responseData
  }


  async requestWarehouse(warehouseType) {
    const responseData = await this.execute(`get`, `/environment/onboard/request-warehouse/${warehouseType}`, {});
    return responseData
  }

  async createOrganization(orgName) {
    const responseData = await this.execute(`post`, `/environment/onboard/create-org`, {
      "org_name": orgName,
    });
    return responseData
  }

  async getWorkSpaces() {
    const responseData = await this.execute(`get`, `/workspace/`, {});
    return responseData;
  }

  async updateWorkSpaces(body) {
    const responseData = await this.execute(`put`, `/workspace/`, body);
    return responseData.token;
  }

  async createWorkSpaces(body) {
    const responseData = await this.execute(`post`, `/workspace/`, body);
    return responseData.token;
  }

  async deleteWorkSpaces(body) {
    const responseData = await this.execute(`delete`, `/workspace/`, body);
    return responseData;
  }

  async tempCheck(name) {
    const responseData = await this.execute(`post`, `/connector/createFTConnectors/temporary_check`, { name: name });
    return responseData
  }

  async createFTConnector(request_body) {
    request_body['group_id'] = this.FTgroupId;
    var responseData;
    if (request_body['service'] === 'google_analytics') {
      request_body['config']['show_reports_on_pbf'] = true;
      request_body['config']['reports'] = [{ table: request_body['config']['schema'], dimensions: ['date'] }];
    } else if (request_body['service'] === 'google_analytics_4') {
      request_body['config']['pbf_fields'] = ['REPORTS', 'HISTORICAL_SYNC_TIMEFRAME', 'ACCOUNT_SETTINGS'];
      request_body['config']['reports'] = [{ table: request_body['config']['schema'], dimensions: ['date'] }];
    } else if (request_body['service'] === 'dropbox') {
      delete request_body['config']['table'];
    } else if (request_body['service'] === 'facebook_ads') {
      request_body['config']['timeframe_months'] = 'TWELVE'
    }
    responseData = await this.execute(`post`, `/connector/createFTConnectors`, { request_body: request_body });
    let result = responseData;
    return result;
  }

  async syncConnector(connector_id) {
    const responseData = await this.execute(`get`, `/connector/sync/${connector_id}`, {});
    return responseData;
  }

  async getMetadata() {
    const responseData = await this.execute(`get`, `/connector/metadata`, {});
    return responseData;
  }

  async getConnectorsInGroup() {
    const response = await this.execute(`get`, `/connector/connectors_by_group/${this.FTgroupId}`, {});
    return response;
  }

  async createConnector(id, name, service, created_at) {
    const responseData = await this.execute(`post`, `/connector/`, {
      connector_id: id,
      connector_name: name,
      service: service,
      created_at: created_at
    });
    return responseData;
  }

  async patchConnectorSchema(id, body) {
    const responseData = await this.execute(`post`, `/connector/schema`, {
      connector_id: id,
      body: body,
    });
    return responseData;
  }

  async getRelations(connector_id_list) {
    const responseData = await this.execute(`post`, `/connector/workspace_relations`, {
      connector_id_list: connector_id_list,
    });

    return responseData;
  }

  async toggleWorkSpace(workspace_id, connector_id, connector_name) {
    const responseData = await this.execute(`post`, `/connector/workspace`, {
      workspace_id: workspace_id,
      connector_id: connector_id,
      connector_name: connector_name,
    });
    return responseData.data;
  }

  async get_subscription_info() {
    const responseData = await this.execute(`get`, `/subscriptions/subscription_info`, {});
    return responseData
  }

  async applyPromo(code) {
    const responseData = await this.execute(`post`, `/subscriptions/apply_promo`, {
      "promo_code": code,
    })
  }

  async approveCertificate(certificate_info) {
    const body = {
      "destination_id": certificate_info.destination_id,
      "certificate": certificate_info.certificate
    }
    const responseData = await this.execute(`post`, `/environment/onboard/approve-certificate`, body)
    return responseData
  }

  async execute(method, path, data) {
    console.log(`${method}, ${path}`);
    let headers = {
      Authorization: 'Bearer ' + this.token,
      accept: 'application/json',
    };
    // if (data) console.log(data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: data,
    });
    console.log(response.status);
    if (response.status >= 400) throw Error(response.data);

    // console.log(response.data);
    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      console.log(`${method}, ${path}, ${cursor}`);
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        console.log(response.data);
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }

  // added methods:
  async saveTransformation(query_string, destination_schema, destination_table, interval) {
    const responseData = await this.execute(`post`, `sql/transformation`, {
      query_string: query_string,
      destination_schema: destination_schema,
      destination_table: destination_table,
      interval: interval,
    });
  }

  async saveTable(query_string, destination_schema, destination_table) {
    const responseData = await this.execute(`post`, `sql/table/save`, {
      query_string: query_string,
      destination_schema: destination_schema,
      destination_table: destination_table,
    });
    return responseData;
  }

  async deleteSavedPrompt(id) {
    const responseData = await this.execute(`post`, `ai/delete_saved_prompt`, {
      id: id
    });
  }

  async renameSavedPrompt(id, name) {
    const responseData = await this.execute(`post`, `ai/rename_saved_prompt`, {
      id: id,
      name: name
    });
  }

  async deleteChartBoard(id) {
    const responseData = await this.execute(`delete`, `chart/board`, {chart_board_id: id})
  }

  async getTableForQuery(sql) {
    const responseData = await this.execute(`post`, `ai/table_for_query`, {
      sql: sql
    });
    return responseData;
  }

  async getTableForQuery(sql) {
    const responseData = await this.execute(`post`, `ai/table_for_query`, {
      sql: sql
    });
    return responseData;
  }

  async getSavedPrompts() {
    const responseData = await this.execute(`get`, `ai/saved_prompts`, {});
    return responseData;
  }

  async savePrompt(prompt, sql, chart_data, schema_name, table_name, name) {
    const responseData = await this.execute(`post`, `ai/save_new_prompt`, {
      prompt: prompt,
      sql: sql,
      chart_data: chart_data,
      name: name,
      schema_name: schema_name,
      table_name: table_name,
    });
    return responseData;
  }

  async saveChart(chart, chart_board_id) {
    chart['chart_board_id'] = chart_board_id
    const responseData = await this.execute(`post`, `chart/`, chart);
    return responseData;
  }

  async saveTableToDashBoard(table) {
    const responseData = await this.execute(`post`, `chart/`, table);
    return responseData;
  }

  async deleteChart(chart_id) {
    const responseData = await this.execute(`delete`, `chart/`, {'id': chart_id});
    return responseData;
  }

  async deleteAnalyticsChart(chart_id) {
    const responseData = await this.execute(`delete`, `analytics_chart/${chart_id}`, {});
    return responseData;
  }

  async duplicateChart(chart_id) {
    const responseData = await this.execute(`get`, `analytics_chart/duplicate/${chart_id}`);
    return responseData;
  }

  async addChartBoard(name) {
    const responseData = await this.execute(`post`, `chart/board`, {name: name});
    return responseData;
  }

  async editChartBoard(name, id) {
    const responseData = await this.execute(`put`, `chart/board`, {name: name, chart_board_id: id});
    return responseData;
  }

  async getChartBoards() {
    const responseData = await this.execute(`get`, `chart/`);
    return responseData;
  }

  async getSavedCharts(chart_board_id) {
    const responseData = await this.execute(`get`, `chart/` + chart_board_id);
    return responseData;
  }

  async saveAnalyticsChart(chart_name) {
    const responseData = await this.execute(`post`, `analytics_chart/`, {
      chart_name: chart_name,
    });
    return responseData;
  }

  async editAnalyticsChart(id, chart_name, analytics_schema, analytics_table, chart_type, filters, x_axis, y_axis, groups, time_column, time_grain, favorited) {
    const responseData = await this.execute(`put`, `analytics_chart/`, {
      id: id,
      chart_name: chart_name,
      analytics_schema: analytics_schema,
      analytics_table: analytics_table,
      chart_type: chart_type,
      filters: filters,
      x_axis: x_axis,
      y_axis: y_axis,
      groups: groups,
      time_column: time_column,
      time_grain: time_grain,
      favorited
    });
    return responseData;
  }

  async loadAnalyticsChart(id) {
    const responseData = await this.execute(`get`, `analytics_chart/load/` + id, {});
    return responseData;
  }

  async getAnalyticsChartData(chart_id, table, y_axis_cols, filters, groups, time_data) {
    const responseData = await this.execute(`post`, `analytics_chart/data`, {
      chart_id, 
      table: table,
      y_axis_cols: y_axis_cols,
      filters: filters,
      groups: groups,
      time_data: time_data
    });
    return responseData;
  }

  async getAnalyticsChartDataById(id) {
    const responseData = await this.execute(`get`, `analytics_chart/data/` + id, {})
    return responseData
  }

  async getAnalyticsFavorites() {
    const responseData = await this.execute(`get`, `analytics_chart/favorites`)
    return responseData;
  }

  async getAnalyticsRecents() {
    const responseData = await this.execute(`get`, `analytics_chart/recents`)
    return responseData;
  }

  async getInsights(table_list, prompt) {
    const responseData = await this.execute(`post`, `ai/prompt/query`, {
      table_list: table_list,
      prompt: prompt
    });
    console.log(responseData)
    return responseData
  }
  
  async pollArtyInsights() {
    const responseData = await this.execute(`get`, `ai/prompt/query/poll`);
    console.log(responseData)
    return responseData
  }

  async customSQL(sql) {
    const responseData = await this.execute(`post`, `ai/prompt/custom_sql`, {
      custom_sql: sql
    });
    console.log(responseData)
    return responseData
  }

  async getArtyAISchemas() {
    const responseData = await this.execute(`get`, `sql/artyai/schemas`, {});
    return responseData;
  }

  async pinChart(chart_id, dashboard_id) {
    const responseData = await this.execute(`post`, `analytics_chart/pin`, {
      chart_id: chart_id, chartboard_id: dashboard_id
    })
    return responseData
  }

  async getDBSchemas(cached=true) {
    const responseData = await this.execute(`get`, `sql/schemas?cached=${cached}`, {});
    return responseData;
  }

  async getInfo() {
    const responseData = await this.execute(`get`, `/sql/info`, {});
    return responseData;
  }

  async updateChartboardName(body) {
    const responseData = await this.execute(`put`, `/chart/board`, body);
    return responseData;
  }

  async postQuery(query_string) {
    const responseData = await this.execute(`post`, `/sql/query`, {
      query_string: query_string,
    });
    return responseData;
  }

  async getSidebarQueries() {
    const responseData = await this.execute(`get`, `/sql/query/save`)
    return responseData
  }

  async getQuerySavedData(query_id) {
    const responseData = await this.execute(`get`, `/sql/query/save/${query_id}`)
    return responseData
  }


  async getQueryHistoryData(query_id) {
    const responseData = await this.execute(`get`, `/sql/query/history/${query_id}`)
    return responseData
  }

  async updateQuery(query_id, query_string) {
    const responseData = await this.execute(`put`, `sql/query/save`, {
      query_id: query_id,
      query_string: query_string,
    })
    return responseData
  }

  async updateQueryName(query_id, name) {
    const responseData = await this.execute(`put`, `sql/query/save`, {
      query_id: query_id,
      name: name
    })
    return responseData
  }

  async saveQuery(query_string, name) {
    const responseData = await this.execute(`post`, `/sql/query/save`, {
      query_string, query_string,
      name: name
    });
    return responseData;
  }

  async saveRecentQuery(query_id) {
    const responseData = await this.execute(`post`, `/sql/query/history/save/${query_id}`);
    return responseData;
  }

  async deleteSavedQuery(query_id) {
    const responseData = await this.execute(`delete`, `/sql/query/save/${query_id}`);
    return responseData;
  }

  async deleteRecentQuery(query_id) {
    const responseData = await this.execute(`delete`, `/sql/query/history/${query_id}`);
    return responseData;
  }

  async deleteAllRecentQueries() {
    const responseData = await this.execute(`delete`, `/sql/query/history`);
    return responseData;
  }

  async getSavedQueries() {
    const responseData = await this.execute(`get`, `/sql/query/saved`);
    return responseData;
  }


  async getScheduledTransformations() {
    const responseData = await this.execute(`get`, `sql/transformation`);
    return responseData;
  }

  async deleteTransformation(id) {
    const responseData = await this.execute(`delete`, `sql/transformation`, {
      id: id
    });
    return responseData;
  }

  async getSchemaTables(schema_name, cached=true) {
    console.log(this.token);
    const responseData = await this.execute(`post`, `/sql/schema/tables?cached=${cached}`, {
      schema_name: schema_name,
    });
    console.log(responseData);
    return responseData;
  }

  async getTableSchema(table_name) {
    const responseData = await this.execute(`post`, `/sql/table/info`, {
      table_name: [table_name],
    });
    return responseData;
  }

  async completeOnboard() {
    const responseData = await this.execute(`get`, `/environment/onboard/complete`);
    return responseData;
  }

  async execute(method, path, data) {
    console.log(`${method}, ${path}`);
    let headers = {
      Authorization: 'Bearer ' + this.token,
      accept: 'application/json',
    };
    if (data) console.log(data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: data,
    });
    console.log(response.status);
    if (response.status >= 400) throw Error(response.data);

    console.log(response.data);
    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      console.log(`${method}, ${path}, ${cursor}`);
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        console.log(response.data);
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }

  async getAllFavouriteTables() {
    const responseData = await this.execute(`get`, `/table_manager/favourite_tables`);
    return responseData;
  }

  async createFavouriteTable(table) {
    const responseData = await this.execute(`post`, `/table_manager/favourite_tables?table=${table}`)
    return responseData;
  }

  async deleteFavouriteTable(table) {
    const responseData = await this.execute(`delete`, `/table_manager/favourite_tables?table=${table}`);
    return responseData;
  }

  async trackUserActivity(url_path, page, home_text) {
    const responseData = await this.execute(`post`, `/users/activity`, {
      page: page,
      url_path: url_path,
      home_text: home_text
    })
  }

  async getUserActivity(models=false) {
    const responseData = await this.execute(`get`, `/users/activity?models=${models}`)
    return responseData
  }
}


function getApiUrl() {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging')
    return 'https://api-staging.artemisdata.io/api/v0';

  else if (process.env.REACT_APP_ENVIRONMENT === 'production')
    return 'https://api.artemisdata.io/api/v0';

  return 'http://127.0.0.1:8000/api/v0';
}

const LetoClient = new _LetoClient();
export default LetoClient;
