import { Card } from 'antd';
import { useState } from 'react';

const AnswerCard = ({ extension, selected }) => {
  const MaxTextLength = props => {
    if (props.text.length > props.length) {
      return <h1>{props.text.substring(0, props.length - 2)}...</h1>;
    }
    return <h1>{props.text}</h1>;
  };

  const highlightCard = () => {};

  return (
    <>
      <div className="mx-2 rounded cursor-pointer hover:bg-green-200 outline-2 outline-[#306440]" hoverable>
        <div className="py-4 px-8">
          <MaxTextLength text={extension.name} length={20} />
        </div>
      </div>
    </>
  );
};

export default AnswerCard;
