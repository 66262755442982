import { useContext, useState } from 'react';
import Tree from '../dbt/Tree';
import { EditorContext } from '../../../../context/EditorProvider';
import { SqlEditorContext } from '../../../../context/SqlEditorProvider';
import FileContentListItem from '../FileContentListItem';

const SqlSidebar = () => {

    const {
        setMenuPos,
        menuItem,
        setMenuItem,
        setContextMenuType,
        openDbtFile,
        openSqlQuery,
        openTab,
        activateOpenedTab
    } = useContext(EditorContext)

    const {
        savedQueries,
        recentQueries,
        sqlTabs,
        updateQueryName,
        newName,
        setNewName,
        initialName,
        isEditing,
        setIsEditing,
        sqlActiveTabId,
        setSqlActiveTabId,
    } = useContext(SqlEditorContext)

    const [searchvalue, setSearchValue] = useState(false)
    const [filteredFiles, setFilteredFiles] = useState()
        
    const onContextMenu = (event, item, type) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuItem(item)
        setMenuPos([event.clientX, event.clientY])
        setContextMenuType(type)
    }

    const handleQueryClick = (item, type) => {
        let sqlTab = Object.entries(sqlTabs).find(([key, value]) => value.query_id == item._id)
        if (!sqlTab || !activateOpenedTab(sqlTab[0], 'sql')) {
            openTab(item, type)
        }
    }

    const handleKeyDown = async (e, id) => {
        if (e.key === "Enter") {
          handleSave(id)
        }
        if (e.key === "Escape") {
          handleCancel()
        }
    }

    const handleSave = (id) => {
        if (isEditing !== null) {
            if (newName != initialName) {
                updateQueryName(id, newName)
            }
        }
    }

    const handleCancel = () => {
        setNewName(initialName)
        setIsEditing(null)
    }

    const handleDropdown = (value) => {
        // setShowDropdown(true);
        searchChange(value);
    };

    const searchChange = async (value) => {
        setSearchValue(value)
        if (value.length >= 2) {
            const savedFiles = savedQueries ? savedQueries.filter(item => (item.name.toLowerCase()).includes(value.toLowerCase())) : []
            const recentFiles = recentQueries ? recentQueries.filter(item => (item.created_at.toLowerCase()).includes(value.toLowerCase())) : []
            setFilteredFiles({saved: savedFiles, recent: recentFiles})
        }
        else {
            setFilteredFiles()
        }
    }

    return (
        <div className='h-full p-3 pt-6 overflow-y-auto overflow-x-hidden no-scrollbar'>
            <input 
                    type="search" 
                    className="bg-gray-50 bg-transparent border w-[240px] rounded-[5px] border-gray-300 text-gray-900 text-sm block px-2.5 py-1 mb-2" 
                    placeholder={"Search queries by name"}
                    onClick={(e) => handleDropdown(e.target.value)}
                    onChange={(e) => searchChange(e.target.value)} 
                    onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        // setShowDropdown(false);
                    }
                    }}
                />
            <div className='text-[16px] font-[500] select-none'>
                Saved Queries
            </div>
            <div className='text-gray-600 select-none w-full'>
                {filteredFiles ?
                    filteredFiles.saved.map((item, idx) => 
                        <div id={idx} className={`flex w-full cursor-pointer items-center hover:bg-gray-200 px-1 py-[2px] rounded-lg ${sqlActiveTabId && sqlTabs[sqlActiveTabId] && sqlTabs[sqlActiveTabId].query_id == item._id ? '!bg-gray-300' : menuItem && menuItem._id == item._id && 'bg-gray-200'}`} onContextMenu={(e) => onContextMenu(e, item, 2)} onClick={(e) => handleQueryClick({name: item.name, _id: item._id, type: 'saved'}, 'sql')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-[5px] w-[16px] h-[16px] min-w-[16px] min-h-[16px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            {
                                isEditing === item._id ?
                                <div className="w-full" onClick={(e) => e.stopPropagation()} onBlur={() => handleCancel()}>
                                    <div className='flex items-center box-border box-content'>
                                        <input
                                        className=" px-1 input-width grow"
                                        type="text"
                                        value={newName}
                                        onChange={(e) => setNewName(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyDown={(e) => handleKeyDown(e, item._id)}
                                        autoFocus
                                        />
                                    </div>
                                </div>
                                :
                                <div className='truncate'>
                                    <FileContentListItem
                                        originalString={item.name}
                                        substringToHighlight={searchvalue}
                                    />
                                </div>
                            }
                        </div>
                    )
                    :
                    savedQueries && savedQueries.map((item, idx) => (
                        <div id={idx} className={`flex w-full cursor-pointer items-center hover:bg-gray-200 px-1 py-[2px] rounded-lg ${sqlActiveTabId && sqlTabs[sqlActiveTabId] && sqlTabs[sqlActiveTabId].query_id == item._id ? '!bg-gray-300' : menuItem && menuItem._id == item._id && 'bg-gray-200'}`} onContextMenu={(e) => onContextMenu(e, item, 2)} onClick={(e) => handleQueryClick({name: item.name, _id: item._id, type: 'saved'}, 'sql')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-[5px] w-[16px] h-[16px] min-w-[16px] min-h-[16px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            {
                                isEditing === item._id ?
                                <div className="w-full" onClick={(e) => e.stopPropagation()} onBlur={() => handleCancel()}>
                                    <div className='flex items-center box-border box-content'>
                                        <input
                                        className=" px-1 input-width grow"
                                        type="text"
                                        value={newName}
                                        onChange={(e) => setNewName(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                        onKeyDown={(e) => handleKeyDown(e, item._id)}
                                        autoFocus
                                        />
                                    </div>
                                </div>
                                :
                                <div className='truncate'>
                                    {item.name}
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
            <div className='flex w-full items-center'>
                <div className='text-[16px] font-[500] select-none'>
                    Recent Executions 
                </div>
                <div className='grow items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" className='m-0 float-right hover:bg-gray-200 cursor-pointer px-[2px] rounded-lg' onClick={(e) => { onContextMenu(e, {}, 4)}}>
                        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 12a1 1 0 1 0 2 0a1 1 0 1 0-2 0m7 0a1 1 0 1 0 2 0a1 1 0 1 0-2 0m7 0a1 1 0 1 0 2 0a1 1 0 1 0-2 0"/>
                    </svg>
                </div>
            </div>
            <div className='text-gray-600 select-none w-full'>
                {filteredFiles ?
                    filteredFiles.recent.map((item, idx) => 
                        <div className={`flex cursor-pointer items-center hover:bg-gray-200 px-1 py-[2px] rounded-lg ${sqlActiveTabId && sqlTabs[sqlActiveTabId] && sqlTabs[sqlActiveTabId].query_id == item._id ? '!bg-gray-300' : menuItem && menuItem._id == item._id && 'bg-gray-200'}`} onContextMenu={(e) => onContextMenu(e, item, 3)} onClick={() => handleQueryClick({_id: item._id, name: String(item.created_at), type: 'history'}, 'sql')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-[5px] w-[16px] h-[16px] min-w-[16px] min-h-[16px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            <div className='truncate'>
                                <FileContentListItem
                                    originalString={String(item.created_at)}
                                    substringToHighlight={searchvalue}
                                />
                            </div>
                        </div>
                    )
                    :
                    recentQueries && recentQueries.map(item => (
                        <div className={`flex cursor-pointer items-center hover:bg-gray-200 px-1 py-[2px] rounded-lg ${sqlActiveTabId && sqlTabs[sqlActiveTabId] && sqlTabs[sqlActiveTabId].query_id == item._id ? '!bg-gray-300' : menuItem && menuItem._id == item._id && 'bg-gray-200'}`} onContextMenu={(e) => onContextMenu(e, item, 3)} onClick={() => handleQueryClick({_id: item._id, name: String(item.created_at), type: 'history'}, 'sql')}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-[5px] w-[16px] h-[16px] min-w-[16px] min-h-[16px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            <div className='truncate'>
                                {String(item.created_at)}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SqlSidebar;
