import React, { useState, useEffect, useRef, useContext} from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';
import { CloseOutlined, DownOutlined} from '@ant-design/icons';
import DataPreview from './DataPreview';
import ConsoleLog from './ConsoleLog';
import WorkflowCopilot from './WorkflowCopilot';

const Component = ({openConsole, setOpenConsole  }) => {
  const {
    interfaceLog,
    selectedNode,
    workflowsConsoleMode,
    setWorkflowsConsoleMode, } = useContext(TableGraphContext);
  const consoleRef = useRef(null);
  const [consoleHeight, setConsoleHeight] = useState('15%'); // Default height as 25%
  const [isResizing, setIsResizing] = useState(false);
  const contentRef = useRef(null);  // Ref for the scrollable content
  const [isUserScrolled, setIsUserScrolled] = useState(false);
  const messagesEndRef = useRef(null)

  useEffect(() => {
    if (!isUserScrolled && contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }

  }, [interfaceLog, isUserScrolled]); 


  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);

    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing]);

  const startResizing = (e) => {
    e.preventDefault();
    setIsResizing(true);
    document.body.classList.add('body-cursor-resize-ns');
  };

  const resize = (e) => {
    if (isResizing) {
      const newHeight = Math.max(window.innerHeight - e.clientY, 100 );
      setConsoleHeight(`${newHeight}px`);
    }
  };

  const stopResizing = () => {
    setIsResizing(false);
    document.body.classList.remove('body-cursor-resize-ns');
  };


  const handleScroll = (e) => {
    // Check if the user has scrolled up
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    setIsUserScrolled(!bottom);
  };

  const scrollToBottom = (behavior = {}) => {
    messagesEndRef.current?.scrollIntoView(behavior)
  } 

  useEffect(() => {
    if (workflowsConsoleMode === 0)
      scrollToBottom({ behavior: "smooth" })
  }, [interfaceLog])

  useEffect(() => {
    if (workflowsConsoleMode === 0)
      scrollToBottom()
  }, [workflowsConsoleMode])
    
  return (
    <div ref={consoleRef }className="w-full bg-[#F7F7F7] border-l-[1px] shadow-sm flex flex-col" 
    style={{height: consoleHeight}}>
      <div className='w-full h-10 bg-[#F7F7F7] relative'>
        <div
          onMouseDown={startResizing}
          className={`cursor-ns-resize	w-full border-t-[6px] border-gray-200 focus:border-gray-400 hover:border-gray-400 ease-in-out ${isResizing && 'border-gray-400'}`}
        ></div>
        <div className='mt-1 ml-2 flex cursor-pointer select-none text-[16px] text-gray-500 space-x-4'>
          <div className={`hover:text-gray-900 px-[4px] ${workflowsConsoleMode == 0 ? 'border-b-[2px] border-gray-500 hover:border-gray-900' : ''}`} onClick={() => setWorkflowsConsoleMode(0)}>
            Terminal
          </div>
          <div className={`hover:text-gray-900 px-[4px] ${workflowsConsoleMode == 2 ? 'border-b-[2px] border-gray-500 hover:border-gray-900' : ''}`} onClick={() => setWorkflowsConsoleMode(2)}>
            Copilot
          </div>
          {selectedNode &&
            <div className={`hover:text-gray-900 px-[4px] ${workflowsConsoleMode == 1 ? 'border-b-[2px] border-gray-500 hover:border-gray-900' : ''}`} onClick={() => setWorkflowsConsoleMode(1)}>
              Preview
            </div>
          }

        </div>
        <button
          className='absolute top-0 right-1'
          onClick={() => {
            setOpenConsole(false);
          }}
        >
          <CloseOutlined style={{color: "gray"}}/>      
        </button>
      </div>
      {
        workflowsConsoleMode == 0 &&
        <div className='pointer-events-none pl-4 flex-grow overflow-auto mr-5 mb-3'
          ref={contentRef}
          onScroll={handleScroll}>
            <ConsoleLog/>
          <div ref={messagesEndRef} />
        </div>
      }
      { workflowsConsoleMode == 2 &&
        <div className='pl-4 flex-grow overflow-auto mr-5 mb-3'>
          <WorkflowCopilot />
        </div>
      }
      {workflowsConsoleMode == 1 &&
        <div className='pl-4 flex-grow overflow-auto mr-5 mb-3'>
          <DataPreview />
        </div>
      }
      </div>
  );
  
};

export default Component;

