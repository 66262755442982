import { createContext, useState, useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';
import SqlEditorClient from './LetoClient';
import { textEditor } from 'react-data-grid';


export const QueryContext = createContext();

export const QueryProvider = ({ children }) => {
  const [warehouseType, setWarehouseType] = useState()
  const [queryString, setQueryString] = useState({});
  const [queryRows, setQueryRows] = useState({});
  const [queryColumns, setQueryColumns] = useState({});
  const [schemaTables, setSchemaTables] = useState();
  const [workspaceLoading, setWorkspaceLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableSchemaLoading, setTableSchemaLoading] = useState(false);
  const [runQueryLoading, setRunQueryLoading] = useState({});
  const [tableSchemaData, setTableSchemaData] = useState([]);
  const [queryTime, setQueryTime] = useState({});
  const [queryError, setQueryError] = useState({});
  const authInfo = useAuthInfo();
  const [saveTableModal, setSaveTableModal] = useState(false);


  //saved queries page info
  const [savedQueries, setSavedQueries] = useState([]);

  //scheduled transformations page info
  const [scheduledTransformations, setScheduledTransformations] = useState([]);

  const tabs = useState();
  const activeTab = useState(0);
  const [selectedSchema, setSelectedSchema] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedTable, setSelectedTable] = useState('');
  const [workspaceList, setWorkspaceList] = useState();
  const [schemaList, setSchemaList] = useState();
  const [fullscreenVisible, setFullscreenVisible] = useState(false);
  const [fullscreenQueryRows, setFullscreenQueryRows] = useState();
  const [fullscreenQueryColumns, setFullscreenQueryColumns] = useState();
  const [uselessState, setUselessState] = useState(false);
  const [sqlEditorMode, setSqlEditorMode] = useState(true);
  const [joinOnColumnsRight, setJoinOnColumnsRight] = useState([]);
  const [joinOnColumnsLeft, setJoinOnColumnsLeft] = useState([]);
  const [joinOnSelectedColumn1, setJoinOnSelectedColumn1] = useState();
  const [joinOnSelectedColumn2, setJoinOnSelectedColumn2] = useState();
  const [joinOnEqualityType, setJoinOnEqualityType] = useState();
  const [selectedJoinType, setSelectedJoinType] = useState();
  const [filterColumns, setFilterColumns] = useState([]);
  const [filterSelectedColumn, setFilterSelectedColumn] = useState({});
  const [selectedFilterEqualityType, setSelectedFilterEqualityType] = useState({});
  const [filterCondition, setFilterCondition] = useState({});
  const [filterCards, setFilterCards] = useState([]);
  const [saveTableSchemasLoading, setSaveTableSchemasLoading] = useState(false);
  const [saveTableLoading, setSaveTableLoading] = useState(false);
  const [saveTableSchemas, setSaveTableSchemas] = useState();
  const [saveTableError, setSaveTableError] = useState();
  const [loadInfo, setLoadInfo] = useState(true)

  const [aiAssistantVisible, setAiAssistantVisible] = useState(false);

  const saveTable = async (query_string, destination_schema, destination_table, tab_id) => {
    setSaveTableLoading(true);
    setUselessState(true);
    const errorTemp = queryError;
    errorTemp[tab_id] = null;
    setQueryError(errorTemp);
    const runLoadingTempTrue = runQueryLoading;
    runLoadingTempTrue[tab_id] = true;
    setRunQueryLoading(runLoadingTempTrue);
    const start = performance.now();
    const response = await SqlEditorClient.saveTable(query_string, destination_schema, destination_table);
    if (response.error) {
      errorTemp[tab_id] = response.error;
      setQueryError(errorTemp);
    } else {
      const tempColumns = queryColumns;
      tempColumns[tab_id] = response.columns;
      setQueryColumns(tempColumns);
      const tempRows = queryRows;
      tempRows[tab_id] = response.rows;
      setQueryRows(tempRows);
    }
    const tempTime = queryTime;
    tempTime[tab_id] = performance.now() - start;
    setQueryTime(tempTime);
    const runLoadingTempFalse = runQueryLoading;
    runLoadingTempFalse[tab_id] = false;
    setRunQueryLoading(runLoadingTempFalse);
    setUselessState(false);
    setSaveTableLoading(false);
  };

  const getSaveTableSchema = async () => {
    setSaveTableSchemasLoading(true);
    const response = await SqlEditorClient.getDBSchemas();
    if (response.error) {
      setSaveTableError(response.error);
    } else {
      setSaveTableSchemas(response);
      setSaveTableError();
    }
    setSaveTableSchemasLoading(false);
  };

  const saveTransformation = async (query_string, destination_schema, destination_table, interval) => {
    const response = await SqlEditorClient.saveTransformation(query_string, destination_schema, destination_table, interval);
  };


  const getScheduledTransformations = async () => {
    const response = await SqlEditorClient.getScheduledTransformations();
    console.log(response)
    setScheduledTransformations(response);
  };

  const deleteTransformation = async transform_id => {
    const response = await SqlEditorClient.deleteTransformation(transform_id);
    await getScheduledTransformations();
  };


  const saveQuery = async input => {
    const response = await SqlEditorClient.saveQuery(input);
  };

  const deleteQuery = async query_id => {
    const response = await SqlEditorClient.deleteQuery(query_id);
    await getSavedQueries();
  };

  const getSavedQueries = async () => {
    const response = await SqlEditorClient.getSavedQueries();
    setSavedQueries(response);
  };

  const getSchemaTables = async schema => {
    setTableLoading(true);
    const response = await SqlEditorClient.getSchemaTables(schema);
    setSchemaTables(response);
    setTableLoading(false);
  };

  const postQuery = async (tab_id, query_string) => {
    setUselessState(true);
    const errorTemp = queryError;
    errorTemp[tab_id] = null;
    setQueryError(errorTemp);
    const runLoadingTempTrue = runQueryLoading;
    runLoadingTempTrue[tab_id] = true;
    setRunQueryLoading(runLoadingTempTrue);
    const start = performance.now();
    const response = await SqlEditorClient.postQuery(query_string);
    if (response.error) {
      errorTemp[tab_id] = response.error;
      setQueryError(errorTemp);
    } else {
      const tempColumns = queryColumns;
      tempColumns[tab_id] = response.columns;
      setQueryColumns(tempColumns);
      const tempRows = queryRows;
      tempRows[tab_id] = response.rows;
      setQueryRows(tempRows);
    }
    const tempTime = queryTime;
    tempTime[tab_id] = performance.now() - start;
    setQueryTime(tempTime);
    const runLoadingTempFalse = runQueryLoading;
    runLoadingTempFalse[tab_id] = false;
    setRunQueryLoading(runLoadingTempFalse);
    setUselessState(false);
    return tempTime
  };

  const getInfo = async () => {
    if (!SqlEditorClient.token) {
      SqlEditorClient.setToken(authInfo.accessToken);
    }
    setWorkspaceLoading(true);
    const response = await SqlEditorClient.getInfo();
    setWarehouseType(response.warehouse_provider);
    setWorkspaceLoading(false);
    console.log(response)
  };

  const getTableSchema = async (table_name) => {
    setTableSchemaLoading(true);
    const response = await SqlEditorClient.getTableSchema(table_name);
    setTableSchemaData(response);
    setTableSchemaLoading(false);
  };

  return (
    <QueryContext.Provider
      value={{
        queryString,
        setQueryString,
        postQuery,
        getInfo,
        queryColumns,
        setQueryColumns,
        queryRows,
        setQueryRows,
        schemaTables,
        getSchemaTables,
        tableLoading,
        workspaceLoading,
        tableSchemaLoading,
        runQueryLoading,
        tableSchemaData,
        getTableSchema,
        setTableSchemaData,
        setSchemaTables,
        queryTime,
        queryError,
        saveQuery,
        getSavedQueries,
        savedQueries,
        deleteQuery,
        tabs,
        activeTab,
        selectedSchema,
        setSelectedSchema,
        selectedWorkspace,
        setSelectedWorkspace,
        selectedTable,
        setSelectedTable,
        workspaceList,
        setWorkspaceList,
        schemaList,
        setSchemaList,
        fullscreenVisible,
        setFullscreenVisible,
        fullscreenQueryRows,
        setFullscreenQueryRows,
        fullscreenQueryColumns,
        setFullscreenQueryColumns,
        uselessState,
        setUselessState,
        sqlEditorMode,
        setSqlEditorMode,
        aiAssistantVisible,
        setAiAssistantVisible,
        joinOnColumnsRight,
        setJoinOnColumnsRight,
        joinOnColumnsLeft,
        setJoinOnColumnsLeft,
        joinOnSelectedColumn1,
        setJoinOnSelectedColumn1,
        joinOnSelectedColumn2,
        setJoinOnSelectedColumn2,
        joinOnEqualityType,
        setJoinOnEqualityType,
        selectedJoinType,
        setSelectedJoinType,
        filterColumns,
        setFilterColumns,
        filterSelectedColumn,
        setFilterSelectedColumn,
        selectedFilterEqualityType,
        setSelectedFilterEqualityType,
        filterCondition,
        setFilterCondition,
        filterCards,
        setFilterCards,
        saveTable,
        getSaveTableSchema,
        saveTableSchemasLoading,
        setSaveTableSchemasLoading,
        saveTableLoading,
        setSaveTableLoading,
        saveTableSchemas,
        setSaveTableSchemas,
        saveTableError,
        setSaveTableError,
        saveTransformation,
        getScheduledTransformations,
        deleteTransformation,
        scheduledTransformations,
        warehouseType,
        setWarehouseType,
        saveTableModal,
        setSaveTableModal,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

export default QueryProvider;
