import { ConnectorProvider } from './ConnectorProvider';
import { UserProvider } from './UserProvider';
import { ThemeProvider } from './ThemeProvider';
import { JobsProvider } from './JobsProvider';
import { StorageProvider } from './StorageProvider';
import { DatasetsProvider } from './DatasetsProvider';
import { NavbarProvider } from './NavbarContext';
import { QueryProvider } from './QueryProvider';
import { OnboardProvider } from './OnboardProvider';
import { SubscriptionProvider } from './SubscriptionProvider';
import { StripeProvider } from './StripeContext';
import { ArtyAIProvider } from './ArtyAIProvider';
import { AnalyticsProvider } from './AnalyticsProvider';
import { TableGraphProvider } from './TableGraphProvider';
import { ArtyChatProvider } from './ArtyChatProvider';
import { ModelEditorProvider } from './ModelEditorProvider';
import { GitProvider } from './GitProvider';
import { AgentProvider } from './AgentProvider';
import { WorkflowSchedulesProvider } from './WorkflowSchedulesProvider';
import { ReactFlowProvider} from 'reactflow';
import { LandingProvider } from './LandingProvider';
import { TableManagementProvider } from './TableManagementProvider';
import {ActivityTracking} from './ActivityTracking'
import { EditorProvider } from './EditorProvider';
import { SqlEditorProvider } from './SqlEditorProvider';
import { EditorCopilotProvider } from './EditorCopilotProvider';


const Provider = ({ children, warehouseType }) => {
  return (
    <UserProvider warehouseType={warehouseType}>
      <LandingProvider>
        <ConnectorProvider>
          <JobsProvider>
            <StorageProvider>
              <DatasetsProvider>
                <ThemeProvider>
                  <QueryProvider>
                    <OnboardProvider>
                        <TableManagementProvider>
                            <SubscriptionProvider>
                              <StripeProvider>
                                <ArtyAIProvider warehouse_type={warehouseType}>
                                  <GitProvider>
                                    <TableGraphProvider> 
                                      <ModelEditorProvider> 
                                        <AgentProvider>
                                          <ArtyChatProvider> 
                                            <WorkflowSchedulesProvider> 
                                              <AnalyticsProvider>
                                              <NavbarProvider>
                                                <ActivityTracking>
                                                  <ReactFlowProvider>
                                                    <SqlEditorProvider>
                                                      <EditorProvider>
                                                        <EditorCopilotProvider>
                                                          {children}
                                                        </EditorCopilotProvider>
                                                      </EditorProvider>
                                                    </SqlEditorProvider>
                                                  </ReactFlowProvider>
                                                </ActivityTracking>
                                              </NavbarProvider>
                                            </AnalyticsProvider>
                                          </WorkflowSchedulesProvider> 
                                        </ArtyChatProvider> 
                                      </AgentProvider>
                                    </ModelEditorProvider> 
                                  </TableGraphProvider> 
                                </GitProvider>
                              </ArtyAIProvider>
                            </StripeProvider>
                          </SubscriptionProvider>
                      </TableManagementProvider>
                    </OnboardProvider>
                  </QueryProvider>
                </ThemeProvider>
              </DatasetsProvider>
            </StorageProvider>
          </JobsProvider>
        </ConnectorProvider>
      </LandingProvider>
    </UserProvider>
  );
};

export default Provider;
