import { createContext, useState, useCallback } from 'react';
import ChatClient from './ChatClient.js';

export const ArtyChatContext = createContext();

export const ArtyChatProvider = ({ children }) => {


    const [chat, setChat] = useState({})
    const [chatId, setChatId] = useState('')
    const [contextMessage, setContextMessage] = useState('')
    const [chatList, setChatList] = useState([{}])
    const [pinnedChatList, setPinnedChatList] = useState([{}])
    const [fetchingChat, setFetchingChat] = useState(false)
    const [newChatName, setNewChatName] = useState("");
    const [initialChatName, setInitialChatName] = useState("");
    const [isEditing, setIsEditing] = useState(null);

    const [returnError, setReturnError] = useState(''); 



  const handleEdit = async (item) => {
      if (item.chat_name) {
          setNewChatName(item.chat_name);
          setInitialChatName(item.chat_name);
      }
      else {
          setNewChatName("Undefined Chat");
          setInitialChatName("Undefined Chat");
      }
      setIsEditing(item._id);
  };

  const setToken = (token) => {
     ChatClient.setToken(token) 
  }

  const handleSave = (dag_id, chat_id) => {
      if (isEditing !== null) {
          updateChatName(dag_id, chat_id, newChatName);
          setIsEditing(null);
      }
  };

  const handleCancel = () => {
      setNewChatName(initialChatName);
      setIsEditing(null);
  };

    const createChat = async (contextId) => {
      setFetchingChat(true)
      try {
        const response = await ChatClient.createAgentChat(contextId)
        setChatId(response._id)
        console.log(response)
      }
      catch {
        console.log('server error')
      }
      setFetchingChat(false)
    }

    const getAllChats = async (dag_id) => {
      const response = await ChatClient.getAllChats(dag_id);
      setChatList(response)
      return response
    }

    const getPinnedChats = async (dag_id) => {
      const response = await ChatClient.getPinnedChats(dag_id)
      setPinnedChatList(response)
    }

    const createPinnedChat = async (chat_id, dag_id) => {
      const response = await ChatClient.createPinnedChat(chat_id)
      getPinnedChats(dag_id)
    }

    const deletePinnedChat = async (chat_id, dag_id) => {
      const response = await ChatClient.deletePinnedChat(chat_id)
      getPinnedChats(dag_id)
    }

    const updateChatName = async (dag_id, chat_id, chat_name) => {
      let response = await ChatClient.updateChatName(chat_id, chat_name)
      getAllChats(dag_id)
    }

    const deleteChat = async (chat_id, dag_id) => {
      await ChatClient.deleteChat(chat_id)
      const response = await getAllChats(dag_id)
      return response
    }

  const promptArty = async (contextId, text) => {
    console.log("text:", text)

    let data = chat.data ? [...chat.data, { type: 'user', content: {content:text, type:'string' }}] : [{ type: 'user', content:{content: text, type: 'string' }}]

    setChat({
      ...chat, 
      data: data
    })
    try {

      let response = await ChatClient.promptAgentChat(contextId, chatId, {'prompt':text})

      // setChatList(response)

      setChatList(prev => prev.map((item) => item._id == response._id ? response : item))

      return response
    }
    catch {
      setChat({
        ...chat, 
        data: chat.data ? [...chat.data, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }] : [{ response_type: 0, response: text }, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }]
      })
    }
     
  }


const promptArty_SSE = async (contextId, text) => {
    // Update chat state to include the new user message
    const data = chat.data ? [...chat.data, { type: 'user', content: { content: text, type: 'string' } }] : [{ type: 'user', content: { content: text, type: 'string' } }];
    setChat({ ...chat, data });
    try {
        // Initialize the SSE connection
      let messageIterator = await ChatClient.promptAgentChat_SSE(contextId, chatId, { 'prompt': text });
      for await (let message of messageIterator) {
        if (message.type == 1) {
          setChat((prevChat) => ({
            ...prevChat,
            data: [...prevChat.data, { type: 'arty', content: { content: message.data, message_type: message.message_type }}]
        }));
        }
        if (message.type == 2) {
          setChat((prevChat) => ({
            ...prevChat,
            data: [...prevChat.data, { type: 'arty', content: { content: message.data, message_type: message.message_type }}]
          }));
        }
      }
    } catch (error) {
        // Handle errors by updating the chat state
        console.error("Error connecting to SSE: ", error);
        setChat((prevChat) => ({
            ...prevChat,
            data: [...prevChat.data, { type: 'error', content: 'A problem occurred when connecting/communicating with the server.' }]
        }));
    }

    
}

  const fetchChat = async (pub_id, id) => {
    setFetchingChat(true)
    try {
      const response = await ChatClient.fetchChat(pub_id, id)
      if (response.data) {
        setChat(response.data)
        setChatId(id)
      }
      else if (response.error) {
        console.log('invalid response', response.error)
      }
    }
    catch {
      console.log('server error')
    }
    setFetchingChat(false)
  }

  return (
    <ArtyChatContext.Provider
      value={{
          setToken,
          chat, 
          setChat,
          chatId,
          setChatId,
          createChat,
          promptArty, 
          promptArty_SSE,
          contextMessage, 
          setContextMessage,
          getAllChats,
          setChatList,
          chatList,
          getPinnedChats,
          pinnedChatList,
          createPinnedChat,
          deletePinnedChat,
          deleteChat,
          fetchingChat, 
          setFetchingChat,
          fetchChat,
          updateChatName,
          newChatName,
          setNewChatName,
          initialChatName,
          setInitialChatName,
          isEditing,
          setIsEditing,    
          handleEdit,
          handleSave,
          handleCancel,
      }}
    >
      {children}
    </ArtyChatContext.Provider>
  )
}



