import { React, useContext } from 'react';
import { ModelEditorContext } from '../../../context/ModelEditorProvider';


/**
 * TODO: refactor SQlEditor.js and ModelEditor.js to use the same codemirror componenent?
 */
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { StandardSQL  } from '@codemirror/lang-sql';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import { EditorView } from "@codemirror/view";
import { langs } from '@uiw/codemirror-extensions-langs';
import { EditorContext } from '../../../context/EditorProvider';


const ModelEditor = ({ tab }) => {

    const {
      dbtTabs,
      onContentChange,
    } = useContext(ModelEditorContext);
  
    const {
      editorHeight
    } = useContext(EditorContext)
  
    // TODO: This is a temporary solution. fix after modeleditorprovider refactor
    const file = dbtTabs[tab.id]
    
    const onChange = (value) => {
      onContentChange(tab.id, value)
    }
    
    const myTheme = createTheme({
        theme: 'light',
        settings: {
          background: '#ffffff',
          foreground: '#000000  ',
          caret: '#000000',
          selection: '#003d73',
          selectionMatch: '#003d73',
        },
        styles: [
          { tag: [t.comment, t.bracket], color: '#8b949e' },
          { tag: [t.className, t.propertyName], color: '#d2a8ff' },
          { tag: [t.variableName, t.attributeName, t.number, t.operator], color: '#79c0ff' },
          { tag: [t.keyword, t.typeName, t.typeOperator], color: '#0000ff' },
          { tag: [t.string], color: '#3dbb97' },
          { tag: [t.meta, t.regexp], color: '#a5d6ff' },
          { tag: [t.name], color: '#000000' },
          { tag: [t.quote], color: '#7ee787' },
          { tag: [t.heading], color: '#d2a8ff', fontWeight: 'bold' },
          { tag: [t.emphasis], color: '#d2a8ff', fontStyle: 'italic' },
          { tag: [t.deleted], color: '#ffdcd7', backgroundColor: 'ffeef0' },
        ],
    })
    

    return (
        <div className='pt-1'>
            <CodeMirror
                value={file ? file.content : ''}
                height={`${parseInt(editorHeight)}px`}
                width="100wh"
                theme={myTheme}
                extensions={[
                  StandardSQL.language.data.of({
                    autocomplete: function myCompletions(context) {
                      const word = context.matchBefore(/(\w|\.){2,}$/);
                      if (!word || (word.from === word.to && !context.explicit)) return null;
                      const options = []
                      return {
                        from: word.from,
                        options: options
                      };
                    },
                  }),
                  langs.sql({upperCaseKeywords: true}),
                  EditorView.lineWrapping,
                  EditorView.theme({
                    "&": {
                        border: "none"
                    },
                    ".cm-activeLineGutter": {
                      color: 'black',
                      fontWeight: '500',
                      backgroundColor: 'transparent',
                    },
                    ".cm-line.cm-activeLine": {
                      borderTopWidth: '1px',
                      borderBottomWidth: '1px',
                      borderColor: '#E5E7EB',
                      backgroundColor: 'transparent'
                    },
                    ".cm-line": {
                      borderTopWidth: '1px',
                      borderBottomWidth: '1px',
                      borderColor: 'white',
                    },
                    "&.cm-editor.cm-focused": {
                        outline: "none"
                    },
                    ".cm-gutters": {
                      backgroundColor: "white",
                      borderRightWidth: '0px',
                      borderLeftColor: '#D1D5DB',
                    },
                  }),
                ]}

                onChange={onChange}

              />
        </div>
    );
};

export default ModelEditor;
