import { useEffect, useState, useContext } from 'react';
import Dropdown from './Dropdown';
import { Popover, Input, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import Func from '../AggregateHelper/Function'

const Col = ({selectedNode, setSelectedNode}) => {
    const {
        setSelectedNodeEdited,
    } = useContext(TableGraphContext);
    
    const [dropdownColumnList, setDropdownColumnlist] = useState()
    const [visible, setVisible] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState([])
    const [name, setName] = useState()
    const [selectedCol, setSelectedCol] = useState({ value: null, label: null });
    const [tempAlias, setTempAlias] = useState()
    const [funcSelected, setFuncSelected] = useState()
    const [groupedColumns, setGroupedColumns] = useState(new Set())
    const [dateFuncArg, setDateFuncArg ] = useState('')


    const handleSelectChange = selectedOption => {
        setSelectedCol(selectedOption);
    };

    useEffect(() => {
        handleVisibleChange(false)
        setFiltersVisible(prev => prev.map(() => false))
    },[selectedNode])

    useEffect(() => {
        if (selectedNode) {
            const optionsArray = Object.entries(selectedNode.data.columns).flatMap(([id, { model_name, column_list }]) =>
            column_list.map((columnName) => ({
                value: columnName,
                label: `${model_name}.${columnName}`, 
                model_name: model_name,
                model_id: id,
            }))
             );
            setDropdownColumnlist(optionsArray)
            setFiltersVisible(selectedNode.data.included_columns.map(() => false));
            const groupedColumns = new Set(selectedNode.data.groupings.map(grouping => grouping.model_id + "." + grouping.column_name));
            setGroupedColumns(groupedColumns);
        }
        else {
            setDropdownColumnlist([]);
        }

    }, []);

    useEffect(() => {
        const groupedColumns = new Set(selectedNode.data.groupings.map(grouping => grouping.model_id + "." + grouping.column_name));
        setGroupedColumns(groupedColumns); 

        if (groupedColumns.size == 0){
            const newColList = selectedNode.data.included_columns.map(col => ({...col, require_function: false, aggregate_function: null}));
            updateIncludedColumns(newColList);

        } else{
            const newColList = selectedNode.data.included_columns.map(col => ({...col, require_function: !groupedColumns.has(col.model_id + "." + col.column_name)}));
            updateIncludedColumns(newColList);
        }
    }, [selectedNode?.data?.groupings]);

    const updateIncludedColumns = (newColList) => {
        setSelectedNode(prev => ({...prev, data: ({...prev.data, included_columns: newColList})}));
    }

    const handleVisibleChange = (visible, i = null) => {
        if (i === null) {
            setVisible(visible);
        } else {
            setFiltersVisible(prev => prev.map((v, index) => index === i ? !v : v));
        }
        const column = selectedNode?.data?.included_columns?.[i];

        setSelectedCol(column?.column_name
          ? { value: column.column_name, label: column.column_name, model_name: column.model_name, model_id: column.model_id}
          : { value: null, label: null });
        
        setTempAlias(column?.alias || null);
        setFuncSelected(column?.aggregate_function || null);
        setDateFuncArg(column?.extra_arguments || null);
        
    };

    const handleClose = (i = null) => {
        if (i === null) {
            setVisible(false)
        }
        else {
            setFiltersVisible(prev =>  prev.map(() => false))
        }
    }

    const handleSave = (i = null) => {
        if (i == null) {
            setFiltersVisible(prev => [...prev, false])
        }
        else{

            const extra_arguments = dateFuncArg ? [dateFuncArg] : ['DATE_PART', 'DATE_TRUNC'].includes(funcSelected) ? ['year'] : null;
            const newColList = selectedNode.data.included_columns.map((col, index) => index === i ? 
            { ...col, model_name: selectedNode.data.included_columns[i].model_name, column_name: selectedCol.value, alias: tempAlias, aggregate_function: funcSelected, extra_arguments: extra_arguments, require_function: (groupedColumns.size == 0 ? false : (funcSelected && tempAlias) || groupedColumns.has(selectedNode.data.included_columns[i].model_id + "." + selectedCol.value) ? false : true)} : col);
            updateIncludedColumns(newColList);
            setTempAlias(null);
            setFuncSelected(null);
            setSelectedCol(null);
            setDateFuncArg(null); 
        }

        handleClose(i)
        setName('')
        setSelectedNodeEdited(true);
    }

    const handleDelete = (i = null) => {
        if (i !== null) {
            const newColList = selectedNode.data.included_columns.filter((_, index) => index !== i);
            updateIncludedColumns(newColList);
            setFiltersVisible(prev => [...prev.slice(0, i), ...prev.slice(i + 1)]);
        }
        setSelectedNodeEdited(true)
    };

    const handleAdd = (column_name, alias = null , model_name, model_id) => {
        const extra_arguments = (groupedColumns.size > 0 && !groupedColumns.has(selectedCol.model_id + "." + selectedCol.value)) ? (dateFuncArg ? [dateFuncArg] : ['DATE_PART', 'DATE_TRUNC'].includes(funcSelected) ? ['year'] : null) : null
        const aggregate_function = (groupedColumns.size > 0 && !groupedColumns.has(selectedCol.model_id + "." + selectedCol.value)) ? funcSelected : null

        const newColList = [...selectedNode.data.included_columns, { model_name: model_name, column_name: column_name, alias: alias, aggregate_function: aggregate_function, extra_arguments: extra_arguments, model_id: model_id, require_function: (groupedColumns.size > 0 && !groupedColumns.has(model_id + "." + column_name))}];
        updateIncludedColumns(newColList);
        filtersVisible.push(false);
        setFuncSelected(null)

        handleSave()
        handleClose()
        setName('')
    }

    const Aggregate = ({index}) => {
        return (<div style={{ 'marginBottom': '10px' }}>
                <Dropdown
                    selected={selectedCol?.value ? {value: selectedCol.value, label: selectedCol.model_name + '.' + selectedCol.value} : null}
                    dropdownList={dropdownColumnList}
                    setSelected={handleSelectChange}
                    loading={false}
                    disabled={false}
                    clearable={true}
                    placeholder= {'Select column...'}
                />
            </div> )
        
    };

    const filter = (i = null) => {
        if(i !== null){
            return(
                <div style={{ width: '300px' }}>
                    <Aggregate index ={i}/>
                    <Input
                        style={{ 'marginBottom': '10px' }}
                        value={ tempAlias}
                        onChange={(e) => setTempAlias(e.target.value)}
                        placeholder="Alias name..."
                    />
                    {
                      (selectedNode.data.included_columns[i].require_function ||  selectedNode.data.included_columns[i].aggregate_function) && 
                      <div>                     
                        <div className="text-[12px] mt-2 text-gray-500">Functions</div>
                        <div className='relative mb-2'>
                            <Func funcSelected={funcSelected} setFuncSelected={setFuncSelected} dateFuncArg={dateFuncArg} setDateFuncArg={setDateFuncArg} currentFunction={selectedNode.data.included_columns[i].aggregate_function} currentDateFuncArg={selectedNode.data.included_columns[i].extra_arguments ? selectedNode.data.included_columns[i].extra_arguments[0] : null}/>
                        </div>
                      </div>  
                    }
                    <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                         <button className={selectedCol?.value ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!selectedCol?.value} onClick={() => handleSave(i)} block>
                            Accept
                        </button>

                        <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose(i)} block>
                            Close
                        </button>
                    </div>
                </div>
            )
        }
    }

    const addColumn = () => {
        return(
            <div style={{ width: '300px' }}>
                <h1>Add a new column:</h1>
                <Aggregate />
                <Input
                    style={{ 'marginBottom': '10px' }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Alias name..."
                />
                {(groupedColumns.size > 0 && selectedCol && !groupedColumns.has(selectedCol.model_id + "." + selectedCol.value)) &&
                    <div>                     
                        <div className="text-[12px] mt-2 text-gray-500">Functions</div>
                        <div className='relative mb-2'>
                            <Func funcSelected={funcSelected} setFuncSelected={setFuncSelected} dateFuncArg={dateFuncArg} setDateFuncArg={setDateFuncArg} currentFunction={null} currentDateFuncArg={null}/>
                        </div>
                    </div>  
                }
                <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={selectedCol?.value ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!selectedCol?.value } onClick={() => handleAdd(selectedCol.value, name, selectedCol.model_name, selectedCol.model_id)} block>
                        Accept
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose()} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }
    return(
        <div>
            {selectedNode.data.included_columns && selectedNode.data.included_columns.map((item, i) =>
                <Popover placement="rightBottom" content={filter(i)} trigger="click" visible={filtersVisible[i]} onVisibleChange={() => handleVisibleChange(!filtersVisible[i], i)} key={i}>
                    <button className={`rounded-[4px] w-full py-[5px] bg-transparent px-[15px] ${item.require_function && (!item.aggregate_function || !item.alias) && groupedColumns && groupedColumns.size > 0 ? "border-red-300" : "border-gray-300"} border-[1px] hover:bg-gray-100`} style={{ marginTop: '5px' }} block>
                        <div className='flex items-center justify-between h-full w-full'>
                        <div className='flex-grow truncate'>{`${item.aggregate_function ? item.aggregate_function : ""} ${item.model_name}.${item.alias ? (item.column_name + ' AS ' + item.alias) : item.column_name}`}</div>
                        <div className='flex-shrink-0'>
                            <Tooltip title='Delete'>
                                <button className='hover:text-red-400' onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleDelete(i)}}>
                                    <DeleteOutlined/>
                                </button>
                            </Tooltip>
                        </div>    
                        </div>
                    </button>
                </Popover>

            )}
            <Popover placement="rightBottom" content={addColumn()} trigger="click" visible={visible} onVisibleChange={() => handleVisibleChange(!visible)}>
                <div className='bg-gray-200 hover:bg-gray-300 rounded p-1 flex justify-center mt-[5px]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>
                add column
            </div>
            </Popover>
        </div>
    )
};

export default Col;


