import { useState, useEffect, useContext } from 'react';
import { Spin } from 'antd';
import Page from '../../components/Page';
import { useHistory } from 'react-router-dom';
import { LandingContext } from '../../context/LandingProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { ModelEditorContext } from '../../context/ModelEditorProvider';
import ConnectDatabase from '../ConnectionPages/ConnectDatabase';
import { UserContext } from '../../context/UserProvider';
import { OnboardContext } from '../../context/OnboardProvider';
import ConnectGit from '../ConnectionPages/ConnectGit';
import { TableGraphContext } from '../../context/TableGraphProvider';


const ModelsLanding = () => {

    const { recentModels } = useContext(LandingContext)
    const history = useHistory();
    const [currentModelHover, setCurrentModelHover] = useState()
    const {
        onItemClicked,
    } = useContext(ModelEditorContext)

    const { git } = useContext(TableGraphContext);

    const {
        connectGit,
        setConnectGit,
    } = useContext(ModelEditorContext);

    const {
        noWarehouse
    } = useContext(UserContext)
    const {
        setupWarehouse,
        setSetupWarehouse,
    } = useContext(OnboardContext)

    if (connectGit) {
        return <ConnectGit/>
    }

    if (setupWarehouse) {
        return <ConnectDatabase/>
    }

    const mouseInteraction = (path=null) => {
        setCurrentModelHover(path)
    }

    const handleClick = (model) => {
        history.push('/models')
        console.log(model)
        onItemClicked({ path: model, name: model.split('/').at(-1)})
    }

    return (
        <Page >
          <div className="h-full overflow-y-auto relative w-full bg-white">
            {
                !recentModels ?
                  <div className='w-full flex justify-center mt-[20%]'>
                    <Spin indicator={<LoadingOutlined style={{fontSize: '40px'}} />} />
                  </div>
              
                :
                <div className='my-[50px] ml-[50px]'>
                    <div>
                        <h1 className='mb-[15px] text-title'>Recent Models</h1>
                        <div className='flex flex-wrap'>
                            {recentModels.length ?
                                recentModels.map(model => 
                                <div className='relative mb-[25px] cursor-pointer rounded-[20px] hover:bg-gray-100 relative  ml-5 mr-5 justify-center border-[1px] border-gray-300 items-center h-[200px] w-[200px]' onMouseOver={() => mouseInteraction(model.object_id)} onMouseOut={() => mouseInteraction()}>
                                    {currentModelHover && currentModelHover == `${model.object_id}` ?
                                        <div className='absolute mt-[45%] w-full justify-center flex z-[20]'>
                        
                                        <button className='mr-[10px] rounded-lg bg-[#4cb043] hover:bg-[#2c9023] py-[5px] px-[10px] text-white' onClick={() => { handleClick(model.object_id) }} >Open</button>
                                        </div>
                                        :
                                        <></>
                        
                                    }
                                
                                    <div className={currentModelHover && currentModelHover == `${model.object_id}` ? 'h-full blur-[7px]  px-[25px] ' : 'h-full  px-[10px] '}>
                                        <div className='pb-[30%]'/>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[20%] h-[20%] mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                                        </svg>

                    
                                        <div className="text-[13px] text-black font-[400] text-center mt-[10%] break-all">{model.object_id}</div>
                                    </div>
                                </div>
                            )
                            :
                            <div className='relative mb-[25px] cursor-pointer rounded-[20px]  hover:bg-gray-100 relative  ml-5 mr-5 justify-center border-[1px] border-gray-300 border-dashed	 items-center h-[200px] w-[200px]' onClick={() => history.push('/models')}>

                                
                                <div className={currentModelHover && currentModelHover == 'get_started' ? 'h-full bg-gray-200' : 'h-full'}>
                                <div className='pb-[35%]'/>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[20%] h-[20%] mx-auto">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                                </svg>


                                <h1 className="text-[16px] text-black font-bold text-center my-auto align-middle mt-[15%]">Get started</h1>
                                </div>
                                
                            </div>
                        }
                            
                        </div>   
                        <h1 className='mt-[75px] '>
                                Finish Setup
                        </h1>
                        <div className="text-center cursor-default">
                <p className="mt-2 text-lg text-gray-900 mt-[50px]">Artemis requires a connection to a database and a Github repository to use various parts of the platform</p>
                <p className="mt-2 text-lg text-gray-900 flex justify-center">
                    Database status:
                    {
                        noWarehouse ? 
                        <div className='flex text-yellow-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <button className='px-2 border-yellow-500 border-[1px] rounded-lg hover:text-yellow-600 hover:border-yellow-600' onClick={() => setSetupWarehouse(true)}>
                                get started
                            </button>
                        </div>
                        :
                        <div className='flex text-green-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            completed
                        </div>
                    }
                </p>
                <p className="mt-2 text-lg text-gray-900 flex justify-center">
                    Github status:
                    {
                        git.noGitConnection ? 
                        <div className='flex text-yellow-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <button className='px-2 border-yellow-500 border-[1px] rounded-lg' onClick={() => setConnectGit(true)}>
                                set up
                            </button>
                        </div>
                        :
                        <div className='flex text-green-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            complete
                        </div>
                    }
                </p>  
            </div>
                    </div>
                </div>
            }
          </div>
        </Page>
      
        );
}

export default ModelsLanding