import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { ModelEditorContext } from '../../context/ModelEditorProvider';

import {
  ViewPlugin,
  DecorationSet,
  EditorView,
  ViewUpdate,
  Decoration,
  WidgetType,
  keymap,
} from '@codemirror/view';
import {
  StateEffect,
  Text,
  Prec,
  StateField,
  EditorState,
  EditorSelection,
} from '@codemirror/state';

const InlineSuggestionEffect = StateEffect.define();

function inlineSuggestionDecoration(view, prefix) {
  const pos = view.state.selection.main.head;
  const widgets = [];
  const w = Decoration.widget({
    widget: new InlineSuggestionWidget(prefix),
    side: 1,
  });
  widgets.push(w.range(pos));
  // console.log('widgets:', widgets)
  return Decoration.set(widgets);
}

class InlineSuggestionWidget extends WidgetType {
  constructor(suggestion) {
    super();
    this.suggestion = suggestion;
  }
  toDOM() {
    const div = document.createElement('span');
    div.style.opacity = '0.4';
    div.className = 'cm-inline-suggestion';
    div.textContent = this.suggestion;
    return div;
  }
}




function insertCompletionText(state, text, from, to) {
  return {
    ...state.changeByRange((range) => {
      if (range == state.selection.main)
        return {
          changes: { from: from, to: to, insert: text },
          range: EditorSelection.cursor(from + text.length),
        };
      const len = to - from;
      if (
        !range.empty ||
        (len &&
          state.sliceDoc(range.from - len, range.from) !=
            state.sliceDoc(from, to))
      )
        return { range };
      return {
        changes: { from: range.from - len, to: range.from, insert: text },
        range: EditorSelection.cursor(range.from - len + text.length),
      };
    }),
    userEvent: 'input.complete',
  }
}

function debouncePromise(fn, wait, abortValue = undefined) {
  let cancel = () => {
    // do nothing
  };
  
  const wrapFunc = (...args) => {
    cancel();
    return new Promise((resolve, reject) => {
      resolve(fn(...args))
      cancel = () => {
        if (abortValue !== undefined) {
          reject(abortValue);
        }
      };
    });
  };
  return wrapFunc;
}

export const InlineSuggestion = (options) => {
  const { delay = 500 } = options
  const {
    testState,
    setTestState,
    suggestionText,
    setSuggestionText,
  } = useContext(ModelEditorContext);

  const renderInlineSuggestionPlugin = ViewPlugin.fromClass(
    class Plugin {
      constructor() {
        // Empty decorations
        this.decorations = Decoration.none;
      }
      update(update) {
        // const suggestionText = update.state.field(InlineSuggestionState)?.suggestion;
        if (!suggestionText) {
          this.decorations = Decoration.none;
          return;
        }
        this.decorations = inlineSuggestionDecoration(update.view, suggestionText);
      }
    },
    {
      decorations: (v) => v.decorations,
    }
  );

  const InlineSuggestionState = StateField.define({
    create() {
      return { suggestion: suggestionText ? suggestionText : null };
    },
    update(__, tr) {
      const inlineSuggestion = tr.effects.find((e) =>
        e.is(InlineSuggestionEffect)
      );
      if (tr.state.doc)
        if (inlineSuggestion) {
          return { suggestion: inlineSuggestion.value.text };
        }
      // console.log('fell through:', suggestionText)
      return { suggestion: suggestionText ? suggestionText : null };
    },
  });


  const fetchFn = debouncePromise(options.fetchFn, delay);
  // const [fetch, setFetch] = useState(false)
  const inlineSuggestionKeymap = Prec.highest(
    keymap.of([
      {
        key: 'Meta-Enter',
        run: () => {
          options.fetchFn()
        }
      },
      {
        key: 'Tab',
        run: (view) => {
          const suggestionText = view.state.field(InlineSuggestionState)?.suggestion;
  
          // If there is no suggestion, do nothing and let the default keymap handle it
          if (!suggestionText) {
            return false;
          }
  
          view.dispatch({
            ...insertCompletionText(
              view.state,
              suggestionText,
              view.state.selection.main.head,
              view.state.selection.main.head
            ),
          });
          return true;
        },
      },
    ])
  );
  
  function fetchSuggestion(fetchFn) {
    return ViewPlugin.fromClass(
      class Plugin {
        async update(update) {
          const doc = update.state.doc;
          // Only fetch if the document has changed
          if (update.docChanged && false) {
            setTestState(false)
            const result = await fetchFn(update.state);
            update.view.dispatch({
              effects: InlineSuggestionEffect.of({ text: result, doc: doc }),
            });
          }
        }
      }
    );
  }

  return [
    InlineSuggestionState,
    fetchSuggestion(fetchFn),
    renderInlineSuggestionPlugin,
    inlineSuggestionKeymap,
  ];
}