import React from 'react';
import ChatOutput from './ChatOutput';


const Output = ({ hasUnsavedChanges, onRetry }) => {
  if (hasUnsavedChanges) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-gray-200 bg-opacity-50">
        <button
          className="p-3 bg-gray-500 text-white rounded"
          onClick={onRetry}
        >
          Rebuild
        </button>
      </div>
    );
  }
  return (
    <div className="h-full p-4 flex flex-col">
      <div className="flex flex-col flex-1">
      <ChatOutput/>


        {/* 
        <iframe
          title="Output Frame 1"
          src="https://app-staging.artemisco.ca/public/1a3db8ce97c546f4"        
          frameBorder="0"
          className="flex-1"
        ></iframe>
          */}
       


      </div>
    </div>
  );
};

export default Output;


