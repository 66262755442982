
import React, { useState, useEffect, useContext } from 'react';
import { Popover, Tooltip } from 'antd';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Dropdown from './Dropdown';
import { TableGraphContext } from '../../../context/TableGraphProvider';

const Joins = ({ content, columns, setSelectedNode, baseParentModelId, joinParentModelId, baseParentModelName, joinParentModelName, joinsIndex}) => {
  const {setSelectedNodeEdited} = useContext(TableGraphContext);
  const [joins, setJoins] = useState(content || []);
  const [addJoinConditionVisible, setAddJoinConditionVisible] = useState(false); // For add new condition
  const [joinConditionsVisible, setJoinConditionsVisible] = useState([]); // For edit existing conditions
  const [currentJoinCondition, setCurrentJoinCondition] = useState({ base_column_name: '', join_column_name: '' });
  const [baseParentColumns, setBaseParentColumns] = useState()
  const [secondParentColumns, setSecondParentColumns] = useState()
  
  useEffect(() => {

      const optionsArray = Object.entries(columns).flatMap(([id, { model_name, column_list }]) =>
      column_list.map((columnName) => ({
          value: columnName,
          label: `${model_name}.${columnName}`, 
          model_name: model_name,
          model_id: id,
      }))
      );
      const filteredBaseParentColumns = optionsArray?.filter(col => col.model_id === baseParentModelId);
      setBaseParentColumns(filteredBaseParentColumns);
  
      const filteredSecondParentColumns = optionsArray?.filter(col => col.model_id === joinParentModelId);
      setSecondParentColumns(filteredSecondParentColumns);
      
  }, [columns, baseParentModelId])

  useEffect(() => {
        if (content) {
          setJoins(content)
        }
        setJoinConditionsVisible(content.map(() => false))
        setAddJoinConditionVisible(false)
  }, [content])


  const handleVisibleChange = (i = null) => {
        if (i === null) {
            setAddJoinConditionVisible(prev => !prev)
            setCurrentJoinCondition({ base_column_name: '', join_column_name: '' })
        } else {
            if (!joinConditionsVisible[i]) {
              setCurrentJoinCondition({ base_column_name: joins[i]['base_column_name'], join_column_name: joins[i]['join_column_name'] })
            }
            setJoinConditionsVisible(prev => prev.map((v, index) => index === i ? !v : v))
        }
  }

  const handleDelete = (index) => {
    // Filter out the join condition at the specified index
    const updatedJoins = joins.filter((_, i) => i !== index);
    setJoins(updatedJoins);

    setJoinConditionsVisible(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    setCurrentJoinCondition({ base_column_name: '', join_column_name: '' })

    setSelectedNode(prev => {
      const newState = { ...prev };
   
      if (newState.data && Array.isArray(newState.data.joins) && newState.data.joins.length > 0) {
        const newJoins = [...newState.data.joins];


    
        newJoins[joinsIndex] = {
          ...newJoins[joinsIndex],
          column_pairs: updatedJoins
        };
    
        newState.data = {
          ...newState.data,
          joins: newJoins,
        };
      }

      return newState;
    });
    setSelectedNodeEdited(true)
  };

  const handleOk = (index) => {
    if (index !== undefined) {
      const updatedJoins = [...joins];
      updatedJoins[index] = currentJoinCondition;
      setJoins(updatedJoins);
      setSelectedNode(prev => {
        const newState = { ...prev };
     
        if (newState.data && Array.isArray(newState.data.joins) && newState.data.joins.length > 0) {
          const newJoins = [...newState.data.joins];
      
          newJoins[joinsIndex] = {
            ...newJoins[joinsIndex],
            column_pairs: updatedJoins, 
          };
      
          newState.data = {
            ...newState.data,
            joins: newJoins,
          };
        }
        return newState;
      });
      handleVisibleChange(index)
    } else {
      const updatedJoins = [...joins, currentJoinCondition];
      setJoins(updatedJoins);
      setSelectedNode(prev => {
        const newState = { ...prev };
     
        if (newState.data && Array.isArray(newState.data.joins) && newState.data.joins.length > 0) {
          const newJoins = [...newState.data.joins];
      
          newJoins[joinsIndex] = {
            ...newJoins[joinsIndex],
            column_pairs: updatedJoins
          };
      
          newState.data = {
            ...newState.data,
            joins: newJoins,
          };
        }

        return newState;
      });
      handleVisibleChange()
    }
    setSelectedNodeEdited(true)
  };

  const handleConditionChange = (field, value) => {
    setCurrentJoinCondition((prev) => ({ ...prev, [field]: value }));
  };

  const joinConditionForm = (index) => (
    <div style={{ width: '300px' }}>
      <div style={{ 'marginBottom': '10px' }}>
        <Dropdown
          selected={
            currentJoinCondition.base_column_name ? { 
              value: currentJoinCondition.base_column_name, 
              label: `${baseParentModelName}.${currentJoinCondition.base_column_name}` 
            }
            : null
          }
          dropdownList={baseParentColumns}
          setSelected={(value) => handleConditionChange('base_column_name', value['value'])}
          loading={false}
          disabled={false}
          clearable={false}
          placeholder='Select base model column...'
        />

      </div>
    <div style={{ 'marginBottom': '10px' }}>

      <Dropdown
        selected={
            currentJoinCondition.join_column_name ? { 
              value: currentJoinCondition.join_column_name, 
              label: `${joinParentModelName}.${currentJoinCondition.join_column_name}` 
            }
            : null
        }
        dropdownList={secondParentColumns}
        setSelected={(value) => handleConditionChange('join_column_name', value['value'])}
        loading={false}
        disabled={false}
        clearable={false}
        placeholder='Select joined model column...'
      />
    </div>
    <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
        <button className={currentJoinCondition.base_column_name && currentJoinCondition.join_column_name ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!currentJoinCondition.join_column_name || !currentJoinCondition.base_column_name } onClick={() => handleOk(index)} >
            Accept
        </button>
        <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleVisibleChange(index)}>
            Close
        </button>
    </div>
    </div>
  );

  return (
    <div>
      {joins.map((join, index) => (
        <div key={index} className="">
            <Popover
              placement="rightBottom"
              content={joinConditionForm(index)}
              title="Edit Join Condition"
              trigger="click"
              visible={joinConditionsVisible[index]}
              onVisibleChange={() => handleVisibleChange(index)}
            >
<button className='rounded-[4px] w-full py-[5px] bg-transparent px-[5px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                <div className='flex items-center'>
                        <div className=''>
                              {`${baseParentModelName}.${join.base_column_name} = ${joinParentModelName}.${join.join_column_name}`}
                                    </div>
                                    <div className='grow '>
                                        <Tooltip title='Delete'>
                                            <button className='relative float-right hover:text-red-400 top-[-3px]' onClick={() => handleDelete(index)}>
                                                <DeleteOutlined/>
                                            </button>
                                        </Tooltip>
                                    </div>   
                </div>
            </button>
      </Popover>

        </div>
      ))}
      <div className="mt-2">
          <Popover
            placement="rightBottom"
            content={joinConditionForm()}
            title="Add Join Condition"
            trigger="click"
            visible={addJoinConditionVisible}
            onVisibleChange={() => handleVisibleChange()}
          >
                <div className='bg-gray-200 hover:bg-gray-300 rounded p-1 flex justify-center'>
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
          add condition
                </div>

        </Popover>

      </div>
    </div>
  );
};

export default Joins;

