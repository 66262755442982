import './style.scss';
import { useContext } from 'react';
import { Result } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { ConnectorContext } from '../../../../../../context/ConnectorProvider';

const ErrorResult = ({ setResultStatus }) => {
  const { createConnectorData } = useContext(ConnectorContext);

  return (
    <div className="result-container">
      <ArrowLeftOutlined className="add-service-back" onClick={() => setResultStatus(false)} />
      <Result
        className="result-status"
        status="error"
        title="Submission Failed"
        subTitle={`${createConnectorData ? createConnectorData.code : 'Error'}: ${createConnectorData ? createConnectorData.message : 'ERROR MESSAGE NOT FOUND'}`}
        extra={[<p key={1}>Please contact support at william@artemisdata.io to link your environment</p>]}
      />
    </div>
  );
};

export default ErrorResult;
