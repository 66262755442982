import { createContext, useState } from 'react';

const MOCK_JOBS = [
  {
    jobs: 52,
    date: 'Apr 26',
  },
  {
    jobs: 76,
    date: 'Apr 27',
  },
  {
    jobs: 56,
    date: 'Apr 28',
  },
  {
    jobs: 156,
    date: 'Apr 29',
  },
  {
    jobs: 96,
    date: 'Apr 30',
  },
];

export const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const [jobs, setJobss] = useState(MOCK_JOBS);

  return (
    <JobsContext.Provider
      value={{
        jobs,
        setJobss,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
