import { React, useState, useEffect } from 'react';
import DataGrid from 'react-data-grid'

const DbtResults = ({ results }) => {
    if (!results || (!results.response && !results.error && !results.result)) {
        return <div className="p-4 text-gray-500"></div>;
    }

    const parseTextWithMarkers = (text) => {
      // Split the text by newlines to handle each line separately
      const lines = text.split('\n')
      const output = []

      lines.forEach((line, lineIndex) => {
        let coloredOutput = []
        const parts = line.split(/(\u001b\[0m|\u001b\[31m|\u001b\[32m)/)
        let currentColor = null

        for (let i = 0; i < parts.length; i++) {
          const part = parts[i]
          if (part === '\u001b[31m' || part === '\u001b[32m') {
            currentColor = part === '\u001b[31m' ? 'red' : 'green'
          } else if (part === '\u001b[0m') {
            currentColor = null
          } else {
            if (currentColor) {
              coloredOutput.push(<span key={`${lineIndex}-${i}`} style={{ color: currentColor }}>{part}</span>)
            } else {
              coloredOutput.push(part)
            }
          }
        }

        // Combine the parts of the line into a single container
        output.push(
          <div key={`line-${lineIndex}`} style={{ whiteSpace: 'pre-wrap' }}>
            {coloredOutput}
          </div>
        )
      })

      return output
    }

    const TimeIcon = (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                     </svg>)

    const renderResultTopbar = (log) => {

      const created_at = new Date(log.created_at).toLocaleString().replace(',', '') 


      return (
        <div className="flex">
          <div className={`border border-1 rounded p-1 
              ${log.result_status === 'Success' ? 'bg-green-200 text-green-700 border-green-400' : 'bg-red-200 text-red-700 border-red-400'}`
          }> 
            {log.result_status} 
          </div>
          <div className="p-1">
            in {log.execution_time}
          </div>

          <div className='border border-1 flex p-1'><div className='mr-1'>{TimeIcon}</div>{created_at}</div>
        </div>
      )
    }

    if (results.type === 'dbt_execute') {
      const parsedResults = parseTextWithMarkers(results.response ?? results.error)
      return (
        <div className="p-4 overflow-auto h-full">
          {results.log && results.log.execution_time && results.log.created_at && renderResultTopbar(results.log)}
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
          className='h-full w-full bg-white rounded-sm py-[16px]'
        >
          <div className='h-full overflow-y-auto'>
            <div className = 'border border-1 p-2'>
                  {parsedResults.map((content, index) => (
                      <div key={index}> {/* Add a key to each child */}
                          <div className='ml-[10px]'>{content}</div>
                      </div>
                  ))}
            </div>
          </div> 
        </div>
        </div>
      )
    } else if (results.type === 'dbt_preview') {
      if (results.error) {
        return (
          <div className='p-4 h-full overflow-y-auto'>
            <div className = 'border border-1 p-2'>

          <div>{results.error}</div>
          </div>
          </div>
        )
      }
      return(
        <div className="p-4 h-full flex flex-col">
            <div className="mt-2 grow overflow-auto">
                      <div className=''>
                          <DataGrid
                              className='h-full overflow-auto'
                              rows={results.result.rows}
                              columns={results.result.columns}
                            /> 

                      </div>
            </div>
         </div>
      )
    }
}

export default DbtResults;
