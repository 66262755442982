import { createContext, useState, useCallback, useEffect, useContext } from 'react';
import GraphClient from './GraphClient.js';
import { useNodesState, useEdgesState } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { GitContext } from './GitProvider.js';
import { addEdge, MarkerType } from 'reactflow';

export const TableGraphContext = createContext();

export const TableGraphProvider = ({ children }) => {
  const [mode, setMode] = useState(false);
  const [workflowsConsoleMode, setWorkflowsConsoleMode] = useState(0)
  const [dags, setDags] = useState([]);
  const [filteredDags, setFilteredDags] = useState();
  const [dagId, setDagId] = useState('');
  const [dagName, setDagName] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const [dbtSchema, setDbtSchema] = useState()
  const [interfaceLog, setInterfaceLog] = useState([])
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openConsole, setOpenConsole] = useState(true);
  const [showDB, setShowDB] = useState(true)
  const [visible, setVisible] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [dbtModel, setDbtModel] = useState('');
  const [selectedEdge, setSelectedEdge] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [editedNodes, setEditedNodes] = useState({});
  const [forceDagSave, setForceDagSave] = useState(false)
  const [showModels, setShowModels] = useState(false)
  const git = useContext(GitContext)
  const [sumContent, setSumContent] = useState([])
  const [filterContent, setFilterContent] = useState([])
  const [groupContent, setGroupContent] = useState([])
  const [orderContent, setOrderContent] = useState([])
  const [joinArtyLoading, setJoinArtyLoading] = useState(false)
  const [aggregateArtyLoading, setAggregateArtyLoading] = useState(false)
  const [blankLoading, setBlankLoading] = useState(false)
  const [retryParseLoading, setRetryParseLoading] = useState(false)
  const [reactFlowInstance, setReactFlowInstance] = useState()
  const [reactFlowSize, setReactFlowSize] = useState({width: 0, height: 0})
  const [showNodeOutline, setShowNodeOutline] = useState(false);
  const [draggingNodeOutline, setDraggingNodeOutline] = useState(false);
  const [previewCachedData, setPreviewCachedData] = useState({})
  const [copilotNodes, setCopilotNodes, onCopilotNodesChange] = useNodesState();
  const [copilotEdges, setCopilotEdges, onCopilotEdgesChange] = useEdgesState();
  const [copilotNodesLoading, setCopilotNodesLoading] = useState({})
  const [hoveredEdges, setHoveredEdges] = useState(new Set())
 
  useEffect(() => {
    if (interfaceLog.length > 50) {
      setInterfaceLog(logs => {
        const [head, ...tail] = logs
        return tail
      })
    }
    if(interfaceLog.length > 0 && !openConsole){
      setOpenConsole(true)
    }
  }, [interfaceLog])

  useEffect(() => {
    if (forceDagSave && nodes && edges) {
      saveDAG()
    }
    setForceDagSave(false)
  }, [forceDagSave])

  useEffect(() => {
    if (!selectedNode && workflowsConsoleMode == 1) {
      setWorkflowsConsoleMode(0)
    }
  }, [selectedNode])

  useEffect(() => {
    setCopilotEdges([])
    setCopilotNodes([])
  }, [dagId])

  const getDags = async () => {
    let response = await GraphClient.getDags();

    setDags(response['data']);
    setFilteredDags(response['data'])

    return response['data']
  };

  const setSelectedNodeEdited = edited => {
    setSelectedNode(prev => Object.assign({}, prev, {edited: edited}))
  }
  

  const fetchSelectedNodeInfo = async id => {
    try {
      const response = await GraphClient.fetchSelectedNodeInfo(id, dagId)
      if (response.error) {
        const defaultErrorMessage = "Server Error: Unable to process node, ensure valid model structure";
        
        if(response.error === "Expected text or file-like object, got <class 'NoneType'>")
        {
          console.log('ERROR', 'Model with name does not exist.');
          setSelectedNode(prev => ({id: prev.id, error: true, errorMessage: "Model with name does not exist."}))

        }else{
          console.log('ERROR', response.error)
          setSelectedNode(prev => ({id: prev.id, error: true, errorMessage: defaultErrorMessage}))
        }
      }
      else {
        setSelectedNode(prev => Object.assign({}, {id: prev.id}, response))
      }
    }
    catch {
      console.log('server error!')
      setSelectedNode(prev => ({id: prev.id, error: true}))
    }

  }

  const workflowAI = async (prompt) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [...prevInterfaceLog, { id: _id, message: 'Generating model...', status: 'Pending' }])
    try {
      let response = await GraphClient.workflowAI(dagId, selectedNode.id, prompt);
      setSelectedNode(prev => Object.assign({}, {id: prev.id}, response))
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))

    } catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
  }

  const getDag = async dag_id => {
    let response = await GraphClient.getDag(dag_id);
    return response['dag'];
  };

  const getDbtModelFile = async model_id =>{
    let response = await GraphClient.getDbtModelFile(model_id);
    return response;
  }

  const setSelectedDag = async dag_id => {
    let dag_result = await getDag(dag_id);
    if (dag_result) {
      setDagId(dag_result._id);
      setNodes(dag_result.nodes);
      setEdges(dag_result.edges);
      setDbtSchema(dag_result.dbt_schema);
    }

    //TODO: call this every time we recieve a dag from backend
  };

  const createDag = async(dag_name) => {
    let response = await GraphClient.createDag(dag_name);
    setDags(response['dags']);
    setFilteredDags(response['dags'])
    return response.dag_id
  }

  const deleteDag = async(dag_id) => {
    let response = await GraphClient.deleteDag(dag_id)
    setDags(response['data']);
    setFilteredDags(response['data'])
  }

  const updateDagName = async(dag_id, new_name) => {
    let response = await GraphClient.updateDagName(dag_id, new_name)
    setDags(response['data']);
    setFilteredDags(response['data'])
  }

  const getDagName = async(dag_id) => {
    let response = await GraphClient.getDagName(dag_id)
    setDagName(response['name'])
    return response['name']
  }

  const updateDagFavorite = async(dag_id, favorite) =>{
    let response = await GraphClient.updateDagFavorite(dag_id,favorite)
    if(!response['error'])
    {
      setDags(response['data']);
      setFilteredDags(response['data']);
    }
    else {
      console.error(response['error'])
    }
  }

  const getParents = node_id => {
    const parentEdges = edges.filter(edge => edge.target === node_id);
    // Then, find all nodes that share an id with the source of these edges
    const parentNodes = parentEdges.map(edge => {
      return nodes.find(node => node.id === edge.source);
    });
    return parentNodes;
  };

  const onElementClick = useCallback((event, element) => {
    if (element.type === 'source') {
      setSelectedNode({id: element.id });
    }
  }, []);

  const getSelectedNode = (node_id = '') => {
    if (nodes) {
      if (node_id) {
        return nodes.find(node => node.id === node_id);
      }
        else return nodes.find(node => selectedNode ? node.id === selectedNode.id : false);
    }
  };

  const getCopilotSelectedNode = () => {
    if (copilotNodes) {
      return copilotNodes.find(node => selectedNode ? node.id === selectedNode.id : false);
    }
  }

  const updateSelectedNode = async data => {
    setSelectedNode(prev => { return { ...prev, data } })    
  }

  const updateNodes = async (node_id, node_data) => {
    setNodes(prev => prev.map(
      node =>
        node.id === node_id
          ? { ...node, data: node_data } // updates the node's data if it's the one you're looking for
          : node
    )); // sets the state with the updated array
  };

  const onDeleteEdge = useCallback(({ id, source }) => {
    setEdges(eds => eds.filter(e => e.id !== id));
  }, []);
  
  const initSelectedSeed = async seed_name => {
    setBlankLoading(true)
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Retrieving seed info', status: 'Pending' }])
    try {
      let response = await GraphClient.getSeedInfo(dagId, selectedNode.id, seed_name);
      setNodes(response.dag.nodes)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    } catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
    setBlankLoading(false)
  }

  const duplicateModel = async model_path =>{
    setBlankLoading(true) 
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Copying model file', status: 'Pending' }])

    try{
      const copyResponse = await GraphClient.duplicateModel(dagId, selectedNode.id, model_path)
      const tempNode = copyResponse.dag.nodes.find(n => n.id === selectedNode.id)
      setSelectedNode({...tempNode, dbt: copyResponse.dbt})
      setNodes(copyResponse.dag.nodes)
      setEdges(copyResponse.dag.edges)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    } catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      )) 
    }
    setBlankLoading(false)
  }

  const initSelectedModel = async model_path => {
    setBlankLoading(true)
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Retrieving model info', status: 'Pending' }])

    try {
      let response = await GraphClient.getModelInfo(dagId, selectedNode.id, model_path);
      if(response.modelInUse){
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Intermediate', message: response.message }  // status of 'Intermediate' removes the elipsis
                : log 
        ))
        duplicateModel(model_path) 
      } else{
        const tempNode = response.dag.nodes.find(n => n.id === selectedNode.id)
        setSelectedNode({...tempNode, dbt: response.dbt})
        setNodes(response.dag.nodes)
        setEdges(response.dag.edges)
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Success' } 
                : log 
        ))
        setBlankLoading(false)
      }
      
    } catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
      setBlankLoading(false)
    }
    
  }

  const initSelectedNode = async table_name => {
    setBlankLoading(true)
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Retrieving schema info', status: 'Pending' }])
    
    try {
      let response = await GraphClient.getSourceTableSchema(dagId, selectedNode.id, table_name);
      setNodes(response.dag.nodes)
      setEdges(response.dag.edges)
      const tempNode = response.dag.nodes.find(n => n.id === selectedNode.id)
      setSelectedNode(tempNode)

      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))

    } catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
    setBlankLoading(false)
  };

  const saveNodeChanges = async () => {
    let _id
    if (selectedNode) {
      _id = uuidv4()
      setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Saving edits', status: 'Pending' }])
    }
    try {
      if (selectedNode) {
        setEditedNodes(prev => {
          const newState = { ...prev };
          delete newState[selectedNode.id];
          return newState;
        });
        setSelectedNodeEdited(false)
        const response = await GraphClient.saveNodeChanges(dagId, selectedNode.id, selectedNode.data);

        setNodes(response.dag.nodes)
        setEdges(response.dag.edges)

        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Success' } 
                : log 
        ))
      }
    }
    catch (e) {
      console.log('error!', e)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
  }

  const retryNodeParse = async (node) => {
    try {
      setRetryParseLoading(true)
      let response = await GraphClient.retryNodeParse(dagId, node.id)
      setNodes(response.dag.nodes)
      setSelectedNode(response.dag.nodes.find(n => n.id === node.id))
      setEdges(response.dag.edges)
      setRetryParseLoading(false)
    }
    catch {
      setRetryParseLoading(false)
    }
  }

  const deleteNode = async (node_id) => {
    try {
      let response = await GraphClient.deleteNode(dagId, node_id);
      if (response?.error){
        console.error(response.error)
      }
    } catch {
      
    }
  }

  const saveDAG = async (edgeList = null, dag_id_from_chatpage=null) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Saving DAG', status: 'Pending' }])
    try {
      const response = await GraphClient.saveDAG(dag_id_from_chatpage ? dag_id_from_chatpage : dagId, { nodes: nodes, edges: edgeList ? edgeList : edges }); // REVIEW: Is there a better way than just adding another parameter to the function?
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
  }

  const saveDAGSpecific = async (nodeList, edgeList) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Saving DAG', status: 'Pending' }])
    try {
      const response = await GraphClient.saveDAG(dagId, { nodes: nodeList, edges: edgeList}); 
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
      return {status: "success"}
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
      return {status: "error"}
 
    }
  }

  const handleNodeRename = async (new_name, old_name) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Renaming dbt model', status: 'Pending' }])
    try {
      let response = await GraphClient.handleNodeRename(dagId, selectedNode.id, new_name, old_name);
      setNodes(response.dag.nodes)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    }
    catch (error) {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', message: error.response.data.detail, color: 'red' } 
              : log 
      ))
      return {status: 'error'}
    }
  }
  const updateNodeDescription = async (description) => {
    let _id = uuidv4()
    try {
      let response = await GraphClient.handleDescriptionChange(dagId, selectedNode.id, {description: description});
      setNodes(response.dag.nodes)
    }
    catch (error) {
      return {status: 'error'}
    }
  }

  const getNodeDescription = async(node_id) =>{
    try {
      let response = await GraphClient.getNodeDescription(dagId, node_id);
      return response;
    }
      catch (error) {
        return {status: 'error'}
    }
  } 
  
  const handleCustomSchemaRename = async (new_custom_schema) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Renaming custom schema', status: 'Pending' }])
    try {
      let response = await GraphClient.handleCustomSchemaRename(dagId, selectedNode.id, new_custom_schema);
      setNodes(response.dag.nodes)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    }
    catch (error) {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', message: error.response.data.detail, color: 'red' } 
              : log 
      ))
      return {status: 'error'}
    }
  }

  const setCustomMaterialization = async (new_custom_materialization) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Setting custom materialization', status: 'Pending' }])
    try {
      let response = await GraphClient.setCustomMaterialization(dagId, selectedNode.id, new_custom_materialization);
      setNodes(response.dag.nodes)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    }
    catch (error) {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', message: error.response.data.detail, color: 'red' } 
              : log 
      ))
      return {status: 'error'}
    }
  }

  const generateNodePositions = (trees, consanguineous_nodes, single_nodes) => {
    const layerWidth = 300; // Horizontal space between layers
    const nodeHeight = 300; // Vertical space between nodes in a layer
    const startX = 100; // Starting x position for the first layer
    let startY = 50; // Starting y position for the first node in a layer
    const consanguineous_offset = 300;
    let positions = {}; // To store the positions of the nodes

    // Make maps of incest nodes (consanguineous_nodes) and single nodes (single_nodes) for faster lookup in the loop

    let consanguineous_map = new Map();
    consanguineous_nodes.forEach(node => { consanguineous_map.set(node, true); });

    let single_map = new Map();
    single_nodes.forEach(node => { single_map.set(node.node_id, node); });

    let lowestOfTheHigh = 50;
    for(let layers of trees){
      let maxLayerLength = Math.max(...Object.values(layers).map(layer => layer.length));
        Object.keys(layers).forEach((layerKey, index) => {
            const layer = layers[layerKey];
            const layerOffset = (maxLayerLength - layer.length) * nodeHeight / 2; // Center align the nodes in each layer
            layer.forEach((nodeId, nodeIndex) => {
                let x = startX + index * layerWidth;
                let y = startY + nodeIndex * nodeHeight + layerOffset + consanguineous_offset * consanguineous_map.has(nodeId);
                if(single_map.has(nodeId)){
                  y = positions[single_nodes.find(node => node.node_id === nodeId).parent].y;
                }
                if(y > lowestOfTheHigh){
                  lowestOfTheHigh = y;
                }
                positions[nodeId] = { x: x, y: y};
            });
        });
        startY = lowestOfTheHigh + nodeHeight; 
      }

      setNodes((prevNodes) => {
          return prevNodes.map((node) => {
              return {
                  ...node,
                  position: positions[node.id],
              };
          });
      });
      return positions;
  }


  const sortDag = async () =>{
    try {
      let response = await GraphClient.sortDag(dagId, { nodes: nodes, edges: edges });
      generateNodePositions(response.trees, response.consanguineous_nodes, response.single_nodes);
      return {status: 'success'};
    }
    catch {
      return {status: 'error'}
    }
  }

  const executeDag = async () => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Executing DAG', status: 'Pending' }])
    try {
      let response = await GraphClient.executeDag(dagId, { nodes: nodes, edges: edges });
      if (response.dag) {
        setNodes(response.dag.nodes)
      }
        if (response.response && response.response.error) {
          setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
            log =>
                log.id === _id
                  ? { ...log, status: 'Error', full_console_log: response.response.error, console_log: response.response.error_summary }
                  : log 
          ))
          return
        }
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))      
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', console_log: '' } 
              : log 
      ))
    }
  };

  const SSE_executeDag = async () => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog=>[...prevInterfaceLog, { id: _id, message: 'Executing DAG', status: 'Pending'}])
    try {
      setWorkflowsConsoleMode(0)
      setOpenConsole(true)
      let messageIterator = await GraphClient.SSE_executeDag(dagId, { nodes: nodes, edges: edges });
      for await (let message of messageIterator) {
        if (message.type === 1) {
          setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
            log =>
                log.id === _id
                ? { ...log, console_log: message.data} 
                  : log 
          ))
        }
        else if (message.type === 2) {
          if (message.data.error) {
            if (message.data.error_summary) {
              setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                log =>
                    log.id === _id
                    ? { ...log, status: 'Error', full_console_log: message.data.error, console_log: message.data.error_summary}
                      : log 
              )) 
            }
            else {
              setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                log =>
                    log.id === _id
                    ? { ...log, status: 'Error', full_console_log: message.data.error, console_log: null}
                      : log 
              ))
            }
            if (message.data.dag) {
              setNodes(message.data.dag.nodes)
            }
          }
          else {
            setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
              log =>
                  log.id === _id
                  ? { ...log, status: 'Success', full_console_log: message.data.response, console_log: null} 
                    : log 
            ))
            setNodes(message.data.dag.nodes)
          }
        }
      }
    }
    catch (error){
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', console_log: '' } 
              : log 
      ))
      console.log('ERROR\n\n',error)
    }
  }

  const SSE_executeDagNode = async node => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [... prevInterfaceLog, { id: _id, message: 'Executing node', status: 'Pending' }])
    try {
      setWorkflowsConsoleMode(0)
      setOpenConsole(true)
      let messageIterator = await GraphClient.SSE_executeDbtModel(dagId, node.data.model_id, {});
      for await (let message of messageIterator) {
        if (message.status && message.data) {
          setNodes(message.data.nodes)
        }
        else {
          if (message.type === 1) {
              setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                log =>
                    log.id === _id
                    ? { ...log, console_log: message.data}
                      : log 
              ))
          }
          else if (message.type === 2) {
            if (message.data.error) {
              if (message.data.error_summary) {
                setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                  log =>
                      log.id === _id
                      ? { ...log, status: 'Error', full_console_log: message.data.error, console_log: message.data.error_summary}
                        : log 
                )) 
              }
              else {
                setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                  log =>
                      log.id === _id
                      ? { ...log, status: 'Error', full_console_log: message.data.error, console_log: null}
                        : log 
                ))
              }
              if (message.data.dag) {
                setNodes(message.data.dag.nodes)
              }
            }
            else {
              setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
                log =>
                    log.id === _id
                    ? { ...log, status: 'Success', full_console_log: message.data.response, console_log: null} 
                      : log 
              ))
              setNodes(message.data.dag.nodes)
            }
          }
        }
      }
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', console_log: '' } 
              : log 
      ))
    }
  };
  
  const previewDagNode = async node => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [...prevInterfaceLog, { id: _id, message: 'Retrieving node preview', status: 'Pending' }])
    try {

      setOpenConsole(true)
      setPreviewCachedData(prev => ({...prev, [node.id]: {loading: true}}))
      setWorkflowsConsoleMode(1)
      let response = await GraphClient.previewDagNode(dagId, node.id, {});
      if (response.error) {
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
            log.id === _id
              ? { ...log, status: 'Error', full_console_log: response.error, console_log: response.error_summary}
              : log
        ))
        setPreviewCachedData(prev => ({...prev, [node.id]: {error: response.error}}))
      }
      else {
        setPreviewCachedData(prev => ({...prev, [node.id]: response.data}))
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
            log.id === _id
              ? { ...log, status: 'Success' }
              : log
        ))
      }
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
          log.id === _id
            ? { ...log, status: 'Error', error_log: 'Error retrieving data' }
            : log
      ))

      setPreviewCachedData(prev => ({...prev, [node.id]: {error: "Unable to retrieve node preview"}}))
    }
  };

  const executeDagNode = async node => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [... prevInterfaceLog, { id: _id, message: 'Executing node', status: 'Pending' }])
    try {
      let response = await GraphClient.executeDbtModel(dagId, node.data.model_id, {});
      if (response.response.error) {
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Error', full_console_log: response.response.error, console_log: response.response.error_summary, } 
                : log 
        ))
      }
      else {
        setNodes(response.dag.nodes)
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Success' } 
                : log 
        ))
      }
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error', console_log: '' } 
              : log 
      ))
    }
  };

  const initializeAggregateNode = async (node1, node_id, edge_list = null) => {
    if (edge_list) {
      await saveDAG(edge_list)
    }
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [...prevInterfaceLog, { id: _id, message: 'Initializing node', status: 'Pending' }])

    let data = [
        { 
        model_id: node1.data.model_id,
        output_columns: node1.data.output_columns
        },
    ];

    try {
      let response = await GraphClient.getAggregateInit(dagId, node_id, {data:data})
      setNodes(response.dag.nodes)
      setEdges(response.dag.edges)
      const tempNode = response.dag.nodes.find(n => n.id === node_id)
      setSelectedNode(tempNode)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Success' } 
              : log 
      ))
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
  }

  const getSuggestedJoinFromNodes = async (parents, node_id, edge_list = null, overwrite = false) => {
    if (edge_list) {
      await saveDAG(edge_list)
    }

    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [...prevInterfaceLog, { id: _id, message: 'Retrieving suggested join', status: 'Pending' }])
 
    let data = parents.map(parent => { return { model_id: parent.data.model_id, output_columns: parent.data.output_columns } })

    try {
      let currentNodeData = nodes.find(n => n.id === node_id).data
      let response = overwrite ? await GraphClient.getJoinInit(dagId, node_id, {data:data}) : await GraphClient.getJoinInitSafe(dagId, node_id, {data:data, currentNodeData: currentNodeData});

      if (response.error) {
        console.log(response.error)
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Error' } 
                : log 
        ))
      }
      else {
        setNodes(response.dag.nodes)
        setEdges(response.dag.edges)
        if(openSidebar){
          const tempNode = response.dag.nodes.find(n => n.id === node_id)
          setSelectedNode(tempNode)
        }

        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
          log.id === _id
            ? { ...log, status: 'Success' } 
            : log 
        ))
      }

      return response;
    }
    catch {
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
      return {error: 'error in suggested join'}
    }
  };

  const acceptCopilotNode = async (node, target_edges) => {
    let _id = uuidv4()
    setInterfaceLog(prevInterfaceLog => [...prevInterfaceLog, { id: _id, message: 'Accepting Copilot Node', status: 'Pending' }])
    setCopilotNodesLoading(prev => {
      prev[node['id']] = true
      return prev
    })
    try {
      target_edges = target_edges.map(edge => ({
        source: edge.source,
        sourceHandle: "b",
        target: edge.target,
        targetHandle: "a",
        type: "smoothstep",
        markerEnd: {
              type: MarkerType.ArrowClosed,
              height: '20px',
              width: '20px',
        },
        id: edge.id
      }))
      const response = await GraphClient.acceptCopilotNode(dagId, { node: node, target_edges: target_edges })
      if (response.error) {
        console.log('error accepting node', response.error)
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Error' } 
                : log 
        ))
      }
      if (response.dag) {
        setNodes(response.dag.nodes)
        setEdges(response.dag.edges)
        setCopilotNodes(prev => prev.filter(item => item.id !== node.id))
        setCopilotEdges(prev => prev.filter(item => !(target_edges.find(edge => edge.id == item.id))))
        setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
          log =>
              log.id === _id
                ? { ...log, status: 'Success' } 
                : log 
        ))
        setSelectedNode(prev => ({...prev}))
      }
    }
    catch (e) {
      console.log('error accepting node', e)
      setInterfaceLog(prevInterfaceLog => prevInterfaceLog.map(
        log =>
            log.id === _id
              ? { ...log, status: 'Error' } 
              : log 
      ))
    }
    setCopilotNodesLoading(prev => {
      prev[node['id']] = false
      return prev
    })

  }

  return (
    <TableGraphContext.Provider
      value={{
        visible,
        setVisible,
        nodes,
        setNodes,
        onNodesChange,
        getParents,
        edges,
        setEdges,
        onEdgesChange,
        selectedEdge,
        setSelectedEdge,
        onElementClick,
        selectedNode,
        setSelectedNode,
        getSelectedNode,
        initSelectedNode,
        initSelectedSeed,
        getSuggestedJoinFromNodes,
        executeDagNode,
        onDeleteEdge,
        columnList,
        setColumnList,
        updateSelectedNode,
        updateNodes,
        dags, setDags, getDags, getDag, setSelectedDag,
        dbtSchema, setDbtSchema,
        createDag,
        deleteDag,
        updateDagName,
        updateDagFavorite,
        dbtModel,
        setDbtModel,
        interfaceLog,
        setInterfaceLog,
        executeDag,
        retryNodeParse,
        retryParseLoading,
        saveDAG,
        saveDAGSpecific,
        openSidebar,
        setOpenSidebar,
        openConsole,
        setOpenConsole,
        dagName,
        setDagName,
        getDagName,
        dagId,
        mode,
        setMode,
        filteredDags,
        setFilteredDags,
        git,
        setShowDB,
        showDB,
        showModels,
        setShowModels,
        initSelectedModel,
        handleNodeRename,
        updateNodeDescription,
        getNodeDescription,
        handleCustomSchemaRename,
        setCustomMaterialization,
        fetchSelectedNodeInfo,
        setSelectedNodeEdited,
        editedNodes,
        setEditedNodes,
        saveNodeChanges,
        setForceDagSave,
        sumContent,
        setSumContent,
        filterContent,
        setFilterContent,
        groupContent,
        setGroupContent,
        orderContent,
        setOrderContent,
        initializeAggregateNode,
        aggregateArtyLoading,
        joinArtyLoading,
        workflowAI,
        blankLoading,
        getDbtModelFile,
        reactFlowInstance,
        setReactFlowInstance,
        reactFlowSize,
        setReactFlowSize,
        sortDag,
        getDbtModelFile,
        previewDagNode,
        showNodeOutline,
        setShowNodeOutline,
        draggingNodeOutline,
        setDraggingNodeOutline,
        workflowsConsoleMode,
        setWorkflowsConsoleMode,
        previewCachedData,
        setPreviewCachedData,
        SSE_executeDag,
        SSE_executeDagNode,
        acceptCopilotNode,
        copilotNodes,
        setCopilotNodes,
        onCopilotNodesChange,
        copilotEdges,
        setCopilotEdges,
        onCopilotEdgesChange,
        getCopilotSelectedNode,
        copilotNodesLoading,
        setCopilotNodesLoading,
        hoveredEdges,
        setHoveredEdges,
        deleteNode,
      }}
    >
      {children}
    </TableGraphContext.Provider>
  );
};
