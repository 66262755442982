import 'antd/dist/antd.variable.min.css';
import { Route, Switch } from 'react-router-dom';
import Provider from './context/Provider';
import NavBar from './components/NavBar';
import './style.scss'
import {TableGraph, Workflows, ChatPage} from './pages/TableGraphs'
import WorkflowSchedules from './pages/WorkflowSchedules'

import TableManagement from './pages/TablePage';
import Landing from './pages/Landing';
import AccountPage from './pages/AccountPage';
import ConnectorsPage from './pages/ConnectorsPage';
import Billings from './pages/Billings';
import {ModelEditor} from './pages/ModelEditor';

import { useAuthInfo } from '@propelauth/react';
import { useRedirectFunctions } from '@propelauth/react';

import { useEffect, useState } from 'react';
import LetoClient from './context/LetoClient';
import GraphClient from './context/GraphClient';
import ChatClient from './pages/ContextAdmin/ChatOutput/ChatClient';

import FivetranApi from './context/FivetranAPI';

import CryptoJs from 'crypto-js';
import Onboarding from './pages/Onboarding/Onboarding';
import InvalidUser from './pages/InvalidUser/InvalidUser';

import ContextAdmin from './pages/ContextAdmin/ContextAdmin';
import SecretsPage from './pages/ContextAdmin/Secrets/SecretsPage';

import ContextAdminHome from './pages/ContextAdmin/ContextAdminHome';
import Analytics from './pages/Analytics/Analytics';
import AnalyticsChart from './pages/Analytics/AnalyticsChart';
import Dashboard from './pages/Analytics/Dashboard'
import Editor from './pages/Editor';


const App = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log = function () { };
  }
  const [notCarltonOrg, setNotCarltonOrg] = useState(true)
  const { redirectToLoginPage } = useRedirectFunctions();
  const authInfo = useAuthInfo();


  const [user, setUser] = useState(null);

  const [warehouseType, setWarehouseType] = useState(null)

  const getMeFunction = (result) => {
    console.log(result)
    if (result.error && result.error === 'No User' || result.error === 'No DB User') {
      setUser({});
      redirectToLoginPage();
    }
    else {
      setUser(result);
      if (result.subscription_type === "carlton22")
        setNotCarltonOrg(false)
      //TODO: validate /me result
      if (result.hasOwnProperty('config')) {
        FivetranApi.setGroupId(result['config']['fivetran_group_id']);
        LetoClient.setGroupId(result['config']['fivetran_group_id']);
      }
      setWarehouseType(result.warehouse_type)

      let hash = 'zrgtVEPtiNeaA5fmxyXlifrbTnNJwQisAxW4HnCt';
      let hash_key = CryptoJs.HmacSHA256(result['user_id'], hash);
      let hash_key_decoded = hash_key.toString(CryptoJs.enc.Hex);
      console.log(authInfo.user.userId);
      console.log(hash_key_decoded);

      // window.Intercom('boot', {
      //   api_base: 'https://api-iam.intercom.io',
      //   app_id: 'p0b3st9a',
      //   name: authInfo.user.firstName + ' ' + authInfo.user.lastName, // Full name
      //   user_id: result['user']['user_id'],
      //   email: authInfo.user.email,
      //   // user_hash: result['intercom_user_hash']
      //   user_hash: hash_key_decoded,
      // }); 

    }
  }

  useEffect(() => {
    if (!authInfo.loading) {
      LetoClient.setToken(authInfo.accessToken);
      GraphClient.setToken(authInfo.accessToken);
      ChatClient.setToken(authInfo.accessToken);
      if (user === null) {
        let attempts = 0;
        let isRequestPending = false;
        let interval = setInterval(() => {
          attempts += 1;
          console.log(attempts);
          if (attempts >= 5) {
            console.log('greater than 5 attempts!');
            clearInterval(interval);
          }
          if (!isRequestPending) {
            isRequestPending = true;
            LetoClient.getMe().then(result => {
              clearInterval(interval);
              console.log('interval cleared!');
              getMeFunction(result);
              isRequestPending = false;
            })
            .catch(error => {
              console.log(error);
              isRequestPending = false;
            });
          }
        }, 2000);
      }
    }
  }, [authInfo]);
  

  if (user && !user.user_id) {
    return (
      <InvalidUser />
    )
  }
  else if (authInfo.loading || authInfo.isLoggedIn) {
    return (
      <Provider warehouseType={warehouseType}>
        {authInfo.loading ?
          <div className="flex flex-col h-full relative">
            <div className='h-[55px] flex' style={{height: '55px'}}><NavBar notCarltonOrg={notCarltonOrg}> </NavBar></div>
            <div className='w-full grow flex justify-center items-center bg-white'>
              <div className='flex flex-col items-center'>
                <div className='w-[100px] h-[100px]'>
                  <div className="page-loading-container">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div> 
                </div>
                </div>
            </div>
          </div>
          :

          (user && user.onboarded !== "true" ?
            <Onboarding setUser={setUser} />
            :
            <div style={{ overflow: 'hidden' }}>
            
              <NavBar notCarltonOrg={notCarltonOrg}> </NavBar>
 
              <Switch>
                <Route exact path="/" component={Landing} />
                {/* <Route path="/artyai" component={ArtyAI} /> */}
                <Route exact path="/analytics" component={Analytics} />
                <Route path="/analytics/charts/:chart_id" component={AnalyticsChart} />
                <Route exact path="/analytics/dashboard" component={Dashboard} />
                <Route path="/analytics/dashboard/:dashboard_id" component={Dashboard} />

                <Route path="/data" component={ConnectorsPage} />
                <Route path="/account" component={AccountPage} />
                {notCarltonOrg &&
                  <Route path="/billings" component={Billings} />
                }
                
                <Route path="/chat/:dag_id" component={ChatPage} />
                
                <Route path="/workflows/schedule" component={WorkflowSchedules} />

                <Route path="/workflows" component={Workflows} />

                <Route path="/editor" component={Editor} />

                <Route path="/contexts/:contextId/secrets" component={SecretsPage} />
                
                <Route path="/contexts/:contextId" component={ContextAdmin} />

                <Route path="/contexts" component={ContextAdminHome} />


                
                <Route path="/tablegraphs/:dag_id" component={TableGraph} />

              </Switch>
          </div>
          )
      }
      </Provider>
    );
  } else {
    redirectToLoginPage();
  }
};

export default App;
