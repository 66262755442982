import {React, useState, useEffect} from 'react';
import {HeartFilled} from '@ant-design/icons';
import {BsTrashFill} from 'react-icons/bs';
import './ChatPage';
import './styles.scss';

const PinnedChatItem = ({initialState, isCurrentChat, isHoveredChat}) => {

  const [isFavourite, setFavourite] = useState(initialState);

  useEffect(() => {
    setFavourite(initialState)
  }, [initialState])


  return (
    <div className = "flex flex-row mr-[5px]">
      <HeartFilled
        className = {`heart-icon ${isFavourite ? "heart-filled" : "heart-not-filled"} ${ isHoveredChat || isCurrentChat? "visible" : "hidden"}`}
        onClick={() => setFavourite(!isFavourite)}
      ></HeartFilled>
    </div>
  ) 
}

export default PinnedChatItem
