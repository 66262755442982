import { useContext, useState, useEffect } from 'react';
import './style.scss';
import LetoClient from '../../../context/LetoClient';
import { Card } from 'antd';
import ConnectorDetailsDrawer from '../ConnectorDetailsDrawer/ConnectorDetailsDrawer';
import { ConnectorContext } from '../../../context/ConnectorProvider';

const ConnectorCard = ({ setOpenSidebar, setSidebarData, connector, name, metaData, status }) => {
  const { workspaces } = useContext(ConnectorContext);
  const [visible, setVisible] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);


  const MaxTextLength = props => {
    if (props.text.length > props.length) {
      return <h1>{props.text.substring(0, props.length - 2)}...</h1>;
    }
    return <h1>{props.text}</h1>;
  };

  const MaxSubtextLength = props => {
    if (props.text.length > props.length) {
      return <p>{props.text.substring(0, props.length - 2)}...</p>;
    }
    return <p>{props.text}</p>;
  };

  const showSidebar = () => {
    setOpenSidebar(true)
  }

  useEffect(() => {
    setCardDetails(connector);
  }, [connector]);

  const onClose = () => {
    setVisible(false);
    setCardDetails(null);
  };

  return (
    <>
      <Card className="connector-container border-[1px] border-gray-300 hover:bg-gray-100 select-none cursor-pointer" onClick={() => {        
        let data = {
          cardDetails : cardDetails, 
            metaData : metaData,
            visible : visible,
            onClose : {},
          connector : connector,
            selectedWorkspaces : selectedWorkspaces,
          setSelectedWorkspaces : setSelectedWorkspaces,
          paused : connector.paused

        }
        setSidebarData(
            data
        )
        showSidebar();
      }}>
        {metaData && <img src={metaData.icon_url} alt={metaData.name} />}
        {metaData && <MaxTextLength text={metaData.name} length={20} />} 
        {/* <h1>{metaData.name}</h1> */}
        <p className="service-name">
         <MaxSubtextLength text={name} length={27}/>
        </p>
        {/* <p className="lakehouse-name-empty">No Workspace</p> */}

        <div className={connector.paused ? 'status-paused' : (status === 'complete' || status === 'connected' ? 'status-on' : 'status-off')}></div>
      </Card>

    {/*
      <ConnectorDetailsDrawer
        cardDetails={cardDetails}
        metaData={metaData}
        visible={visible}
        onClose={onClose}
        connector={connector}
        selectedWorkspaces={selectedWorkspaces}
        setSelectedWorkspaces={setSelectedWorkspaces}
        paused={connector.paused}
      /> */}
    </>
  );
};

export default ConnectorCard;
