import { useContext, useState } from "react";
import { Col, InputNumber, Row, Slider } from 'antd';
import { StripeContext } from "../../context/StripeContext";

const marks = {
    100: '100',
    200: '200',
    300: '300',
    400: '400',
    500: '500',
    750: '750',
    1000: '1000',
}

const PurchaseCreditModal = () => {
    const {
        purchaseCreditModalVisible,
        setPurchaseCreditModalVisible,
        create_payment_intent,
    } = useContext(StripeContext)

    const [inputValue, setInputValue] = useState(100);

    const onChange = (newValue) => {
        setInputValue(newValue);
    };

    const purchaseCredits = (amount) => {
        create_payment_intent(amount)
      }

        
    return purchaseCreditModalVisible ? (
        <div
            className="bg-gray-700 h-screen w-screen z-50 fixed top-0 left-0 bg-opacity-50"
            onClick={() => {
                // setPurchaseCreditModalVisible(false);
            }}
        >
            <div className="relative mx-auto max-w-[20px] h-full md:h-auto top-[25vh]" style={{maxWidth: "55vw"}}>
                <div className="relative text-center bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Select amount of Artemis credits to purchase</h3>
                        <br />
                        <Row>
                            <Col span={1}/>
                            <Col span={18}>
                                <Slider
                                
                                min={100}
                                max={1000}
                                marks={marks}
                                onChange={onChange}
                                step={10}
                                value={typeof inputValue === 'number' ? inputValue : 0}
                                />
                            </Col>
                            <Col span={5}>
                                <InputNumber
                                min={100}
                                max={1000}
                                step={10}
                                style={{
                                    margin: '0 25px',
                                    fontSize: '25px',
                                }}
                                value={inputValue}
                                onChange={onChange}
                                bordered={false}
                                />
                                <h1 style={{textAlign: 'left', paddingLeft: "47px"}}>credits</h1>
                            </Col>
                        </Row>
                        <br />
                        <br/> 
                        <button type="button" className="text-white bg-[#1F422A] hover:bg-[#537650] rounded-lg text-sm font-medium px-5 py-2.5" style={{marginRight: "15px"}} onClick={() => {purchaseCredits(inputValue)}}> Continue </button>
                        <button type="button" className="text-gray-500 bg-white  hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => {setPurchaseCreditModalVisible(false)}}> Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    ) :
    (<></>)
}

export default PurchaseCreditModal;