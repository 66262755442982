import React , {useState, useContext, useRef, useMemo, useEffect} from 'react';
import { TableGraphProvider, TableGraphContext } from '../../context/TableGraphProvider';
import { ArtyChatContext } from '../../context/ArtyChatProvider';
import { ArtyAIContext} from '../../context/ArtyAIProvider';
import PinChartModal from '../ArtyAI/pinChartModal';
import PinTableModal from '../ArtyAI/pinTableModal';
import { Tooltip as AntdTooltip, List, Tooltip } from 'antd';
import ArtyAiErrorModal from '../ArtyAI/ArtyAiErrorModal';
import ArtyAiChartLimitModal from '../ArtyAI/ArtyAIChartLimitError';
import { PushpinFilled, DownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorView } from "@codemirror/view";
import WorkflowNavBar from './WorkflowNavbar';
import Sidebar from '../../components/Sidebar';
import { BsChatLeftText, BsTrashFill } from "react-icons/bs";
import {HiOutlineLightBulb} from "react-icons/hi"
import './styles.scss';
import PinnedChatItem from './PinnedChatItem';
import Page from '../../components/Page';
import { UserContext } from '../../context/UserProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ChatPageGraph from './ChatPageGraph';
import { addEdge } from 'reactflow';


const ChatComponent = () => {
    const { dag_id } = useParams();
    const { user } = useContext(UserContext)
    const { 
        chat, 
        setChat, 
        chatId,
        setChatId,
        createChat,
        convertPipelineToDAG,
        promptArty, 
        promptArtySql, 
        contextMessage,
        getAllChats,
        setChatList,
        chatList,
        getPinnedChats,
        pinnedChatList,
        createPinnedChat,
        deletePinnedChat, 
        deleteChat,
        fetchChat, 
        fetchingChat,
        updateChatName,
        newChatName,
        setNewChatName,
        initialChatName,
        setInitialChatName,
        isEditing,
        setIsEditing,
        handleEdit,
        handleSave,
        handleCancel,
    } = useContext(ArtyChatContext);
      
    const {
        setPinChartView,
        setPinChart,
        setPinTableView,
        setPinTable,
        getChartBoards,
        artySidebarOpen,
        setArtySidebarOpen,
      } = useContext(ArtyAIContext)

    const {saveDAG, nodes, edges, setSelectedNode} = useContext(TableGraphContext);

    const [fetched, setFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputMessage, setInputMessage] = useState('');
    const [showChats, setShowChats] = useState(true)
    const [showPinnedChats, setShowPinnedChats] = useState(false)
    const [hoveredChat, setHoveredChat] = useState(false)
    const [hoveredPinnedChat, setHoveredPinnedChat] = useState(false)
    const [expanded, setExpanded] = useState(-1)
    const [copied, setCopied] = useState(false)
    const [showDag, setShowDag] = useState(false)
    const history = useHistory()

    useEffect(() => {

        const fetchData = async () => {
            if (!fetched) {
                await createChat(dag_id)
                getPinnedChats(dag_id)
                setFetched(true)
            }
        };
        fetchData();
        setSelectedNode(null)
    }, [dag_id, fetched]); 

    useEffect(() => {
        if(nodes && edges && showDag){
            const saveDag = async () => {

                await saveDAG(undefined, dag_id) // TODO: ADD EDGELIST
                setShowDag(false)
                history.push(`/tablegraphs/${dag_id}`)
            }
            saveDag()

        }
    },[showDag,nodes,edges])

    const SaveChart = (chart, sql) => {
        setPinChartView(true)
        setPinChart({...chart, 'sql': sql, 'x_axis': [chart.x_axis], 'y_axis': [chart.y_axis]})
    }   

    const SaveTable = (table, sql) => {
        setPinTableView(true)
        setPinTable({'chart_board_id': "", 'chart_type': "table", 'sql': sql, 'x_axis': [table.columns[0].name], 'y_axis': [table.columns[1].name], 'plot_name': ""})
    }

    const itemDoubleClick = (item) => {
        fetchChat(item._id)
    }

    const itemClick = (item) => {
        fetchChat(item._id)
    }

    const accessPrompt = (prompt) => {
        console.log('prompt:', prompt);
        setInputMessage(prompt)
    };

    const onDelete = async (item, length, index) => {
        const response = await deleteChat(item._id, dag_id)
        if (index >= (length - 1)) {
            if (length === 1) {
                console.log(chatList)
                setChat(response[0])
                setChatId(response[0]._id)
            }
            else {
                setChat(response[index-1])
                setChatId(response[index-1]._id)
            }
        }
    }

    const pinnedChatExists = (chat) => {
        if (chat.pinned) {
            return true
        }
        return false
      };
    
    const addPinnedChat = (item) => {
        if (!pinnedChatExists(item)) {
            setChat(item);
            setChatId(item._id);
            createPinnedChat(item._id, dag_id);
            getPinnedChats(dag_id);
        }
    };
    
       const removePinnedChat = (item) => {
        if (pinnedChatExists(item)) {
            setChat(item);
            setChatId(item._id);
            deletePinnedChat(item._id, dag_id);
            getPinnedChats(dag_id);
        }
      };

    const handleKeyDown = async (e, chat_id) => {
        console.log("handle key down")
        if (e.key === "Enter") {
            handleSave(dag_id, chat_id);
        }
    };
    
    const handleChatName = (item) => {

        if (isEditing === item._id) {
            return (
                <div className="flex w-[200px]">
                    <input 
                        className="border border-[#B0B0B0] h-[17px] bg-[#F0F0F0] text-[13px] w-[200px] rounded-sm p-[8px]"
                        type="text" 
                        value={newChatName} 
                        onChange={(e) => setNewChatName(e.target.value)} 
                        onBlur={handleCancel}
                        onKeyDown={(e) => handleKeyDown(e, item._id)}
                    />
                </div> 
            )
        }
        else {
            if (item.chat_name) {
                return <a style={{fontSize: '13px', marginLeft: "10px", marginBottom:'0px', position: 'relative', top: '-5px', userSelect: 'none'}} onClick={() => handleEdit(item)}>{item.chat_name}</a>
            }
            else {
                return <a style={{fontSize: '13px', marginLeft: "10px", marginBottom:'0px', position: 'relative', top: '-5px', userSelect: 'none'}}  onClick={() => handleEdit(item)}>New Chat</a>
            }
        }
    }

    const togglePinnedChats = () => {
        setShowPinnedChats(!showPinnedChats);
        getPinnedChats(dag_id)
    }

    const togglePastChats = () => {
        setShowChats(!showChats);
        getAllChats(dag_id)
      }
    

    const adjustTextareaHeight = () => {
        const textarea = document.getElementById("textarea");
        textarea.style.height = "40px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };
    
    const changeDetailsMode = (index) => {
        
        if (expanded === index) {
            setExpanded(-1)

        }
        else {
            setExpanded(index)
        }
    }
const renderTable = (tableData) => {
    if (!tableData || tableData.length === 0) {
        return <div>No Data</div>;
    }

    // Get column headers from the first item's keys
    const columns = tableData[0] ? Object.keys(tableData[0]) : [];
    return (
        <div className='max-h-80'>
        <table className="min-w-60 max-w-full leading-normal">
            <thead>
                <tr>
                    {columns.map((header, idx) => (
                        <th key={idx + "header"} className="px-2 py-2 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className=''>
                {tableData && tableData.length && tableData.map((row, idx) => (
                    <tr key={idx + "tableData"}>
                        {columns.map((col, colIdx) => (
                            <td key={colIdx + "row"} className="border-b border-gray-200 bg-white text-sm">
                                {row[col]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    );
};

    const onSqlExecute = (sql, index) => {
        let response = promptArtySql(dag_id, sql, index) 
    }

    const onPipelineExecute = async (message) => {
        let response = await convertPipelineToDAG(message)
        if(response){
            setShowDag(true)
        }
     }

    const handleCopy = (text) => {
        setCopied(true)
        navigator.clipboard.writeText(text)
        setTimeout(() => {
            setCopied(false)
        }, 1000 )
    }


    const renderMessage = (message, index) => {
        switch (message.response_type) {
            case -1: 
                return (
                    <div key={index + "error"} className="flex w-full text-[#ff6963] text-left bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='mr-[10px]'>
                            Error:
                        </div>
                        <div className="">
                            {message.response}
                        </div>
                    </div>
                );

            case 0: 
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {user.first_name ? user.first_name : 'User'}:
                        </div>
                        <div className="text-left ">

                            {message.response}
                        </div>
                    </div>
                );
            case 1: 
                return (
                   <div> 
                        <div key={index + "explaination"} className="flex w-full bg-[#F7F6F6] border-b-[1px] border-gray-300 py-[15px] px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            Arty:
                        </div>
                        
                        <div className="text-left">

                            {message.response}
                        </div> 
                    </div>
                        {message.sql ?
                            <div key={index + "sql"} className="flex w-full bg-[#F7F6F6] border-b-[1px] border-gray-300 py-[15px] px-[30px]">
                            <div className='text-gray-500 mr-[10px] select-none'>
                                Arty:
                            </div>
                            <div className="relative">
                                <CodeMirror
                                id="sql-code"
                                className='relative w-90% bg-[#f0f0f0] line-wrap theme-custom'
                                extensions={[
                                    sql(),
                                    EditorView.lineWrapping,
                                    EditorView.theme({
                                    ".cm-activeLineGutter": {
                                        color: 'black',
                                        fontWeight: '500',
                                        backgroundColor: 'transparent',
                                    },
                                    ".cm-content": {
                                        paddingRight: '0px !important'
                                    },
                                    ".cm-scroller": {
                                        paddingBottom: '30px !important',
                                        backgroundColor: '#f0f0f0'
                                    },
                                    ".cm-line.cm-activeLine": {
                                        backgroundColor: '#e8e8e8',
                                        borderRadius: '4px',
                                    },
                                    ".cm-gutters": {
                                        backgroundColor: "#f0f0f0",
                                    },
                                    }),
                                ]}
                                value={message.sql}
                                editable={false}
                                >
                                </CodeMirror>
                                <div className='absolute bottom-0 right-0 mb-2 mr-2' onClick={()=>{onSqlExecute(message.sql, index)}}>       
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="w-6 h-6 hover:scale-110">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 01 0 1.972l-11.54 6.347a1.125 1.125 0 01-1.667-.986V5.653z" />
                                </svg>
                                </div>
                            </div>
                            </div>
                            : 

                            message.data_pipeline ?
                            <div key={index + "pipeline"} className="flex w-full bg-[#F7F6F6] border-b-[1px] border-gray-300 py-[15px] px-[30px]">
                                <div className='text-gray-500 mr-[10px] select-none'>
                                Arty:
                                </div>
                                <div className="overflow-hidden flex-row overflow-x-auto py-4 bg-white h-[300px] w-full rounded-lg">
                                    <ChatPageGraph message={message} />
                                </div>
                                <Tooltip placement='leftTop' title='Run Pipeline'>
                                        <div className='mb-2 mr-2' onClick={() =>{onPipelineExecute(message)}}>       
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="w-6 h-6 hover:scale-110">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 01 0 1.972l-11.54 6.347a1.125 1.125 0 01-1.667-.986V5.653z" />
                                        </svg>
                                        </div>
                                    </Tooltip>
                            </div>
                            : <div></div>
                            }
                    
    {
        message.loading?   
        <div key={index + "loading"} className="flex w-full bg-[#F7F6F6] border-b-[1px] border-gray-300 py-[15px] px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            Arty:
                        </div>
                        
                        <div className="text-left">
                            Executing SQL Query : {message.sql}
                        </div> 
                    </div> : <div></div>
    }

    {
        message.results?   
        <div key={index + "table"} className="flex w-full bg-[#F7F6F6] border-b-[1px] border-gray-300 py-[15px] px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            Arty:
                        </div>
                        
                        <div className="text-left overflow-auto">

                            {renderTable(message.results)}

                        </div> 
                    </div> : <div></div>
    }



                    </div>

                );
            default:
                return null;
        }
    }

    const handeKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            // event.stopPropagation()
            handleSubmit(event)
        }
    }

    const chatPrompts = (chat) => {
        return (
            <div className="">
                {chat.examples && <div>  
                    <div className = "flex items-center justify-center mt-[100px] text-[#7C7C7C] text-[25px]">Ask Arty Anything!</div>
                    <div className = "flex flex-col items-center justify-center">
                        <HiOutlineLightBulb className = "flex items-center  self-center justify-center mt-[10px] h-7 w-7 stroke-[#7C7C7C]"></HiOutlineLightBulb>
                        <div className = "flex items-center justify-center mt-[5px] text-[#7C7C7C] text-[18px]">Examples</div>
                    </div>
                    <div className = "flex items-center justify-center mt-[10px]">
                        <div className = "w-1/2 flex flex-col justify-center self-center text-center rounded-lg bg-[#E3E3E3]">
                            <List
                            dataSource={chat.examples}
                            renderItem={(item) => (
                                <List.Item className = "hover:bg-[#E9E9E9] p-[10px]" onClick={() => accessPrompt(item.prompt)}>
                                    <List.Item.Meta 
                                        title = {item.prompt}
                                    />
                                </List.Item>
                            )}
                            >
                            </List>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // handle user message submission here
        // and start loading stat
        setIsLoading(true);
        await promptArty(dag_id, inputMessage)
        setInputMessage('');
        setIsLoading(false);
    }
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [chat]);

    
    const sidebarContent = () => {
        return (
          <div className='sider-style ml-[20px] mt-5 overflow-y-scroll'> 
            <div onClick={() => {togglePinnedChats()}} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
                <DownOutlined className='duration-[50ms]' style={showPinnedChats ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
                Pinned Chats
            </div>
            <div className={showPinnedChats ? 'pinned-chat-sider' : 'chat-sider-hidden'}>
                {pinnedChatList ? 
                <List
                dataSource={pinnedChatList}
                renderItem={(pinnedItem) => (
                    <List.Item className = {chat._id === pinnedItem._id? 'relative bg-[#f0f0f0] hover:bg-[#EaEaEa] rounded-lg focus:bg-[#E7E7E7]rounded-lg' : 'relative hover:bg-[#E7E7E7] focus:bg-[#E7E7E7] rounded-lg'} style={{height: '45px'}} onMouseEnter={() => setHoveredPinnedChat(pinnedItem)} onMouseLeave={() => setHoveredPinnedChat([])}
                    onClick={() => itemClick(pinnedItem)} 
                    onDoubleClick={() => itemDoubleClick(pinnedItem)}>
                        <List.Item.Meta 
                            avatar = {<BsChatLeftText size = "11px" style = {{marginTop: "-5px", marginLeft: "10px", strokeWidth: "0.2px", marginBottom: '0px', position: 'relative', top: '-4px'}}/>}
                            title = {handleChatName(pinnedItem)}
                        />
                        <a className='text-[11px] ml-10px mb-[0px] mt-[3px] absolute top-[22px] left-[35px] select-none' style = {(hoveredPinnedChat._id === pinnedItem._id && isEditing === null) || (chat._id === pinnedItem._id && isEditing === null)? {transition: '100ms ease-in-out', opacity: '1'} : {transition: '100ms ease-in-out', opacity: '0'}}>{"number of messages: " + (pinnedItem.num_messages ?  pinnedItem.num_messages : 0)}</a>
                        <div onClick={() => (pinnedChatExists(pinnedItem) ? (removePinnedChat(pinnedItem)) : (addPinnedChat(pinnedItem)))}>
                        </div>
                    </List.Item>
                )}
                > 
                </List> : 
                <a className = 'text-[12px] ml-[16px] mb-[20px]'>No Pinned Chats</a>}
            </div>
            <div onClick={() => {togglePastChats()}} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
                <DownOutlined className='duration-[50ms]' style={showChats ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'10px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
                Chat History
             </div>
            <div className={showChats ? 'chat-sider' : 'chat-sider-hidden'}>
                {chatList && 
                <List
                dataSource={chatList}
                renderItem={(item, index) => (
                    <List.Item className = {chat._id === item._id? 'relative bg-[#f0f0f0] hover:bg-[#EaEaEa] rounded-lg focus:bg-[#E7E7E7] rounded-lg' : 'relative hover:bg-[#E7E7E7] focus:bg-[#E7E7E7] rounded-lg'} style={{height: '45px'}} onClick={() => itemClick(item)} onDoubleClick={() => itemDoubleClick(item)} onMouseEnter={() => setHoveredChat(item)} onMouseLeave={() => setHoveredChat([])}>
                        <List.Item.Meta
                            avatar = {<BsChatLeftText size = "11px" style = {{marginTop: "-5px", marginLeft: "10px", strokeWidth: "0.2px", marginBottom: '0px', position: 'relative', top: '-4px'}}/>}
                            title = {handleChatName(item)}
                        />
                        <a className='text-[11px] ml-10px mb-[0px] mt-[3px] absolute top-[22px] left-[35px] select-none' style={ (hoveredChat._id === item._id && isEditing === null) || (chat._id === item._id && isEditing === null)? {transition: '100ms ease-in-out', opacity: '1'} : {transition: '100ms ease-in-out', opacity: '0'}}>{"number of messages: " + (item.num_messages ?  item.num_messages : 0)} </a>
                        <div onClick={() => (pinnedChatExists(item) ? (removePinnedChat(item)) : (addPinnedChat(item)))}>
                            <PinnedChatItem initialState = {pinnedChatExists(item)} isCurrentChat = {chat._id === item._id} isHoveredChat = {item._id === hoveredChat._id}/>
                        </div>
                        <button onClick={(event) => {onDelete(item, chatList.length, index); event.stopPropagation();}}>
                            <BsTrashFill className={ hoveredChat._id === item._id || chat._id === item._id? 'visible fill-[#B9B8B8] hover:fill-[#A4A4A4] mt-[5px] ml-[5px] mr-[10px]' : 'hidden'}></BsTrashFill>
                        </button>
                    </List.Item>
                )}
                >
                </List>}
            </div>
          </div>
        );
    }
    
    return (
      <Page>
        <div className='select-none'>
            <WorkflowNavBar dag_id={dag_id} page={"chat"}/>
        </div>
        <Sidebar setSidebarData = {()=>{}} openSidebar={artySidebarOpen} setOpenSidebar = {setArtySidebarOpen} sidebarContent = {sidebarContent()} removePadding={true}>
            <div className="flex w-full relative flex-col justify-between h-full bg-[#F7F7F7]">
                <div className="overflow-y-auto p-4 grow overflow-auto">
                <Spin className='grow' indicator={<div className='flex justify-center mt-[15vh]'><LoadingOutlined style={{fontSize: 24}} spin/></div>} spinning={fetchingChat}>
                    {chat.data && chat.data.length > 0? <div className='max-h-[60%]'> {(chat.data).map(renderMessage)}</div> : chatPrompts(chat)}
                    <div ref={messagesEndRef} />
                </Spin>
                </div>
                <div className="border-t-2 border-gray-200 p-4">
                    {isLoading ? (
                    <input className= "w-full rounded-md border-gray-300 shadow-sm p-2" disabled placeholder="Loading..." />
                    ) : (
                        <form onSubmit={(e) => handleSubmit(e)} className="mt-[8px] mb-[8px] flex justify-content">
                            <div className="input-field w-full">
                                <textarea
                                    id="textarea"
                                    className="w-full h-[40px] max-h-[100px] outline-black rounded-lg p-2 resize-none materialize-textarea"
                                    value={inputMessage}
                                    onKeyDown={handeKeyDown}
                                    onChange={handleInputChange}
                                    onInput={adjustTextareaHeight}
                                    placeholder="Type your message..."
                                    disabled={isLoading}
                                    autoFocus={true}
                                />
                            </div>
                            <input
                                className="bg-black h-[40px] ml-2 text-white p-2 rounded-lg cursor-pointer"
                                type="submit"
                                value="Send"
                                disabled={isLoading}
                            />
                        </form>
                    )}
                </div>
            </div>
        </Sidebar>
      </Page>
    );
}

  

export default ChatComponent;
