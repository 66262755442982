import Select from "react-select";

const customStyles = {
    control: (provided) => ({
        ...provided,
        position: 'relative',
        height: '35px',
        minHeight: '35px',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
        padding: '0px'

      }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '35px',
        padding: '0px',
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        padding: '0px',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        paddingRight: '5px',
        paddingLeft: '2px'
    }),
    menu: (provided) => ({
        ...provided,
        height: "150px",
        wordWrap: "break-word",
        position: "absolute",
        zIndex: 99,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: "150px",
        wordWrap: "break-word",
        
    })
};

const AnalyticsDropdown = ({ selected, dropdownList, setSelected, loading, disabled, clearable = true, style = {}, placeholder="Select..."}) => {
    return (
        <div style={style}>
            <Select
                menuPlacement="auto"
                minMenuHeight='150px'
                value={selected}
                onChange={setSelected}
                options={dropdownList}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                    ...theme.colors,
                    primary25: '#537650',
                    primary: '#1F422A',
                    },
                })}
                isLoading={loading}
                isDisabled={disabled}
                isClearable={clearable}
                styles={customStyles}
                placeholder={placeholder}
            />
        </div>
    );
  };
  
  export default AnalyticsDropdown;