import { useContext, useState, useRef, useEffect } from 'react';
import Page from '../../components/Page';
import './styles.scss';
import { EditorContext } from '../../context/EditorProvider';
import Sidebar from '../../components/Sidebar';
import BaseEditorSidebar from '../../components/Editor/Sidebar/BaseEditorSidebar';
import EditorTabs from '../../components/Editor/EditorTabs';
import ContextMenu from '../../components/Editor/ContextMenu';
import EditorPage from '../../components/Editor/EditorPage';
import EditorPageManager from '../../components/Editor/EditorPageManager';



const Editor = () => {
    const {
        sidebarOpen,
        setSidebarOpen,
        sidebarWidth,
        setSidebarWidth,
        sidebarRef,
    } = useContext(EditorContext)

    return (
        <Page>
            <ContextMenu/>
            <Sidebar
                setSidebarData={() => { }}
                sidebarContent={<BaseEditorSidebar />}
                openSidebar={sidebarOpen}
                setOpenSidebar={setSidebarOpen}
                hideButtonWhenClosed={false}
                removePadding={true}
                sidebarWidth={sidebarWidth || '330px'}
                setSidebarWidth={setSidebarWidth}
                providedRef={sidebarRef}
                hideButtonWhenOpen={true}
            >
                <div className='h-full'>
                    <div className=''>
                        <EditorTabs/>
                    </div>
                    <div className='editor-page-height'>
                        <EditorPageManager/>
                    </div>
                </div>
            </Sidebar>
        </Page>
    );
};

export default Editor;
