import { createContext, useState } from 'react';

const MOCK_DATASETS = [
  { key: 1, name: '2022-03-02 Quarterly Report', lastViewed: '14 minutes ago' },
  { key: 2, name: '2021-03-02 Quarterly Report', lastViewed: 'a day ago' },
  { key: 3, name: '2020-03-02 Quarterly Report', lastViewed: '2 days ago' },
  { key: 4, name: '2019-03-02 Quarterly Report', lastViewed: 'a week ago' },
  { key: 5, name: '2018-03-02 Quarterly Report', lastViewed: 'a month ago' },
  { key: 6, name: '2017-03-02 Quarterly Report', lastViewed: '3 months ago' },
];

export const DatasetsContext = createContext();

export const DatasetsProvider = ({ children }) => {
  const [datasets, setDatasets] = useState(MOCK_DATASETS);

  return (
    <DatasetsContext.Provider
      value={{
        datasets,
        setDatasets,
      }}
    >
      {children}
    </DatasetsContext.Provider>
  );
};
