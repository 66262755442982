import { createContext, useState, useEffect } from 'react';
import SqlEditorClient from '../context/LetoClient';
import { useAuthInfo } from '@propelauth/react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

export const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    const [chartName, setChartName] = useState('New Chart')
    const [currentChartID, setCurrentChartId] = useState()
    const [analyticsTableSelected, setAnalyticsTableSelected] = useState()
    const [analyticsSchemaSelected, setAnalyticsSchemaSelected] = useState()
    const [chartTypeSelected, setChartTypeSelected] = useState({})
    const [metricsSelected, setMetricsSelected] = useState([])
    const [groupsSelected, setGroupsSelected] = useState([])
    const [analyticsSchemaList, setAnalyticsSchemaList] = useState([])
    const [analyticsTableList, setAnalyticsTableList] = useState([])
    const [analyticsColumnList, setAnalyticsColumnList] = useState([])
    const [analyticsSchemaLoading, setAnalyticsSchemaLoading] = useState(false)
    const [analyticsTableLoading, setAnalyticsTableLoading] = useState(false)
    const [analyticsColumnLoading, setAnalyticsColumnLoading] = useState(false)
    const [readyToGraph, setReadyToGraph] = useState(false)
    const [chartData, setChartData] = useState(null)
    const [filtersSelected, setFiltersSelected] = useState([])
    const [favorites, setFavorites] = useState([])
    const [recents, setRecents] = useState([])
    const [generateGraph, setGenerateGraph] = useState(false)
    const [favorited, setFavorited] = useState(false)
    const [timeColumn, setTimeColumn] = useState()
    const [timeGrain, setTimeGrain] = useState()
    const [visible, setVisible] = useState(false)
    const [metricsVisible, setMetricsVisible] = useState([])
    const [groupVisible, setGroupVisible] = useState(false)
    const [groupsVisible, setGroupsVisible] = useState([])
    const [dummyRefreshState, setDummyRefreshState] = useState(false)
    const [dashboardId, setDashboardId] = useState(null)
    const [dashboardName, setDashboardName] = useState('New Dashboard')
    const [dashboardRows, setDashboardRows] = useState([{ rowId : uuidv4(), type : 'empty' }])
    const [dashboardChartData, setDashboardChartData] = useState({})
    const { pathname } = useLocation();
    const [pinModalView, setPinModalView] = useState(false)
    const [pinLoading, setPinLoading] = useState(false)
    const [generatedChartType, setGeneratedChartType] = useState('')
    const [chartLoading, setChartLoading] = useState(false)

    const authInfo = useAuthInfo();

    useEffect(() => {
        if (readyToGraph) {
            getChartData(currentChartID, analyticsTableSelected.value, metricsSelected, filtersSelected, groupsSelected, {column: timeColumn, grain: timeGrain})
            
        }
        setGenerateGraph(false)
    }, [generateGraph])

    useEffect(() => {
        if (analyticsSchemaSelected && analyticsSchemaSelected.value) {
            getSchemaTables(analyticsSchemaSelected.value)
        }
    }, [analyticsSchemaSelected])

    useEffect(() => {
        if (pathname == '/analytics') {
            getFavorites()
            getRecents()
            resetState()
        }
        if (pathname.includes("/analytics/charts/")) {
            console.log('reset state!')
            setCurrentChartId(pathname.split("/analytics/charts/")[1])
            getDBSchemas()
        }
    }, [pathname])

    useEffect(() => {
        if (analyticsTableSelected && analyticsTableSelected.value && chartTypeSelected && Object.keys(chartTypeSelected).length) {
            if (chartTypeSelected && chartTypeSelected.value.includes('Time Series')) {
                if (timeColumn && Object.keys(timeColumn).length && timeGrain && Object.keys(timeGrain).length && metricsSelected && Object.keys(metricsSelected).length) {
                    setReadyToGraph(true)
                    console.log('true!')
                }
                else {
                    setReadyToGraph(false)
                    console.log('false!', timeColumn, timeGrain, metricsSelected)
                }
            }
            else {
                if (groupsSelected && Object.keys(groupsSelected).length && metricsSelected && Object.keys(metricsSelected).length) {
                    setReadyToGraph(true)
                    console.log('true!')
                }
                else {
                    setReadyToGraph(false)
                    console.log('false!')
                }
            }
        }

    }, [groupsSelected, dummyRefreshState, analyticsSchemaSelected, timeColumn, timeGrain ,analyticsTableSelected, chartTypeSelected])

    useEffect(() => {
        if (chartTypeSelected && chartTypeSelected.value && !chartTypeSelected.value.includes('Time Series') && (timeColumn || timeGrain)) {
            
            setTimeColumn()
            setTimeGrain()
        }
    }, [chartTypeSelected])

    const resetState = () => {
        // setChartName('')
        setAnalyticsTableSelected()
        setAnalyticsSchemaSelected()
        setChartTypeSelected()
        setMetricsSelected([])
        setAnalyticsSchemaList([])
        setAnalyticsTableList([])
        setAnalyticsColumnList([])
        setAnalyticsSchemaLoading(false)
        setAnalyticsTableLoading(false)
        setAnalyticsColumnLoading(false)
        setReadyToGraph(false)
        setChartData(null)
        setFiltersSelected([])
        setGenerateGraph(false)
        setFavorited(false)
    }

    const DeleteChart = async (chart_id) => {
        const response = await SqlEditorClient.deleteAnalyticsChart(chart_id)
        setRecents(response)
        return response
    }

    const DuplicateChart = async (chart_id) => {
        const response = await SqlEditorClient.duplicateChart(chart_id)
        setRecents(response)
        return response
    }
    
    const createChart = async () => {
        const response = await SqlEditorClient.saveAnalyticsChart('New Chart')
        console.log(response)
        setCurrentChartId(response)
        return response
    }

    const saveChart = async () => {
        const response = await SqlEditorClient.editAnalyticsChart(
            currentChartID,
            chartName, 
            JSON.stringify(analyticsSchemaSelected), 
            JSON.stringify(analyticsTableSelected), 
            JSON.stringify(chartTypeSelected), 
            JSON.stringify(filtersSelected),
            "[]", // TODO remove unused x_axis column from analytics table
            JSON.stringify(metricsSelected),
            JSON.stringify(groupsSelected),
            JSON.stringify(timeColumn),
            JSON.stringify(timeGrain),
            favorited // TODO MOVE THIS OUT OF EDIT, should have separate endpoint
        )
    }

    const loadChart = async(id) => {
        const response = await SqlEditorClient.loadAnalyticsChart(id)
        setChartName(response['chart_name'])
        setAnalyticsSchemaSelected(JSON.parse(response['analytics_schema']))
        setAnalyticsTableSelected(JSON.parse(response['analytics_table']))
        setChartTypeSelected(JSON.parse(response['chart_type']))
        setFiltersSelected(JSON.parse(response['filters']))
        setGroupsSelected(JSON.parse(response['groups']))
        setMetricsSelected(JSON.parse(response['y_axis']))
        setTimeColumn(JSON.parse(response['time_column']))
        setTimeGrain(JSON.parse(response['time_grain']))
        setFavorited(response['favorited'])
        setChartData(null)
        setGenerateGraph(true)
    }

    const saveDashboard = async () => {

    }

    const loadDashboard = async(id) => {

    }

    const getFavorites = async() => {
        const response = await SqlEditorClient.getAnalyticsFavorites()
        setFavorites(response)
    }

    const getRecents = async() => {
        const response = await SqlEditorClient.getAnalyticsRecents()
        setRecents(response)
    }
    
    const getChartData = async (chart_id, table, y_axis_cols, filters, groups, time_data) => {
        setChartLoading(true)
        setGeneratedChartType(chartTypeSelected.value)
        try {
            const response = await SqlEditorClient.getAnalyticsChartData(chart_id, table, y_axis_cols, filters, groups, time_data)
            if (response && !response.error && response.rows.length) {
                setChartData(response)
            }
        }
        catch {
        }
        setChartLoading(false)
        
    }

    const getDashboardChartData = async (id) => {
        const response = await SqlEditorClient.getAnalyticsChartDataById(id)
        setDashboardChartData(prevState => ({
            ...prevState,
            [id]: response
        }))
    }

    const getTableColumns = async table => {
        setAnalyticsColumnLoading(true)
        const response = await SqlEditorClient.getTableSchema([table])
        var columnList = []
        response[0].columns.forEach(column => {
            var columnEntry = {}
            columnEntry['value'] = column[0]
            columnEntry['label'] = column[0]
            columnList.push(columnEntry)
        })
        setAnalyticsColumnList(columnList)
        setAnalyticsColumnLoading(false)
    }

    const pinChart = async (chart_id, dashboard_id) => {
        setPinLoading(true)
        try {
            const response = await SqlEditorClient.pinChart(chart_id, dashboard_id)
        }
        catch {
            console.log('error pinning chart')
        }
        
        setPinLoading(false)
        setPinModalView(false)
    }

    const getSchemaTables = async schema => {
        setAnalyticsTableLoading(true)
        const response = await SqlEditorClient.getSchemaTables(schema)
        SqlEditorClient.getSchemaTables(schema, false).then(
            setAnalyticsTableList(response)
        ).catch()
        setAnalyticsTableList(response)
        setAnalyticsTableLoading(false)
    }

    const getDBSchemas = async () => {
        setAnalyticsSchemaLoading(true)
        const response = await SqlEditorClient.getDBSchemas()
        SqlEditorClient.getDBSchemas(false).then(
            setAnalyticsSchemaList(response)
        ).catch()
        setAnalyticsSchemaList(response)
        setAnalyticsSchemaLoading(false)
    }

    useEffect(() => {
        if (authInfo.isLoggedIn) {
            SqlEditorClient.setToken(authInfo.accessToken)
        }
    }, [authInfo])

    return (
        <AnalyticsContext.Provider
            value={{
                resetState,
                chartName,
                setChartName,
                dashboardId, 
                setDashboardId,
                dashboardName, 
                setDashboardName,
                analyticsTableSelected,
                setAnalyticsTableSelected,
                analyticsSchemaSelected,
                setAnalyticsSchemaSelected,
                chartTypeSelected,
                setChartTypeSelected,
                metricsSelected,
                setMetricsSelected,
                analyticsSchemaList,
                setAnalyticsSchemaList,
                analyticsTableList,
                setAnalyticsTableList,
                analyticsColumnList,
                setAnalyticsColumnList,
                getDBSchemas,
                getSchemaTables,
                analyticsSchemaLoading,
                setAnalyticsSchemaLoading,
                analyticsTableLoading,
                setAnalyticsTableLoading,
                analyticsColumnLoading,
                setAnalyticsColumnLoading,
                readyToGraph, 
                setReadyToGraph,
                chartData,
                setChartData,
                filtersSelected,
                setFavorites,
                favorites,
                setRecents,
                recents,
                setGenerateGraph,
                generateGraph,
                setFavorited,
                favorited,
                setDashboardRows,
                dashboardRows,
                setDashboardChartData,
                dashboardChartData,
                setFiltersSelected,
                getTableColumns,
                getChartData,
                getDashboardChartData,
                saveChart,
                loadChart,
                saveDashboard,
                loadDashboard,
                getFavorites,
                getRecents,
                groupsSelected,
                setGroupsSelected,
                timeColumn,
                setTimeColumn,
                timeGrain,
                setTimeGrain,
                groupVisible,
                setGroupVisible,
                groupsVisible,
                setGroupsVisible,
                metricsVisible,
                setMetricsVisible,
                visible,
                setVisible,
                createChart,
                currentChartID,
                setCurrentChartId,
                dummyRefreshState,
                setDummyRefreshState,
                pinModalView,
                setPinModalView,
                pinChart,
                pinLoading,
                setPinLoading,
                DuplicateChart,
                DeleteChart,
                generatedChartType,
                setGeneratedChartType,
                chartLoading,
                setChartLoading,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};
