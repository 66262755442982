// WorkflowGrid.js
import React from 'react';
import { Tooltip, Popconfirm} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { TableGraphContext } from '../../context/TableGraphProvider';
// import FavoriteIcon from './FavoriteIcon'; // Adjust import paths as necessary



const WorkflowGrid = ({ workflows }) => {

    const { 
        deleteDag,
        updateDagName,
        updateDagFavorite,
    } = useContext(TableGraphContext);

    const [hoveredWorkflow, setHoveredWorkflow] = useState(false)
    const history = useHistory();
    const [isEditing, setIsEditing] = useState(null);
    const [newName, setNewName] = useState("");
    const [initialName, setInitialName] = useState("");

    const onEditorClick = async (dag_id, event) => {
        if (event.target.tagName !== 'INPUT' && event.target.tagName !== 'BUTTON' && !event.target.closest('.exclude-click')) {
            history.push(`/tablegraphs/${dag_id}`);
            }
        }

    const handleEdit = (item) => {
        setNewName(item.name);
        setInitialName(item.name);
        setIsEditing(item._id);
    };

    const handleSave = async (dag_id) => {
        if (isEditing !== null) {
            console.log(newName)
            // Call your API to update item's name
            await updateDagName(dag_id, newName);
            setIsEditing(null);
        }
    };

    const handleCancel = () => {
        setNewName(initialName);
        setIsEditing(null);
    };

    const handleKeyDown = async (e, dag_id) => {
        if (e.key === "Enter") {
            handleSave(dag_id);
        }
    };
    const FavoriteIcon = ({ isFavorite }) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={isFavorite ? "#4cb043" : "none"} stroke={isFavorite ? "#4cb043" : "currentColor"} strokeWidth="1.5" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
        </svg>
    );    

    const handleFavorite = (dag_id, isFavorite) => {
        updateDagFavorite(dag_id, isFavorite === undefined ? true : !isFavorite);
    }

    const createdAt = (time) => {
        return String((new Date(Number(time))).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }))
    }

    const lastUpdated = (time) => {
        if (Math.round((Date.now() - time) / (8.64 * 10 ** 7)) > 30) {
            return String(Math.round((Date.now() - time) / (30 * 8.64 * 10 ** 7))) + ' months ago'
        }
        if ((Date.now() - time) / (8.64 * 10 ** 7) > 30) {
            return String(Math.round((Date.now() - time) / (30 * 8.64 * 10 ** 7))) + ' month ago'
        }
        if (Math.round((Date.now() - time) / (8.64 * 10 ** 7)) > 1) {
            return String(Math.round((Date.now() - time) / (8.64 * 10 ** 7))) + ' days ago'
        }
        if ((Date.now() - time) / (8.64 * 10 ** 7) > 1) {
            return String(Math.round((Date.now() - time) / (8.64 * 10 ** 7))) + ' day ago'
        }
        else {
            return String(Math.round((Date.now() - time) / (3.6 * 10 ** 6))) + ' hours ago'
        }
    }
  return (
      <div className='flex mx-2 px-6 flex-wrap'>
        {workflows.map((item, index) => (
          
                <div 
                key={index}
                className="relative mb-[25px] cursor-pointer rounded-lg  hover:bg-gray-100 relative my-5 mx-3 justify-center border-[1px] border-gray-300 items-center h-[200px] w-[200px]"
                onMouseEnter={() => setHoveredWorkflow(item)}
                onMouseLeave={() => setHoveredWorkflow()}
                onClick={(e) => { onEditorClick(item._id, e) }}
                >
                    <div className='min-w-[25%] pl-[20px] pt-[20px]'>
                    {isEditing === item._id ? (
                        <div className="flex items-center">
                        <input 
                            className="exclude-click border border-gray-900 focus:border-black p-1 text-xl font-bold max-w-[150px]"
                            type="text" 
                            value={newName} 
                            onChange={(e) => setNewName(e.target.value)} 
                            onBlur={handleCancel}
                            onKeyDown={(e) => handleKeyDown(e, item._id)}
                            onClick={(e) => e.stopPropagation()}
                            autoFocus
                        />
                        <CheckCircleOutlined className='exclude-click cursor-pointer' onMouseDown={()=>handleSave(item._id)}/>
                        <CloseCircleOutlined className='exclude-click' onClick={handleCancel}/>
                        </div>
                    ) : (
                        <h2 
                        className="exclude-click text-xl font-bold select-none cursor-text truncate"
                        onClick={() => handleEdit(item)}
                        >
                        {item.name}
                        </h2>
                    )}
                    </div>

                    <div className='grow justify-center flex-col pl-[20px]'>
                        <p className="text-xs text-gray-500 mr-[30px] select-none">Created On: {createdAt(item.created_at)}</p>
                        <p className="text-xs text-gray-500 mr-[30px] select-none">Last Executed: {lastUpdated(item.last_updated)}</p>
                        <p className="text-xs text-gray-500 select-none"> Number of Nodes: {item.nodes.length}</p>
                    </div>
                    <div className='absolute grow bottom-7 w-full'>
                        <div className="flex justify-center space-x-6">
                            <Tooltip title='Favorite'>   
                                <div 
                                    className="exclude-click hover:bg-gray-200 text-black font-bold py-2 rounded select-none cursor-pointer"
                                    onClick={() => { handleFavorite(item._id, item.isFavorite) }}
                                >
                                    {item.isFavorite ? <FavoriteIcon isFavorite={true} /> : <FavoriteIcon isFavorite={false} /> }
                                </div>
                            </Tooltip>
                            <Tooltip title='Editor'>
                                <div 
                                    className="hover:bg-gray-200 text-black font-bold py-2 rounded select-none cursor-pointer"
                                    onClick={(e) => { onEditorClick(item._id, e) }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>

                                </div>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <div onClick={(e) => e.stopPropagation()}>
                                <Popconfirm
                                    title={`Are you sure you want to delete this workflow?`}
                                    onConfirm={() => {deleteDag(item._id)}}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="bottom"
                                    okButtonProps={{ 
                                        className: 'bg-[#5A7554] focus:bg-[#3D6343] hover:bg-[#34563A] hover:shadow-md ',
                                        style: {width: '60px', borderRadius: '5px'} }} 
                                    cancelButtonProps={{
                                        className: 'border-solid border-[1.5px] border-[#5A7554] hover:border-[#34563A] hover:shadow-md rounded-sm hover:bg-white focus:bg-white text-[#5A7554] focus:text-[#34563A] hover:text-[#34563A]',
                                        style: {width: '60px',  borderRadius: '5px'}
                                    }}
                                >
                                    <div 
                                        className="exclude-click hover:bg-gray-200 text-black font-bold py-2 rounded select-none cursor-pointer"
                                        onClick={(e) => e.stopPropagation()} 
                                    >
                                        <div className='rounded-lg opacity-1 transition-[1000ms] ease-in-out'>
                                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-6 h-6'>
                                                <path fill="currentColor" strokeWidth="1" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </Popconfirm>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

        ))}
      </div>
  );
};

export default WorkflowGrid;
