import React, { useState, useEffect } from 'react';
import { Slider } from "antd";

const marks = {
  0: '1h',
  1: '2h',
  2: '3h',
  3: '6h',
  4: '12h',
  5: '1d',
  6: '3d',
  7: '7d'
};

const findIntervalMark = (intervalMinutes) => {
  const intervals = [60, 120, 180, 360, 720, 1440, 4320, 10080];
  const closest = intervals.reduce((prev, curr) => Math.abs(curr - intervalMinutes) < Math.abs(prev - intervalMinutes) ? curr : prev);
  return marks[intervals.indexOf(closest)];
};

const EditWorkflowSchedulesModal = ({ isOpen, onClose, schedule, logs }) => {
  const [intervalText, setIntervalText] = useState('0');

  useEffect(() => {
    if (schedule && schedule.data && schedule.data.interval_minutes) {
      setIntervalText(findIntervalMark(schedule.data.interval_minutes));
    }
  }, [schedule]);

  if (!isOpen) return null;

  const parseLogContent = (content) => {
    // Remove ANSI color codes for simplicity
    return content.replace(/\u001b\[.*?m/g, '');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[80%] h-[80%] rounded-lg overflow-hidden">
        <div className="p-5">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Schedule Details</h2>
            <button className="px-4 py-2 rounded text-black" onClick={onClose}>Close</button>
          </div>
          <div className="flex justify-between">
            <div className="w-full mr-4">
              <h3 className="text-lg font-semibold mb-4">Interval</h3>
              <div className="mb-4">{intervalText}</div>
            </div>
            <div className="w-full">
              <h3 className="text-lg font-semibold mb-4">Workflows</h3>
              <ul className="list-inside flex flex-wrap">
                {schedule.data.workflows.map(workflow => (
                  <li key={workflow._id} className="p-4 w-auto border rounded">
                    {workflow.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="overflow-auto p-5" style={{ maxHeight: "75%", paddingBottom: 15 }}>
          <h3 className="text-lg font-semibold mb-2">Run History</h3>
          {logs ? logs.map((log, index) => (
            <div key={index} className="mb-4 p-2 border rounded shadow-sm">
              <h4 className="text-md font-semibold">Workflow: {log.data.workflow.name}</h4>
              <p><strong>Status:</strong> {log.data.status}</p>
              <p><strong>Time:</strong> {new Date(log.created_at).toLocaleString()}</p>
              <details>
                <summary>Details</summary>
                <pre className="text-xs whitespace-pre-wrap">{parseLogContent(log.data.content)}</pre>
              </details>
            </div>
          )): <div> </div>}
        </div>
      </div>
    </div>
  );
};

export default EditWorkflowSchedulesModal;

