import { ConfigProvider } from 'antd';

ConfigProvider.config({
  theme: {
    primaryColor: '#1f422a',
  },
});

export const ThemeProvider = ({ children }) => {
  return <ConfigProvider>{children}</ConfigProvider>;
};
