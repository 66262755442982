import React, { createContext, useState, useContext, useCallback } from 'react';
import GraphClient from './GraphClient';
export const WorkflowSchedulesContext = createContext();

export const WorkflowSchedulesProvider = ({ children }) => {
  const [workflowSchedules, setWorkflowSchedules] = useState([]);
  const [workflowScheduleLogs, setWorkflowScheduleLogs] = useState([]);
  const [workflowSchedulesConfig, setWorkflowSchedulesConfig] = useState({});
  
    const updateWorkflowSchedule = useCallback((id, updatedSchedule) => {
    setWorkflowSchedules((prevSchedules) =>
      prevSchedules.map((schedule) => (schedule.id === id ? updatedSchedule : schedule))
    );
  }, []);


  const getWorkflowScheduleById = useCallback((id) => {
    return workflowSchedules.find((schedule) => schedule.id === id);
  }, [workflowSchedules]);


  const getWorkflowSchedules = async () => {
    let response = await GraphClient.getScheduledWorkflows();

    let schedules = response['schedules']
    let logs = response['logs']

    setWorkflowSchedules(schedules);
    setWorkflowScheduleLogs(logs);
  }

  const addWorkflowSchedule = async(data) => {

    let response = await GraphClient.saveScheduledWorkflows(data)
    setWorkflowSchedules(response)

  }

  const deleteWorkflowSchedule = async(data) => {
    let response = await GraphClient.deleteScheduledWorkflow(data)
    setWorkflowSchedules(response)
  }




  const getWorkflowSchedulesConfig = async () => {
    try {
      const response = await GraphClient.getWorkflowSchedulesConfigs();


      setWorkflowSchedulesConfig(response);
    } catch (error) {
      console.error("Failed to fetch workflow schedules config:", error);
    }
  };

  const getWorkflowSchedulesFivetranConnectors = async (groupId) => {
    try {
      const response = await GraphClient.getWorkflowSchedulesFivetranConnectors(groupId);
      console.log(response)

      return response
    } catch (error) {
      console.error("Failed to fetch workflow schedules config:", error);
    }
  };

  const getWorkflowSchedulesFivetranGroups = async () => {
    try {
      const response = await GraphClient.getWorkflowSchedulesFivetranGroups();
      console.log(response)

      return response
    } catch (error) {
      console.error("Failed to fetch workflow schedules config:", error);
    }
  };



  const saveTestFivetranSchedulesConfig = async (API_KEY, API_SECRET) => {
    try {
      const response = await GraphClient.saveTestFivetranSchedulesConfig({'API_KEY': API_KEY, 'API_SECRET': API_SECRET});


      setWorkflowSchedulesConfig(response);
    } catch (error) {
      console.error("Failed to fetch workflow schedules config:", error);
    }
  };

  return (
    <WorkflowSchedulesContext.Provider
      value={{
        getWorkflowSchedulesConfig,
        getWorkflowSchedulesFivetranConnectors,
        getWorkflowSchedulesFivetranGroups,
        saveTestFivetranSchedulesConfig,
        getWorkflowSchedules,
        workflowSchedules,
        workflowSchedulesConfig,
        workflowScheduleLogs,
        setWorkflowScheduleLogs,
        addWorkflowSchedule,
        deleteWorkflowSchedule,
        updateWorkflowSchedule,
        getWorkflowScheduleById,
      }}
    >
      {children}
    </WorkflowSchedulesContext.Provider>
  );
};

// Custom hook to use context
export const useWorkflowSchedules = () => {
  const context = useContext(WorkflowSchedulesContext);
  if (context === undefined) {
    throw new Error('useWorkflowSchedules must be used within a WorkflowSchedulesProvider');
  }
  return context;
};

