import { useContext } from "react";
import { OnboardContext } from "../../context/OnboardProvider";

const ConnectFail = () => {
    const {
        connectionError,
        setConnectionError,
        setConnectionLoading
    } = useContext(OnboardContext)

    return connectionError ? (
        <div
            className="bg-gray-700 h-screen w-screen fixed z-50 top-0 left-0 bg-opacity-50"
            onClick={() => {
                setConnectionLoading(false)
                setConnectionError();
            }}
        >
            <div className="relative mx-auto w-full max-w-md h-full md:h-auto top-[25vh]">
                <div className="relative text-center bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 text-center">
                        <svg aria-hidden="true" className="mx-auto mb-4 w-14 h-14 text-red-500 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                {connectionError[0].title}
                            </h3>
                            <div>
                                {connectionError[0].message}
                            </div>
                            <br />
                            <h3 className="mb-5 text-sm font-normal text-black dark:text-gray-400">
                                If this error persists, please contact william@artemisdata.io for further support
                            </h3>
                        </h3>
                        <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => {
                            setConnectionLoading(false)
                            setConnectionError()
                        }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    ) :
        (<></>)
}

export default ConnectFail;
