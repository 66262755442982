import { createContext, useState, useEffect } from 'react';

import FivetranApi from './FivetranAPI';
import LetoClient from './LetoClient';
import { useAuthInfo } from '@propelauth/react';
import axios from 'axios';
import GraphClient from './GraphClient.js';

export const OnboardContext = createContext();

export const OnboardProvider = ({ children }) => {
    const [warehouseType, setWarehouseType] = useState()
    const [requiredFields, setRequiredFields] = useState([])
    const [guideText, setGuideText] = useState()
    const [sqlPath, setSqlPath] = useState("")
    const [host, setHost] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [port, setPort] = useState("")
    const [database, setDatabase] = useState("")
    const [pat, setPat] = useState("")
    const [credentials, setCredentials] = useState("")
    const [orgId, setOrgId] = useState()
    const [orgError, setOrgError] = useState()
    const [currentPage, setCurrentPage] = useState(5);
    const [nameErrorModalVisible, setNameErrorModalVisible] = useState(false)
    const [connectionError, setConnectionError] = useState()
    const [connectionLoading, setConnectionLoading] = useState(false)
    const [validPromoResponse, setValidPromoResponse] = useState(true)
    const [promoLoading, setPromoLoading] = useState(false)
    const [sharedAvailable, setSharedAvailable] = useState(true)
    const [accessCodeError, setAccessCodeError] = useState(false)
    const [certificate, setCertificate] = useState({})
    const [applyCertificateLoading, setApplyCertificateLoading] = useState(false)
    const [connectGit, setConnectGit] = useState(false)
    const [setupWarehouse, setSetupWarehouse] = useState(false)
    const [urlValue, setUrlValue] = useState('')
    const [patValue, setPatValue] = useState('')
    const [testingRepo, setTestingRepo] = useState(false)
    const [connectGitError, setGitConnectError] = useState()

    // console.log(currentPage)
    const completeOnboard = () => {
        LetoClient.completeOnboard()
    }

    const approveCertificate = async (certificate_info) => {
        try {
            setApplyCertificateLoading(true)
            const response = await LetoClient.approveCertificate(certificate_info)
            setApplyCertificateLoading(false)
            setCurrentPage(8)
            return true
        }
        catch {
            console.log('error!')
            setApplyCertificateLoading(false)
        }
    }

    const warehouseRequiredFields = async () => {
        const response = await LetoClient.warehouseRequiredFields(warehouseType)
        setRequiredFields(response.fields)

        let guide = await axios.get(response.guide)
        console.log(guide.data)
        setGuideText(guide.data)
        if (warehouseType == "artemis") {
            setSharedAvailable(response["shared_avaliable"])
        }
    }

    const saveAndTest = async () => {
        setTestingRepo(true)
        try {
            let response = await GraphClient.cloneRepo({ repo_url: urlValue, pat: patValue })
            if (response.error) {
                setGitConnectError(response.error)
                setTestingRepo(false)
            }
            else {
                setTestingRepo(false)
                nextPage()
            }
        }
        catch {
            setTestingRepo(false)
            setGitConnectError('Server error, unable to complete at this time')
        }
        // setTimeout(() => setTestingRepo(false), 2000)
        
    }

    const connectWarehouse = async () => {
        setConnectionLoading(true)
        const body = {
            "warehouse_provider": warehouseType,
            "pat": pat,
            "credentials": credentials,
            "database": database,
            "sql_path": sqlPath,
            "host": host,
            "user": username,
            "password": password,
            "port": port,
        }
        try {
            const response = await LetoClient.connectWarehouse(body)

            if (response.code === 'certificate_approval') {
                setConnectionLoading(false)
                for (let i = 0; i < response.data.cert_list.length; i++) {
                    response.data.cert_list[i]['id'] = i
                }
                setCertificate(response.data)
            }

            else {
                nextPage()
                setConnectionLoading(false)
                return true
            }
        }
        catch (err) {
            setConnectionError(err.response.data.detail)
            console.log(err.response.data.detail)
            setConnectionLoading(false)
            return false
        }
    }

    const connectArtemisManaged = async (accessCode) => {
        try {
            setAccessCodeError(false)
            const response = await LetoClient.connectArtemisManaged(accessCode)
            nextPage()
        }
        catch (err) {
            setAccessCodeError(true)
        }
    }

    const createOrganization = async (orgName) => {
        try {
            const response = await LetoClient.createOrganization(orgName)
            setOrgId(response.org_id)
            console.log("org created!")
            console.log(response.org_id)
            nextPage()
        }
        catch (err) {
            setOrgError(err.response.data.detail)
            setNameErrorModalVisible(true)
        }
    }

    const nextPage = (warehouse = null) => {
        setCurrentPage(currentPage + 1);
    };

    const previousPage = () => {
        setRequiredFields()
        setCurrentPage(currentPage - 1);
    }

    const requestWarehouse = async (warehouseType) => {
        const response = await LetoClient.requestWarehouse(warehouseType)
        return response
    }

    const applyPromo = async (code) => {
        try {
            setPromoLoading(true)
            const response = await LetoClient.applyPromo(code)
            nextPage()

        }
        catch (err) {
            console.log(err.response.data.detail)
            setValidPromoResponse(false)
        }
        setPromoLoading(false)
    }


    return (
        <OnboardContext.Provider
            value={{
                promoLoading,
                setPromoLoading,
                validPromoResponse,
                setValidPromoResponse,
                applyPromo,
                warehouseType,
                setWarehouseType,
                requiredFields,
                setRequiredFields,
                warehouseRequiredFields,
                connectWarehouse,
                createOrganization,
                previousPage,
                nextPage,
                orgId,
                setOrgId,
                orgError,
                setOrgError,
                guideText,
                setGuideText,
                currentPage,
                setCurrentPage,
                nameErrorModalVisible,
                setNameErrorModalVisible,
                requestWarehouse,
                connectionError,
                setConnectionError,
                sqlPath,
                setSqlPath,
                host,
                setHost,
                username,
                setUsername,
                password,
                setPassword,
                port,
                setPort,
                database,
                setDatabase,
                pat,
                setPat,
                credentials,
                setCredentials,
                connectArtemisManaged,
                connectionLoading,
                setConnectionLoading,
                sharedAvailable,
                accessCodeError,
                setAccessCodeError,
                certificate,
                setCertificate,
                approveCertificate,
                applyCertificateLoading,
                setApplyCertificateLoading,
                connectGit,
                setConnectGit,
                setupWarehouse,
                setSetupWarehouse,
                saveAndTest,
                urlValue,
                setUrlValue,
                patValue,
                setPatValue,
                testingRepo,
                setTestingRepo,
                completeOnboard,
                connectGitError,
                setGitConnectError,
            }}>
            {children}
        </OnboardContext.Provider>
    )
}
