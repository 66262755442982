import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import { WorkflowSchedulesContext } from '../../context/WorkflowSchedulesProvider';
import { Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { BsSearch } from 'react-icons/bs';
import CreateWorkflowSchedulesModal from './CreateWorkflowSchedulesModal';
import EditWorkflowSchedulesModal from './EditWorkflowSchedulesModal';

const WorkflowSchedulesComponent = () => {
  const { workflowSchedules, getWorkflowSchedules, createWorkflowSchedule, updateWorkflowSchedule, deleteWorkflowSchedule, workflowScheduleLogs  } = useContext(WorkflowSchedulesContext);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEditingSchedule, setSelectedEditingSchedule] = useState({});
  const [selectedEditingScheduleLog, setSelectedEditingScheduleLog] = useState([]);

  useEffect(() => {
    getWorkflowSchedules();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSchedules = searchTerm.length > 0
    ? workflowSchedules.filter(schedule =>
        schedule.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : workflowSchedules;

    const onCreateSubmit = async () => {
        await createWorkflowSchedule({ name: 'New Schedule', /* other properties */ });
    };
    
    const handleCreateClick = () => {
        setIsCreateModalOpen(true);
    };

    const handleEditClick = (schedule) => {
        console.log(schedule)
        setSelectedEditingSchedule(schedule);
        setSelectedEditingScheduleLog(workflowScheduleLogs[schedule._id]?workflowScheduleLogs[schedule._id] : [] ) 
        setIsEditModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setIsCreateModalOpen(false);
    };
    
    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };


//TODO: write a better one
const convertMinutesToTimeline = (minutes) => {
  if (minutes < 120) return '1h'; // Up to 2 hours
  if (minutes < 180) return '2h'; // Up to 3 hours
  if (minutes < 360) return '3h'; // Up to 6 hours
  if (minutes < 720) return '6h'; // Up to 12 hours
  if (minutes < 1440) return '12h'; // Up to 1 day
  if (minutes < 4320) return '1d'; // Up to 3 days
  if (minutes < 10080) return '3d'; // Up to 7 days
  return '7d';
};

//TODO: write a better one, and iron out edge / error cases
const formatDateString = (dateString) => {
    if (dateString === null || dateString === "" || typeof dateString !== "string") {
        return "Never";
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return "Never";
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns a 0-indexed month (0-11) so add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedMonth = month.toString().padStart(2, '0');
    const formattedDay = day.toString().padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
}

  return (
    <Page>
      <div className="flex flex-col w-full h-full overflow-y-auto bg-white">
        <div className='w-full px-6 my-2'>
                    <div className="py-4 rounded-lg flex items-center w-full">
                        <h1 className="text-2xl font-bold select-none my-auto ml-0 px-2">Scheduled Jobs</h1>
                        <div className="flex flex-row ml-auto mr-0 gap-4">
                            <div className="searchbar-styling flex items-center my-[5px]">
                                <div className="relative w-full">
                                    <input type="search" className="bg-gray-50 border w-full rounded-[5px] border-gray-300 text-gray-900 text-sm block pl-10 px-2.5 py-[9.5px]" placeholder="Search" onChange={handleSearchChange} />
                                    <BsSearch className="absolute left-3 top-3 text-gray-400" />
                                </div>
                            </div>
                            <button className="border border-gray-300 w-[200px] my-1 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200" onClick={handleCreateClick}>
                                <a className="font-bold select-none">Create</a>
                            </button>
                        </div>
                    </div>
                </div>


<div className='flex mx-2 flex-wrap'>
  {filteredSchedules.length > 0 ? (
    filteredSchedules.map((schedule, index) => (
      <div className='w-[200px] mx-5 my-2' key={index}>
        <div className='w-[200px] h-[200px] rounded-[20px] border-[1px] border-gray-300 hover:bg-gray-100 flex flex-col justify-between p-4'>
          <div>
            <h2 className="text-xl font-bold">{schedule.data.workflows.map(workflow => workflow.name).join(', ')}</h2>
            <p className="text-xs text-gray-500">Type: {schedule.type}</p>
            {schedule.type === 'cron' && (
              <p className="text-xs text-gray-500">Interval: {convertMinutesToTimeline(schedule.data.interval_minutes)}</p>
            )}
            <p className="text-xs text-gray-500">Last Run: {formatDateString(schedule.last_executed)}</p>

          </div>
          <div className="flex justify-center space-x-2">

            <button className="p-2 rounded hover:bg-gray-200" onClick={() => {handleEditClick(schedule)}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>

            </button>

            <button className="p-2 rounded hover:bg-gray-200" onClick={() => {deleteWorkflowSchedule(schedule._id)}}>
                                                  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-6 h-6'>
                                                <path fill="currentColor" strokeWidth="1" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
                                            </svg>
            </button>

          </div>
        </div>
      </div>
    ))
  ) : (
    <div className='mt-[75px] w-full text-center text-gray-500 text-[24px] font-600'>
      No schedules found...
    </div>
  )}
</div>

      </div>
    <CreateWorkflowSchedulesModal className='h-4/5 w-4/5' isOpen={isCreateModalOpen} onClose={handleCloseCreateModal} />

    <EditWorkflowSchedulesModal isOpen={isEditModalOpen} onClose={handleCloseEditModal} schedule={selectedEditingSchedule} logs={selectedEditingScheduleLog} />

    </Page>
  );
};

export default WorkflowSchedulesComponent;

