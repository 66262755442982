//named it AgentProvider instead of ContextProvider because of naming confusions, worth digging
import { createContext, useState, useCallback } from 'react';
import GraphClient from './GraphClient.js';

export const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
  const [contexts, setContexts] = useState();
  const [context, setContext] = useState({});

 const fetchMetabaseContext = async (url, username, password) => {
    try {
      const response = await GraphClient.connectMetabase(url, username, password);
      return response;
    } catch (error) {
      console.error('Error fetching Metabase context:', error);
    }
  };

 const fetchURLContext = async (url, annotation) => {
    try {
      const response = await GraphClient.connectURL(url, annotation);
      return response;
    } catch (error) {
      console.error('Error fetching Metabase context:', error);
    }
  };

  const fetchTableColumns = async (tableName) => {
    try {
      const response = await GraphClient.getTableColumns(tableName);
      if (response) {
        // Assuming response is the columns data
        return response.columns
      }
    } catch (error) {
      console.error('Error fetching table columns:', error);
    }
  };

  const setSelectedSource = (selectedSourceData) => {
    const existingSourceIndex = context.data.sources.findIndex(source => source.id === selectedSourceData.id);

    let updatedSources;
    if (existingSourceIndex !== -1) {
      // Update the existing source
      updatedSources = [...context.data.sources];
      updatedSources[existingSourceIndex] = selectedSourceData;
    } else {
      // Add the new source
      updatedSources = [...(context.data.sources || []), selectedSourceData];
    }

    const updatedContext = { ...context, data: { ...context.data, sources: updatedSources }};
    setContext(updatedContext);
  };

  // Existing method to fetch all contexts
  const fetchContexts = async () => {
    try {
      const response = await GraphClient.fetchContexts();       
      if (response) {
        setContexts(response);
      }
    } catch (error) {
      console.error('Error fetching contexts:', error);
    }
  };

 const createContext = async () => {
    try {
      const response = await GraphClient.createContext();
      if (response) {
        // Assuming response is the updated list of contexts
        setContexts(response);
      }
    } catch (error) {
      console.error('Error creating context:', error);
    }
  };

const fetchContextById = useCallback(async (contextId) => {
    try {
      const response = await GraphClient.fetchContextById(contextId);
      return response; 
    } catch (error) {
      console.error('Error fetching context by ID:', error);
    }
  }, []);

const updateFunctionInContext = (functionData) => {
  // Clone the current context to avoid direct mutation
  const updatedContext = { ...context };

  // Ensure the context has a functions property and it's an array
  updatedContext.data.functions = updatedContext.data.functions ? [...updatedContext.data.functions] : [];

  // Find the index of the function in the list
  const functionIndex = updatedContext.data.functions.findIndex(func => func.id === functionData.id);

  if (functionIndex !== -1) {
    // Update the function data if it exists in the list
    updatedContext.data.functions[functionIndex] = functionData;
  } else {
    // Append the new function data if it does not exist
    updatedContext.data.functions.push(functionData);
  }

  // Update the current context
  setContext(updatedContext);
};

//DELETES THE ITEMS IN A CONTEXT
const deleteItemById = (itemId, itemType) => {
  console.log(itemType)
  console.log(itemId)
  console.log(context) 
  
  //ensure that the itemType exists and is an array in the context's data
  if (context.data[itemType] && Array.isArray(context.data[itemType])) {
    const updatedItems = context.data[itemType].filter(item => item.id !== itemId);
    setContext(prevContext => ({
      ...prevContext,
      data: {
        ...prevContext.data,
        [itemType]: updatedItems
      }
    }));
  } else {
    console.error(`Error: ${itemType} is not an array or does not exist in context.data`);
  }
};
    
//DELETES THE CONTEXT
    const deleteContextById = async (itemId) => {
        try {
            // Assuming deleteContextById makes the API call to delete the context
            await GraphClient.deleteContextById(itemId);

            // Filter out the deleted context from the contexts array
            const updatedContexts = contexts.filter(context => context._id !== itemId);
            setContexts(updatedContexts);

            // If the deleted context is the currently selected one, reset the current context
            if (context._id === itemId) {
                setContext({});
            }
        } catch (error) {
            console.error(`Error deleting context with ID ${itemId}:`, error);
        }
    };



const updateSourceInContext = (sourceData) => {
  const updatedContext = { ...context };

  // Ensure the context has a sources property and it's an array
  updatedContext.data.sources = updatedContext.data.sources ? [...updatedContext.data.sources] : [];

  // Find the index of the source in the list
  const sourceIndex = updatedContext.data.sources.findIndex(src => src.id === sourceData.id);

  if (sourceIndex !== -1) {
    // Update the source data if it exists in the list
    updatedContext.data.sources[sourceIndex] = sourceData;
  } else {
    // Append the new source data if it does not exist
    updatedContext.data.sources.push(sourceData);
  }

  // Update the current context
  setContext(updatedContext);
};

  const updateContext = async (contextId, updatedData) => {
    try {
      const response = await GraphClient.updateContext(contextId, updatedData);
      // Assuming response contains the updated context
      if (response) {
        // Update the context in local state
        setContexts(contexts?.map(ctx => ctx.id === contextId ? response : ctx));
        // Optionally, update the current context if it's the one being updated
        if (context.id === contextId) {
          setContext(response);
        }
      }
    } catch (error) {
      console.error('Error updating context:', error);
    }
  };
  
  const updateContextName = async (contextId, newName) => {
    try {
      // Call the backend to update the context name
      const updatedContext = await GraphClient.updateContextName(contextId, newName);

      // Update the local state
      setContexts(contexts?.map(ctx => ctx._id === contextId ? updatedContext : ctx));

      // Optionally, update the current context if it's the one being updated
      if (context._id === contextId) {
        setContext(updatedContext);
      }
    } catch (error) {
      console.error('Error updating context name:', error);
    }
  };




  const generateContextSuggestion = async (data) => {
    try {
      const response = await GraphClient.generateContextSuggestion(context._id, data);
      // Assuming response contains the updated context
      if (response) {
        return response 
      }
    } catch (error) {
      console.error('Error updating context:', error);
    }
  };
  const createSecret = async (key, value) => {
    try {
      const response = await GraphClient.createSecret(key, value);
      return response;
    } catch (error) {
      console.error('Error creating secret:', error);
    }
  };

  const getSecrets = async () => {
    try {
      const response = await GraphClient.getSecrets();
      return response;
    } catch (error) {
      console.error('Error fetching secrets:', error);
    }
  };

  const updateSecret = async (key, newValue) => {
    try {
      const response = await GraphClient.updateSecret(key, newValue);
      return response;
    } catch (error) {
      console.error('Error updating secret:', error);
    }
  };

  const deleteSecret = async (key) => {
    try {
      const response = await GraphClient.deleteSecret(key);
      return response;
    } catch (error) {
      console.error('Error deleting secret:', error);
    }
  };
  return (
    <AgentContext.Provider
      value={{
        updateContextName,
        deleteContextById,
          deleteItemById,

        fetchMetabaseContext, fetchURLContext, // TODO: move these to a more robust system

        fetchContexts,
        createContext,
        fetchContextById,
        contexts,
        updateContext,
          fetchTableColumns,
        setContexts,
        context, setContext, //current context
          setSelectedSource,
      updateFunctionInContext,
          generateContextSuggestion,
    updateSourceInContext,
        createSecret,
        getSecrets,
        updateSecret,
        deleteSecret,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};


