// CopilotResults tab not results from copilot -> bad name
import React, { useState, useEffect, useRef, useContext} from 'react';
import { ArtyChatContext } from '../../../context/ArtyChatProvider';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../../context/UserProvider';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { EditorCopilotContext } from '../../../context/EditorCopilotProvider';
import { ModelEditorContext } from '../../../context/ModelEditorProvider';
import { SqlEditorContext } from '../../../context/SqlEditorProvider';

import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorView } from "@codemirror/view";
import ReactMarkdown from 'react-markdown';


const antIcon = (
    <div className="text-dot-loading-container">
        <div className="text-dot" />
    </div>
);

const CopilotResults = ({ tab }) => {

    const { promptModelEditorCopilot, promptSqlEditorCopilot, promptModelEditorCopilotExecute,  createChat,  } = useContext(EditorCopilotContext);

    const { user } = useContext(UserContext) 

    const { dbtTabs } = useContext(ModelEditorContext) 
    const { sqlTabs } = useContext(SqlEditorContext) 



    const chat = (() => {
        if (tab.type === 'sql') {
            return sqlTabs[tab.id].results[sqlTabs[tab.id].copilotResultId].chat;
        } else if (tab.type === 'dbt') {
            return dbtTabs[tab.id].results[dbtTabs[tab.id].copilotResultId].chat;
        } else {
            return []; // Or another appropriate default value
        }
    })();




    const [isLoading, setIsLoading] = useState(false);
    const [inputMessage, setInputMessage] = useState('');

    useEffect(() => {
        scrollToBottom({ behavior: "smooth" })
    }, [chat])
    
    useEffect(() => {
        scrollToBottom()
    }, [])

    useEffect(() => {
        const initChat = async () => {
            setIsLoading(true);
            await createChat();
            setIsLoading(false);
        };
        initChat();
    }, []);

    const handeKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            // event.stopPropagation()
            handleSubmit(event)
        }
    }

    const adjustTextareaHeight = () => {
        const textarea = document.getElementById("textarea");
        textarea.style.height = "40px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // handle user message submission here
        // and start loading stat
        setIsLoading(true);

        if (tab.type === 'sql') {
            await promptSqlEditorCopilot(tab, inputMessage);

        } else if (tab.type === 'dbt') {
            await promptModelEditorCopilot(tab, inputMessage);
        }

        setInputMessage('');

        adjustTextareaHeight()
        setIsLoading(false);
    }
    const messagesEndRef = useRef(null);

    const scrollToBottom = (behavior = {}) => {
        messagesEndRef.current?.scrollIntoView(behavior)
    } 

    const renderMessage = (message, index) => {
        if (!message || !message.response) {
            return (
                <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                    <div className='text-gray-500 mr-[10px] select-none'>
                        {user.first_name ? user.first_name : 'User'}:
                    </div>
                    <div className="text-left whitespace-pre-line">
                    </div>
                </div>
            );
        }
        switch (message.response_type) {
            case -1:
                return (
                    <div key={index + "error"} className="flex w-full text-[#ff6963] text-left bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='mr-[10px]'>
                            Error:
                        </div>
                        <div className="whitespace-pre-line">
                            {message.response}
                        </div>
                    </div>
                );

            case 0:
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {user.first_name ? user.first_name : 'User'}:
                        </div>
                        <div className="text-left whitespace-pre-line">

                            {message.response}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {'Copilot'}:
                        </div>
                        <div className="text-left whitespace-pre-line">

                            {message.response}
                        </div>
                    </div>
                )
            case 2:

                let code;
                if (tab.type === 'sql') {
                    code = message.sql
                } else if (tab.type === 'dbt') {
                    code = message.dbt_file
                }
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {'Copilot'}:
                        </div>
                        <div className="text-left">
                            {message.response}

                            <CodeMirror
                                  id="sql-code"
                                  className='relative w-90% bg-[#f0f0f0] overflow-auto theme-custom'
                                  extensions={[
                                    sql(),
                                    EditorView.lineWrapping,
                                    EditorView.theme({
                                      ".cm-activeLineGutter": {
                                        color: 'black',
                                        fontWeight: '500',
                                        backgroundColor: 'transparent',
                                      },
                                      ".cm-content": {
                                        paddingRight: '0px !important'
                                      },
                                      ".cm-scroller": {
                                        paddingBottom: '30px !important',
                                        backgroundColor: '#f0f0f0'
                                      },
                                      ".cm-line.cm-activeLine": {
                                        backgroundColor: '#e8e8e8',
                                        borderRadius: '4px',
                                      },
                                      ".cm-gutters": {
                                        backgroundColor: "#f0f0f0",
                                      },
                                    }),
                                  ]}
                                  value={code}
                                  editable={false}
                                >
                            </CodeMirror>
                        </div> 
                    </div>
                )
            default:
                return null;
        }
    }

    return (
        <div className='h-full w-full'>
        <div className="flex relative flex-col justify-between h-full bg-white">
            <div className="overflow-y-auto py-2 grow overflow-auto">
                    {chat && chat.length > 0 &&
                        <div className='max-h-[60%]'>
                            {chat.map(renderMessage)}
                            <div ref={messagesEndRef} />
                        </div>}
            </div>
            <div className="border-t-2 border-gray-200 py-2 content-end">
                <Spin indicator={antIcon} spinning={isLoading}>
                    <form onSubmit={(e) => handleSubmit(e)} className="px-5 mt-1 flex w-full justify-content">
                        <div className="grow">
                            <textarea
                                id="textarea"
                                className="w-full h-[40px] max-h-[120px] border border-1 rounded-lg p-2 resize-none"
                                value={inputMessage}
                                onKeyDown={handeKeyDown}
                                onChange={handleInputChange}
                                onInput={adjustTextareaHeight}
                                placeholder="Help me write ..."
                                disabled={isLoading}
                                autoFocus={true}
                            />
                        </div>
                        <input
                            className="bg-black h-[40px] ml-2 text-white p-2 rounded-lg cursor-pointer"
                            type="submit"
                            value="Send"
                            disabled={isLoading}
                        />
                    </form>
                </Spin>
            </div>
        </div>
        </div>
    )
}

export default CopilotResults

