import React, { useContext, useState, useEffect } from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';
import { Tooltip } from 'antd';

const SCHEMA_START_PATTERN = /^[A-Za-z_]/;
const SCHEMA_PATTERN = /^[A-Za-z_][A-Za-z0-9_]*$/;

const CustomSchema = ({ node }) => {
  const {
    handleCustomSchemaRename,
    dbtSchema,
    setSelectedNode,
    setSelectedNodeEdited,
  } = useContext(TableGraphContext);

  const [editedCustomSchema, setEditedCustomSchema] = useState('')
  const [customSchemaOpen, setCustomSchemaOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [schemaError, setSchemaError] = useState(false)

  useEffect(() => {
    setIsEditing(false)
    setEditedCustomSchema('')
    if (node.data.custom_schema){
      setCustomSchemaOpen(true)
    } else {
      setCustomSchemaOpen(false)
    }
  }, [node]);

  const openCustomSchema = async () => {
    setIsEditing(true)
    setCustomSchemaOpen(true)
  }

  const editCustomSchema = e => {
    setEditedCustomSchema(e.target.value)
    if (SCHEMA_START_PATTERN.test(e.target.value) && SCHEMA_PATTERN.test(e.target.value)) {
      setSchemaError(false)
    } else {
      setSchemaError(true)
    }
  }

  const saveCustomSchemaEdit = async () => {
    const old_custom_schema = node.data.custom_schema ?? ''
    if (old_custom_schema === editedCustomSchema) {
      if (editedCustomSchema == '') {
        setCustomSchemaOpen(false)
      }
      setIsEditing(false)
      return
    }

    setSelectedNode(prev => ({...prev, data: {...prev.data, custom_schema: editedCustomSchema}}))
    if (editedCustomSchema == '') {
      setCustomSchemaOpen(false)
    }
    setEditedCustomSchema()
    setIsEditing(false)
    setSelectedNodeEdited(true)
  }

  const cancelCustomSchemaEdit = () => {
    setEditedCustomSchema()
    if (!node.data.custom_schema) {
      setCustomSchemaOpen(false)
    }
    setIsEditing(false)
  }

  const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
          saveCustomSchemaEdit();
      }
  }

  const startEditingCustomSchema = async () => {
    setEditedCustomSchema(node.data.custom_schema ?? '')
    setIsEditing(true)
    if (node.data.custom_schema && SCHEMA_START_PATTERN.test(node.data.custom_schema ) && SCHEMA_PATTERN.test(node.data.custom_schema )) {
      setSchemaError(false)
    } else {
      setSchemaError(true)
    }
  }

  return (
      <div className="flex justify-center">
        <div className='w-full relative mb-[20px]'>
          { customSchemaOpen ?
            <div>
              <div className='mb-[0px] font-[500]'>
                Custom Schema
              </div>
                {isEditing ? (
                  <div className='flex mt-[0px] relative'>
                    <div className="flex flex-col justify-center h-[40px] w-[full] pr-[5px] font-[400] text-[14px] pl-[0px] relative top-[8px]">
                      { dbtSchema }_
                    </div>
                    <input className={`border-[1px] text-ellipsis ${schemaError ? "border-red-500"  : "border-gray-200"} rounded-lg py-[2px] px-[5px] flex-1 h-[40px] w-[full] pr-[50px] font-[400] text-[14px] pl-[20px] relative top-[8px] overflow-hidden`} value={editedCustomSchema} onChange={(e) => editCustomSchema(e)} onKeyDown={(e) => handleKeyDown(e)} autoFocus/>
                    <div className='relative'>
                    <div className={'absolute right-[5px] top-[17px] flex transition-all transition-[100ms] ease-in bg-white'}>
                      <div 
                        className="relative"
                      >
                        {schemaError ?                         
                        <Tooltip title={!SCHEMA_START_PATTERN.test(editedCustomSchema) ? 'Schema name must start with a letter or underscore' : "Schema name must only contain letters, digits, and underscores"}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md ${schemaError ? "cursor-not-allowed" : ""}`} onClick={() => !schemaError && saveCustomSchemaEdit()}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </Tooltip>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md ${schemaError ? "cursor-not-allowed" : ""}`} onClick={() => !schemaError && saveCustomSchemaEdit()}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      }


                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" onClick={() => cancelCustomSchemaEdit()}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    </div>
                    
                  </div>
                ) : (
                  <div className='flex mt-[0px] relative'>
                  <a 
                    className="flex flex-row gap-[5px] justify-start h-[40px] w-[full] pr-[5px] font-[400] text-[14px] pl-[0px] relative top-[16px]"
                    onClick={() => startEditingCustomSchema()}
                  >
                  <div>
                      { dbtSchema }_{ node.data.custom_schema ?? '' }
                  </div>
                      <div>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 relative">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                      </div>
                  </a>
                  </div>
                )}
            </div>
          :
            <div className='bg-gray-200 text-[12px] hover:bg-gray-300 rounded p-1 flex justify-center items-center mt-[5px]' onClick={() => openCustomSchema()}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>
                Set Custom Output Schema
            </div>
          }

        </div>
      </div>
  )
}

export default CustomSchema;
