import { useState, useEffect, useContext } from 'react';
import { Spin } from 'antd';

import './styles.scss';
import Page from '../../components/Page';
import { useHistory } from 'react-router-dom';
import { LandingContext } from '../../context/LandingProvider';
import { TableGraphContext } from '../../context/TableGraphProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../context/UserProvider';
import CompleteSetup from '../../components/Onboarding/CompleteSetup';
import ModelsLanding from '../../components/Landing/ModelsLanding';
import { GitContext } from '../../context/GitProvider';

const Landing = () => {
  const {
    recentChartboards,
    recentWorkflows,
    getActivity
  } = useContext(LandingContext)
  const {
    createDag
  } = useContext(TableGraphContext)
  const {
    noGitConnection
  } = useContext(GitContext);
  const {
      noWarehouse
  } = useContext(UserContext)
  const history = useHistory();
  const [currentWorkflowHover, setCurrentWorkflowHover] = useState()
  const [currentDashboardHover, setCurrentDashboardHover] = useState()
  const [landingModal, setLandingModal] = useState(false)
  

  useEffect(() => {
    getActivity(!noGitConnection && noWarehouse)
  }, [noGitConnection, noWarehouse])

  const createAndEdit = async () => {
    let dag_id = await createDag('placeholder name')
    history.push('tablegraphs/' + dag_id)
  }

  const handleClick = (route) => {
    if (route === 'add_new') {
      createAndEdit()
    }
    else {
      history.push(route)
    }
    
  };

  const dashboardMouseOver = (dashboard) => {
    setCurrentDashboardHover(`${dashboard.home_text}/${dashboard.object_id}`)

  }
  const workflowMouseOver = (workflow) => {
    setCurrentWorkflowHover(`${workflow.home_text}/${workflow.object_id}`)
  }

  const mouseOut = () => {
    setCurrentWorkflowHover()
    setCurrentDashboardHover()
  }

  if (noWarehouse && noGitConnection) {
    return (
      <Page >
        <CompleteSetup />
      </Page>
    )
  }

  if (noWarehouse) {
    return (
      <Page>
        <ModelsLanding />
      </Page>
    )
  }



return (
  <Page >
    <div className="h-full overflow-y-auto relative w-full bg-white">
      <div>
        {landingModal &&
          <div
            className="bg-gray-700 h-screen w-screen fixed z-50 top-0 left-0 bg-opacity-50"
            onClick={() => {
              setLandingModal(false);
            }}
          >
            <div className="z-90 relative mx-auto top-[25vh] w-[700px]">
              <div className="relative text-center bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="py-6 text-center">
                  <h1 className='text-[20px] text-left mb-[25px] px-6'> Where do you want to start?</h1>
                  <div className='flex justify-center mb-[20px] px-[15px]'>
                  <div className="w-[200px] h-[150px] border-[1px] border-gray-300 rounded-lg mx-[15px] cursor-pointer hover:bg-gray-100" onClick={() => handleClick('add_new')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[24px] h-[24px] mx-auto mt-[25%]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <div>
                        Workflow
                      </div>
                    </div>
                    <div className="w-[200px] h-[150px] border-[1px] border-gray-300 rounded-lg mx-[15px] cursor-pointer hover:bg-gray-100" onClick={() => handleClick('/artyai')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[24px] h-[24px] mx-auto mt-[25%]" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <div>
                        Chart
                      </div>


                    </div>
                    <div className="w-[200px] h-[150px] border-[1px] border-gray-300 rounded-lg mx-[15px] cursor-pointer hover:bg-gray-100" onClick={() => handleClick('/analytics')}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[24px] h-[24px] mx-auto mt-[25%]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <div>
                        Dashboard
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      
      
      {/* <button onClick={() => getActivity()}>test</button> */}
      {
          !recentWorkflows || !recentChartboards ?
            <div className='w-full flex justify-center mt-[20%]'>
              <Spin indicator={<LoadingOutlined style={{fontSize: '40px'}} />} />
            </div>
        
          :
          <div className='my-[50px] ml-[50px]'>
            <div className='relative'>
              <div className='absolute right-[40px]'>
        <button className='bg-black px-[20px] py-[7px] text-white rounded-lg hover:bg-gray-700' onClick={() => setLandingModal(true)}> Create </button>
      </div>
      <h1 className='mb-[15px] text-title'>Recent Workflows</h1>
      <div className='flex flex-wrap'>
        {recentWorkflows.map(workflow => 
          <div className='relative mb-[25px] cursor-pointer rounded-[20px]  hover:bg-gray-100 relative mx-5 justify-center border-[1px] border-gray-300 items-center h-[200px] w-[200px]' onMouseOver={() => workflowMouseOver(workflow)} onMouseOut={() => mouseOut()}>
            {currentWorkflowHover && currentWorkflowHover == `${workflow.home_text}/${workflow.object_id}` ?
              <div className='absolute mt-[45%] w-full justify-center flex z-[20]'>

                <button className='mr-[10px] rounded-lg bg-[#4cb043] hover:bg-[#2c9023] py-[5px] px-[10px] text-white' onClick={() => { handleClick(workflow.object_id) }} >Editor</button>
                <button className='ml-[10px] rounded-lg bg-[#4cb043] hover:bg-[#2c9023] py-[5px] px-[10px] text-white' onClick={() => { handleClick('chat/' + workflow.object_id.split('tablegraphs/')[1]) }} >Chat</button>
              </div>
              :
              <></>

            }
            
            <div className={currentWorkflowHover && currentWorkflowHover == `${workflow.home_text}/${workflow.object_id}` ? 'h-full blur-[7px]' : 'h-full'}>
              <div className='pb-[35%]'/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[20%] h-[20%] mx-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
              </svg>

              <h1 className="text-[16px] text-black font-bold text-center my-auto align-middle mt-[15%] truncate overflow-hidden px-[6px]">{workflow.home_text}</h1>
            </div>
            
          </div>
        )}
        <div className='relative mb-[25px] cursor-pointer rounded-[20px]  hover:bg-gray-100 relative  ml-5 mr-5 justify-center border-[1px] border-gray-300 border-dashed	 items-center h-[200px] w-[200px]' onMouseOver={() => workflowMouseOver('add_new')} onMouseOut={() => mouseOut()}>
            {currentWorkflowHover && currentWorkflowHover == 'add_new' ?
              <div className='absolute mt-[45%] w-full justify-center flex z-[20]'>

                <button className=' rounded-lg bg-[#4cb043] hover:bg-[#2c9023] py-[5px] px-[10px] text-white' onClick={() => { handleClick('add_new') }} >Create</button>
              </div>
              :
              <></>

            }
            
            <div className={currentWorkflowHover && currentWorkflowHover == 'add_new' ? 'h-full blur-[7px]' : 'h-full'} onClick={() => handleClick('add_new')}>
              <div className='pb-[35%]'/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[20%] h-[20%] mx-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>


              <h1 className="text-[16px] text-black font-bold text-center my-auto align-middle mt-[15%]">New</h1>
            </div>
            
          </div>
      </div>
      <h1 className='mb-[15px] text-title'>Recent Dashboards</h1>
      {recentChartboards && !recentChartboards.length && 
        <div className=' mt-[50px] w-full text-center'>
          <h3>There is nothing here... </h3>
          <h5>Go to the analytics page to start building your first dashboard</h5>

        </div>
      }
      <div className='flex flex-wrap'>
        {recentChartboards.map(chartboard => 
          <div className='relative mb-[25px] cursor-pointer rounded-[20px]  hover:bg-gray-100 relative  ml-5 mr-5 justify-center border-[1px] border-gray-300 items-center h-[200px] w-[200px]' onMouseOver={() => dashboardMouseOver(chartboard)} onMouseOut={() => mouseOut()}>
          {currentDashboardHover && currentDashboardHover == `${chartboard.home_text}/${chartboard.object_id}` ?
            <div className='absolute mt-[45%] w-full justify-center flex z-[20]'>

              <button className='rounded-lg bg-[#4cb043] hover:bg-[#2c9023] py-[5px] px-[15px] text-white' onClick={() => { handleClick(chartboard.object_id) }} >View</button>
            </div>
            :
            <></>

          }
          
          <div className={currentDashboardHover && currentDashboardHover == `${chartboard.home_text}/${chartboard.object_id}` ? 'h-full blur-[7px]' : 'h-full'}>
            <div className='pb-[35%]'/>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[20%] h-[20%] mx-auto">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
              </svg>


            <h1 className="text-[16px] text-black font-bold text-center my-auto align-middle mt-[15%]">{chartboard.home_text}</h1>
          </div>
          
        </div>
        )
        }
        </div>
            </div>
            </div>
        }
      
    </div>
  </Page>

  );



};

export default Landing;
