import React, {useEffect, useState, useMemo, useRef} from 'react'
import ReactFlow, {
    ReactFlowProvider,
    Background,
  } from 'reactflow';
import 'reactflow/dist/style.css';
import Node from './Node';


function ChatPageGraph({message}) {
    const [nodes, setNodes] = useState()
    const [edges, setEdges] = useState()
    const reactFlowWrapper = useRef(null);
    const flowStyles = { height: 1000 };
    const nodeTypes = useMemo(
        () => ({
          custom: Node,
          source: Node,
          logic: Node,
        }),
        []
      );

    useEffect(() => {
        if(message?.data_pipeline && message?.edges){
                let pos_x = 100;
                let pos_y = 50;

                setNodes(message.data_pipeline.map((node) => {
                    if (node.type == 'logic') {
                        pos_x += 200;
                        pos_y = 50;
                    }
                    const nodeData = {
                        id: node.node_id,
                        data: { type: node.type, description: node.description, model_name: node.table_name, isFromChatPage: true},
                        position: { x: pos_x, y: pos_y },
                        type: node.type,
                    };
                    pos_y += 150;
                    return nodeData;
                }));
                
                setEdges(message.edges.map((edge) => {
                    return{
                        id: `e${edge.source}-${edge.target}`,
                        source: edge.source,
                        target: edge.target,
                        animated: true,
                    }
                }))    
            }
    },[message])


  return (
    <ReactFlowProvider >
        <div style={{ height: '120%', width: '100%', transform: 'translate(0,-20px)'}} ref={reactFlowWrapper}>
        <ReactFlow 
            nodes={nodes}
            edges={edges}
            style={flowStyles}
            nodeTypes={nodeTypes}
            className='overflow-hidden' 
        >
            <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
        </div>
    </ReactFlowProvider>
  )
}

export default ChatPageGraph