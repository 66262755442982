import { useContext, useState, useEffect } from "react";
import { ArtyAIContext } from "../../context/ArtyAIProvider";
import { AnalyticsContext } from "../../context/AnalyticsProvider";
import Select from "react-select";
import { Spin } from "antd";

const customStyles = {
    control: (provided) => ({
        ...provided,
        maxHeight: "100px",
        overflowY: "auto",
        width: "200px",
    }),
    menu: (provided) => ({
        ...provided,
        maxHeight: "200px",
        width: "200px",
        wordWrap: "break-word",
        position: "absolute",
        zIndex: "99",

    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: "200px",
        wordWrap: "break-word",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        maxHeight: "38px",
        postion: "sticky",
        top: "0",
    })
  }

const PinModal = () => {
    const {
        chartBoards,
        pinChartView,
        setPinChartView,
        setPinChart,
    } = useContext(ArtyAIContext)

    const {
        pinChart,
        currentChartID,
        pinModalView,
        setPinModalView,
        pinLoading,
    } = useContext(AnalyticsContext)

    const [selected, setSelected] = useState()
    const [options, setOptions] = useState()



    useEffect(() => {
        if (chartBoards){
            const temp = []
            for (const x of chartBoards) {
                temp.push({ label: x.name, value: x.chart_board_id })
            }
        
            setOptions(temp)
        }
    }, [chartBoards])

    return pinModalView ? (
        <div
            className="bg-gray-700 h-screen w-screen fixed z-50 top-0 left-0 bg-opacity-50"
        >
            <div className="relative mx-auto w-full max-w-md h-full md:h-auto top-[25vh]">
                <div className="relative text-center bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Select a dashboard to pin chart to</h3>
                        <div className='flex ml-[100px] mb-[30px]'>
                        <Select
                            className='mb-[0px]'
                            value={selected}
                            onChange={setSelected}
                            options={options}
                            placeholder="Select Dashboard"
                            isDisabled={!chartBoards}
                            isLoading={false}
                            theme={(theme) => ({
                            ...theme,
                            colors: {
                            ...theme.colors,
                            primary25: '#537650',
                            primary: '#1F422A',
                            },
                            })}
                            styles={customStyles} 
                            isMulti={false}

                        />
                        </div>
                        <div className="flex w-full justify-center">
                        <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => { setPinModalView(false); setPinChart(); setSelected()}}>Cancel</button>
                        <Spin spinning={pinLoading}>
                            <button type="button" className="ml-[25px] text-gray-500 bg-white hover:bg-green-700  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" disabled={!selected} onClick={() => { pinChart(currentChartID, selected.value); setPinChart(); setSelected() }}>Save</button>                    
                        </Spin>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    ) :
    (<></>)
}

export default PinModal;
