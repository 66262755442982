import React, { useContext, useState, useEffect } from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';

const CustomMaterialization = ({ node }) => {
  const {
    setSelectedNode,
    setSelectedNodeEdited, 
  } = useContext(TableGraphContext);

  const [materializationMenu, setMaterializationMenu] = useState(false)
  const [editedMaterialization, setEditedMaterialization] = useState('')

  useEffect(() => {
    if (node.data.custom_materialization){
      setEditedMaterialization(node.data.custom_materialization)
    } else {
      setEditedMaterialization('Set Custom Materialization')
    }
  }, [node]);

  const saveMaterialization = async (materialization) => {
    setEditedMaterialization(materialization)
    setSelectedNode(prev => ({...prev, data: {...prev.data, custom_materialization: materialization === 'default' ? null : materialization}}))
    setSelectedNodeEdited(true)
  }

  const materializationTypes = ['default', 'table', 'view', 'incremental', 'ephemeral', 'materialized view']

  return (
            <div className='w-full relative'>
              <div className='mb-[0px] font-[500]'>
                Materialization
              </div>
              <div className='relative'>
                <div className='bg-gray-200 text-[12px] hover:bg-gray-300 rounded p-1 flex justify-between items-center mt-[5px]' onClick={() => setMaterializationMenu(!materializationMenu)}>
                    <div className='flex-grow text-center'>
                        {editedMaterialization === 'default' ? 'Set Custom Materialization' : editedMaterialization}
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
              </div>

                  {materializationMenu && (
                    <div>
                      <div
                        className="absolute top-[0px] left-[0px] w-full h-full z-[10]"
                        onClick={() => setMaterializationMenu(false)}
                      />
                      <div
                        id="dropdown"
                        className="cursor-pointer z-[99] border-gray-300 bg-white border-[1px] rounded-lg p-[5px] divide-gray-300 w-[130px] shadow absolute"
                      >
                        <ul className="">
                          {materializationTypes.map((materialization, index) => (
                            <li key={index}>
                              <div
                                className="flex text-black text-[12px] hover:bg-gray-100"
                                onClick={() => {
                                  saveMaterialization(materialization);
                                  setMaterializationMenu(false);
                                }}
                              >
                                <div className="relative top-[4px] left-[5px]">{materialization}</div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
            </div> 
  )
}

export default CustomMaterialization;
