import React, { useContext, useState, usePar } from "react";
import { ArtyAIContext } from "../../context/ArtyAIProvider";
import { BarGraph } from "../../components/Charts/BarGraph";
import { LineGraph } from "../../components/Charts/LineGraph";
import { PieGraph } from "../../components/Charts/PieGraph";
import { SingleValue } from "../../components/Charts/SingleValue";
import { ScatterGraph } from "../../components/Charts/ScatterGraph";
import DataGrid from 'react-data-grid';
import { DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin, Button, message, Popconfirm } from "antd";
import AnalyticsGraph from "../Analytics/AnalyticsGraph";

const SavedChartsScreen = ({ setViewMode }) => {

    const {
        applyPrompt,
        setView,
        deleteSavedPrompt,
        renameSavedPrompt,
        deleteChart,
        savedCharts,
        setSavedCharts,
        savedChartsLoading,
        setSavedChartsLoading,
        selectedChartBoard,
        editChartBoard,
        updateChartboardName,
    } = useContext(ArtyAIContext)
  
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [newName, setNewName] = useState("");
    const [initialName, setInitialName] = useState("");
    const [isEditing, setIsEditing] = useState(false);    
  
    const handleEdit = () => {
      setNewName(selectedChartBoard.name);
      setInitialName(selectedChartBoard.name);
      setIsEditing(true);
    };
  

  const handleSave = async (e = null) => {
    if (e) {
      e.preventDefault()
    }
    console.log('handle save')
      if (isEditing) {
          // Call your API to update item's name
          await updateChartboardName(selectedChartBoard.chart_board_id, newName);
          setIsEditing(false);
      }
  };

  const handleCancelEdit = () => {
      console.log('handle cancel edit')
      setNewName(initialName);
      setIsEditing(null);
  };
    
  
    const handleOk = async (id) => {
      setConfirmLoading(true);
      await deleteChart(id)
      let filtered = structuredClone(savedCharts)
      filtered['data'] = filtered['data'].filter(function( obj ) {
        return obj.chart._id != id;
      })
      setSavedCharts(filtered)
      setConfirmLoading(false)
      setOpen(false)

    };
  
    const handleCancel = () => {
      setOpen(false);
    };

    const onApply = (prompt) => {
        applyPrompt(prompt)
        setView(2)
        setViewMode('prompt')
    }
  
    const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
          handleSave();
      }
    };

    const onDelete = (id) => {
      // deleteChart(id)
      let filtered = structuredClone(savedCharts)
      filtered['data'] = filtered['data'].filter(function( obj ) {
        console.log(obj.chart._id)
        return obj.chart._id != id;
      })
      setSavedCharts(filtered)
    }
  

    return (
        <div className="h-full w-full">
        {selectedChartBoard ?
          <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 flex">
              {isEditing ? (
                <div className="flex items-center space-x-2">
                    <input 
                        className="border rounded-lg border-gray-900 focus:border-black p-1 text-xl font-bold"
                        type="text" 
                        value={newName} 
                        onChange={(e) => setNewName(e.target.value)} 
                        onKeyDown={(e) => handleKeyDown(e)}
                        autoFocus
                    />
                    <CheckCircleOutlined className='hover:text-[#4cb043]' onClick={(e) => handleSave(e)}/>
                    <CloseCircleOutlined className='hover:text-red-400' onClick={() => handleCancelEdit()}/>
                </div>
            ) : (
                <h2 
                    className="text-xl font-bold flex"
                    onClick={() => handleEdit()}
                >
                    {selectedChartBoard.name}
                    <div className="ml-[5px] relative top-[1px]">
                <button onClick={()=>console.log('click!')}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
                </button>
              </div>
                </h2>
            )}
              
            </h1>
        </div>
      </div>
            {!savedCharts['data'] ?
              <div className="w-full text-center">
                <Spin indicator={<LoadingOutlined className='text-[35px] mt-[25vh]'/>} />
              </div>
              
      :      
      <div className="mt-8 mb-[100px] w-full"> 
                {savedCharts['data'].length ?
                
                
                  <div className="flex flex-wrap">
                    {savedCharts['data'].map(chart => {
                      console.log(chart)
                      if (chart.version === 'custom') {
                        return (
                          <div className="w-full h-[500px]">
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart.chart.chart_name}
                              </h3>
                              <Popconfirm
                              
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'}>
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
              
                            
                                </div>
                              </Popconfirm>
                            </div>
                          <AnalyticsGraph chartData={chart.table} generatedChartType={JSON.parse(chart.chart.chart_type).value}/>
                          </div>
                        )
                      }
                      else if (chart['chart']['table_type'] == "bar") {
                        return (
                          <div className='w-full h-500 w-1/2 content-center'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}
                              </h3>
                              <Popconfirm
                              
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'}>
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
              
                            
                                </div>
                              </Popconfirm>
                            </div>
                            <BarGraph rows={chart['table']['rows']} chart_data={chart['chart']} />
                          </div>
                        )
                      } else if (chart['chart']['table_type'] == "line") {
                        console.log(chart)
                        return (
                          <div className='w-full h-500 w-1/2'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}
                              </h3>
                              <Popconfirm
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'}>
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
                            
                                </div>
                              </Popconfirm>
                            </div>
                            <LineGraph className='w-1/2' rows={chart.table.rows} chart_data={chart['chart']} />
                          </div>
                        )
                      } else if (chart['chart']['table_type'] == "pie") {
                        return (
                          <div className='w-1/2'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}

                              </h3>
                              <Popconfirm
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'}>
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
                                </div>
                              </Popconfirm>
                            </div>
                            <PieGraph className='w-1/2' rows={chart.table.rows} chart_data={chart['table']} />
                          </div>
                        )
                      } else if (chart['chart']['table_type'] == "single value" || chart.chart_type == "single_value") {
                        return (
                          <div className='w-1/2'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}

                              </h3>
                              <Popconfirm
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'}>
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
                            
                                </div>
                              </Popconfirm>
                            </div>
                            <SingleValue className='w-1/2' rows={chart.table.rows} chart_data={chart['chart']} />
                          </div>
                        )
                      } else if (chart['chart']['table_type'] == "scatter") {
                        return (
                          <div className='w-1/2'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}

                              </h3>
                              <Popconfirm
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'} >
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />
                            
                                </div>
                              </Popconfirm>
                            </div>
                            <ScatterGraph className='w-1/2' rows={chart.table.rows} chart_data={chart['chart']} />
                          </div>
                        )
                      
                      }
                      else if (chart['chart']['table_type'] == "table") {
                        return (
                          <div className='h-500 w-1/2 content-center'>
                            <div className='flex justify-center'>
                              <h3 className='my-[5px]'>
                                {chart['chart']['table_name']}
                              </h3>
                              <Popconfirm
                                title="Confirm deletion"
                                description="Confirm you would like to delete this chart"
                                open={open}
                                okText="Delete"
                                okType="danger"
                                onConfirm={() => handleOk(chart['chart']['_id'])}
                                okButtonProps={{
                                  className: 'bg-red-500 hover:bg-red-400 text-white',
                                  loading: confirmLoading,
                                }}
                                cancelButtonProps={{
                                  className: 'bg-white text-black shadow-none hover:bg-gray-100 hover:text-black'
                                }}
                                onCancel={handleCancel}
                              >
                                <div className={true ? 'text-[20px] transition-all duration-400 text-[#000000]' : 'text-[20px] transition-all duration-400 text-gray-300'} >
                            
                                  <DeleteOutlined className='ml-2 hover:text-red-500 hover:cursor-pointer' />

                                </div>
                              </Popconfirm>
                            </div>
                            <div className="flex items-center justify-center rounded-lg p-2 grow h-500">
                              <DataGrid
                                style={{
                                  zIndex: 99,
                                }}
                                className="rdg-light"
                                columns={chart.table.columns}
                                rows={chart.table.rows}
                              />
                            </div>
                          </div>
                        )
                      
                      }
                    }
                    )
                    }
                  </div>
                  :
                  <div className="w-full mt-[25vh]">
                    <div className="text-center text-[20px] text-gray-600">
                      There's nothing here...
                    </div>
                    <div className="text-center text-[16px] text-gray-600">
                      add a chart in the chart builder or arty pages
                    </div>
                  </div>
                }
                </div>
                }
              
            </>
          :
          <div className="text-center w-full mt-[25vh] text-[20px] text-gray-600">
            Select a dashboard
          </div>
          }
    </div>
    )
}

export default SavedChartsScreen
