import './style.scss';
import { Tabs, Button, Tooltip, Modal, Input, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState, useContext } from 'react';
import LetoClient from '../../../context/LetoClient';
import { UserContext } from '../../../context/UserProvider';
import { ConnectorContext } from '../../../context/ConnectorProvider';

const { TabPane } = Tabs;

const WorkSpace = () => {
  const currentUser = useContext(UserContext)
  const {
    setWorkspaces,
    setCurrentWorkSpace,
    currentWorkspace,
    workspaces,

    // getWorkSpaces
  } = useContext(ConnectorContext)
  // const [currentWorkSpace, setCurrentWorkSpace] = useState(0);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [addWorkName, setAddWorkName] = useState("");
  const [inputStatus, setInputStatus] = useState("");

  const getWorkSpaces = async () => {
    try {
      const data = await LetoClient.getWorkSpaces()
      setWorkspaces(data)
    } catch (err) {
      console.log("Unable to retrieve workspaces")
      console.log(err)
    }
  };

  const handleOnChange = (value) => {
    setAddWorkName(value)
    // connectorContext.setRefreshWorkspaces(!connectorContext.refreshWorkspaces)
    setInputStatus("")
  };


  const handleAddSubmit = async () => {
    setInputStatus('');
    if (addWorkName === '' || currentUser.user.currentOrg == []) {
      setInputStatus('error');
      return;
    }
    const body = { workspace_name: addWorkName, org_id: currentUser.user.currentOrg[0] };
    await LetoClient.createWorkSpaces(body);

    getWorkSpaces();

    handleCancel(false);
    //logic
    setAddWorkName('');
    setIsAddModalVisible(false);
  };

  const handleEditSubmit = () => {
    setInputStatus('');
    if (addWorkName === '' || currentUser.user.currentOrg == []) {
      setInputStatus('error');
      return;
    }
    console.log('new name: ' + addWorkName);
    const body = { new_workspace_name: addWorkName, workspace_id: currentWorkspace };
    LetoClient.updateWorkSpaces(body);

    getWorkSpaces();

    handleCancel(false);
    setAddWorkName('');
    setIsAddModalVisible(false);
  };

  const showAddModal = () => {
    setIsAddModalVisible(true);
    setInputStatus('');
    setAddWorkName('');
  };
  const showEditModal = () => {
    setIsEditModalVisible(true);
    setInputStatus('');
    setAddWorkName('');
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
    setIsEditModalVisible(false);
    setInputStatus('');
    setAddWorkName('');
  };

  const onChange = key => {
    setCurrentWorkSpace(key);
  };

  const confirmDelete = () => {
    const body = { workspace_id: currentWorkspace };
    LetoClient.deleteWorkSpaces(body);

    getWorkSpaces();
  };
  return (
    <div>
      <Tabs className="tabs-container" value={currentWorkspace} onChange={onChange}>
        <TabPane tab="All" key="All" />
        {workspaces &&
          workspaces.map(workspace => <TabPane tab={workspace.workspace_name} key={workspace.workspace_id} />)}
      </Tabs>
      <Tooltip title="Add New Workspace">
        <Button
          className="add-workspace-button"
          style={{ background: '#537650', borderColor: '#537650' }}
          type="primary"
          shape="circle"
          onClick={() => showAddModal()}
          icon={<PlusOutlined />}
        />
      </Tooltip>

      <Tooltip title="Edit Workspace">
        <Button
          disabled={currentWorkspace == 'All'}
          className="edit-workspace-button"
          type="primary"
          shape="circle"
          onClick={() => showEditModal()}
          icon={<EditOutlined />}
        />
      </Tooltip>

      <Tooltip title="Delete WorkSpace">
        <Popconfirm
          disabled={currentWorkspace == 'All'}
          title="Are you sure to delete this workspace?"
          onConfirm={confirmDelete}
          okText="Yes"
          cancelText="No"
          placement="bottomRight"
        >
          <Button
            disabled={currentWorkspace == 'All'}
            className="delete-workspace-button"
            type="danger"
            shape="circle"
            style={currentWorkspace == 'All'? {} : {    backgroundColor: "rgba(255, 0, 0, 0.857)"}}
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </Tooltip>

      <Modal title="Add Workspace" style={{ background: '#537650', borderColor: '#537650' }} centered visible={isAddModalVisible} onOk={handleAddSubmit} onCancel={handleCancel}>
        <Input
          value={addWorkName}
          status={inputStatus}
          addonBefore="Workspace Name:"
          onChange={e => handleOnChange(e.target.value)}
          onPressEnter={() => handleAddSubmit()}
        />
      </Modal>

      <Modal
        title="Edit Workspace"
        centered
        visible={isEditModalVisible}
        onOk={handleEditSubmit}
        onCancel={handleCancel}
      >
        <Input
          value={addWorkName}
          status={inputStatus}
          addonBefore="New Workspace Name:"
          onChange={e => handleOnChange(e.target.value)}
          onPressEnter={() => handleEditSubmit()}
        />
      </Modal>
    </div>
  );
};

export default WorkSpace;
