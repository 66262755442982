import { useContext, useState } from "react";
import { ArtyAIContext } from "../../context/ArtyAIProvider";


const AddBoardModal = () => {
    const {
        chartBoards,
        setChartBoards,
        addChartBoard,
        addboardModalVisible,
        setAddboardModalVisible,

    } = useContext(ArtyAIContext)

    const [name, setName] = useState('')

    const saveBoard = async () => {
        await addChartBoard(name);
        setName('');
        setAddboardModalVisible(false);
    }

        
    return addboardModalVisible ? (
        <div className="bg-gray-700 h-screen w-screen fixed z-50 top-0 left-0 bg-opacity-50">
            <div className="relative mx-auto w-full max-w-md h-full md:h-auto top-[25vh]">
                <div className="relative text-center bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Input a name for your new dashboard and save</h3>
                        <div className='flex ml-[20px]'>
                            <label for="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white relative top-[5px]">Dashboard name: </label>
                            <input type="text" id="small-input" className="mb-[15px] block ml-[10px] w-2/3 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        {/* <label for="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Board name</label> */}
                        {/* <input type="text" id="small-input" className="mb-[15px] block m-auto w-2/3 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/> */}
                        <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => { setAddboardModalVisible(false);  setName('')}}>Close</button>
                        <button type="button" className="ml-[25px] text-gray-500 bg-white hover:bg-green-700  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => saveBoard()}>Save</button>                    
                    </div>
                </div>
            </div>
        </div>
    ) :
    (<></>)
}

export default AddBoardModal;