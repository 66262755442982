import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export const PieGraph = ({chart_data, rows}) => {
    console.log(chart_data)

    return (
      <ResponsiveContainer width="100%" height="85%">
            
              <PieChart
                width={500}
                height={300}
                data={rows}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Pie
                  dataKey={chart_data.y_axis}
                  startAngle={0}
                  endAngle={360}
                  data={rows}
  
                  outerRadius={80}
                  fill="#1f422a"
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index
                  }) => {
                    const RADIAN = Math.PI / 180;
                    // eslint-disable-next-line
                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                    // eslint-disable-next-line
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    // eslint-disable-next-line
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);
          
                    return (
                      <text
                        x={x}
                        y={y}
                        fill="#1f422a"
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {rows[index][chart_data['x_axis']]} : {value.toString().substring(0, 6) + "..."}
                      </text>
                    );
                  }}
                />
                <Tooltip/>
              </PieChart>
  
      </ResponsiveContainer>
      
      
      )


}
