import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import { Layout, Button, Radio, List, Skeleton, Tooltip } from 'antd';
import { PlusOutlined, AppstoreAddOutlined, BarChartOutlined, PushpinOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
import PinModal from './PinModal';
const AnalyticsHome = () => {
    const history = useHistory();

    const {
        recents,
        loadChart,
        setChartName,
        createChart,
        setPinModalView,
        setCurrentChartId,
        DeleteChart,
        DuplicateChart,
    } = useContext(AnalyticsContext)

    const [selected, setSelected] = useState('charts')

    const open = (chart) => {
        setChartName(chart.chart_name)
        history.push(`analytics/charts/${chart["_id"]}`)
    }

    const NewChart = async () => {
        const response = await createChart()
        setChartName('New Chart')
        history.push(`analytics/charts/${response}`)
    }

    const pinChart = (e, chart) => {
        e.stopPropagation()
        setCurrentChartId(chart._id)
        setPinModalView(true)
    }
    const duplicateChart = (e, chart) => {
        e.stopPropagation()
        DuplicateChart(chart._id)
    }
    const deleteChart = (e, chart) => {
        e.stopPropagation()
        DeleteChart(chart._id)
    }


    return (
        <div className='bg-white'>
            <PinModal/>
            <div className='mt-[30px] mx-[40px]'>
                <div className='flex align-center  mb-[10px] select-none'>
                    <h1 className='text-title'>Chart History</h1>
                    <div className='grow'>
                        <div className='float-right'>
                            <button className='text-[16px] flex bg-gray-200 hover:bg-gray-300 px-[10px] py-[5px] rounded-lg' onClick={() => NewChart()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="relative top-[2px] w-[20px] h-[20px]">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>

                            
                                New
                            </button>

                        </div>
                    </div>
                </div>
                <ul className="w-full">
                    {recents && recents.length ? recents.map((chart) => 
                    
                        <li className="cursor-pointer select-none border-b-[1px] border-gray-300" key={chart._id} onClick={() => open(chart)}>
                            <div className="flex items-center space-x-4 hover:bg-gray-100 rounded-lg py-[10px] px-[10px]">
                                <BarChartOutlined style={{ fontSize: '24px' }}/>
                                <p className="text-[20px] text-gray-900 mr-[20px]">
                                {chart.chart_name}
                                </p>
                                <div className='flex float-right'>
                                        Last Edited:
                                        {' ' + moment(chart.last_modified).fromNow()}
                                    </div>

                                <div className='grow'>
                                    <div className='flex float-right'>
                                        <Tooltip placement="top" title="Pin Chart to Dashboard">
                                            <PushpinOutlined data-tooltip-target="pin-chart" className='text-[20px] mr-[5px] hover:bg-gray-300 p-[5px] rounded-lg' onClick={(e) => pinChart(e, chart)}/>
                                        </Tooltip> 

                                        <Tooltip placement="top" title="Duplicate Chart">
                                            <CopyOutlined className='text-[20px] mr-[5px] hover:bg-gray-300 p-[5px] rounded-lg' onClick={(e) => duplicateChart(e, chart)}/>
                                        </Tooltip>

                                        <Tooltip placement="topRight" title="Delete Chart">
                                            <DeleteOutlined className='text-[20px] mr-[5px] hover:bg-gray-300 p-[5px] rounded-lg' onClick={(e) => deleteChart(e, chart)}/>
                                        </Tooltip>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </li>
                    
                    )
                        :
                        <div className='mt-[75px] text-center text-gray-500 text-[24px] font-600'>
                            No charts found...
                        </div>
                }
                
                </ul>
            </div>
        </div>
    )
};
export default AnalyticsHome;