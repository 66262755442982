import React from 'react';

const FileUploadMockup = () => {
  return (
    <div className="disabled opacity-[20%] flex flex-col items-center justify-center rounded-lg p-4">

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
</svg>

      <p className="mt-2 text-gray-500">
        Drag and drop your files here, or click to select files.
      </p>
    </div>
  );
};

export default FileUploadMockup;

