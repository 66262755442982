import React, {useContext, useState, useEffect} from 'react';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import { UserContext } from '../../../../context/UserProvider';
import { v4 as uuidv4 } from 'uuid';


const GitMenu = ({ menuItem, setMenuItem, setMenuPos }) => {

    const {
        git,
        getFileTree,
        showAll,
        setConsoleMessages,
    } = useContext(ModelEditorContext)

    const handleStageFile = async (e, file) => {
        e.stopPropagation()
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Staging changes to ${file}...`}])
        await git.stageUnstageFile(file, true)
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
        setMenuPos()
        setMenuItem()
    }

    const handleUnstageFile = async (e, file) => {
        e.stopPropagation()
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Discarding changes to ${file}...`}])
        await git.stageUnstageFile(file, false)
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
        setMenuPos()
        setMenuItem()
    }

    const handleStash = async (e, file) => {
        e.stopPropagation()
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Stashing changes in ${file}... `}])
        const response = await git.stashFleChanges(file)
        if (response)
            setConsoleMessages(prev => prev.map((item) => item.id === id ? { ...item, message: item.message + response } : item))
        else 
            setConsoleMessages(prev => prev.map((item) => item.id === id ? { ...item, message: item.message + 'Done' } : item))
        getFileTree(showAll)
        setMenuPos()
        setMenuItem()
    }

    if (menuItem.type == 'staged') {
        return (
            <div>     
                <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleUnstageFile(e, menuItem.file) }>
                    <div className="text-gray-900">Unstage</div>
                </div>
                <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleStash(e, menuItem.file) }>
                    <div className="text-gray-900">Discard</div>
                </div>
            </div>
        )
    }
    if (menuItem.type == 'unstaged') {
        return (
            <div>     
                <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleStageFile(e, menuItem.file) }>
                    <div className="text-gray-900" >Stage</div>
                </div>
                <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleStash(e, menuItem.file) }>
                    <div className="text-gray-900">Discard</div>
                </div>
            </div>
        )
    }
    return (
        <div>     
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleStageFile(e, menuItem.file) }>
                <div className="text-gray-900">Stage</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none" onClick={(e)=> handleStash(e, menuItem.file) }>
                    <div className="text-gray-900">Discard</div>
                </div>
        </div>
    )
}

export default GitMenu
