import { useContext, useEffect, useState, useRef } from 'react';
import Dropdown from './Dropdown';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Button, Popover, Input, Tooltip } from 'antd';
import { FilterOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';

const Operators = [
    {"value" : "=", "label" : "="},
    {"value" : "!=", "label" : "!="},
    {"value" : "<", "label" : "<"},
    {"value" : "<=", "label" : "<="},
    {"value" : ">", "label" : ">"},
    {"value" : ">=", "label" : ">="},
    {"value" : "IN", "label" : "IN"},
    {"value" : "NOT IN", "label" : "NOT IN"},
    {"value" : "LIKE", "label" : "LIKE"},
    {"value" : "IS", "label" : "IS"},
    {"value" : "IS NOT", "label" : "IS NOT"}
]

const LogicOperators = [
    {"value": "AND", "label": "AND"},
    {"value": "OR", "label": "OR"}
];

const Filters = ({ columnList, content, setSelectedNode  }) => {
    const {setSelectedNodeEdited, selectedNode} =  useContext(TableGraphContext);
    const [operatorSelected, setOperatorSelected] = useState("AND");
    const [dropdownColumnList, setDropdownColumnlist] = useState()
    const [columnSelected, setColumnSelected] = useState()
    const [comparisonSelected, setComparisonSelected] = useState()
    const [visible, setVisible] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState([])
    const [filterValue, setFilterValue] = useState('')
    const inputRef = useRef(null)

    //TODO: optimize
    useEffect(() => {
        const optionsArray = Object.entries(columnList).flatMap(([id, { model_name, column_list }]) =>
            column_list.map((columnName) => ({
                value: columnName,
                label: `${model_name}.${columnName}`, 
                model_name: model_name,
                model_id: id,
            }))
            );
            setDropdownColumnlist(optionsArray)
    }, [columnList])

    useEffect(() => {
        console.log(content)
        if (content) {
            setFiltersVisible(content.map(() => false))
        }
        handleVisibleChange(false)
    }, [content])

    const handleVisibleChange = (visible, i = null) => {
        if (i === null) {
            if (visible) {
                setColumnSelected(null)
                setComparisonSelected(null)
                setFilterValue()
            }
            setVisible(visible)
        } else {
            if (visible) {
                console.log(content)
                setColumnSelected({'value':content[i].column_name, 'model_id': content[i].model_id, 'model_name': content[i].model_name, 'label': content[i].model_name + '.' + content[i].column_name})
                setComparisonSelected(content[i].comparison)
                setFilterValue(content[i].filter_value)
            }
            setFiltersVisible(prev => prev.map((item, index) => index === i ? visible : item))
        }
    }

    const handleVisibleChangeWithIndex = (i) => (visible) => {
        handleVisibleChange(visible, i)
    }
    
    const handleClose = (i = null) => {
        if (i === null) {
            setVisible(false)
        }
        else {
            setFiltersVisible(prev =>  prev.map((item, index) => index === i ? false : item))
        }
    }

const updateFilters = (newFilters) => {
    setSelectedNode(prev => {
        return { ...prev, data: {...prev.data, filters: newFilters }}
    })
}

    const handleFocus = () => {
        if (filterValue === undefined) {
            setFilterValue('\'\'')
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.input.setSelectionRange(1, 1)
                }
            }, 0)
        }
    }

const handleSave = (i = null) => {
    const newFilter = {
        operator: content && content.length > 0 && i != 0 ? operatorSelected : null,
        model_id: columnSelected.model_id,
        model_name: columnSelected.model_name,
        column_name: columnSelected.value,
        comparison: comparisonSelected,
        filter_value: filterValue,
    };

    setSelectedNode(prev => {
        const updatedFilters = i === null ? [...prev.data.filters, newFilter] : prev.data.filters.map((item, index) => index === i ? newFilter : item);
        return { ...prev, data: {...prev.data, filters: updatedFilters }};
    });
    setSelectedNodeEdited(true);
    handleClose(i);
};

    const handleDelete = (i) => {
        const newFilters = selectedNode.data.filters.filter((item, index) => index !== i);

        if (newFilters.length > 0) {
            newFilters[0].operator = null;
        }
        updateFilters(newFilters);
        setFiltersVisible(prev => prev.filter((item, index) => index !== i ))
    }

    

    const filter = (i = null) => {
        return(
            <div style={{ width: '300px' }}>
{content && content.length > 0 && i != 0 ?   // Only show logic operator dropdown for non-first filters
                <div style={{ marginBottom: '10px' }}>
                    <Dropdown
                        selected={{ value: operatorSelected, label: operatorSelected }}
                        dropdownList={LogicOperators}
                        setSelected={(e) => setOperatorSelected(e.value)}
                        loading={false}
                        disabled={false}
                        clearable={true}
                        placeholder="Select logic..."
                    />
                </div> : <div></div>
            }
                <div style={{ 'marginBottom': '10px' }}>
                    <Dropdown
                        selected={columnSelected ? columnSelected : null}
                        dropdownList={dropdownColumnList}
                        setSelected={(e) => setColumnSelected(e)}
                        loading={false}
                        disabled={false}
                        clearable={true}
                        placeholder='Select column...'
                    />
                </div>
                <div style={{ 'marginBottom': '10px' }}>
                    <Dropdown
                        selected={comparisonSelected ? {value: comparisonSelected, label: comparisonSelected} : null}
                        dropdownList={Operators}
                        setSelected={(e) => setComparisonSelected(e.value)}
                        loading={false}
                        disabled={false}
                        clearable={true}
                        placeholder='Select comparison...'
                    />
                </div>
                <Input
                    ref={inputRef}
                    style={{ 'marginBottom': '10px' }}
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    onFocus={handleFocus}
                    placeholder="Filter value..."
                />
                <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={columnSelected && comparisonSelected && filterValue ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!columnSelected || !comparisonSelected || !filterValue} onClick={() => handleSave(i)} block>
                        Accept
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose(i)} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }
    return(
        <div>
            { content && content.map((item, i) => {
                return(
                    <div key={i}>
                        <Popover placement="rightBottom" content={filter(i)} trigger="click" visible={filtersVisible[i]} onVisibleChange={handleVisibleChangeWithIndex(i)}>
                            <button className='rounded-[4px] w-full py-[5px] bg-transparent px-[15px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                                <div className='flex align-center h-full w-full' >
                                   {i !== 0 && (
                                <div className='mr-1'>
                                    {`${item.operator}` || "AND"} 
                                </div>
                            )}
                            <div className=''>
                                {`${item.model_name}.${item.column_name} ${item.comparison} ${item.filter_value}`}
                            </div>
                                    <div className='grow '>
                                        <Tooltip title='Delete'>
                                            <button className='relative float-right hover:text-red-400 top-[-3px]' onClick={() => handleDelete(i)}>
                                                <DeleteOutlined/>
                                            </button>
                                        </Tooltip>
                                    </div>    
                                </div>
                            </button>
                        </Popover>
                    </div>
                )
            })}
            <Popover placement="rightBottom" content={filter()} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
                <div className='bg-gray-200 hover:bg-gray-300 rounded p-1 flex justify-center mt-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    add filter
                </div>
            </Popover>
        </div>
    )
};

export default Filters;
