const Tooltip = ({ message, children, side="" }) => {
    if (side == 'top') {
        return (
            <div className='flex group h-full w-full items-center justify-center'>
                <div className="peer">
                    {children}
                </div>
                <div className="fixed z-[1] scale-0  peer-hover:scale-100">
                    <div className="shadow-md select-none absolute bottom-5 right-[-24px] whitespace-nowrap rounded bg-white p-2 border-gray-300 border-[2px]"
                        style={{ zIndex: 10 }}>
                        {message}
                    </div>
                </div>
            </div>
        );
    }
    else if (side == 'left') {
        return (
            <div className='flex h-full w-full items-center'>
                <div className="peer">
                    {children}
                </div>
                <div className="fixed z-[1] scale-0  peer-hover:scale-100">
                    <div className="shadow-md select-none absolute right-1 top-[-20px] whitespace-nowrap rounded bg-white p-2 border-gray-300 border-[2px]" style={{zIndex: '10'}}>
                        {message}
                    </div>
                </div>
            </div>
        );
    }
    else if (side == 'bottom') {
        return (
            <div className='flex group h-full w-full items-center justify-center'>
                <div className="peer">
                    {children}
                </div>
                <div className="fixed z-[1] scale-0  peer-hover:scale-100">
                    <div className="shadow-md select-none absolute top-5 right-[-24px] whitespace-nowrap rounded bg-white p-2 border-gray-300 border-[2px]" style={{zIndex: '10'}}>
                        {message}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className='flex h-full w-full items-center justify-center'>
                <div className="peer">
                    {children}
                </div>
                <div className="fixed z-[1] scale-0  peer-hover:scale-100">
                    <div className="shadow-md select-none absolute left-5 top-[-18px] whitespace-nowrap rounded bg-white p-2 border-gray-300 border-[2px]" style={{zIndex: '10'}}>
                        {message}
                    </div>
                </div>
            </div>
        );   
    }
};

export default Tooltip
