import React, { useState, useEffect, useContext } from 'react';
import { ModelEditorContext } from '../../context/ModelEditorProvider';


const ConnectGit = () => {
    const {
        urlValue,
        setUrlValue,
        patValue,
        setPatValue,
        CloneRepo,
        connectGit,
        setConnectGit,
        testingRepo,
        connectGitError,
    } = useContext(ModelEditorContext);
    
    const saveAndTest = () => {
        CloneRepo()
    }

    if (!connectGit) {
        //button to start connecting git process has not been pressed
        return (
            <div className='w-full text-center pt-[25vh] select-none bg-white h-full'>
                <h1>No Repository Connected</h1>
                <h2>Artemis uses git to manage your teams dbt environment</h2>
                <button onClick={() => setConnectGit(true)} className='mt-[20px] px-4 py-3 text-[20px] rounded-lg border-gray-300 border-[1px] hover:bg-gray-200 hover:border-gray-400'> Get Started </button> 
            </div>
        )
    }
    

    return (
        (
            testingRepo ?
            //page to show when waiting for connection response from server
            <div className='w-full h-full bg-white'>
                <div className='pt-[25vh] text-center w-full'>
                    Attempting to clone repository...
                </div>
            </div>
            :
            <div className='w-full  h-full flex'>
                <div className='w-1/2 h-full bg-white pt-[5vh] relative'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 ml-10 cursor-pointer p-2 hover:bg-gray-200 rounded-lg mb-[12vh]" onClick={() => setConnectGit(false)}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                    <div className='mx-10'>
                    <h2>
                        Repository URL
                    </h2>
                    <input value={urlValue} onChange={(e) => setUrlValue(e.target.value)} placeholder='https://github...' className='border-[1px] rounded-lg border-gray-200 p-2 w-full mt-[10px]'/>

                    <h2 className='mt-[30px]'>
                        Personal Access Token
                    </h2>
                    <input value={patValue} onChange={(e) => setPatValue(e.target.value)} placeholder='token...' className='border-[1px] rounded-lg border-gray-200 p-2 w-full mt-[10px]' />
                    </div>
                    <br></br>
                    { connectGitError && 
                      <div className='w-full text-center text-[16px] px-[5vw] font-[500] text-red-400 mt-[20px]'>
                        Unable to clone repository, responded with error: {connectGitError}
                      </div>
                    }
                    <div className='w-full text-center absolute bottom-0 pb-[8vh]'>
                    <button className='cursor-pointer px-4 py-3 border-gray-200 border-[1px] rounded-lg hover:bg-gray-100 hover:border-gray-300' disabled={!patValue || !urlValue} onClick={() => saveAndTest()}>
                        Save & Test
                    </button>
                    </div>  
                </div>
                <div className='w-1/2 pt-[50px] px-10 h-[100%]'>
                    <h1 className='mb-[15px]'> How to set up your Github Repository</h1>
                    <h3>Retrieving repository url</h3>
                    <div> 1. On <a className="text-blue-400 hover:text-blue-700" href="https://github.com/settings/profile" target="_blank"> Github</a>, navigate to the repository of your dbt project. </div>
                    <div className=''> 2. Click the <span className='bg-gray-300 rounded-md px-[5px]'>{' <> Code'}</span> button in the top right corner. Copy the HTTPS url and paste it into the repository url input.</div>
                    <h3 className='mt-[15px]'>Generating Personal Access Token</h3>
                    1. Navigate to <a className="text-blue-400 hover:text-blue-700" href="https://github.com/settings/profile" target="_blank"> Github </a> and open your profile settings. This can be done on any page by clicking on your profile icon in the top right corner, then clicking Settings
                    <br/>
                    2. Click {'<>'} Developer Settings from the bottom of the sidebar, then open Fine-grained tokens in the Personal access tokens section
                    <br />
                    4. Generate a new token. Input name and set expiry date <div className='text-[10px]'> Note: New token will have to be generated and provided after expiry date to continue service.</div> 
                    <br />
                    <div className='pl-[15px]'>
                        For repository access, allow 'Only select repositories' and select the repo which your dbt project is tied to.
                    </div>
                    <div className='pl-[15px]'>
                        For permissions, allow the token to read and write to repository contents. To do this, open the 'Repository permissions' section and change the access to the Contents option to 'Read and Write'
                    </div>
                    <br />
                </div>
            </div>
        )
    )
}

export default ConnectGit
