import React, { useState, useEffect, useContext } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { TableManagementContext } from '../../context/TableManagementProvider';
import { Pagination } from 'antd';
import { AgentContext } from '../../context/AgentProvider';


const comingSoonItems = [
  { name: "API", comingSoon: true },
  { name: "PostgreSQL", comingSoon: true },
  { name: "CSV", comingSoon: true },
];

const highlightStyle = {
  border: '2px solid gray', // Example highlight style
};


const SourceEditor = ({ selectedNode, updateSourceInContext, handleUnsavedChanges }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedSourceType, setSelectedSourceType] = useState(null);
  const [codeInput, setCodeInput] = useState('');

  const [metabaseUrl, setMetabaseUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  // related to URL as source
  const [url, setUrl] = useState('');
  const [annotation, setAnnotation] = useState('');


  const { fetchTableColumns, setSelectedSource, fetchMetabaseContext, fetchURLContext } = useContext(AgentContext);

  const { filteredTableList, setFilteredTableList, tableList } = useContext(TableManagementContext);
  console.log(filteredTableList)
  const [selectedTables, setSelectedTables] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8; // Adjust as needed
  const [searchTerm, setSearchTerm] = useState('');
  const paginatedTables = () => {
    // Safely slice the list with fallback
    return (filteredTableList || []).slice((currentPage - 1) * pageSize, currentPage * pageSize);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const renderTableList = () => {
    return paginatedTables().map(table => (
    <div 
      className='mx-5 my-1 p-2 border border-gray-300 rounded-lg cursor-pointer transition duration-300 ease-in-out hover:scale-105' 
      key={table.name} 
      onClick={() => handleTableSelection(table)}
    >
      {table.schema}.{table.table}
    </div>

    ));
  };




const renderSelectedTables = () => {
  return (
    <div className="flex overflow-x-auto overflow-y-auto h-auto gap-4 p-3"> 
      { selectedTables.length > 0 ? selectedTables.map(table => (
        <div key={table.table}
             className="relative min-w-[300px] h-40 p-4 border border-gray-300 rounded-lg flex flex-col justify-between transition duration-500 ease-out hover:scale-105">
          <button onClick={() => handleDeleteTable(table)} className="absolute top-1 right-1 text-sm">
            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='mt-1 mr-1 w-4 h-4'>
              <path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
            </svg>
          </button>
          <span className="font-bold overflow-hidden text-ellipsis whitespace-nowrap mb-1">{table.schema}.{table.table}</span>
          <div className="overflow-y-auto h-full w-full border-t border-gray-300"> 
            {table.columns && table.columns.map(column => (
              <span key={column} className="block p-2 overflow-hidden text-ellipsis whitespace-nowrap">{column}</span> 
            ))}
          </div>
        </div>
      )) : 

        <div>
            No tables selected
        </div>

      }
    </div>
  );
};


const handleDeleteTable = (table) => {
  const updatedTables = selectedTables.filter(t => t.table !== table.table);
  setSelectedTables(updatedTables);
};

const handleTableSelection = async (table) => {
  if (!selectedTables.find(t => t.table === table.table)) {
    const columns = await fetchTableColumns(`${table.schema}.${table.table}`);
    const tableWithColumns = { ...table, columns: columns || [] };

    setSelectedTables(prevSelectedTables => {
      // Check again if the table is already selected
      if (!prevSelectedTables.find(t => t.table === table.table)) {
        return [...prevSelectedTables, tableWithColumns];
      } else {
        // If the table is already selected, return the previous state
        return prevSelectedTables;
      }
    });
  }
};


useEffect(() => {
  if (selectedNode && selectedNode.data) {
    // Set the selected source type
    if (selectedNode.data.type) {
      setSelectedSourceType(selectedNode.data.type);
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }

    // Set the code input if it exists
    if (selectedNode.data.code) {
      setCodeInput(selectedNode.data.code);
    }

    // Check if the selected node has tables data and set it
    if (selectedNode.data.tables) {
      const tablesWithSchemaAndColumns = selectedNode.data.tables.map(table => {
        return {
          schema: table.schema, // Assuming 'schema' is a property of each table object
          table: table.table,   // Assuming 'table' is the table name property of each table object
          columns: table.columns || []  // Assuming 'columns' is stored in each table object
        };
      });

      setSelectedTables(tablesWithSchemaAndColumns);
    }
if (selectedNode.data.metabase_config) {
      const { url, username, password } = selectedNode.data.data.metabase_config;
      setMetabaseUrl(url || '');
      setUsername(username || '');
      setPassword(password || '');
    } else {
      // Reset Metabase config fields if not present
      setMetabaseUrl('');
      setUsername('');
      setPassword('');
    }
  }
}, [selectedNode]);


  
  useEffect(() => {
    // Filter the table list based on the search term
    if(tableList){
    const filtered = tableList.filter(table => 
      table.table.toLowerCase().includes(searchTerm.toLowerCase()) || 
      (table.schema && table.schema.toLowerCase().includes(searchTerm.toLowerCase()))
    );
      setFilteredTableList(filtered);
      setCurrentPage(1); // Reset to first page on new search
    }
  }, [searchTerm, tableList]);

  const handleSourceSelection = (constant) => {
    const type = constant;
    updateSourceInContext({ ...selectedNode, data: { type } });
    setSelectedSourceType(type);
    setCurrentStep(2);
  };

const handleURLBuildClick = async () => {
  console.log(url)
  let context = await fetchURLContext(url, annotation)
  console.log(context)
   
};

const handleBuild = async () => {
  const tablesWithColumns = [];

  for (const table of selectedTables) {
    const columns = await fetchTableColumns(`${table.schema}.${table.table}`);
    
    // Create an object for each table with its schema, table name, and columns
    tablesWithColumns.push({
      schema: table.schema,
      table: table.table,
      columns: columns || []
    });
  }

  // Construct the source data object
  const sourceData = {
    id: selectedNode.id, // Generate a unique ID
    type: "source",
    dateCreated: new Date().toISOString(),
    lastUpdated: new Date().toISOString(),
    data: {
      type: selectedSourceType,
      tables: tablesWithColumns, // Use the array of table objects
    },
  };
  
  // Update the context with this new source
  setSelectedSource(sourceData);
  handleUnsavedChanges();
};

  const handleMetabaseBuildClick = async () => {
  if (selectedSourceType === 'Metabase') {

    let context = await fetchMetabaseContext(metabaseUrl, username, password)
    console.log(context)
  

    const metabaseSourceData = {
      id: selectedNode.id,
      type: selectedSourceType,
      data: {
        metabase_config: {
        url: metabaseUrl,
        username,
        password
      },
        context: context
      },
      dateCreated: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
    };
    updateSourceInContext({ ...selectedNode, data: metabaseSourceData });
  } else {
      updateSourceInContext({ ...selectedNode, data: { type: 'code', code: codeInput } });
    }
    handleUnsavedChanges();
  };

  const handleConstantClick = (constant) => {
    const type = constant;
    updateSourceInContext({ ...selectedNode, data: { type } });
    setSelectedSourceType(type);
    setCurrentStep(2); // Move to the second step
  };

  const renderFirstStep = () => {
  const sourceConstants = ['Metabase', 'JSON', 'BigQuery', ...comingSoonItems.map(item => item.name)];
    return (
<div className="grid grid-cols-2 gap-4">
      {sourceConstants.map((constant, index) => {
        const isComingSoon = comingSoonItems.some(item => item.name === constant && item.comingSoon);
        const isHighlighted = !isComingSoon && selectedSourceType === (constant === 'Demo Template' ? 'template' : 'code');

        return (
          <div key={index}
               className={`p-4 mr-[20px] ml-[20px] justify-center text-center border border-gray-300 rounded-lg mb-4 ${isHighlighted ? 'highlight-class' : ''}`}
               onClick={!isComingSoon ? () => handleConstantClick(constant) : null}
               style={isHighlighted ? highlightStyle : null}
               onMouseEnter={!isComingSoon ? (e) => e.currentTarget.style.transform = "scale(1.05)" : null}
               onMouseLeave={!isComingSoon ? (e) => e.currentTarget.style.transform = "scale(1)" : null}>
            {constant}
            {isComingSoon && (
              <div className="relative top-0 right-0 bg-gray-200 text-gray-700 text-xs rounded-bl-lg px-2 py-1">
                Coming Soon
              </div>
            )}
          </div>
        );
      })}
  </div>
    )
    // UI for selecting the source type
  };

  const renderSecondStep = () => {
  const hasFilteredTableList = filteredTableList && filteredTableList.length > 0;



if (selectedSourceType === 'Warehouse') {
  return (
    <div>
      {hasFilteredTableList || searchTerm != ''? (
        <div className="flex flex-col h-full">
          <div className="flex-1 overflow-auto ml-5 border-b border-gray-300">
            <h2 className="text-md font-bold">Selected Tables:</h2>
            {renderSelectedTables()}
          </div>
          <div className="flex-1 overflow-auto p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-col items-start">
                <Pagination
                  current={currentPage}
                  showSizeChanger={false}
                  pageSize={pageSize}
                  total={filteredTableList.length}
                  onChange={setCurrentPage}
                  className="mt-4"
                />
              </div>
              <input
                type="search"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search tables..."
                className="p-2 border border-gray-300 rounded"
              />
            </div>
            {renderTableList()}
          </div>
          <button 
            onClick={handleBuild} 
            className="border border-1 border-black hover:scale-105 font-bold py-2 px-10 rounded m-5"
          >
            Build
          </button>
        </div>
      ) : (
        <p>No tables found.</p>
      )}
    </div>
  );
}


    if (selectedSourceType === 'JSON') {
      return (
        <div className="modal bg-gray-500" style={{
          position: 'relative',
          top: '10%', left: '10%',
          width: '80%', height: '80%',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CodeMirror
            value={codeInput}
            onChange={(value) => setCodeInput(value)}
            extensions={[python()]}
            height="200px"
          />
          <button
            onClick={handleBuild}
            style={{
              marginTop: '20px',
              alignSelf: 'center'
            }}
            className="bg-gray-500 hover:bg-gray-700 text-white w-5 font-bold py-2 px-4 rounded"
          >
            Build
          </button>
        </div>
      );
    }


if (selectedSourceType === 'Metabase') {
  return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Metabase URL
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Metabase URL"
          value={metabaseUrl}
          onChange={(e) => setMetabaseUrl(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Username
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Password
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button
        onClick={handleMetabaseBuildClick}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Build
      </button>
    </div>
  );
}

  if (selectedSourceType === 'URL') {
    
 return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          URL
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Preview
        </label>
        <iframe
          src={url}
          title="URL Preview"
          className="w-full h-64 border rounded"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        ></iframe>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Annotations
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Enter annotations here"
          value={annotation}
          onChange={(e) => setAnnotation(e.target.value)}
        ></textarea>
      </div>
      <button
        onClick={handleURLBuildClick}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
      >
        Build
      </button>
    </div>
  );
  }


    return <div>Customization for {selectedSourceType}</div>;
  };

  return (
    <div className='overflow-auto mt-[25px]'>
      {currentStep === 1 && renderFirstStep()}
      {currentStep === 2 && renderSecondStep()}
    </div>
  );
};

export default SourceEditor;

