import React, {useContext, useEffect}  from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';
import Page from '../../components/Page';
import { ArtyChatContext } from '../../context/ArtyChatProvider';
import { BsSearch } from 'react-icons/bs';
import { UserContext } from '../../context/UserProvider';
import PageSetup from '../../components/Onboarding/PageSetup';
import WorkflowGrid from './WorkflowGrid'; // Adjust import paths as necessary
import { useHistory } from 'react-router-dom';


const WorkflowsComponent = () => {
    
    const history = useHistory()

    const { dags,
        getDags,
        createDag,
        setInterfaceLog,
        setNodes,
        setEdges,
        setOpenSidebar,
        filteredDags,
        setFilteredDags,
        git
    } = useContext(TableGraphContext);
    const {
        noWarehouse
    } = useContext(UserContext)

    const {
        setChat,
        setContextMessage,
        setChatList,

    } = useContext(ArtyChatContext)

    useEffect(() => {
        getDags();
        setOpenSidebar(false)
        setNodes()
        setEdges()
        setInterfaceLog([])
        setChat([])
        setContextMessage('')
        setChatList([{}])
    },[]);


    const onCreate = async (dag_name) => {
        await createDag(dag_name)
    }


    const searchChange = (value) => {
        if (value.length > 1) {
            setFilteredDags(dags.filter(item => (item.name.toLowerCase()).includes(value.toLowerCase())))
        }
        else {
            setFilteredDags(dags)
        }
    }

    function handleSearchSubmit(event) {
        event.preventDefault();
    }

    const WORKFLOWS_DOCS_URL = 'https://artemisdata.notion.site/Workflows-Overview-67bf2636e05f45baa85c5c5114f174c6'

    if ((noWarehouse !== null && noWarehouse) || (git.noGitConnection !== null && git.noGitConnection)) {
        return(
            <Page>
                <PageSetup gitRequired={true} databaseRequired={true} type={'Workflows'} />
            </Page>
        )
    }

    return (
        <Page>
            <div className="flex flex-col w-full h-full overflow-y-auto bg-white">
                <div className='w-full px-6 my-2'>
                    <div className = "py-4 rounded-lg flex items-center w-full">  
                        <h1 className="text-2xl font-bold select-none my-auto ml-0 px-2">Workflows</h1>
                        <div className = "flex flex-row ml-auto mr-0 gap-4">
                            <form className="searchbar-styling flex items-center my-[5px]" onSubmit={handleSearchSubmit}>   
                                <div className="relative w-full">
                                    <input type="search" className="bg-gray-50 border w-full rounded-[5px] border-gray-300 text-gray-900 text-sm block pl-10 px-2.5 py-[9.5px]" placeholder="Search" onChange={(e) => searchChange(e.target.value)} />
                                    <BsSearch className="absolute left-3 top-3 text-gray-400"></BsSearch>
                                </div>
                            </form>
                            <button className="border border-gray-300 w-[200px] my-1 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200" onClick = {() => {onCreate('placeholder name')}}>
                                <a className= "font-bold select-none">Create new</a>
                            </button>
                            <button className="text-gray-400 border border-gray-300 w-[200px] my-1 rounded-lg flex justify-center items-center bg-[#F7F7F7] cursor-not-allowed disabled">
                                <a className= "font-bold select-none cursor-not-allowed">Edit Config</a>
                            </button>
                             <button className="border border-gray-300 my-1 px-4 mr-2 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200"
                                onClick = {() => {
                                    history.push('workflows/schedule')
                                }}>
                                <a className="font-bold select-none">Schedule</a>
                            </button> 
                            <button className="border border-gray-300 my-1 px-4 mr-2 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200"
                                onClick = {() => {
                                    window.open(WORKFLOWS_DOCS_URL, '_blank');
                                }}>
                                <a className="font-bold select-none">Docs</a>
                            </button> 
                        </div>
                    </div>

                </div>
                {filteredDags ?
                    filteredDags.length > 0 ? (
                    <div className='w-full my-2'>
                        <div className = "py-4 rounded-lg flex items-center w-full">  
                            {
                                filteredDags && filteredDags.some(item => item.isFavorite) && (
                                <div>
                                    <WorkflowGrid workflows={filteredDags.filter(dag => dag.isFavorite)} />
                                </div>
                            )
                            }
                        </div>
                        <div className = "py-4 rounded-lg flex items-center w-full">  
                            {
                                filteredDags && (
                                    <WorkflowGrid workflows={filteredDags.filter(dag => !dag.isFavorite)} />
                                )
                            }
                        </div>
                    </div>
                    ): (
                    <div className='mt-[75px] text-center text-gray-500 text-[24px] font-600'>
                        No workflows found...
                    </div>
                        )
                    :
                    <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                        <div className='flex flex-col items-center'>
                            <div className='w-[100px] h-[100px]'>
                                <div className="page-loading-container">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div> 
                            </div>
                        </div>
                    </div>
            }
                
            </div>
        </Page>
    );
};

export default WorkflowsComponent;

