import { useContext, useEffect, useState } from 'react';
import { ArtyAIContext } from '../../context/ArtyAIProvider';
import { useAuthInfo } from '@propelauth/react';
import SavedChartsScreen from '../ArtyAI/ArtySavedChartsScreen';
import Sidebar from '../../components/Sidebar';
import Page from '../../components/Page';
import { DownOutlined } from '@ant-design/icons';
import { List, Spin } from 'antd'
import { Layout, Button, Radio, Skeleton } from 'antd';
import { PlusOutlined, AppstoreAddOutlined, BarChartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './style.scss'
import AddBoardModal from '../ArtyAI/addBoardModal.js';
import { useParams } from 'react-router-dom';


const Dashboard = () => {
  const { dashboard_id } = useParams()
  const {
    artyAISchemaList, 
    artyAITableList,
    getSavedPrompts,
    getSavedCharts,
    viewMode,
    setViewMode,
    savedCharts,
    setSavedChartsLoading,
    savedChartsLoading,
    warehouseType,
    artySidebarOpen,
    setArtySidebarOpen,
    setShowCharts,
    showCharts,
    chartBoards,
    getChartBoards,
    setSelectedChartBoard,
    setAddboardModalVisible,
    setSavedCharts,
    setDashboardId,
    dashboardId, 
  } = useContext(ArtyAIContext)

  const authInfo = useAuthInfo();
  const history = useHistory();
  const [selected, setSelected] = useState('dashboards')
  
  useEffect(() => {
    if (dashboard_id) {
      setDashboardId(dashboard_id)
    }
    else {
      setSelectedChartBoard()
    }
  }, [dashboard_id])

  const MenuChange = (val) => {
    if (val == 'charts') {
      history.push('/analytics')
      setSelectedChartBoard()
    }
    else {
      history.push('/analytics/dashboard')
    }
  }


  const itemClick = (item) => {
    setShowCharts(true)
    setSavedCharts({})
    setSelectedChartBoard(item)
    if (item.chart_board_id === dashboardId) {
      getSavedCharts(item.chart_board_id)
    }
    setDashboardId(item.chart_board_id)
    history.push(`/analytics/dashboard/${item.chart_board_id}`)
    setViewMode('saved_charts')
  }

  const DivName = () => {
    if (!showCharts) {
      return 'scrollable-div-hidden'
    }
    else {
      return 'scrollable-div-showing'
    }
  } 

  const setInitialState = () => {
    setShowCharts(false)
    setSavedChartsLoading(true)
    return <div className="flex"> <Spin spinning={savedChartsLoading} className='self-center mt-[30vh] mx-auto'/></div>
  }

  const addNewBoard = () => {
    setAddboardModalVisible(true)
  }

  useEffect(() => {
    if (authInfo.isLoggedIn && !chartBoards && !artyAISchemaList.length && warehouseType != 'postgres') {
      getChartBoards()

    }
    else if (authInfo.isLoggedIn && !chartBoards && !artyAITableList.length && warehouseType === 'postgres') {
      getChartBoards()
    }
  }, [authInfo])

  useEffect(() => {
    if (viewMode === 'saved') {
      getSavedPrompts()
    } else if (viewMode === 'saved_charts' && !savedCharts['data']) {
      setSavedChartsLoading(true)
      console.log('getting charts')
      // getSavedCharts()
    }
  }, [viewMode])

  const sidebarContent = () => {
    return (
        <div>
          
        <div className='sider-style overflow-y-hidden'> 
        <Radio.Group className='w-[93%] mb-[10px]' value={'dashboards'} onChange={(e) => MenuChange(e.target.value)}>
          <Radio.Button className='w-1/2' value='dashboards' style={{ borderRadius: '12px 0 0 12px', padding: '20px 30px 20px 30px', background: selected === 'dashboards' ? 'black' : 'white', color: selected === 'dashboards' ? 'white' : 'black'}}>
              <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                  <AppstoreAddOutlined style={{ transform: 'rotate(270deg)', fontSize: '22px'}}/>
                  <p style={{ fontSize: '15px', userSelect: 'none' }}>Dashboards</p>
              </div>
          </Radio.Button>
          <Radio.Button className='w-1/2' value='charts' style={{ borderRadius: '0 12px 12px 0', padding: '20px 30px 20px 30px', background: selected === 'charts' ? 'black' : 'white', color: selected === 'charts' ? 'white' : 'black'}}>
              <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                  <BarChartOutlined style={{ fontSize: '22px' }}/>
                  <p style={{ fontSize: '15px', userSelect: 'none' }}>Charts</p>
              </div>
          </Radio.Button>
        </Radio.Group>
              <div onClick={() => {setShowCharts(!showCharts) }} className=' text-[15px] font-[800] cursor-pointer' style={{userSelect: 'none'}}>
                <DownOutlined className='duration-[50ms]' style={showCharts ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', top:'-3px', position: 'relative'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', top:'-3px', position: 'relative'}}/>
                Dashboards
              </div>
          
          <div
          id="scrollableDiv"
          className={DivName()}
          >
            { chartBoards && 
              <List
                dataSource={chartBoards}
                renderItem={(item) => (
                  <List.Item key={item.name}  style={{height: '30px', userSelect: 'none'}} onClick={() => itemClick(item)}>
                      <div className='flex cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                        </svg>
                        <div className='ml-[5px]'>
                          {item.name}
                        </div>
                        
                      </div>
                    
                      
                  </List.Item>
                )}
              >
              <List.Item key={'new'}  style={{height: '30px', userSelect: 'none'}} onClick={() => addNewBoard()}>
                      <div className='flex cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-[10px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div className='ml-[5px] mt-[10px]'>
                          Add New
                        </div>
                      </div>
                  </List.Item>
              </List>
            }
          </div>
        </div>
        </div>

      );
  }
  return (
    <Page>
      <AddBoardModal/>
      <Sidebar setSidebarData = {()=>{}} openSidebar={artySidebarOpen} setOpenSidebar = {setArtySidebarOpen} sidebarContent = {sidebarContent()} removePadding={false}>
        <div className="bg-white w-full flex justify-center">
          <div className='w-[95%] m-[20px]'>
            <SavedChartsScreen setViewMode={setViewMode} /> 
          </div>
        </div>
      </Sidebar>
    </Page>
  )
};

export default Dashboard;