import React, { useState, useEffect, useContext } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import { AgentContext } from '../../context/AgentProvider';
import FunctionEditor from './FunctionEditor';
import SourceEditor from './SourceEditor';
import Output from './Output';

const ParameterCapsule = ({ index, parameter, onParameterChange }) => {
  const handleNameChange = (e) => {
    onParameterChange(index, 'value', e.target.value);
  };

  const handleDescriptionChange = (e) => {
    onParameterChange(index, 'description', e.target.value);
  };

  return (
    <div className="flex flex-col mr-2">
      <label className="font-medium">Name</label>
      <input
        type="text"
        value={parameter.value}
        onChange={handleNameChange}
        className="mb-2 p-1 border border-gray-300 rounded"
        placeholder="Parameter Name"
      />
      <label className="font-medium">Description</label>
      <input
        type="text"
        value={parameter.description}
        onChange={handleDescriptionChange}
        className="p-1 border border-gray-300 rounded"
        placeholder="Description"
      />
    </div>
  );
};

const highlightStyle = {
  border: '2px solid gray', // Example highlight style
};


const ContextualEditor = ({ selectedNode, handleUnsavedChanges, renderOutput }) => {
  const { updateFunctionInContext, updateSourceInContext, generateContextSuggestion } = useContext(AgentContext);
  
  //TODO: fix this to be one condition flow

  switch (selectedNode.type) {
    case 'function':
      return <FunctionEditor 
                selectedNode={selectedNode} 
                updateFunctionInContext={updateFunctionInContext} 
                handleUnsavedChanges={handleUnsavedChanges}
                generateContextSuggestion={generateContextSuggestion}
              />;
    case 'source':
      return <SourceEditor 
                selectedNode={selectedNode} 
                updateSourceInContext={updateSourceInContext} 
                handleUnsavedChanges={handleUnsavedChanges}

              />;
    case 'output':
      return <Output/>
    default:
      return <div>
        </div>;
  }
};

export default ContextualEditor;



const _ContextualEditor = ({ selectedNode, handleUnsavedChanges }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parameters, setParameters] = useState([]);
  const [code, setCode] = useState('');
useEffect(() => {
  if (selectedNode) {
    setName(selectedNode.name || '');
    setDescription(selectedNode.description || '');
    // Directly use the parameters as they are already in the correct format
    setParameters(selectedNode.parameters || []);
    setCode(selectedNode.code || '');
  } else {
    // Reset the form if no node is selected
    setName('');
    setDescription('');
    setParameters([]);
    setCode('');
  }
}, [selectedNode]);
  const [functionDefinition, setFunctionDefinition] = useState('');
  const { updateFunctionInContext,updateSourceInContext  } = useContext(AgentContext);

const [consoleMessages, setConsoleMessages] = useState([]);
const [selectedSourceType, setSelectedSourceType] = useState(null);

useEffect(() => {
  if (selectedNode && selectedNode.data && selectedNode.data.type) {
    setSelectedSourceType(selectedNode.data.type);
  }
}, [selectedNode]);

  const saveFunctionData = () => {
    console.log(code)
   if (!name.trim() || !code.trim()) {
      setConsoleMessages([...consoleMessages, "Contextual build failed: Name or Code is missing"]);
      return;
    }


    const functionData = {
      id: selectedNode.id, // Assuming each node has a unique ID
      name,
      description,
      parameters,
      code,
      type: selectedNode.type // Ensure the type is preserved
    };
    updateFunctionInContext(functionData);
    setConsoleMessages([...consoleMessages, "Build successful"]);
    handleUnsavedChanges();
  };

  // Helper function to construct the function definition line
  const updateFunctionDefinition = () => {
    const paramsString = parameters.join(', ');
    return `def ${name}(${paramsString}):`;
  };


const handleParameterChange = (index, field, newValue) => {
  let newParameters = [...parameters];
  if (typeof index === 'number') {
    newParameters[index] = { ...newParameters[index], [field]: newValue };
  }
  setParameters(newParameters);
};

// useEffect(() => {
//   const formattedName = name.replace(/\s+/g, '_');
//   const paramsString = parameters.join(', ');
//   const functionDefinitionLine = `def ${formattedName}(${paramsString}):`;
//   setCode(`${functionDefinitionLine}\n${code.split('\n').slice(1).join('\n')}`);
// }, [name, parameters]);



  const addParameter = () => {
    setParameters([...parameters, '']);
  };

  const constructFunctionDefinition = () => {
    const formattedName = name.replace(/\s+/g, '_');
    const paramsString = parameters.join(', ');
    return `def ${formattedName}(${paramsString}):`;
  };


  const handleParameterBlur = () => {
    setFunctionDefinition(constructFunctionDefinition());
  };
const renderNodeEditor = () => {
  if (!selectedNode) return <div>Select a node to edit</div>;

  switch (selectedNode.type) {
    case 'function':
      return renderFunctionEditor();
    case 'source':
      return renderSourceEditor();
    default:
      return <div>Unsupported node type</div>;
  }
};

  const renderConsoleMessages = () => (
    <div className="border border-black p-2 m-5 mt-5 h-[250px] max-h-[250px] overflow-auto">
      {consoleMessages.map((message, index) => (
        <div key={index}>{message}</div>
      ))}
    </div>
  );

  const [showCodeModal, setShowCodeModal] = useState(false);
  const [codeInput, setCodeInput] = useState('');

const handleConstantClick = (constant) => {
  const type = constant === 'Demo Template' ? 'template' : 'code';
  updateSourceInContext({ ...selectedNode, data: { type } });
  setSelectedSourceType(type); // Update local state
};

  const handleBuildClick = () => {
    // Update context with Code data
    updateSourceInContext({ ...selectedNode, data: { type: 'code', code: codeInput } });
    setShowCodeModal(false);
  };

const comingSoonItems = [
  { name: "Big Query", comingSoon: true },
  { name: "API", comingSoon: true },
  { name: "PostgreSQL", comingSoon: true },
  { name: "CSV", comingSoon: true },
];

const renderSourceEditor = () => {
  const sourceConstants = ['Demo Template', 'Code', ...comingSoonItems.map(item => item.name)];

  return (
    <div className="grid grid-cols-2 gap-4">
      {sourceConstants.map((constant, index) => {
        const isComingSoon = comingSoonItems.some(item => item.name === constant && item.comingSoon);
        const isHighlighted = !isComingSoon && selectedSourceType === (constant === 'Demo Template' ? 'template' : 'code');

        return (
          <div key={index}
               className={`p-4 mr-[20px] ml-[20px] justify-center text-center border border-gray-300 rounded-lg mb-4 ${isHighlighted ? 'highlight-class' : ''}`}
               onClick={!isComingSoon ? () => handleConstantClick(constant) : null}
               style={isHighlighted ? highlightStyle : null}
               onMouseEnter={!isComingSoon ? (e) => e.currentTarget.style.transform = "scale(1.05)" : null}
               onMouseLeave={!isComingSoon ? (e) => e.currentTarget.style.transform = "scale(1)" : null}>
            {constant}
            {isComingSoon && (
              <div className="relative top-0 right-0 bg-gray-200 text-gray-700 text-xs rounded-bl-lg px-2 py-1">
                Coming Soon
              </div>
            )}
          </div>
        );
      })}
      {showCodeModal && (
        <div className="modal bg-gray-500" style={{
          position: 'relative',
          top: '10%', left: '10%',
          width: '80%', height: '80%',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <CodeMirror
            value={codeInput}
            onChange={(value) => setCodeInput(value)}
            extensions={[python()]}
            height="200px"
            // ... other CodeMirror props
          />
          <button
            onClick={handleBuildClick}
            style={{
              marginTop: '20px',
              alignSelf: 'center'
            }}
          >
            Build
          </button>
        </div>
      )}
    </div>
  );
};


  const renderFunctionEditor = () => (
    <div className="flex flex-col h-full">
    <label className="mb-2 font-medium">Name</label>
    <input
      className="p-2 border border-gray-300 rounded mb-4"
      value={name}
      onChange={(e) => setName(e.target.value)}
      placeholder="Name"
    />
    <label className="mb-2 font-medium">Description</label>
    <textarea
      className="p-2 border border-gray-300 rounded mb-4"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder="Description"
    />      
    <label className="mb-2 font-medium">Parameters</label>
      <div className="flex overflow-x-auto mb-4">
{parameters.map((param, index) => (
  <ParameterCapsule
    key={index}
    index={index}
    parameter={param}
    onParameterChange={handleParameterChange}
  />
))}
        <button 
          onClick={addParameter} 
          className="border border-dashed border-gray-300 rounded-full px-3 py-1"
        >
          +
        </button>
      </div>
      <label className="mb-2 font-medium">Function Definition</label>
      <CodeMirror
        value={code}
        height="200px"
        extensions={[python()]}
        options={{
          theme: 'default',
          mode: 'python',
          lineNumbers: true,
          editable: (pos) => pos.line > 0
        }}
  onChange={(newValue, viewUpdate) => {
    setCode(newValue);
  }}
      />

      <button
        onClick={saveFunctionData}
        className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-10 mx-5 rounded"
      >
        Build
      </button>
      {renderConsoleMessages()} {/* Console Output Section */}

    </div>
  );

  return (
    <div className="flex flex-col h-full w-full p-4">
      {renderNodeEditor()}

    </div>
  );
};


