import { useContext } from 'react';
import SqlSidebar from './sql/sqlSidebar';
import DbtSidebar from './dbt/dbtSidebar';
import DatabaseSidebar from './Database/DatabaseSidebar';
import { EditorContext } from '../../../context/EditorProvider';
import Tooltip from '../../Tooltip/Tooltip';

const options = [
    {
        'label': 'DBT',
        'icon': (
            <svg height="18" width="18" strokeWidth='1' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                <path d="M12.6741 7.6225L12.856 7.5173L12.9568 7.3329L13.3753 6.56716L13.3753 6.56701L14.6253 4.28094C15.0438 3.57574 15.0214 2.63232 14.5213 1.95243C13.8664 0.969905 12.5966 0.826418 11.6995 1.29126L11.6909 1.29571L11.6825 1.30038L8.64896 2.9729L8.6406 2.97751L8.63237 2.98232C8.41296 3.11067 8.16962 3.17987 7.93482 3.18192L7.93221 3.18195C7.69763 3.18482 7.4563 3.12079 7.24233 2.99788L7.23456 2.99341L7.22668 2.98914L4.30427 1.40194C3.45758 0.877989 2.38284 1.04499 1.70299 1.70074C1.0153 2.35498 0.804602 3.43302 1.3116 4.302L2.95882 7.31115L2.96474 7.32196L2.97101 7.33257C3.09553 7.54329 3.16227 7.78172 3.16301 8.01475C3.16231 8.26148 3.09622 8.51243 2.97291 8.73548L2.97287 8.73546L2.96882 8.74299L1.3534 11.7456C0.827754 12.7025 1.05522 13.9658 2.03194 14.604C2.70142 15.0472 3.58477 15.0848 4.28873 14.7217L4.29721 14.7173L4.30557 14.7127L7.45315 12.9861L7.51326 12.9532L7.56292 12.9127L7.57237 12.9055C7.58333 12.8973 7.60206 12.8838 7.62697 12.8673C7.67846 12.8332 7.74755 12.7923 7.82266 12.7591C7.96031 12.6983 8.0194 12.7039 8.02822 12.7044C8.65116 13.0355 9.28777 13.3529 9.86099 13.6386C9.99509 13.7055 10.1257 13.7706 10.2519 13.8338C10.942 14.1793 11.4686 14.4518 11.7633 14.6463L11.7786 14.6564L11.7945 14.6658C12.6975 15.2002 13.7825 14.9106 14.3952 14.1982C14.9982 13.5115 15.1828 12.4559 14.6714 11.5956L12.5298 7.70594L12.6741 7.6225Z" stroke="currentColor" stroke-width="1" />
                <circle cx="7.75" cy="7.75" r="1.5" stroke="currentColor" strokeWidth="1" />
                <path d="M13.1252 8.75276L11.0667 5.10001" stroke="currentColor" strokeLinecap="round" strokeWidth="1" />
                <path d="M8.93881 13.1824L5.29962 11.1" stroke="currentColor" strokeLinecap="round" strokeWidth="1"/>
            </svg>
        )
    },
    {
        'label': 'SQL',
        'icon': (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" height="18" width="18">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
            </svg>

        )
    },
    {
        'label': 'Warehouse',
        'icon': (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" height="18" width="18">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
            </svg>
        )
    }
]

const BaseEditorSidebar = () => {
    const {
        editorMode,
        setEditorMode
    } = useContext(EditorContext)

    const handeModeClick = (idx) => {
        setEditorMode(idx)
    }

    const editorSidebarContent = () => {
        if (editorMode == 0) {
            return <DbtSidebar/>
        }
        if (editorMode === 1) {
            return <SqlSidebar/>
        }
        if (editorMode === 2) {
            return <DatabaseSidebar/>
        }
    }

    return (
        <div className='w-full h-full flex overflow-clip'>
            <div className='w-[50px] h-full border-r-[2px] flex-none'>
                <div className='px-1 pt-4'>
                    {options.map((item, idx) => 
                        <Tooltip message={item.label}>
                            <div className={`my-1 p-2 rounded-md cursor-pointer ${idx === editorMode ? 'bg-gray-300' : 'hover:bg-gray-200'}`} onClick={() => handeModeClick(idx)}>
                                    {item.icon}
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className='h-full grow overflow-clip'>
                {editorSidebarContent()}
            </div>

        </div>
    );
};

export default BaseEditorSidebar;
