import { useContext, useEffect, useState, useRef } from 'react';
import { EditorContext } from '../../context/EditorProvider';
import SqlEditor from './SqlEditor';
import ModelEditor from './ModelEditor';
import EditorResults from './EditorResults';
import EditorToolbar from './EditorToolbar';

const EditorPage = ({ tab }) => {
    
    const {
        getToolbarFunctions,
        editorHeight,
        setEditorHeight
    } = useContext(EditorContext)

    const resizeRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);

    const renderTabContent = (tab) => {
        switch (tab.type) {
            case 'sql':
                return <SqlEditor tab={tab} />;
            case 'dbt':
                return <ModelEditor tab={tab}/>;
            default:
                return <div>Unknown</div>;
        }
    }

    const startResizing = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsResizing(true);
        document.body.classList.add('body-cursor-resize-ns');
    };

    const resize = (e) => {
        if (isResizing) {
        const newHeight = Math.min(Math.max(e.clientY - 87, 100), window.innerHeight - 200); // Ensure minimum width
        setEditorHeight(newHeight);
        }
    };

    const stopResizing = () => {
        setIsResizing(false);
        document.body.classList.remove('body-cursor-resize-ns');
    };

    useEffect(() => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);

        return () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResizing);
        };
    }, [isResizing]);

    const toolbarFunctions = tab ? getToolbarFunctions(tab.type) : []

    return (
        <div className='h-full overflow-hidden'>
            <div className='relative pb-1 border border-b-2' style={{height: `${parseInt(editorHeight)}px`}}>
                {renderTabContent(tab)}
                <div className='absolute right-0 bottom-0'>
                    <EditorToolbar tab={tab} toolbarFunctions={toolbarFunctions} />
                </div>
            </div>
            <div
                onMouseDown={startResizing}
                className={`cursor-ns-resize w-full border-t-[6px] focus:border-gray-400 hover:border-gray-400 transition-[2000ms] ease-in-out ${isResizing && 'border-gray-400'}`}
                style={{ zIndex: 0}}
            />
            <div className='overflow-hidden' style={{height: `${window.innerHeight - 87 - parseInt(editorHeight)}px`}}>
                <EditorResults tab={tab}/>
            </div>
        </div>
    );
};

export default EditorPage;
