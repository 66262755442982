import { useContext, useEffect, useState, useRef } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import AnalyticsDropdown from './AnalyticsDropdown';
import { Button, Popover, Input, Tooltip } from 'antd';
import { FilterOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';

const Operators = [
    {"value" : "=", "label" : "Equal to (=)"},
    {"value" : "!=", "label" : "Not Equal To (!=)"},
    {"value" : "<", "label" : "Less than (<)"},
    {"value" : "<=", "label" : "Less than or equal to (<=)"},
    {"value" : ">", "label" : "Greater than (>)"},
    {"value" : ">=", "label" : "Greater than or equal to (>=)"},
    {"value" : "in", "label" : "In"},
    {"value" : "not in", "label" : "Not in"},
    {"value" : "like", "label" : "Like"}
]

const AnalyticsChartFilters = () => {
    const {
        filtersSelected,
        setFiltersSelected,
        analyticsColumnLoading,
        analyticsColumnList,
    } = useContext(AnalyticsContext)
    const [dummyRefreshState, setDummyRefreshState] = useState(false)
    const [columnSelected, setColumnSelected] = useState()
    const [operatorSelected, setOperatorSelected] = useState()
    const [value, setValue] = useState('')
    const [visible, setVisible] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState([])

    const handleVisibleChange = (visible, i = null) => {
        if (i === null) {
            if (visible) {
                setColumnSelected(null)
                setOperatorSelected(null)
                setValue('')
            }
            setVisible(visible)
        } else {
            if (visible) {
                setColumnSelected(filtersSelected[i].column)
                setOperatorSelected(filtersSelected[i].operator)
                setValue(filtersSelected[i].value)
            }
            var data = filtersVisible
            data[i] = visible
            setFiltersVisible(data)
        }
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleVisibleChangeWithIndex = (i) => (visible) => {
        handleVisibleChange(visible, i)

    }
    
    const handleClose = (i = null) => {
        if (i === null) {
            setVisible(false)
        } else {
            var data = filtersVisible
            data[i] = false
            setFiltersVisible(data)
        }
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleSave = (i = null) => {
        var data = filtersSelected
        if (i === null) {
            data.push({'column': columnSelected, 'operator': operatorSelected, 'value': value})
        } else {
            data[i] = {'column': columnSelected, 'operator': operatorSelected, 'value': value}
        }
        setFiltersSelected(data)
        handleClose(i)
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleDelete = (i) => {
        var data = filtersSelected
        data.splice(i, 1)
        setFiltersSelected(data)
        setDummyRefreshState(!dummyRefreshState)
    }


    const filter = (i = null) => {
        return(
            <div style={{ width: '300px' }}>
                <div style={{ 'marginBottom': '10px' }}>
                    <AnalyticsDropdown
                        selected={columnSelected}
                        dropdownList={analyticsColumnList}
                        setSelected={setColumnSelected}
                        loading={analyticsColumnLoading}
                        disabled={false}
                        clearable={true}
                    />
                </div>
                <div style={{ 'marginBottom': '10px' }}>
                    <AnalyticsDropdown
                        selected={operatorSelected}
                        dropdownList={Operators}
                        setSelected={setOperatorSelected}
                        loading={analyticsColumnLoading}
                        disabled={false}
                        clearable={true}
                    />
                </div>
                <Input
                    style={{ 'marginBottom': '10px' }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Filter value"
                />
                <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={columnSelected && operatorSelected && value ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!columnSelected || !operatorSelected || !value} onClick={() => handleSave(i)} block>
                        Save
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose(i)} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }
    return(
        <div>
            { filtersSelected && filtersSelected.map((selected, i) => {
                return(
                    <div>
                        <Popover placement="rightBottom" content={filter(i)} trigger="click" visible={filtersVisible[i]} onVisibleChange={handleVisibleChangeWithIndex(i)}>
                            <button className='rounded-[4px] w-full py-[5px] bg-transparent px-[15px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                                <div className='flex align-center h-full w-full' >
                                    <div className='max-w-[80%]'>{selected['column'].value + " " + selected['operator'].value + " " + selected['value']}</div>
                                    <div className='grow '>
                                        <Tooltip title='Delete'>
                                            <button className='relative float-right hover:text-red-400 relative top-[-3px]' onClick={() => handleDelete(i)}>
                                                <DeleteOutlined/>
                                            </button>
                                        </Tooltip>
                                    </div>    
                                </div>
                            </button>
                        </Popover>
                    </div>
                )
            })}
            <Popover placement="rightBottom" content={filter()} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
                <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                    <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                        <FilterOutlined style={{ fontSize: '16px' }}/> 
                        <div> Add Filter</div>
                    </div>
                </button>
            </Popover>
        </div>
    )
};

export default AnalyticsChartFilters;