import './style.scss';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, Card, Input, Tooltip } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { ConnectorContext } from '../../../context/ConnectorProvider';
import AddService from './AddService';

const ConntectorsModal = () => {
  const { loading, visible, setVisible, connectorsMeta, setCreateConnectorData, setResultStatus } =
    useContext(ConnectorContext);
  const [search, setSearch] = useState('');
  const [service, setService] = useState('');
  const [serviceView, setServiceView] = useState(false);

  useEffect(() => {
    setCreateConnectorData(null);
    if (!serviceView) {
      setResultStatus(false);
    }
  }, [serviceView, setCreateConnectorData, setResultStatus]);

  const handleClose = () => {
    setVisible(false);
    setResultStatus(false);
    setServiceView(false);
    setSearch('');
    setService('');
    setCreateConnectorData(null);
  };

  const handleSearch = items => {
    if (search === '') {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
  };

  const handleService = id => {
    let data = connectorsMeta.filter(s => s.id === id)[0];
    setService(data);
    setServiceView(true);
  };

  return (
    <Modal
      title="Create a connector"
      centered
      visible={visible}
      onOk={() => handleClose()}
      onCancel={() => !loading && handleClose()}
      width={1000}
      footer={[]}
    >
      {serviceView ? (
        <AddService service={service} serviceView={serviceView} setServiceView={setServiceView} />
      ) : (
        <div>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            className="modal-search"
            placeholder="Search.."
            prefix={<SearchOutlined />}
          />
          <div className="modal-container">
            {handleSearch(connectorsMeta).map((item, i) => (
              <Tooltip key={i} title={`Add New ${item.name} Connector`}>
                <Card
                  hoverable
                  style={{ width: 200 }}
                  cover={<img className="modal-icon" alt={item.name} src={item.icon_url} />}
                  className="modal-card"
                  onClick={() => handleService(item.id)}
                >
                  <p className="modal-title">{item.name}</p>
                </Card>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ConntectorsModal;
