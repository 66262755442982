
function FileContentListItem(props) {

    const { originalString, substringToHighlight } = props;
    let parts = []
    if (originalString && substringToHighlight) {
      parts = (originalString.toLowerCase()).split(substringToHighlight.toLowerCase());
    }

    return (
      <div className = "hover:bg-gray-200 rounded-md px-2 w-full break-word text-ellipsis overflow-hidden" style={{}}>
        {parts.map((part, index) => (
          <span key={index}>
            {part}
            {index !== parts.length - 1 && (
              <span style={{ color: '#4CB043' }}>{substringToHighlight}</span>
            )}
          </span>
        ))}

        
      </div>     
    );
  }
  
export default FileContentListItem;