import axios from 'axios';
export const API_URL = getApiUrl();
// import EventSource from 'eventsource'


class _LetoClient {
  token = '';

  constructor() {
    this.baseUrl = API_URL;
  }

  setToken(token) {
    this.token = token;
  }

  async getScheduledWorkflows() {
    const responsedata = await this.execute('get', `/schedule`);
    return responsedata;
  }

  async saveScheduledWorkflows(data) {
    const responsedata = await this.execute('post', `/schedule`, {'data': data});
    return responsedata;
  }

  async deleteScheduledWorkflow(scheduledId) {
    const responsedata = await this.execute('delete', `/schedule/${scheduledId}`);
    return responsedata;
  }

  async getWorkflowSchedulesConfigs() {
    const responseData = await this.execute('get', `/schedule/config`);
    return responseData;
  }

  async getWorkflowSchedulesFivetranGroups() {
    const responseData = await this.execute('get', `/schedule/fivetran/groups`);
    return responseData;
  }

  async getWorkflowSchedulesFivetranConnectors(groupId) {
    const responseData = await this.execute('get', `/schedule/fivetran/groups/${groupId}/connectors`);
    return responseData;
  }

  async saveTestFivetranSchedulesConfig(data) {
    const responsedata = await this.execute('post', `/schedule/config/fivetran`, {'data': data});
    return responsedata;
  }

  async buildContextCode(functions) {
    const responseData = await this.execute('post', `/context/`, functions);
    return responseData;
  }
  async deleteContextById(contextId) {
    return await this.execute('delete', `/contexts/${contextId}`);
  }
  async updateContextName(contextId, newName) {
    const data = { name: newName };
    return await this.execute('patch', `/contexts/${contextId}/name`, data);
  }

  async connectURL(url, annotation) {
    const urlCredentials = {
      url: url,
      annotation: annotation
    };
    return await this.execute('post', '/contexts/sources/url', urlCredentials);
  }

  async connectMetabase(url, username, password) {
    const metabaseCredentials = {
      url: url,
      username: username,
      password: password
    };
    return await this.execute('post', '/contexts/sources/metabase', metabaseCredentials);
  }

  async getTableColumns(tableName) {
    const responseData = await this.execute('get', `/contexts/sources/${tableName}`);
    return responseData;
  }  async getDags() {
    const responseData = await this.execute('get', `/dag/`);
    return responseData;
  }
  async getDag(dag_id) {
    const responseData = await this.execute('get', `/dag/${dag_id}`);
    return responseData;
  }

  async getNodeDescription(dag_id, node_id){
    const responseData = await this.execute('get', `/dag/${dag_id}/node/${node_id}/description`);
    return responseData;
  }
    // Create a new secret
    async createSecret(key, value) {
        const data = { key: key, value: value };
        return await this.execute('post', '/contexts/secrets/', data);
    }

    // Get all secrets for the organization
    async getSecrets() {
        return await this.execute('get', '/contexts/secrets/');
    }

    // Get a specific secret by key
    async getSecret(key) {
        return await this.execute('get', `/contexts/secrets/${key}`);
    }

    // Update an existing secret
    async updateSecret(key, newValue) {
        const data = { value: newValue };
        return await this.execute('put', `/contexts/secrets/${key}`, data);
    }

    // Delete a secret
    async deleteSecret(key) {
        return await this.execute('delete', `/contexts/secrets/${key}`);
    }
  async createDag(dag_name) {
    const responseData = await this.execute("post", `/dag/${dag_name}`, {});
    return responseData;
  }

  async deleteDag(dag_id) {
    const responseData = await this.execute("delete", `/dag/${dag_id}`, {});
    return responseData;
  }

  async updateDagName(dag_id, new_name) {
    const responseData = await this.execute("patch", `/dag/${dag_id}`, {'new_name': new_name});
    return responseData;
  }
  async getDagName(dag_id) {
    const responseData = await this.execute("get", `/dag/${dag_id}/name`);
    return responseData;
  }
  
  async updateDagFavorite(dag_id, favorite){
    const responseData = await this.execute("patch", `/dag/${dag_id}/favorite`,{'favorite': favorite});
    return responseData;
  }

  async getSeedInfo(dag_id, node_id, seed_name) {
    const responseData = await this.execute('get', `/dag/${dag_id}/node/${node_id}/seed/${seed_name}`);
    return responseData;
  }
    
  async getModelInfo(dag_id, node_id, model_path) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/init/source/model/${model_path}`);
    return responseData;
  }

  async duplicateModel(dag_id, node_id, model_path) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/duplicate/model/${model_path}`);
    return responseData;
  }
  
  async getSourceTableSchema(dag_id, node_id, table_name) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/init/source/table/${table_name}`);
    return responseData;
  }

  async workflowAI(dag_id, node_id, prompt) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/arty`, {prompt: prompt});
    return responseData;
  }

  async getLogicDbtModel(data, model_name = '') {
    const responseData = await this.execute('post', `/logic/${model_name}`, data);
    return responseData;
  }

  async getJoinInit(dag_id, node_id, data) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/init/logic`, data);
    return responseData;
  }

  async getJoinInitSafe(dag_id, node_id, data) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/init/logic/safe`, data);
    return responseData;
  }

  async getAggregateInit(dag_id, node_id, data) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/init/logic`, data);
    return responseData;
  }


  async executeDbtModel(dag_id, model_id, data) {
    const responseData = await this.execute('post', `/execute/${dag_id}/${model_id}`, data, 300000);
    return responseData;
  }

  async previewDagNode(dag_id, node_id) {
    const responseData = await this.execute('get', `/preview/${dag_id}/${node_id}`);
    return responseData;
  }

  async saveNodeChanges(dag_id, node_id, data) {
    const responseData = await this.execute('patch', `/dag/${dag_id}/node/${node_id}/save/edits`, data);
    return responseData;
  }

  async retryNodeParse(dag_id, node_id) {
    const responseData = await this.execute('post', `/dag/${dag_id}/node/${node_id}/retry/parse`);
    return responseData
  }

  async deleteNode(dag_id, node_id) { // #this removes the 'workflow' field in dbt_models collection 
    const responseData = await this.execute('delete', `/dag/${dag_id}/node/${node_id}`);
    return responseData;
  }

  async saveDAG(dag_id, data)
  {
    const responseData = await this.execute('post', `/dag/${dag_id}/save/metadata`, data);
    return responseData;
  } 

  async handleNodeRename(dag_id, node_id, new_name, old_name) {
    const responseData = await this.execute('patch', `/dag/${dag_id}/node/${node_id}/rename`, {new_name: new_name, old_name: old_name});
    return responseData;
  }

  async handleDescriptionChange(dag_id, node_id, description){
    const responseData = await this.execute('patch', `/dag/${dag_id}/node/${node_id}/description`,description);
    return responseData;
  }

  async handleCustomSchemaRename(dag_id, node_id, new_custom_schema) {
    const responseData = await this.execute('patch', `/dag/${dag_id}/node/${node_id}/schema`, {new_custom_schema: new_custom_schema});
    return responseData;
  }

  async setCustomMaterialization(dag_id, node_id, new_custom_materialization) {
    const responseData = await this.execute('patch', `/dag/${dag_id}/node/${node_id}/materialization`, {new_custom_materialization: new_custom_materialization});
    return responseData;
  }

  async executeDag(dag_id, data) {
    const responseData = await this.execute('post', `/execute/${dag_id}`, data, 300000);
    return responseData;
  }

  async sortDag(dag_id, data) {
    const responseData = await this.execute('post', `/dag/${dag_id}/sort`, data);
    return responseData;
  }
  
  async SSE_executeDag(dag_id, data) {
    let eventSource = await this.executeServerSentEvent('post', `/sse/execute/${dag_id}`, data)
    return yieldServerSentEventMessages(eventSource)
  }

  async SSE_executeDbtModel(dag_id, model_id, data = {}) {
    let eventSource = await this.executeServerSentEvent('post', `/sse/execute/${dag_id}/${model_id}`, data)
    return yieldServerSentEventMessages(eventSource)
  }

  async convertPipelineToDAG(data) {
    const responseData = await this.execute('post', `/arty/convertPipeline`, data);
    return responseData;
  }

  async promptArtyCopilot(dag_id, data) {
    return await this.executeServerSentEvent('post', `/sse/copilot/${dag_id}`, data);
  }

  async promptArtyChat(dag_id, chat_id, data) {
    const responseData = await this.execute('post', `/arty/${dag_id}/${chat_id}`, data);
    return responseData;
  }

  async promptArtyChatSSE(dag_id, chat_id, data) {
    let eventSource = await this.executeServerSentEvent('post', `/sse/arty/${dag_id}/${chat_id}`, data);
    return yieldServerSentEventMessages(eventSource);
  }

  async getChatName(data) {
    const responseData = await this.execute('post', `/arty/name`, data);
    return responseData;
  }

  async promptArtyDBTCopilotSSE(chat_id, data) {
    const eventSource = await this.executeServerSentEvent('post', `/sse/copilot/dbt/${chat_id}`, data);
    return yieldServerSentEventMessages(eventSource);
  }
  
  async compileDBTCopilot(chat_id, data) {
    const responseData = await this.execute('post', `/copilot/dbt/${chat_id}/execute`, data);
    return responseData;
  }

  async createArtyDBTCopilot() {
    const responseData = await this.execute('get', `/copilot/dbt/`);
    return responseData;
  } 

  async promptArtySQLCopilot(chat_id, data) {
    const responseData = await this.execute('post', `/copilot/sql/${chat_id}`, data);
    return responseData;
  }
  
  async createArtySQLCopilot() {
    const responseData = await this.execute('get', `/copilot/sql/`);
    return responseData;
  } 

  async promptArtyChatSql(dag_id,chat_id, data) {
    const responseData = await this.execute('post', `/arty/${dag_id}/${chat_id}/sql`, data);
    return responseData;
  }

  async getPinnedChats(dag_id) {
    const responseData = await this.execute('get', `/pinned/${dag_id}`);
    return responseData;
  }

  async createPinnedChat(chat_id) {
    const responseData = await this.execute('post', `/pinned/${chat_id}`);
    return responseData;
  }

  async deletePinnedChat(chat_id) {
    const responseData = await this.execute('delete', `/pinned/${chat_id}`);
    return responseData;
  }

  async getAllChats(dag_id) {
    const responseData = await this.execute('get', `/chats/${dag_id}`);
    return responseData;
  }

  async createArtyChat(dag_id) {
    const responseData = await this.execute('post', `/chats/${dag_id}`);
    return responseData;
  }

  async updateChatName(chat_id, chat_name) {
    const responseData = await this.execute("patch", `/chats/${chat_id}/${chat_name}`);
    return responseData;
  }

  async deleteChat(chat_id) {
    const responseData = await this.execute('delete', `/chats/${chat_id}`);
    return responseData;
  }
  
  /*
   * dbt model editor endpoints
   */

  async getDbtModelFile(model_id) {
    const responseData = await this.execute('get', `/dbt/${model_id}`);
    return responseData;
  }

  async getFileTree(all) {
    const responseData = await this.execute('get', `/dbt/?all=${all}`);
    return responseData;
  }
  async getFileContent(file_path) {
    const responseData = await this.execute('get', `/dbt/path/${file_path}`);
    return responseData;
  }

  async saveModelEdits(file_path, content) {
    const responseData = await this.execute('put', `/dbt/edit/${file_path}`, {data: content});
    return responseData;
  }
  
  async uploadSeedCSV(file) {
    return await this.executeFileUpload('post', '/dbt/seed', file);
  }

  async createNewFolder(id) {
    return await this.execute('post', `/dbt/files/${id}`, {type: 'folder'});
  }

  async createNewFile(id) {
    return await this.execute('post', `/dbt/files/${id}`, { type: 'file' });
  }

  async deleteItem(id) {
    return await this.execute('delete', `/dbt/files/${id}`);
  }

  async duplicateItem(id) {
    return await this.execute('post', `/dbt/files/${id}`, {type: 'duplicate'});
  }

  async getCurrentBranches() {
    const responseData = await this.execute('get', `/git/`);
    return responseData;  
  }

  async stashChanges(file_path) {
    const responseData = await this.execute('post', `/git/stash/${file_path}`)
    return responseData
  }
  
  async createAndPushBranch(data) {
    const responseData = await this.execute('post', `/git/`, data);
    return responseData;  
  }

async setCurrentBranch(branch_name, remote) {
    const url = `/git/checkout/${branch_name}?remote=${remote}`;
    const responseData = await this.execute('get', url);
    return responseData;  
}

  async getCommitStatus() {
    const responseData = await this.execute('get', `/git/stage`);
    return responseData;  
  }

  async getCurrentBranchContext() {
    //TODO: deprecate??, switch to status method
    const responseData = await this.execute('get', `/git/context`);
    return responseData;
  }
  
  async getCurrentBranchDiff() {
    const responseData = await this.execute('get', `/git/diff`);
    return responseData;
  }

  async setStageUnstageFiles(data) {
    const responseData = await this.execute('post', `/git/stage`, data);
    return responseData;
  }

  async getCurrentFileDiff(file) {
    const responseData = await this.execute('get', `/git/diff/${file}`);
    return responseData;
  }

  async commitStagedFiles(data) {
    const responseData = await this.execute('post', `/git/commit`, data);
    return responseData;
  }

  async pushRemoteBranch() {
    const responseData = await this.execute('post', `/git/push`, {'branch': 'active'});
    return responseData;
  }

  async pullRemoteBranch() {
    const responseData = await this.execute('get', `/git/pull`);
    return responseData
  }

  async fetchRemoteBranch() {
    const responseData = await this.execute('get', `/git/fetch`);
    return responseData
  }

  async generateSuggestion(item) {
    return await this.execute('post', `/dbt/copilot`, item);
  }

  async promptSuggestion(item, prompt) {
    return await this.execute('post', `/dbt/copilot`, {file: item.base, prompt: prompt});
  }

  async getDbtModel(model_id) {
    return await this.execute('get', `/dbt/model/${model_id}`);
  }

  async updateDbtFileName(path, name) {
    return await this.execute('put', `/dbt/rename`, {path: path, name: name});
  }

  async executeFile(file) {
    return await this.execute('post', `/dbt/file/execute`, {file_name: file}, 300000);
  }

  async previewModel(model_name) {
    return await this.execute('get', `/dbt/preview/${model_name}`)
  }

  async executeFileSSE(file) {
    let eventSource = await this.executeServerSentEvent('post', `/sse/dbt/file/execute`, { file_name: file });
    return yieldServerSentEventMessages(eventSource)
  }
  

  async cloneRepo(data) {
    return await this.execute('post', `/git/init`, data)
  }

  async fetchChat(id) {
    return await this.execute('get', `/chat/data/${id}`)
  }

  async findFileContent(search_string, all, include_dot_files) {
    return await this.execute('get', `/find/${search_string}/?all=${all}&include_dot_files=${include_dot_files}`)
  }
  async fetchSelectedNodeInfo(id, dag_id) {
    return await this.execute('get', `/dag/${dag_id}/node/${id}`)
  }
  async fetchContexts() {
    return await this.execute('get', `/contexts/`);
  }

  async createContext() {
    return await this.execute('post', '/contexts/', {});
  }

  async fetchContextById(contextId) {
    return await this.execute('get', `/contexts/${contextId}`);
  }

async updateContext(contextId, updatedData) {
  return await this.execute('put', `/contexts/${contextId}`, updatedData);
}

async generateContextSuggestion(contextId, data) {
  return await this.execute('post', `/contexts/${contextId}/copilot`, data);
}
  
  async acceptCopilotNode(dag_id, data) {
    return await this.execute('post', `/copilot/dag/${dag_id}/accept`, data)
  }

  async execute(method, path, data, timeout = 30000) {
    console.log(`${method}, ${path}`);
    let headers = {
      Authorization: 'Bearer ' + this.token,
      accept: 'application/json',
    };
    if (data) console.log(data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: data,
      timeout: timeout
    });
    console.log(response.status);
    if (response.status >= 400) throw Error(response.data);

    return response.data;
  }

  async executeFileUpload(method, path, file, data ={}) {
    let headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: formData,
    });

    if (response.status >= 400) throw Error(response.data);

    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      console.log(`${method}, ${path}, ${cursor}`);
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        console.log(response.data);
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }

  async executeServerSentEvent(method, path, data) {
    const response = await fetch(`${this.baseUrl}${path}`,{
      method: method,
      headers: {
        Authorization: 'Bearer ' + this.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    return response
  }
}

async function* yieldServerSentEventMessages(eventSource) {
  const decoder = new TextDecoder('utf-8')
  const reader = eventSource.body.getReader()
  var { value, done } = await reader.read();
  let buffer = '';
  let match;
  while (!done) {
    buffer += decoder.decode(value, { stream: true });
    const pattern = /data:\s({[\s\S]*?})\s*\n\s*\n/g;
    let lastIndex = 0
    while ((match = pattern.exec(buffer)) !== null) {
      const jsonString = match[1];
      lastIndex = pattern.lastIndex
      try {
        const message = JSON.parse(jsonString);
        yield message
      }
      catch (error) {
        console.log(error)
      }
    }
    buffer = buffer.slice(lastIndex);
    ({ value, done } = await reader.read())
  }
}

function getApiUrl() {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging')
    return 'https://graph-staging.artemisdata.io';
  
  else if (process.env.REACT_APP_ENVIRONMENT === 'production')
    return 'https://graph.artemisdata.io';

  return 'http://127.0.0.1:8080'
}

const LetoClient = new _LetoClient();
export default LetoClient;
