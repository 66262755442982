import { useContext, useEffect, useState, useRef } from 'react';
import { EditorContext } from '../../context/EditorProvider';
import SqlEditor from './SqlEditor';
import ModelEditor from './ModelEditor';
import EditorResults from './EditorResults';
import EditorToolbar from './EditorToolbar';
import EditorPage from './EditorPage';

const EditorPageManager = () => {
    
    const {
        tabList,
        activeTab,
    } = useContext(EditorContext)

    const [tab, setTab] = useState()

    useEffect(() => {
        setTab(tabList[activeTab])
    }, [tabList, activeTab])

    if(tabList.length === 0) {
        return <div></div>
    }

    return (
        <div className='h-full'>
            {
                tab ?
                    <EditorPage tab={tab}/>
                    :
                    <div>
                    </div>
            }
        </div>
    );
};

export default EditorPageManager;
