import React, { useContext, useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import { AgentContext } from '../../context/AgentProvider';
import CodeMirror from '@uiw/react-codemirror';
import { python } from '@codemirror/lang-python';
import AddNodeModal from './AddNodeModal';
import OutputConfig from './OutputConfig';
import Console from './Console';
import Uploader from './Uploader';


const Box = ({ content, onDelete, isSelected }) => {
  if (!content) return null;
  const renderFunctionContent = () => {
    const { name, description, parameters, code } = content;
    return (
      <div className='text-sm p-2'>
        <div className='text-md font-bold'>{name ? name : 'Unnamed Function'}</div>
        <div className='text-xs border-1 border-gray-600 border p-1 min-h-[25%] max-h-[65%] overflow-hidden'>{description ? description : <br/>}</div>
      </div>
    );
  };

  console.log(content)
  const renderNodeContent = () => {
    switch (content.type) {
      case 'source':
        return (
          <div className='w-full h-full p-2 justify-center'>
            <div className='font-bold w-full text-center'>{content.data?.type}</div>
            <div className='text-xs border-1 border-gray-600 border p-1 min-h-[20%] max-h-[65%] overflow-hidden'>{content.data?.tables ? content.data?.tables.map( (table) => (
              <div>
              {table.schema}.{table.table}
              </div>
            )) : <br/>}</div> 
          </div>
        );
      case 'function':
        return renderFunctionContent();
      case 'output':
        return (
          <div>
            <div>ID: {content.id}</div>
            <div>Data: {JSON.stringify(content)}</div>
          </div>
        );
      default:
        return <div>Unknown Node Type</div>;
    }
  };

  return (
    <div className={`relative mb-[15px] rounded-[20px] ml-2 mr-2 justify-center items-center h-[175px] w-[175px] 
                    ${isSelected ? 'border-2 border-gray-400' : 'border border-gray-300'}`}>
      <button
        onClick={(e) => onDelete(e, content.id)}
        className="absolute bottom-0 right-0 text-black font-bold px-1 rounded select-none cursor-pointer"
        style={{ transform: 'translate(-50%, -50%)' }} // Adjusting the transform to keep the button within the borders
      >
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-4 h-4'>
          <path fill="currentColor" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
        </svg>
      </button>
      <div className="flex flex-col justify-between h-full">
        {renderNodeContent()}
      </div>
    </div>
  );
};




const createNewNode = (type, data) => {
  return {
    id: String(Date.now()), // Unique ID based on timestamp
    type: type,
    dateCreated: new Date().toISOString(),
    lastUpdated: new Date().toISOString(),
    data
  };
};

const ScrollableList = ({ title, items, onAddNew, onDelete, onNodeClick, selectedNodeId }) => {
  return (
    <div className="flex flex-col mb-2">
      <h2 className="text-l font-bold mb-1">{title}</h2>
      <div className="flex p-2 overflow-y-hidden overflow-x-auto gap-4">
        {items.map(item => (
          <div key={item.id} onClick={() => onNodeClick(item)} className="transition duration-500 ease-out hover:scale-105">
            <Box content={item} onDelete={onDelete} isSelected={item.id === selectedNodeId} />
          </div>
        ))}
        <div className="relative hover:scale-105 mb-[15px] cursor-pointer rounded-[20px] ml-5 mr-5 flex justify-center items-center h-[175px] w-[175px] border border-gray-300"
             onClick={onAddNew}>
          <span className="text-2xl">+</span>
        </div>
      </div>
    </div>
  );
};


const Editor =  ({onNodeSelect, selectedNode, handleUnsavedChanges, hasUnsavedChanges, onRetry}) => {

  const { context, setContext, deleteItemById, generateContextSuggestion, updateFunctionInContext } = useContext(AgentContext);

  const [sources, setSources] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [outputs, setOutputs] = useState([]);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalNodeType, setModalNodeType] = useState(null); // 'source' or 'function'
 
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);

  const handleDelete = (e, itemId, itemType) => {
    e.stopPropagation();
    deleteItemById(itemId, itemType);
    handleUnsavedChanges();
  };

  useEffect(() => {
    if (context) {
      console.log(context)
      // Update states when the relevant parts of context change
      setSources(context.data?.sources || []);
      setFunctions(context.data?.functions || []);
      setOutputs(context.data?.outputs || []);
    }
  }, [context, context.data?.sources, context.data?.functions, context.data?.outputs]);

  const handleAddSource = () => {
    setModalNodeType('source');
    setIsModalOpen(true);
  };

  const handleAddFunction = () => {
    setModalNodeType('function');
    setIsModalOpen(true);
  };
  
  const handleModalSubmit = (modalData) => {
    if (modalNodeType === 'source') {
      setContext(prevContext => {
      // Ensure prevContext.data.sources is an array before spreading it
      const existingSources = Array.isArray(prevContext.data.sources) ? prevContext.data.sources : [];
      const updatedSources = [...existingSources, modalData];
      return {
        ...prevContext,
        data: {
          ...prevContext.data,
          sources: updatedSources,
        },
      };
    });
  } else if (modalNodeType === 'function') {
  setContext(prevContext => {
    // Ensure prevContext.data.functions is an array before spreading it
    const existingFunctions = Array.isArray(prevContext.data.functions) ? prevContext.data.functions : [];
    const updatedFunctions = [...existingFunctions, modalData];
    return {
      ...prevContext,
      data: {
        ...prevContext.data,
        functions: updatedFunctions,
      },
    };
  });

    }
    setIsModalOpen(false); // Close modal after submission
    handleUnsavedChanges();
  };
 const fetchSuggestion = async (user_prompt) => {
    setLoadingSuggestion(true)
   let data = {
      'user_prompt': user_prompt,
     'function_data' : {
    } 
   }
    console.log(data)
   let response = await generateContextSuggestion(data)
    const functionData = {
    id: String(Date.now()), // Unique ID based on timestamp
      name: response.name,
      description: response.description,
      parameters: response.parameters,
      code: response.code,
      type: 'function'
    };
    updateFunctionInContext(functionData);
    setLoadingSuggestion(false)

 }
  
  const handleAddOutput = () => {
    const newOutput = createNewNode('output', {}); // Empty data for new output
    setOutputs(prevOutputs => [...prevOutputs, newOutput]);
  };

  const handleNodeClick = (node) => {
    onNodeSelect(node);
  };

  const handleOutputClick = () => {
    onNodeSelect({'type': 'output'})
  };

  const handleSave = () => {
    // Update the context with new states
    setContext({
      ...context,
      data: {
        sources,
        functions,
        outputs
      }
    });
    handleUnsavedChanges();
  };
  const [selectedView, setSelectedView] = useState('live'); // Initialize selected view as 'live'

  const handleButtonClick = (view) => {

    // setSelectedView(view);

  };
  return (
    <div className="flex flex-col h-full w-full pt-5 pl-5 pr-5 ">
    { /*<h2 className="text-xl font-bold mb-1 mt-3">Agents [BETA] -- <a href='https://artemisdata.notion.site/Arty-Agents-Platform-BETA-e70b12e429184a6ca6edaa36e0f4fca8?pvs=4' target="_blank" rel="noopener noreferrer" className="underline">
        Docs
    </a></h2> */}
      
      <ScrollableList title="Context" items={sources} onAddNew={() => handleAddSource()} 
                  onDelete={(e, itemId) => handleDelete(e, itemId, 'sources')}
                      onNodeClick={handleNodeClick} selectedNodeId={selectedNode?.id} />
      <ScrollableList title="Functions" items={functions} onAddNew={() => handleAddFunction()} 
                    onDelete={(e, itemId) => handleDelete(e, itemId, 'functions')}
                      onNodeClick={handleNodeClick} selectedNodeId={selectedNode?.id} />
      

      <div className="relative mb-[25px] rounded-[20px] ml-5 mr-5 mt-5 h-[175px] w-full flex items-center justify-center cursor-pointer" >
  <div className='grid grid-cols-1'>
   {hasUnsavedChanges  ? 
    
        <button
          className="p-2 flex justify-center hover:scale-105 bg-gray-500 text-white rounded"
          onClick={onRetry}
        >
         
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>

        </button>
     : 
    <button
          className="p-2 flex justify-center hover:scale-105  border-2 border bg-green-300 rounded"
          onClick={handleOutputClick}
        >
           
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
</svg>

      </button>
  }

      <button
          className="p-3 hover:scale-105 border-2 border rounded flex justify-center"
          onClick={() => handleButtonClick('outputConfig')}
        >
           

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
</svg>


      </button>

      <button
          className="p-3 hover:scale-105 border-2 border rounded flex justify-center"
          onClick={() => handleButtonClick('outputConfig')}
        >
           

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
</svg>

      </button>

    <button
          className="p-3 hover:scale-105 border-2 border rounded flex justify-center"
          onClick={() => handleButtonClick('console')}
        >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>
      </button>
    <button
          className="p-3 hover:scale-105 border-2 border rounded flex justify-center"
          onClick={() => handleButtonClick('upload')}
        >
           
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
</svg>

      </button>
</div>
      <div className='border border-1 w-full h-full m-10'>

       {selectedView === 'outputConfig' && (
            <div className='m-5 justify-center w-full h-full flex'>
              <OutputConfig /> {/* Content for Output Config view */}
          </div>
        )}
   {selectedView === 'console' && (
            <div className='m-5 justify-center w-full h-full flex'>
            <Console/>
          </div>
        )}

   {selectedView === 'upload' && (
            <div className='border-2 border-dashed border-gray-300 justify-center w-full h-full flex'>
            <Uploader/>
          </div>
        )}
 
    </div>


        </div>



      <AddNodeModal open={isModalOpen} onClose={() => setIsModalOpen(false)} type={modalNodeType} onSubmit={handleModalSubmit} fetchSuggestion={fetchSuggestion} loadingSuggestion={loadingSuggestion} />

    </div>
  );
};

export default Editor;



