import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import { AgentContext } from '../../context/AgentProvider';
import Editor from './Editor';
import ContextualEditor from './ContextualEditor';
import AgentsNavbar from './AgentsNavbar';
import Output from './Output';

const ContextAdmin = () => {
  const { contextId } = useParams();
  const { fetchContextById, setContext, context, updateContext, updateContextName } = useContext(AgentContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedNode, setSelectedNode] = useState({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const handleUnsavedChanges = () => {
    setHasUnsavedChanges(true);
  };

  const openSelectedNode = (node) => {
    setSelectedNode(node);
  };
  
  
  useEffect(() => {
    const fetchContext = async () => {
      try {
        const response = await fetchContextById(contextId);
        setContext(response);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    };

    fetchContext();
  }, [contextId, fetchContextById]);



  // useEffect(() => {
  //   if (context.data && selectedNode.id) {
  //     const updatedNode = context.data.sources?.concat(context.data.functions).find(node => node?.id === selectedNode?.id);
  //     if (updatedNode) {
  //       setSelectedNode(updatedNode);
  //     }
  //   }
  // }, [context, selectedNode.id]);

  const updateName = async (newName) =>{
          await updateContextName(context._id, newName);
  }

  const handleRetry = async () => {

    await updateContext(context._id, context.data);

    setHasUnsavedChanges(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Render your context details here
  return (
  <Page>
    <AgentsNavbar updateContextName={updateName} name={context.name} />
    <div className="flex h-full"> {/* Ensures full screen height */}
      <div className="w-1/2 flex flex-col"> {/* 50% width and vertical split */}
        <div className="flex-1"> {/* 50% height of the left side */}
<Editor onNodeSelect={openSelectedNode} selectedNode={selectedNode} handleUnsavedChanges={handleUnsavedChanges} hasUnsavedChanges={hasUnsavedChanges} onRetry={handleRetry}/>
        </div>
    
    {/* 
        <div className="overflow-auto flex-1 bg-gray-200"> 
            <Output hasUnsavedChanges={hasUnsavedChanges} onRetry={handleRetry} />
        </div>
    */}

      </div>

        <div className="w-1/2 bg-gray border-t-1 border">
          <ContextualEditor handleUnsavedChanges={handleUnsavedChanges} selectedNode={selectedNode} />
        </div>

    </div>
  </Page>
  );
};

export default ContextAdmin;



