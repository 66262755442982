import { useState, useContext, useEffect } from 'react';

import ActionLink from '../ActionLink';
import Card from '../Card';
import { StripeContext } from '../../context/StripeContext';
import { SubscriptionContext } from '../../context/SubscriptionProvider';
import './style.scss';


const ConsumptionUnits = () => {
  const [cost, setCost] = useState(12);
  const [jobs, setJobs] = useState(100);
  const [maxJobs, setMaxJobs] = useState(150);

  const {
    credits,
  } = useContext(SubscriptionContext)

  const {
    purchaseCreditModalVisible,
    setPurchaseCreditModalVisible,
  } = useContext(StripeContext)

  const DisplayCredits = () => {
    if (credits != null) {
      if (credits >= 1000000) {
        return <div>{(Math.floor(credits / 1000000)).toFixed(1) + " M"}</div>
      }
      else if (credits >= 10000) {
        return <div>{(Math.floor(credits / 10000)).toFixed(1) + " K"}</div>
      }
      else {
        return <div>{(credits).toFixed(1)}</div>
      }
    }
  }

  

  return (
    <Card title="Remaining Credits" info="Artemis Credit Units" contentClass="consumption-content">
      <h1 className="credits">
        <DisplayCredits/>
      </h1>

      <div className="purchase-credits">
        <ActionLink text="Purchase credits" onClick={() => { console.log("true!"); setPurchaseCreditModalVisible(true) }}/>

      </div>
    </Card>
  );
};

export default ConsumptionUnits;
