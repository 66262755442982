import React, { useContext, useState, useEffect, Fragment, useRef } from 'react';
import Tree from './Tree';
import FileContentListItem from '../FileContentListItem';
import { Combobox, Transition } from '@headlessui/react'

import { BranchesOutlined, ReloadOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { BsFolder2 } from 'react-icons/bs'
import CSVUploadModal from './CSVUploadModal'
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import BranchToolbar from './BranchToolbar';
import './styles.scss'
import Tooltip from '../../../Tooltip/Tooltip';
import { EditorContext } from '../../../../context/EditorProvider';
import { Divider } from 'antd';
import SearchTree from './SearchTree';

const DbtSidebar = () => {
    const {
        git,
        openDiffTab,
        getFileTree,
        showAll,
        fileTree,
        setFileTree,
        setShowAll,
        filteredFiles,
        setFilteredFiles,
        onItemClicked,
        setFileStructure,
        getFiles,
        setConsoleMessages,
        findFileContent,
        setFileContentList,
        fileContentList,
        searchValue,
        setSearchValue,
        isCSVModalOpen, setIsCSVModalOpen,
    } = useContext(ModelEditorContext);

    const {
        editorMode,
        contextMenuType,
        setContextMenuType,
        menuPos,
        setMenuPos,
        menuItem,
        setMenuItem,
        openDbtFile
    } = useContext(EditorContext)

    const [branches, setBranches] = useState([
        { id: 0, name: 'main' },
        { id: 1, name: 'dev' },
        { id: 2, name: 'test' }, 
        { id: 3, name: 'local' }, 
    ])
    const inputRef = useRef(null)
    const [selected, setSelected] = useState({id: 0, name: 'main'})
    const [query, setQuery] = useState('')
    const [focused, setFocused] = useState()
    const [openStates, setOpenStates] = useState([false, false, false])
    const [filteredTree, setFilteredTree] = useState()

    const filteredBranches =
        query == undefined
        ? branches
        : branches.filter((branch) =>
                branch.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(query.toLowerCase().replace(/\s+/g, ''))
            )
    
    const handleChange = (val) => {
        setSelected(val)
        setQuery('')
        setFocused(false)
    }

      
    const [showCommitPreview, setShowCommitPreview] = useState(false)
    const [showBranchesPreview, setShowBranchesPreview] = useState(false)
    const [commitMessage, setCommitMessage] = useState('');
    const [newBranchName, setNewBranchName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [isInFileSearch, setIsInFileSearch] = useState(false)
    const [showOnlySql, setShowOnlySql] = useState(false);

    const handleCommitStagedChanges = () => {
        git.commitStagedChanges(commitMessage)
        setCommitMessage('')
    };
    
    useEffect(() => {
        getFileTree(showAll);
        searchChange(searchValue)
    }, [showOnlySql, showAll]);
    
    useEffect(() => {
        if (isInFileSearch && searchValue && searchValue.length >= 3) {
        findFileContent(searchValue.toLowerCase(), showOnlySql, showAll)
        }
    }, [isInFileSearch])

    
    const currentBranch = git.branchData && git.branchData.current_branch ?  git.branchData.current_branch : ""  

    const handleCheckout = async (branchName, remote) => {
        let id = uuidv4()
        if(remote){
            setConsoleMessages(prev => [...prev, {id: id, message: `Checking out remote branch ${branchName}... `}])

        } else {
            setConsoleMessages(prev => [...prev, {id: id, message: `Checking out to branch ${branchName}... `}])
        }
        const response = await git.switchBranch(branchName, remote)
        if (response)
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + response } : file))
        else
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + 'Done' } : file))

        getFileTree(showAll)
    }

    const onContextMenu = (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuItem(item)
        setMenuPos([event.clientX, event.clientY])
        setContextMenuType(1)
    };
    
    const handleCreate = async () => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, { id: id, message: `Creating new branch ${newBranchName}...` }])
        await git.createBranch(newBranchName)
        await handleCheckout(newBranchName, false)
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
        setNewBranchName('')
    }

    const toggleCommitPreview = () => {
        setShowCommitPreview(!showCommitPreview)
    }

    const toggleBranchesPreview = () => {
        setShowBranchesPreview(!showBranchesPreview)
    }

    const handleFileClicked = async (e, file_content) => {
        e.stopPropagation();
        openDbtFile(file_content);
    };

    const handleDropdown = (value) => {
        setShowDropdown(true);
        searchChange(value);
    };

    const handleStageFile = async (file) => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Staging changes to ${file}...`}])
        await git.stageUnstageFile(file, true)
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
    }

    const handleUnstageFile = async (file) => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Unstaging changes to ${file}...`}])
        await git.stageUnstageFile(file, false)
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
    }
    
    const handleSearchToggle = async () => {
        setShowOnlySql(!showOnlySql)
    }

    const searchChange = async (value) => {
        setSearchValue(value)
        if (!isInFileSearch) {
            if (value.length >= 2) {
                // let files = getFiles(fileTree).filter(item => (item.name.toLowerCase()).includes(value.toLowerCase()))
                // setFilteredFiles(files)
                const toggledList = {}
                setFilteredTree(fileTree.children ? fileTree.children.map(item => handleSearchFilter(item, value, toggledList)).filter(x => x) : [])
                setFileStructure(prev => ({...prev, ...toggledList}))

            }
            else {
                setFilteredTree()
            }
        }
        else {
            if (value.length >= 3) {
                await findFileContent(value.toLowerCase(), showOnlySql, showAll)
            }
            else {
                setFileContentList([])
                setSearchValue('')
            }
        }
    }

    const handleSearchFilter = (item, value, toggledList, force_include=false) => {
        if ((item.path.toLowerCase()).includes(value.toLowerCase())) {
            if (item.children) {
                const children = item.children.map(child => {
                    let val = handleSearchFilter(child, value, toggledList, true)
                    if (val) {
                        return val
                    }
                    else if (force_include) {
                        return child   
                    }
                }).filter(x => x)
                
                if (children.length) {
                    toggledList[item.path] = true
                    return { ...item, children: children, toggled: true }
                }
                toggledList[item.path] = false
                return item
            }
            toggledList[item.path] = false
            return item
        }
        else {
            if (item.children) {
                const children = item.children.map(child => {
                    let val = handleSearchFilter(child, value, toggledList, false)
                    if (val) {
                        return val
                    }
                    else if (force_include) {
                        return child
                    }
                }).filter(x => x)
                if (children.length) {
                    toggledList[item.path] = true
                    return {...item, children: children}
                }
                else if (force_include) {
                    toggledList[item.path] = false
                    return item
                }
            }
            else if (force_include) {
                toggledList[item.path] = false
                return item
            }
        }
    }

    function handleSearchSubmit(event) {
        event.preventDefault();
    }
    
    const handlePull = async () => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Pulling changes... `}])
        const response = await git.handlePull()
        if (response)
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + response } : file))
        else 
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + 'Done' } : file))
        
        getFileTree(showAll)
    }

    const handleStash = async (file) => {
        console.log(file)
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Stashing changes in ${file}... `}])
        const response = await git.stashFleChanges(file)
        if (response)
            setConsoleMessages(prev => prev.map((item) => item.id === id ? { ...item, message: item.message + response } : item))
        else 
            setConsoleMessages(prev => prev.map((item) => item.id === id ? { ...item, message: item.message + 'Done' } : item))
        
        getFileTree(showAll)
    }
    
    const handlePush = async () => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `Pushing changes... `}])
        const response = await git.handlePush()
        if (response)
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + response } : file))
        else  
            setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + 'Done' } : file))
        }

        const handleFetch = async () => {
        let id = uuidv4()
        setConsoleMessages(prev => [...prev, {id: id, message: `fetching changes...`}])
        await git.handleFetch()
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
        }
    
    return (
        <div className='h-full p-3 pt-6 overflow-y-auto overflow-x-hidden no-scrollbar'>
            <CSVUploadModal isOpen={isCSVModalOpen} onClose={() => setIsCSVModalOpen(false)} />
            <div className='mb-2 flex flex-row w-full items-center gap-3' onBlur={() => { setFocused(false); setQuery('')}}>
                <Combobox value={selected} onChange={handleChange}>
                    <div className="relative w-full">
                        <div className={`flex inset-y-0 right-0 items-center px-2 relative flex-grow cursor-default overflow-hidden border-[1px] border-gray-300 rounded-[5px] text-left ${focused && 'ring-2 ring-inset ring-indigo-600'}`} onClick={() => setFocused(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                <path fill="currentColor" d="M26 18a3.995 3.995 0 0 0-3.858 3H18a3.003 3.003 0 0 1-3-3v-4a4.951 4.951 0 0 0-1.026-3h8.168a4 4 0 1 0 0-2H9.858a4 4 0 1 0 0 2H10a3.003 3.003 0 0 1 3 3v4a5.006 5.006 0 0 0 5 5h4.142A3.994 3.994 0 1 0 26 18Zm0-10a2 2 0 1 1-2 2a2.002 2.002 0 0 1 2-2ZM6 12a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2Zm20 12a2 2 0 1 1 2-2a2.003 2.003 0 0 1-2 2Z"/>
                            </svg>
                            <Combobox.Input
                                className="input-custom border-none outline-none w-full py-1 pl-3 bg-inherit leading-5 text-gray-600"
                                placeholder={selected.name}
                                value={query}
                                onChange={(event) => setQuery(event.target.value)}
                                ref={inputRef}
                                type='text'
                                autoComplete='off'
                            />
                        </div>
                        <Transition
                            as={Fragment}
                            show={focused}
                            className="z-[99] shadow-md"
                            afterLeave={() => { inputRef.current?.blur(); setQuery('') }}
                        >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1" onMouseDown={(e) => e.preventDefault()}>
                                {focused && filteredBranches.length === 0 && query !== '' ? (
                                    <div className="relative cursor-default select-none px-4 py-2 text-gray-600">
                                        Create new branch: {query}
                                    </div>
                                ) : (
                                    focused && filteredBranches.map((branch) => (
                                        <Combobox.Option
                                            key={branch.id}
                                            className='relative cursor-default select-none rounded-lg mx-1 pl-2 p-2 pr-4 text-gray-600 hover:bg-gray-200'
                                            value={branch}
                                        >
                                            <span className={`block truncate`}>
                                                {branch.name}
                                            </span>
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </Combobox>
                <div className='flex flex-row text-center items-center justify-center gap-1'>
                    <Tooltip message="Sync" side="right">
                        <div className='w-[25px] h-[25px] rounded-lg hover:bg-gray-200 flex items-center justify-center cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div>
                    </Tooltip>
                    <Tooltip message="New Branch" side="right">
                        <div className='w-[25px] h-[25px] rounded-lg hover:bg-gray-200 flex items-center justify-center cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="text-gray-600 ml-[10px]">
                {git.branchStatus.ahead} ahead, {git.branchStatus.behind} behind
            </div>
            <div className='select-none ml-[10px]'>
                {git.commitStatus.staged && git.commitStatus.staged.length > 0 && 
                    <div>
                        <div className='flex items-center text-gray-600' onClick={() => setOpenStates(prev => { prev[0] = !prev[0]; return [...prev] })}>
                            <DownOutlined 
                              className={'duration-[50ms]'}
                              style={{
                                transform: openStates[0] ? 'rotate(0deg)' : 'rotate(-90deg)',
                                marginRight: '5px',
                                marginLeft: '0px',
                                top: '0px',
                                position: 'relative',
                                fontSize: '12px'
                              }}
                            />
                            {git.commitStatus.staged.length} staged changes
                        </div>
                        {openStates[0] ?
                            <div>
                                {
                                    git.commitStatus.staged.map(item =>
                                        <div className={`group flex items-center p-1 truncate cursor-pointer rounded-lg hover:bg-gray-200 ${menuItem && menuItem.file === item.file ? 'bg-gray-200' : ''}`} onContextMenu={(e) => onContextMenu(e, { file: item.file, type: 'staged' })}>
                                            <div className={`mr-2 flex-none rounded-lg w-6 text-center py-[2px] border-[1px] border-gray-200 group-hover:border-gray-400 ${menuItem && menuItem.file === item.file ? 'border-gray-400' : ''}`}>
                                                {item.status}
                                            </div>
                                            {item.file}
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div/>
                        }
                    </div>
                }
                {git.commitStatus.unstaged && git.commitStatus.unstaged.length &&
                    <div>
                        < div className='flex items-center text-gray-600' onClick={() => setOpenStates(prev => { prev[1] = !prev[1]; return [...prev] })}>
                            <DownOutlined 
                              className={'duration-[50ms]'}
                              style={{
                                transform: openStates[1] ? 'rotate(0deg)' : 'rotate(-90deg)',
                                marginRight: '5px',
                                marginLeft: '0px',
                                top: '0px',
                                position: 'relative',
                                fontSize: '12px'
                              }}
                            />
                            {git.commitStatus.unstaged.length} unstaged changes
                        </div>
                        {openStates[1] ?
                            <div>
                                {
                                    git.commitStatus.unstaged.map(item =>
                                        <div className={`group flex items-center p-1 truncate cursor-pointer rounded-lg hover:bg-gray-200 ${menuItem && menuItem.file === item.file ? 'bg-gray-200' : ''}`} onContextMenu={(e) => onContextMenu(e, { file: item.file, type: 'unstaged' })}>
                                            <div className={`mr-2 flex-none rounded-lg w-6 text-center py-[2px] border-[1px] border-gray-200 group-hover:border-gray-400 ${menuItem && menuItem.file === item.file ? 'border-gray-400' : ''}`}>
                                                {item.status}
                                            </div>
                                            {item.file}
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div/>
                        }
                    </div>    
                }
                {git.commitStatus.untracked && git.commitStatus.untracked.length &&
                    <div className='w-full'>
                        <div className='flex items-center text-gray-600' onClick={() => setOpenStates(prev => { prev[2] = !prev[2];  return [...prev]})}>
                            <DownOutlined 
                              className={'duration-[50ms]'}
                              style={{
                                transform: openStates[2] ? 'rotate(0deg)' : 'rotate(-90deg)',
                                marginRight: '5px',
                                marginLeft: '0px',
                                top: '0px',
                                position: 'relative',
                                fontSize: '12px'
                              }}
                            />
                            {git.commitStatus.untracked.length} untracked changes 
                        </div>
                        {openStates[2] ?
                            <div>
                                {
                                    git.commitStatus.untracked.map(item =>
                                        <div className={`group w-full flex items-center p-1 truncate cursor-pointer rounded-lg hover:bg-gray-200 ${menuItem && menuItem.file === item ? 'bg-gray-200' : ''}`} onContextMenu={(e) => onContextMenu(e, { file: item, type: 'untracked' })}>
                                            <div className={`mr-2 flex-none rounded-lg w-6 text-center py-[2px] border-[1px] border-gray-200 group-hover:border-gray-400 ${menuItem && menuItem.file === item ? 'border-gray-400' : ''}`}>
                                                A
                                            </div>
                                            {item}
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <div/>
                        }
                    </div>    
                }
            </div>

            <Divider className="my-[10px]" />

            <form className="searchbar-styling ml-[0px] pt-[5px] flex z-50 items-center my-[5px] w-full" onSubmit={handleSearchSubmit}>   
            <div className="relative w-full z-50 mb-1">
                <div className="flex flex-row w-full gap-3">
                <input 
                    type="search" 
                    className="bg-gray-50 bg-transparent border flex-grow rounded-[5px] border-gray-300 text-gray-900 text-sm block px-2.5 py-1" 
                    placeholder={isInFileSearch? "Search content in files" : "Search files by name"}
                    onClick={(e) => handleDropdown(e.target.value)}
                    onChange={(e) => searchChange(e.target.value)} 
                    onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        setShowDropdown(false);
                    }
                    }}
                />
            <div className = "flex flex-row text-center items-center justify-center gap-1">
                    <button onClick={() => setIsInFileSearch(false)}>
                        <BsFolder2 className={isInFileSearch? "hover:bg-gray-300 p-1 rounded-md" : "bg-gray-300 p-1 rounded-md"}style ={{ width: '25px', height: '25px'}}/>
                    </button>
                    <button onClick={() => setIsInFileSearch(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={isInFileSearch ? "relative w-[25px] h-[25px] p-1 rounded-md bg-gray-300" : "relative w-[25px] h-[25px] rounded-md hover:bg-gray-300 p-1"}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                    </button>
            </div>
                </div> 
                {isInFileSearch ?  
                <div>
                { fileContentList && fileContentList.length ? 
                <ul className ="h-full mb-[50px] overflow-y-scroll">
                    {searchValue && searchValue.length >= 3 && fileContentList.map((item) => (
                    <div className = "mt-[5px] text-gray-600">
                        <details open={true}>
                        <summary className = "list-none overflow-y-hidden flex flex-row">
                            <DownOutlined className='duration-[50ms]' style={{transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'4px', position: 'relative', fontSize:'12px'}}/>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="relative top-[3px] mr-[5px] w-[15px] h-[15px] min-w-[15px] min-h-[15px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                            <div className="overflow-x-hidden text-ellipsis cursor-default select-none">
                            {item.path}
                            </div>
                        </summary>
                            <div className = "ml-[5px] pl-[10px] border-l border-l-[2.4px] border-l-gray-400 cursor-pointer select-none">
                            {item.matches.map((match, matchIndex) => (
                                <li key={matchIndex} onMouseDown={(e) => handleFileClicked(e, item)}>
                                <FileContentListItem
                                    originalString={match}
                                    substringToHighlight={searchValue}
                                />
                                </li>
                            ))}
                            </div>
                        </details>
                    </div> 
                    ))}
                </ul>
                : (searchValue? <div className = "mt-[10px] ml-[5px] text-gray-600">No matching results.</div> :
                <></>)}
                </div> 
                : 
                searchValue && searchValue.length >= 2  && 
                <div className="h-full mb-[50px] overflow-y-scroll">
                {filteredTree && filteredTree.map((item) => 
                    <SearchTree data={item} setFileTree={setFileTree} setFilteredTree={setFilteredTree} first={true} mode={0} searchValue={searchValue}/>
                )
                // <ul>
                //     {filteredFiles.map(item => (
                //     <div className = "mt-[5px] text-gray-600 flex w-full" >
                //         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="relative top-[3px] mr-[5px] w-[15px] h-[15px] min-w-[15px] min-h-[15px]">
                //             <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                //         </svg>
                //         <div className="overflow-x-hidden text-ellipsis grow cursor-pointer">
                //         <li key={item.name} onClick={(e) => handleFileClicked(e, item)} onContextMenu={(e) => onContextMenu(e, item)}>
                //                 <FileContentListItem
                //                 originalString={item.path}
                //                 substringToHighlight={searchValue}
                //                 /> 
                //             </li>
                //         </div>
                //     </div> 
                //     ))}
                // </ul>
                // : <div className = "mt-[10px] ml-[5px] text-gray-600">No matching results.</div>
                }
                </div>
            }
            </div>
            </form>
            {!isInFileSearch && !(searchValue && searchValue.length >= 2) &&
                
                fileTree && fileTree.children && fileTree.children.map((item) => 
                      <Tree data={item} setFileTree={setFileTree} first={true} mode={0}/>
                    )            
            }
        </div>
    );
};

export default DbtSidebar;
