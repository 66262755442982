import React, { useState, useEffect, useRef, useContext} from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';
import { CloseOutlined, DownOutlined} from '@ant-design/icons';

const ConsoleLog = () => {
    const {
        interfaceLog,
    } = useContext(TableGraphContext);
    const [showFullConsoleLog, setShowFullConsoleLog] = useState({});

    const toggleFullConsoleLog = (logId) => {
        setShowFullConsoleLog(prevState => ({
            ...prevState,
            [logId]: !prevState[logId]
        }));
      };

    return (
        <>
            {interfaceLog.map((item) =>
            <div>
              <div className=' justify-end text-gray-500 right-[0px] pointer-events-auto'>
                {item.message + '...'}
                <div className={`inline-flex items-center ${item.status === 'Success' ? 'text-[#4cb043]' : item.status === 'Error' ? 'text-[#ff6963]' : ''}`}>
                  <span className="mr-2">{item.status}</span>
                  {item.full_console_log &&
                    <div onClick={() => toggleFullConsoleLog(item.id)}>
                      <DownOutlined className='duration-[50ms]' style={showFullConsoleLog[item.id] ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginLeft: '-5px', top: '-3px', position: 'relative', fontSize: '12px' } : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '0px', marginLeft: '-5px', top: '-3px', position: 'relative', fontSize: '12px' }} />
                    </div>
                  }
                </div>
                {showFullConsoleLog[item.id] ?

                  <div className='inline-blockoverflow-x-auto w-[100%]'>
                    <div className={`inline ${item.status === 'Error' ? 'text-[#ff6963]' : ''}`} style={{ whiteSpace: 'pre' }}>
                      {item.full_console_log}
                    </div>
                  </div>
                  :
                  (
                    item.console_log &&
                    <div className='inline-block overflow-x-auto w-[100%]'>
                      <div className={`inline ${item.status === 'Error' ? 'text-[#ff6963]' : ''}`} style={{ whiteSpace: 'pre' }}>
                        {item.console_log}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          )}
        </>
    )
}

export default ConsoleLog