import './style.scss';
import { useContext, useState, useEffect } from 'react';
import { ConnectorContext } from '../../../../context/ConnectorProvider';
import { Input, Button, Spin } from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import SuccessResult from './AddResult/SuccessResult';
import ErrorResult from './AddResult/ErrorResult/ErrorResult';

const AddService = ({ service, serviceView, setServiceView }) => {
  const { resultStatus, setResultStatus, loading, createConnectors, createConnectorData, setCreateConnectorData } =
    useContext(ConnectorContext);
  const [connectorName, setConnectorName] = useState('');
  const [inputStatus, setInputStatus] = useState('');

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    setCreateConnectorData(null);
  }, [setCreateConnectorData]);

  useEffect(() => {
    if (createConnectorData !== null) {
      setResultStatus(true);
    }
  }, [createConnectorData, setResultStatus]);

  const handleSubmit = () => {
    setInputStatus('');
    if (connectorName === '') {
      setInputStatus('error');
      return;
    }
    createConnectors(service.id, connectorName);
  };

  const handleOnChange = value => {
    setConnectorName(value);
    setInputStatus('');
  };

  return (
    <>
      <Spin spinning={loading} tip="Do not refresh the page... Performing data magic..." indicator={loadingIcon}>
        {resultStatus ? (
          (createConnectorData !== null && createConnectorData.code) === 'Success' ? (
            <SuccessResult setResultStatus={setResultStatus} />
          ) : (
            <ErrorResult setResultStatus={setResultStatus} />
          )
        ) : (
          <div className="add-service-container">
            <ArrowLeftOutlined className="add-service-back" onClick={() => !loading && setServiceView(false)} />
            <img className="add-service-icon" alt={service.name} src={service.icon_url} />
            <p className="add-service-title">{service.name}</p>
            <p className="add-service-info">{service.description}</p>
            <a href={service.link_to_docs} target="_blank">
              <p className="add-service-learn">
                {<InfoCircleOutlined />} Learn more about {service.name}
              </p>
            </a>
            <div className="add-service-input">
              <Input
                value={connectorName}
                status={inputStatus}
                disabled={loading}
                addonBefore="Schema Name:"
                onChange={e => handleOnChange(e.target.value)}
                onPressEnter={() => handleSubmit()}
              />
            </div>

            <div className="add-service-button">
              <Button style={{ background: '#537650', borderColor: '#537650' }}
                block size="default" type="primary" onClick={() => handleSubmit()}>
                Add Connector
              </Button>
            </div>
          </div>
        )}
      </Spin>
    </>
  );
};

export default AddService;
