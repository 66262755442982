import React, {useContext, useState, useEffect} from 'react';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import { UserContext } from '../../../../context/UserProvider';
import { SqlEditorContext } from '../../../../context/SqlEditorProvider';
import { EditorContext } from '../../../../context/EditorProvider';



const SqlRecentMenu = ({ menuItem, setMenuItem, setMenuPos }) => {
    const {
        saveRecentQuery,
        deleteRecentQuery
    } = useContext(SqlEditorContext);

    const {
        handleItemDelete
    } = useContext(EditorContext)

    const handleDelete = (e) => {
        e.stopPropagation()
        deleteRecentQuery(menuItem._id)
        setMenuPos()
        setMenuItem()
    }

    return (
        <div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => saveRecentQuery(menuItem._id)}>Save Query</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleDelete(e)}>Delete Query</div>
            </div>
        </div>
    )   
}

export default SqlRecentMenu
