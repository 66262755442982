import { useContext, useState } from "react";
import { OnboardContext } from "../../context/OnboardProvider";
import { Input, Radio, Space, Spin } from 'antd';

const ApproveCertificateModal = () => {   
    const {
        certificate,
        setCertificate,
        approveCertificate,
        applyCertificateLoading,
    } = useContext(OnboardContext)
    const [value, setValue] = useState();
    const onChange = (e) => {
        setValue(e.target.value);
    };
    
    const approveClick = () => {
        approveCertificate({'destination_id': certificate.destination_id, 'certificate': certificate.cert_list[value]})
    }

    return certificate.cert_list && certificate.cert_list.length ? (
        <div
            className="bg-gray-700 h-screen w-screen fixed z-50 top-0 left-0 bg-opacity-50">
            <div className="relative mx-auto w-full max-w-md h-full md:h-auto top-[25vh]">
                <div className="relative text-center bg-white w-[40vw] rounded-lg shadow dark:bg-gray-700">
                    <div className="p-6 text-center" >
                        <h1>Confirm TLS Certificate</h1>
                        <br/>
                        <div>We fetched the TLS certificates for this source but were unable to verify the certificate chain back to a trusted root authority. Please confirm with your DBA which certificate in the chain should be trusted.</div>
                        <br/>
                        <div>Select the trusted certificate below.</div>
                        <div style={{fontStyle: 'italic'}}>Note: we will automatically trust all end certificates issued by this trusted certificate.</div>
                        <br/>
                        <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                                {certificate.cert_list.map(item => (
                                    <Radio value={item.id}>
                                        <div style={{overflowWrap: "break-word", maxWidth: "30vw"}}>
                                            {item.name}
                                        </div>
                                    </Radio>
                                ))
                                }
                            </Space>
                        </Radio.Group>
                        <br />
                        <br />
                        <div className="flex w-full place-content-center">
                            <button type="button" className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5" onClick={() => { setCertificate({}) }}>Cancel</button>
                            <Spin spinning={applyCertificateLoading}>
                                <button type="button" disabled={value === undefined} className={value === undefined? "bg-gray-100 text-gray-500 ml-[10px] text-sm font-medium px-5 py-2.5 rounded-lg border border-gray-200" : "ml-[10px] text-white bg-[#1f422a] hover:bg-[#537650] rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5"} onClick={() => {approveClick()}}>Approve</button>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) :
    (<></>)
}

export default ApproveCertificateModal;