import React, { useContext } from 'react';
import { TableGraphProvider, TableGraphContext } from '../../context/TableGraphProvider';
import BlankSourceSidebar from './Sidebars/BlankSourceSidebar'
import SourceSidebar from './Sidebars/SourceSidebar'
import InitialSidebar from './Sidebars/InitialSidebar';
import LogicSidebar from './Sidebars/LogicSidebar'
import { Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import CopilotSidebar from './Sidebars/CopilotSidebar';
const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

export default () => { 
  const { getSelectedNode, selectedNode, editedNodes, getCopilotSelectedNode } = useContext(TableGraphContext);

  const getSidebarComponent = (sidebarType) => {
    switch(sidebarType){
      case 'default':
        return <InitialSidebar/>
      case 'blank-source':
        return <Spin indicator={loadingIcon} spinning={selectedNode && selectedNode.loading === true}><BlankSourceSidebar/></Spin>
      case 'source':
        return <Spin indicator={loadingIcon} spinning={selectedNode && selectedNode.loading === true}><SourceSidebar/></Spin>
      case 'logic':
        return <Spin indicator={loadingIcon} spinning={selectedNode && selectedNode.loading === true}><LogicSidebar /></Spin>
      case 'suggested':
        return <Spin indicator={loadingIcon} spinning={selectedNode && selectedNode.loading === true}><CopilotSidebar /></Spin>
      default:
        break;
    }
  }

  const StatusTag = () => {
    let color = '';
    let text = '';

    if (selectedNode?.id && ((editedNodes && selectedNode.id in editedNodes) || selectedNode?.edited)) {
        color = 'gold';
        text = 'Unsaved';
    } else if (selectedNode?.data?.status?.code === 'error') {
        color = 'red';
        text = 'Error';
    } else if (selectedNode?.data?.status?.code === 'executed') {
        color = 'green';
        text = 'Executed';
    }

    return color && text ? <Tag className='ml-2' color={color}>{text}</Tag> : null;
  };

  const getTitle = (sidebarType) => {

    switch (sidebarType) {
      case 'default':
        return <div> </div>
        break;
      case 'blank-source':
        return  <div className='w-[95%] p-[12px] font-[600]'>
                  Source
                  <StatusTag/>
                </div>
        break;
      case 'source':
        return  <div className='w-[95%] p-[12px] font-[600]'>
                  Source
                  <StatusTag/>
                </div>
        break;
      case 'logic':
        return  <div className='w-[95%] p-[12px] font-[600]'>
                  Logic
                  <StatusTag/>
                </div>
        break;
      case 'suggested':
        return <div className='w-[95%] p-[12px] font-[600]'>
                  Copilot Node
                  <StatusTag/>
                </div>
      default:
        break;
    }

  }

  let sidebarType = 'default'
  let node = getSelectedNode()
  if (!node) {
    node = getCopilotSelectedNode()
  }
  if (node){
    if (node.type === 'source') sidebarType = node.data.model_id ? 'source' : 'blank-source' // Checks if the model has been initialized
    else if (node.type === 'logic') sidebarType = 'logic'
    else if (node.type === 'suggested') sidebarType = 'suggested'
  }

  return (
    <div className='bg-[#f7f7f7] h-full flex flex-col'>
      <div className="w-full border-b-[1px] border-gray-300">
          {getTitle(sidebarType)}
        
      </div>
      <div className="w-[100%] overflow-y-auto px-4 pt-4 grow">
          {getSidebarComponent(sidebarType)} 

      </div>
    </div>

  );
};
