
import React from 'react';
import ChatOutput from './ChatOutput.js';
import { ArtyChatContext, ArtyChatProvider } from './ArtyChatProvider';

const ChatOutputWithProvider = () => {
    return (
        <ArtyChatProvider>
            <ChatOutput />
        </ArtyChatProvider>
    );
}

export default ChatOutputWithProvider;

