import React, { PureComponent } from 'react';
import { ScatterChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter } from 'recharts';


export const ScatterGraph = ({chart_data, rows}) => {
    console.log(chart_data)
    console.log(rows)

    return (
      <ResponsiveContainer width="100%" height="85%">
            
              <ScatterChart
                width={500}
                height={300}
                data={rows}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={chart_data.x_axis} />
                <YAxis />
                <Tooltip />
                <Legend />
                <XAxis dataKey={chart_data.x_axis} />
                <YAxis dataKey={chart_data.y_axis} />
                <Scatter name={chart_data.plot_name} data={rows} fill="#1f422a" />
              </ScatterChart>
  
      </ResponsiveContainer>
      
      
      )


}
