import React, {useContext, useState, useEffect} from 'react';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import { UserContext } from '../../../../context/UserProvider';
import { EditorContext } from '../../../../context/EditorProvider';



const DbtMenu = ({ menuItem, setMenuItem, setMenuPos }) => {
    const {
        createNewFolder,
        createNewFile,
        deleteItem,
        duplicateItem,
        onItemClicked,
        handleEdit,
        setIsCSVModalOpen,
    } = useContext(ModelEditorContext);

    const {
        handleItemDelete
    } = useContext(EditorContext)


    const NewFolder = async (e) => {
        e.stopPropagation()
        await createNewFolder(menuItem)
        setMenuPos()
        setMenuItem()
    }
    const NewFile = async (e) => {
        e.stopPropagation()
        await createNewFile(menuItem)
        setMenuPos()
        setMenuItem()
    }

    const DeleteItem = async (e) => {
        e.stopPropagation()
        await deleteItem(menuItem)
        handleItemDelete(menuItem, 'dbt')
        setMenuPos()
        setMenuItem()
    }

    const DuplicateItem = async (e) => {
        e.stopPropagation()
        await duplicateItem(menuItem)
        setMenuPos()
        setMenuItem()
    }

    const uploadCSV = (e) => {
        e.stopPropagation()
        setMenuPos()
        setMenuItem()
        setIsCSVModalOpen(true)
    }

    return (
        menuItem.children ?
        <div>     
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className="text-gray-900" onClick={(e) => NewFolder(e)}>New Folder</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className="text-gray-900" onClick={(e) => NewFile(e)}>New File</div>
            </div>
            { menuItem.path != '.' && 
                <>
                    <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                        <div className="text-gray-900" onClick={(e) => DeleteItem(e)}>Delete Folder</div>
                    </div>
                    <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                        <div className=" text-gray-900" onClick={(e) => handleEdit(menuItem)}>Rename Folder</div>
                    </div>
                </>
            }
            
        </div>
        :
        <div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className="text-gray-900" onClick={(e) => onItemClicked(menuItem)}>Open File</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className="text-gray-900" onClick={(e) => DeleteItem(e)}>Delete File</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleEdit(menuItem)}>Rename File</div>
            </div>
        </div>
    )
}

export default DbtMenu
