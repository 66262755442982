import { useContext, useEffect, useState } from 'react';
import { Tree, Drawer, Row, Col, Button, Popconfirm, Slider, Spin, Tag, Space, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ConnectorContext } from '../../../context/ConnectorProvider';
import LetoClient from '../../../context/LetoClient';

import { SubscriptionContext } from '../../../context/SubscriptionProvider';

const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const marks = ['15m', '30m', '1h', '2h', '3h', '6h', '8h', '12h', '24h'];


const setup_state_map = {
  incomplete: <Tag color="gold">Incomplete</Tag>,
  connected: <Tag color="green">Connected</Tag>,
  broken: <Tag color="red">Broken</Tag>,
  paused: <Tag color="gold">Paused</Tag>,
};

const convertToMinutes = mark => {
  const m = marks[mark];
  let minutes = m.slice(0, m.length - 1);
  if (m.includes('h')) {
    minutes *= 60;
  }
  return minutes;
};

const convertToMark = m => {
  let mark = m + 'm';

  if (!marks.includes(mark)) {
    mark = m / 60 + 'h';
  }

  return marks.indexOf(mark);
};

const convertToTree = source => {
  const selected = [];
  var columns = []
  var count = 0;
  for (var schema in source.schemas) {
    columns.push({
      title: schema,
      key: 'root.' + schema,
      children: [],
    });

    const tables = source.schemas[schema].tables;

    for (var key in tables) {
      columns[count].children.push({
        title: key,
        key: key,
        selectable: !tables[key]['enabled_patch_settings']['allowed'],
        disableCheckbox: !tables[key]['enabled_patch_settings']['allowed']
      });
      if (tables[key].enabled) {
        selected.push(key);
      }
    }
    count++;
  }

  return { treeData: columns, selected };
};

const updateTree = (list, key, columns) => {
  let newSelected = [];
  const newTree = list.map(node => {
    if (node.key === key) {
      const { children, selected } = ParseColumns(key, columns);
      newSelected = newSelected.concat(selected);
      return {
        ...node,
        children,
      };
    }

    if (node.children) {
      const nestedUpdate = updateTree(node.children, key, columns);
      newSelected = newSelected.concat(nestedUpdate.newSelected);
      return { ...node, children: nestedUpdate.newTree };
    }

    return node;
  });
  return { newSelected, newTree };
};

const ParseColumns = (key, { columns }) => {
  let selected = [];
  let children = [];
  if (columns) {
    children = Object.keys(columns).map(column => {
      const newKey = key + '.' + column;
      if (columns[column].enabled) {
        selected.push(newKey);
      }
      return {
        title: column,
        key: newKey,
        children: [],
        isLeaf: true,
        disableCheckbox: !columns[column]['enabled_patch_settings']['allowed']
      };
    });
  }

  return { children, selected };
};

const ConnectorDetailsDrawer = ({
  cardDetails,
  visible,
  onClose,
  connector,
  selectedWorkspaces,
  setSelectedWorkspaces,
  paused,
}) => {
  const {
    getConnectorLink,
    editLink,
    deleteConnectors,
    applyLoading,
    syncLoading,
    pauseLoading,
    syncConnector,
    editConnectors,
    editConnectorSchema,
    schema,
    setSchema,
    getConnectorSchema,
    getConnectorSchemaColumns,
    workspaces,
  } = useContext(ConnectorContext);

  const {
    setNoCreditModalVisible,
    checkCredits,
  } = useContext(SubscriptionContext)

  const options = [];
  const [syncFrequency, setSyncFrequency] = useState();
  const [schemaTree, setSchemaTree] = useState();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [schemaLoading, setSchemaLoading] = useState();
  const [pausedConnector, setPausedConnector] = useState();

  for (let i = 0; i < workspaces.length; i++) {
    options.push({
      label: workspaces[i].workspace_name,
      value: workspaces[i].workspace_id,
    });
  }

  useEffect(() => {
    if (cardDetails !== null) {
      setPausedConnector(paused)
      setSyncFrequency(convertToMark(cardDetails.sync_frequency));
      setSchemaTree(null);
      if (cardDetails.status.setup_state !== 'incomplete') {
        setSchemaLoading(true);
        getConnectorSchema(cardDetails.id);
      } else {
        setSchemaLoading(false);
        setSchemaTree(null);
        setSelectedColumns(null);
      }
    }
  }, [visible, cardDetails]);


  useEffect(() => {
    if (cardDetails !== null && schema) {
      const { treeData, selected } = convertToTree(schema);
      setSchemaTree(treeData);
      setSelectedColumns(selected);
      setSchemaLoading(false);
    }
  }, [schema]);

  const handleSelectChange = value => {
    setSelectedWorkspaces(value);
  };

  const confirmDelete = async () => {
    await deleteConnectors(cardDetails.id);
    onClose();
  };

  const onSubmit = () => {
    let minutes = convertToMinutes(syncFrequency);
    if (minutes !== cardDetails.sync_frequency) {
      editConnectors(cardDetails.id, { sync_frequency: minutes }, "sync");
    }
    LetoClient.toggleWorkSpace(selectedWorkspaces, connector.id, connector.schema);
    const schemaPatch = {
      enable_new_by_default: schema.enable_new_by_default,
      schemas: {}
    }
    for (let i = 0; i < schemaTree.length; i++) {
      schemaPatch['schemas'][schemaTree[i].title] = createSchemaPatch(selectedColumns, schemaTree[i])
    }
    editConnectorSchema(cardDetails.id, schemaPatch);
  };

  const createSchemaPatch = (checkedKeys, this_schema) => {
    const schemaPatch = {
    }
    schemaPatch['enabled'] = !!checkedKeys.length
    schemaPatch['tables'] = {}
    for (var i = 0; i < checkedKeys.length; i++) {
      const column_split = checkedKeys[i].split('.')
      if (column_split.length > 1 && schema.schemas[this_schema.title]['tables'][column_split[0]] && schema.schemas[this_schema.title]['tables'][column_split[0]]['enabled_patch_settings']['allowed']) {
        if (!schemaPatch['tables'][column_split[0]]) {
          schemaPatch['tables'][column_split[0]] = {
            enabled: true,
            columns: {}
          }
        }
        if (schema.schemas[this_schema.title]['tables'][column_split[0]]['columns'][column_split[1]] && schema.schemas[this_schema.title]['tables'][column_split[0]]['columns'][column_split[1]]['enabled_patch_settings']['allowed']) {
          schemaPatch['tables'][column_split[0]]['columns'][column_split[1]] = {
            enabled: true,
          }
        }
      }
      else {
        if (schema.schemas[this_schema.title]['tables'][checkedKeys[i]] && schema.schemas[this_schema.title]['tables'][checkedKeys[i]]['enabled_patch_settings']['allowed'] && !schemaPatch['tables'][checkedKeys[i]]) {
          schemaPatch['tables'][checkedKeys[i]] = {
            enabled: true,
          }
        }
      }
    }
    for (var i = 0; i < this_schema.children.length; i++) {
      if (!(this_schema.children[i].title in schemaPatch['tables']) && schema.schemas[this_schema.title]['tables'][this_schema.children[i].title].enabled_patch_settings.allowed) {
        schemaPatch['tables'][this_schema.children[i].title] = {
          enabled: false,
        }
      }
      if (this_schema.children[i]['children']) {
        for (var n = 0; n < this_schema.children[i]['children'].length; n++) {
          if (schema.schemas[this_schema.title]['tables'][this_schema.children[i].title]['columns'][this_schema.children[i].children[n].title].enabled_patch_settings.allowed && !(this_schema.children[i].children[n].title in schemaPatch['tables'][this_schema.children[i].title]['columns'])) {
            schemaPatch['tables'][this_schema.children[i].title]['columns'][this_schema.children[i].children[n].title] = {
              enabled: false,
            }
          }
        }
      }
    }
    return (schemaPatch)
  }

  const onTreeChange = (checkedKeys) => {
    setSelectedColumns(checkedKeys);

  };

  const onTreeLoadData = ({ key, children }) =>
    new Promise(resolve => {
      if (children) {
        resolve();
        return;
      }
      var this_schema = ''
      for (var i = 0; i < Object.keys(schema.schemas).length; i++) {
        if (key in schema.schemas[Object.keys(schema.schemas)[i]]['tables']) {
          this_schema = Object.keys(schema.schemas)[i]
        }
      }
      getConnectorSchemaColumns(cardDetails.id, this_schema, key)
        .then(data => {
          if (data) {
            schema.schemas[this_schema].tables[key]['columns'] = data.columns
            setSchema(schema)
            setSchemaTree(origin => {
              const { newTree, newSelected } = updateTree(origin, key, data);
              setSelectedColumns(selected => {
                selected = selected.concat(newSelected);
                selected = selected.filter(k => k !== key);
                return selected;
              });
              setSchemaTree(newTree)
              return newTree;
            });
          }
          resolve();
        })
        .catch(err => {
          resolve();
        });
    });

  const renderTree = () => {
    if (schemaTree) {
      return (
        <Tree
          className='bg-[#F7F7F7]'
          checkable
          selectable
          defaultExpandedKeys={['root']}
          treeData={schemaTree}
          checkedKeys={selectedColumns}
          onCheck={onTreeChange}
          loadData={onTreeLoadData}
        />
      );
    } else if (schemaLoading) {
      return <Spin spinning={schemaLoading} indicator={loadingIcon} />;
    } else {
      return <p>Schema is unavailable since setup is incomplete</p>;
    }
  };

  const onPauseConnector = () => {
    if (pausedConnector && checkCredits()) {
      setNoCreditModalVisible(true)
    }
    else {
      editConnectors(cardDetails.id, { paused: !pausedConnector }, "pause");
      setPausedConnector(!pausedConnector)
    }
  }

  const onSyncConnectorFunction = () => {
    if (checkCredits()) {
      setNoCreditModalVisible(true)
    }
    else
      syncConnector(cardDetails.id);
  };
const custom_hover = {  boxShadow: '3px 4px 10px -1px rgba(0, 0, 0, 0.6), -6px 6px 15px -2px rgba(0, 0, 0, 0.6)',
  transform: 'translateY(-0.25rem)',}


  return (
    <>
      <div className = "w-full text-center font-bold text-xl">
        {cardDetails.schema + " - " + cardDetails.service}
        </div>   
        <br/>
        <div className ="flex justify-center mt-4">

          <div style={{ width: "1.5rem" }} />

            <div className="connector-item flex-1">
                  <Spin spinning={syncLoading} indicator={loadingIcon}>
                    <button className = "w-full rounded-lg h-10 text-white hover:bg-gray-900 bg-black" onClick={onSyncConnectorFunction}>
                      Sync Now
                    </button>
                  </Spin>
            </div>


          <div style={{ width: "1.5rem" }} />
            <div className="connector-item flex-1">
              <Popconfirm
                title={pausedConnector ? "Are you sure you want to enable this connector?" : "Are you sure you want to pause this connector?"}
                onConfirm={onPauseConnector}
                okText="Yes"
                cancelText="No"
                placement="bottom">
                  <Spin spinning={pauseLoading} indicator={loadingIcon}>
                    <button className="w-full rounded-lg h-10 border-[1px] border-gray-300 hover:bg-gray-200">
                      {pausedConnector ? "Enable Connector" : "Pause Connector"}
                    </button>
                  </Spin>
              </Popconfirm>
            </div>
        <div style={{ width: "1.5rem" }} />

        </div>
          
            <div className = "w-full flex justify-center">
            <div style={{ width: "1.5rem" }} />

            <div className="w-full p-5">
              <p className="connector-item-label">Sync Freqency:</p>
              <Slider
                className="sync-frequency"
                marks={marks}
                tipFormatter={null}
                step={null}
                value={syncFrequency}
                onChange={v => setSyncFrequency(v)}
                max={8}
              />
              </div>
        <div style={{ width: "1.5rem" }} />

      </div>


  <div className = "w-full flex justify-center">     

  <Spin spinning={applyLoading} indicator={loadingIcon}>
    <button className="py-2 connector-edit-button border-[1px] border-gray-300 w-[120px] rounded-lg hover:bg-gray-200" onClick={() => onSubmit()}>
      Apply Changes
    </button>
  </Spin>


    <button className="py-2 mx-[5px] connector-edit-button border-[1px] w-[120px] border-gray-300 rounded-lg hover:bg-gray-200"
          onClick={() => getConnectorLink(cardDetails.id).then(link => window.open(link, '_blank')).catch()}
    >
      Edit Details
    </button>


    <Popconfirm
            title="Are you sure you want to delete this connector?"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
            placement="bottom"
    >
      <button className="py-2 connector-edit-button border-[1px] border-gray-300 w-[120px] rounded-lg hover:bg-[#ce2029] opacity-[0.8] hover:text-white hover:border-none">
        Delete
      </button>
    </Popconfirm>
    <div style={{ width: "1.5rem" }} />

  </div>

<br/>
 <div className = "w-full flex">
    <div style={{ width: "1.5rem" }} />

  {cardDetails && (
        <Row>
  
        <Col span={12}>
            <div className="connector-item">
              <p className="connector-item-label">Service:</p>
              {cardDetails.service}
            </div>
          </Col>
          <Col span={12}>
            <div className="connector-item">
              <p className="connector-item-label">Schema:</p>
              {cardDetails.schema}
            </div>
          </Col>
          <Col span={12}>
            <div className="connector-item">
              <p className="connector-item-label">Status:</p>
              {pausedConnector ? setup_state_map["paused"] : setup_state_map[cardDetails.status.setup_state]}
            </div>
          </Col>
          <Col span={12}>
            <div className="connector-item">
              <p className="connector-item-label">Connected At:</p>
              {new Date(cardDetails.created_at).toLocaleDateString()}
            </div>
          </Col>
          <Col span={24}>
            <div className="connector-item">
              <p className="connector-item-label">Schema:</p>
              {renderTree()}
            </div>
          </Col>
        </Row>
  )}
</div>

    </>
  );
};

export default ConnectorDetailsDrawer;
