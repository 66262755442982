import { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import { Button } from 'antd';
import { BarChart, Bar, LineChart, Line, ScatterChart, Scatter, AreaChart, Area, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text } from 'recharts';

const AnalyticsGraph = ({chartData, generatedChartType, pointerEvents=true, userSelect=true, isAnimationActive=true}) => {

    const CustomizedLabel = ({item}) => {
        return (
            <Text
            
                x={0}
                y={-20}
                dx={-300}
                dy={40}
                textAnchor="start"
                width={180}
                transform="rotate(-90)"
                z={50}
            >         
                {item}
                
            </Text>
        );
    };

    if (chartData) {
        console.log('made it here')
        if (generatedChartType === "Time Series BarChart") {
            console.log('made it here x2')
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none'}}>
                    <ResponsiveContainer className='w-full'>
                        <BarChart
                            data={chartData.rows}
                            margin={{
                                top: 10,
                                right: 50,
                                left: 50,
                                bottom: 60,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='x_axis' />
                            {chartData.metrics.map((item) => 
                                <YAxis label={<CustomizedLabel item={item}/>}/>
                            )

                            }
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {chartData.columns.map((item, i) => {
                                return (<Bar dataKey={item} fill={chartData.colors[i]}  isAnimationActive={isAnimationActive} />)
                            })}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )
        } else if (generatedChartType === "BarChart") {

            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none'}}>
                    <ResponsiveContainer>
                        <BarChart
                            data={chartData.rows}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 60,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='x_axis' />
                            <YAxis/>
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {
                                chartData.columns.map((item, i) => <Bar dataKey={item} fill={chartData.colors[i]} isAnimationActive={isAnimationActive} />)
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )
        }
        else if (generatedChartType === "Time Series LineChart") {
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none' }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={chartData.rows}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 30,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='x_axis' />
                            <YAxis/>
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {chartData.columns.map( (item, i) => { return ( <Line dataKey={item} stroke={chartData.colors[i]} isAnimationActive={isAnimationActive}/> ) })}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            )
        } else if (generatedChartType === "LineChart") {
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none' }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={chartData.rows}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 30,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='x_axis' />
                            <YAxis/>
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {
                                chartData.columns.map((item, i) => <Line dataKey={item} stroke={chartData.colors[i]} isAnimationActive={isAnimationActive} />)
                            }
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            )
        } else if (generatedChartType === "ScatterChart") {
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none' }}>
                    <ResponsiveContainer>
                        <ScatterChart
                            type="scatter"
                            data={chartData.rows}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 30,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x_axis" />
                            {
                                chartData.columns.map((item, i) => <YAxis dataKey={item} fill={chartData.colors[i]} />)
                            }
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {
                                chartData.columns.map((item, i) => <Scatter name={item} fill={chartData.colors[i]} />)
                            }
                        </ScatterChart>
                    </ResponsiveContainer>
                </div>
            )
        } else if (generatedChartType === "AreaChart") {
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none' }}>
                    <ResponsiveContainer>
                        <AreaChart
                            type="area"
                            data={chartData.rows}
                            fill={chartData.colors[0]}
                            margin={{
                                top: 30, 
                                right: 30,
                                left: 30,
                                bottom: 30,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x_axis" />
                            <Tooltip />
                            {chartData.columns.length < 20 &&
                                <Legend wrapperStyle={{fontSize: '10px'}}/>
                            }
                            {
                                chartData.columns.map((item, i) => <Area dataKey={item} fill={chartData.colors[i]}  stroke={chartData.colors[i]}/>)
                            }
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            )
        } else if (generatedChartType === "PieChart") {
            const total = chartData.rows.reduce((sum, entry) => sum + entry[chartData.columns[0]], 0);
            return (
                <div style={{ 'display': 'flex', height: '100%', width: '100%', pointerEvents: pointerEvents ? 'auto' : 'none', userSelect: userSelect ? 'auto' : 'none' }}>
                     <ResponsiveContainer>
                        <PieChart
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 30,
                            }} 
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <Pie 
                                data={chartData.rows} 
                                dataKey={chartData.columns[0]} 
                                nameKey='x_axis' 
                                label={({ name, value }) => `${name} ${(value / total * 100).toFixed(2)}%`} 
                                fill={chartData.colors[0]}
                                isAnimationActive={isAnimationActive}
                            />
                            <Tooltip formatter={(value, name, props) => `${value} (${(value / total * 100).toFixed(2)}%)`} />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    } else {
        return (
            <div></div>
        )
    }
};

export default AnalyticsGraph

