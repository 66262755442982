import axios from 'axios';
export const API_URL = getApiUrl();

class _LetoClient {
  token = '';

  constructor() {
    this.baseUrl = API_URL;
  }

  setToken(token) {
    this.token = token;
  }

  async promptAgentChat(contextId, chatId, data) {
    const responseData = await this.execute('post', `/contexts/${contextId}/chat/${chatId}/prompt`, data);
    return responseData;
  }

  async createAgentChat(contextId) {
    /*
     * takes in current context_id and creates a new chat object and returns the object _id -> TODO
     */
    const responseData = await this.execute('post', `/contexts/${contextId}/output/chat`, {});
    return responseData;
  }

  async fetchChat(pub_id, chat_id) {
    const responseData = await this.execute('get', `/public/chats/${pub_id}/${chat_id}`);
    return responseData;
  }


  async getAllChats(dag_id) {
    const responseData = await this.execute('get', `/public/chats/${dag_id}`);
    return responseData;
  }

 

  async updateChatName(chat_id, chat_name) {
    const responseData = await this.execute("patch", `/chats/${chat_id}/${chat_name}`);
    return responseData;
  }

  async deleteChat(chat_id) {
    const responseData = await this.execute('delete', `/chats/${chat_id}`);
    return responseData;
  }
  
  async promptAgentChat_SSE(contextId, chatId, data) {
    let eventSource = await this.executeServerSentEvent('post', `/sse/contexts/${contextId}/chat/${chatId}/prompt`, data);
    return yieldServerSentEventMessages(eventSource);
  }

  async executeServerSentEvent(method, path, data) {
    const response = await fetch(`${this.baseUrl}${path}`,{
      method: method,
      headers: {
        Authorization: 'Bearer ' + this.token,
        'Content-Type': 'application/json'
      },
      // data: data,
      body: JSON.stringify(data),
    })
    return response
  }

  async execute(method, path, data) {
    console.log(`${method}, ${path}`);
    let headers = {
      Authorization: 'Bearer ' + this.token,
      accept: 'application/json',
    };
    if (data) console.log(data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: data,
    });
    console.log(response.status);
    if (response.status >= 400) throw Error(response.data);

    console.log(response.data);
    return response.data;
  }

  async executeFileUpload(method, path, file, data ={}) {
    let headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      headers: headers,
      url: path,
      data: formData,
    });

    if (response.status >= 400) throw Error(response.data);

    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      console.log(`${method}, ${path}, ${cursor}`);
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        console.log(response.data);
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }
}

async function* yieldServerSentEventMessages(eventSource) {
  const decoder = new TextDecoder('utf-8')
  const reader = eventSource.body.getReader()
  var { value, done } = await reader.read();
  let buffer = '';
  let match;
  while (!done) {
    buffer += decoder.decode(value, { stream: true });
    const pattern = /data:\s({[\s\S]*?})\s*\n\s*\n/g;
    let lastIndex = 0
    while ((match = pattern.exec(buffer)) !== null) {
      const jsonString = match[1];
      lastIndex = pattern.lastIndex
      try {
        const message = JSON.parse(jsonString);
        yield message
      }
      catch (error) {
        console.log(error)
      }
    }
    buffer = buffer.slice(lastIndex);
    ({ value, done } = await reader.read())
  }
}

function getApiUrl() {
  if (process.env.REACT_APP_ENVIRONMENT === 'staging')
    return 'https://graph-staging.artemisdata.io';
  
  else if (process.env.REACT_APP_ENVIRONMENT === 'production')
    return 'https://graph.artemisdata.io';

  return 'http://127.0.0.1:8080'
}

const LetoClient = new _LetoClient();
export default LetoClient;


