import React from 'react';

const ConsoleShell = () => {
  return (
    <div className="disabled opacity-[40%] w-full h-full text-white rounded-lg">
      <div className="text-gray-600">
        Artemis@cluster-2:~$ __
        <span className="text-white">{'_'}</span>
      </div>
    </div>
  );
};

export default ConsoleShell;

