import { createContext, useState, useContext } from 'react';
import GraphClient from './GraphClient.js';
import { ModelEditorContext } from './ModelEditorProvider';
import { SqlEditorContext } from './SqlEditorProvider';

export const EditorCopilotContext = createContext();

export const EditorCopilotProvider = ({ children }) => {
    const [chat, setChat] = useState({});
    const [chatId, setChatId] = useState('');
    const [contextMessage, setContextMessage] = useState('');
    const [chatList, setChatList] = useState([{}]);
    const [pinnedChatList, setPinnedChatList] = useState([{}]);
    const [fetchingChat, setFetchingChat] = useState(false);
    const [newChatName, setNewChatName] = useState("");
    const [initialChatName, setInitialChatName] = useState("");
    const [isEditing, setIsEditing] = useState(null);
    const [returnError, setReturnError] = useState('');

    const { dbtTabs, setDbtTabs } = useContext(ModelEditorContext);
    const { sqlTabs, setSqlTabs } = useContext(SqlEditorContext);


    const handleEdit = async (item) => {
        if (item.chat_name) {
            setNewChatName(item.chat_name);
            setInitialChatName(item.chat_name);
        } else {
            setNewChatName("Undefined Chat");
            setInitialChatName("Undefined Chat");
        }
        setIsEditing(item._id);
    };

    const setToken = (token) => {
       GraphClient.setToken(token);
    }

    const handleSave = (dag_id, chat_id) => {
        if (isEditing !== null) {
            updateChatName(dag_id, chat_id, newChatName);
            setIsEditing(null);
        }
    };

    const handleCancel = () => {
        setNewChatName(initialChatName);
        setIsEditing(null);
    };

    const createChat = async () => {
        setFetchingChat(true);
        try {
            const response = await GraphClient.createArtyDBTCopilot();
            setChatId(response._id);
            console.log(response);
        } catch {
            console.log('server error');
        }
        setFetchingChat(false);
    };

    const getAllChats = async (dag_id) => {
        const response = await GraphClient.getAllChats(dag_id);
        setChatList(response);
        return response;
    };

    const getPinnedChats = async (dag_id) => {
        const response = await GraphClient.getPinnedChats(dag_id);
        setPinnedChatList(response);
    };

    const createPinnedChat = async (chat_id, dag_id) => {
        const response = await GraphClient.createPinnedChat(chat_id);
        getPinnedChats(dag_id);
    };

    const deletePinnedChat = async (chat_id, dag_id) => {
        const response = await GraphClient.deletePinnedChat(chat_id);
        getPinnedChats(dag_id);
    };

    const updateChatName = async (dag_id, chat_id, chat_name) => {
        let response = await GraphClient.updateChatName(chat_id, chat_name);
        getAllChats(dag_id);
    };

    const deleteChat = async (chat_id, dag_id) => {
        await GraphClient.deleteChat(chat_id);
        const response = await getAllChats(dag_id);
        return response;
    };



// Helper function to update a result in the dbtTabs state
const updateResult = (tabId, resultId, newChat) => {
    setDbtTabs(prev => ({
        ...prev,
        [tabId]: {
            ...prev[tabId],
            results: {
                ...prev[tabId].results,
                [resultId]: {
                    ...prev[tabId].results[resultId],
                    chat: newChat
                }
            }
        }
    }));
};

    const promptSqlEditorCopilot = async (tab, text) => {
        const tabId = tab.id; 
        const resultId = sqlTabs[tabId].copilotResultId;
        const currentChat = sqlTabs[tabId].results[resultId].chat || [];
        const updatedChat = [
            ...currentChat,
            { response_type: 0, response: text }, // Initial query
            { response_type: 1, response: '' } // Placeholder for response
        ];

        setSqlTabs(prev => ({
            ...prev,
            [tabId]: {
                ...prev[tabId],
                results: {
                    ...prev[tabId].results,
                    [resultId]: {
                        ...prev[tabId].results[resultId],
                        chat: updatedChat
                    }
                }
            }
        }));

      try {
        let response = await GraphClient.promptArtySQLCopilot(chatId, { 'prompt': text })

        console.log(response)

        setSqlTabs(prev => ({
            ...prev,
            [tabId]: {
                ...prev[tabId],
                results: {
                    ...prev[tabId].results,
                    [resultId]: {
                        ...prev[tabId].results[resultId],
                        chat: response.data
                    }
                }
            }
        }));

      } catch {
        const errorChat = [
            ...updatedChat,
            { response_type: -1, response: 'A problem occurred when connecting/communicating with the server.' }
        ];

        setSqlTabs(prev => ({
            ...prev,
            [tabId]: {
                ...prev[tabId],
                results: {
                    ...prev[tabId].results,
                    [resultId]: {
                        ...prev[tabId].results[resultId],
                        chat: errorChat
                    }
                }
            }
        }));
    }

    };

const promptModelEditorCopilot = async (tab, text) => {
    const tabId = tab.id; 
    const resultId = dbtTabs[tabId].copilotResultId;
    const currentChat = dbtTabs[tabId].results[resultId].chat || [];
    const updatedChat = [
        ...currentChat,
        { response_type: 0, response: text }, // Initial query
        { response_type: 1, response: '' } // Placeholder for response
    ];

    // Update the chat at the start
    updateResult(tabId, resultId, updatedChat);

    try {
        const messageIterator = await GraphClient.promptArtyDBTCopilotSSE(chatId, { 'prompt': text, 'context': dbtTabs[tabId] });

        for await (let message of messageIterator) {
            if (message.type === 0) {
                // Update the last chat entry with the ongoing explanation response
                const newChat = updatedChat.map((item, index) =>
                    index === updatedChat.length - 1 ? { response_type: 1, response: message.data.explanation } : item
                );

                // Update the result chat continuously
                updateResult(tabId, resultId, newChat);
            }

            if (message.type === 2) {
              
                const finalChat = [...currentChat, ...message.data.data]
                console.log(finalChat)
                // Final update to the result chat
                updateResult(tabId, resultId, finalChat);
            }
        }
    } catch {
        // Update with an error message if something goes wrong
        const errorChat = [
            ...updatedChat,
            { response_type: -1, response: 'A problem occurred when connecting/communicating with the server.' }
        ];

        // Update the result chat with the error state
        updateResult(tabId, resultId, errorChat);
    }
};

const promptModelEditorCopilotExecute = async (model, index) => {
  try {
      setChat((currentChat) => {
        // Make a shallow copy of the current chat data to maintain immutability
        let updatedChatData = [...currentChat.data];
        
        // Check if the index is within the bounds of the chat data array
        if (index >= 0 && index < updatedChatData.length) {
          // Create a new object with the existing chat data and add the 'results' field
          let updatedChatObject = {
            ...updatedChatData[index],
            loading: true// Insert the response data into the 'results' field
          };

          // Replace the object at the given index with the updated object
          updatedChatData[index] = updatedChatObject;
        }

        // Return the updated chat state
        return {
          ...currentChat,
          data: updatedChatData
        };
      });


    let response = await GraphClient.compileDBTCopilot(chatId, { 'model': model });

    // Check if the response is valid and contains data
    if (response && response.data) {
      // Update the chat object at the given index with the results
      setChat((currentChat) => {
        // Make a shallow copy of the current chat data to maintain immutability
        let updatedChatData = [...currentChat.data];
        
        // Check if the index is within the bounds of the chat data array
        if (index >= 0 && index < updatedChatData.length) {
          // Create a new object with the existing chat data and add the 'results' field
          let updatedChatObject = {
            ...updatedChatData[index],
            results: response.data // Insert the response data into the 'results' field
          };

          // Replace the object at the given index with the updated object
          updatedChatData[index] = updatedChatObject;
        }

        // Return the updated chat state
        return {
          ...currentChat,
          data: updatedChatData
        };
      });
    }
  } catch (error) {
    // Handle error by updating the chat object with an error message
    setChat((currentChat) => {
      let updatedChatData = [...currentChat.data];

      if (index >= 0 && index < updatedChatData.length) {
        let updatedChatObject = {
          ...updatedChatData[index],
          results: { response_type: -1, response: 'A problem occurred when connecting/communicating with the server.' }
        };

        updatedChatData[index] = updatedChatObject;
      }

      return {
        ...currentChat,
        data: updatedChatData
      };
    });
  }
}


    const fetchChat = async (pub_id, id) => {
        setFetchingChat(true);
        try {
            const response = await GraphClient.fetchChat(pub_id, id);
            if (response.data) {
                setChat(response.data);
                setChatId(id);
            } else if (response.error) {
                console.log('invalid response', response.error);
            }
        } catch {
            console.log('server error');
        }
        setFetchingChat(false);
    };

    return (
        <EditorCopilotContext.Provider
            value={{
                setToken,
                chat,
                setChat,
                chatId,
                setChatId,
                createChat,
                promptModelEditorCopilot,
                promptSqlEditorCopilot,
                promptModelEditorCopilotExecute,
                contextMessage,
                setContextMessage,
                getAllChats,
                setChatList,
                chatList,
                getPinnedChats,
                pinnedChatList,
                createPinnedChat,
                deletePinnedChat,
                deleteChat,
                fetchingChat,
                setFetchingChat,
                fetchChat,
                updateChatName,
                newChatName,
                setNewChatName,
                initialChatName,
                setInitialChatName,
                isEditing,
                setIsEditing,
                handleEdit,
                handleSave,
                handleCancel,
            }}
        >
            {children}
        </EditorCopilotContext.Provider>
    );
}


