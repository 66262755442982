import './style.scss';
import {
    DownOutlined, SettingOutlined,
    BookOutlined, LogoutOutlined,
  RobotOutlined, DatabaseOutlined, LineChartOutlined
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItems } from './MenuItems';
import { NavbarContext } from '../../context/NavbarContext';
import { useLogoutFunction } from '@propelauth/react';


const NavBar = ({ notCarltonOrg }) => {
    const logoutFn = useLogoutFunction();
    const [dropdown, setDropdown] = useState(false)
    const navbarPage = useContext(NavbarContext);
    const history = useHistory();

    const compute_regex = /compute/;
    const data_regex = /data/;
    const billings_regex = /billings/;
    const table_regex = /tables/;
    const arty_regex = /artyai/;
    const analytics_regex = /analytics/;
    const worksheets_regex = /tablegraphs/;
    const workflows_regex = /workflows/
    const chat_regex = /chat\//
    const editor_regex = /editor/
    const agents_regex = /contexts/

    const { pathname } = useLocation();
    const DOCUMENTATION_URL = 'https://artemisdata.notion.site/Artemis-Docs-05bbd7e73e144946bfd318d5c3315aae'
    const items = (
        <Menu
          style={{width: '15vw', minWidth: '200px', position: 'absolute', left: '-50px', userSelect: 'none'}}
          items={[
            {
                  label: <div className='flex' onClick={() => { setDropdown(false); history.push('/analytics') }}> <LineChartOutlined style={{position: 'relative', top: '3px', marginRight: '5px', marginLeft: '5px', userSelect: 'none'}} />Analytics</div>,
                key: '-3',
                link: '/analytics'
              },
            {
                  label: <div className='flex' onClick={() => { setDropdown(false); history.push('/data') }}> <DatabaseOutlined style={{position: 'relative', top: '3px', marginRight: '5px', marginLeft: '5px', userSelect: 'none'}} />Data</div>,
                key: '-2',
                link: '/data'
              },
              {
                type: 'divider',
                link: ''
              },

      {
                  label: <div className='flex' onClick={() => { setDropdown(false); history.push('/account') }}> <SettingOutlined style={{position: 'relative', top: '3px', marginRight: '5px', marginLeft: '5px', userSelect: 'none'}} /> Account settings</div>,
                key: '0',
                link: '/account'
              },
            //   {
            //       label: <div onClick={() => { setDropdown(false); history.push('/billing') }}> <CreditCardOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}} /> Billing</div>,
            //     key: '1',
            //   },
              {
                type: 'divider',
                link: ''
              },
              {
                  label: <div onClick={() => { setDropdown(false); window.open(DOCUMENTATION_URL, '_blank') }}> <BookOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}} /> Documentation </div>,
                key: '3',
              },
            //   {
            //       label: <div onClick={() => { setDropdown(false); history.push({search: '?chat=true'}) }}> <MessageOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}} /> Chat with us </div>,
            //     key: '4',
            //   },
            //   {
            //       label: <div onClick={() => { setDropdown(false);  history.push('/') }}> <UserAddOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px'}}/> Invite Friends </div>,
            //     key: '5',
            //   },
              {
                type: 'divider',
              },
              {
                label: <div onClick={() => logoutFn()}> <LogoutOutlined style={{position: 'relative', top: '-3px', marginRight: '2px', marginLeft: '5px', userSelect: 'none'}}/> Sign out </div>,
                key: '7',
              },
          ]}
        />
      );
  
    useEffect(() => {
      keyFunction()
    }, [pathname])
  

    const keyFunction = () => {
        // var window_location = window.location.href;
        if (compute_regex.test(pathname)) {
            navbarPage.setPageKey('3');
            return '3';
        }
        if (analytics_regex.test(pathname)) {
            navbarPage.setPageKey('7');
            return '7';
        }
        if (data_regex.test(pathname)) {
            navbarPage.setPageKey('6');
            return '6';
        }
        if (billings_regex.test(pathname)) {
            navbarPage.setPageKey('9');
            return '9';
        }
        if (arty_regex.test(pathname)) {
            navbarPage.setPageKey('11')
            return '11'
        }
        if (worksheets_regex.test(pathname)) {
            navbarPage.setPageKey('4')
            return '4'
        }
        if (workflows_regex.test(pathname)) {
          navbarPage.setPageKey('4')
          return '4'
        }
        if (chat_regex.test(pathname)) {
          navbarPage.setPageKey('4')
          return '4'
        }
        if (editor_regex.test(pathname)) {
          navbarPage.setPageKey('12')
          return '12'
        }
        if (agents_regex.test(pathname)) {
          navbarPage.setPageKey('8')
          return '8'
        }
      
        navbarPage.setPageKey('1');
        return '1';
    };
    const menuClick = item => {

        if(item.label === "Diana")
          window.location.href = 'https://beta.artemisdata.io';

        navbarPage.setPageKey(item.key);
        history.push(item.link);
    };


  return (
    (worksheets_regex.test(pathname) ? 
      <></>
        :
      
<nav className="navbar select-none" style={{height: '55px', backgroundColor: '#F7F7F7', display: 'flex', alignItems: 'center', borderBottom: '1px solid #ccc'}}>
<div style={{ width: "1rem" }} />


        <img src="https://s3-us-west-2.amazonaws.com/public.notion-static.com/e092fde8-6695-46b9-a66d-28bdec452eb5/twitter_logo.png" alt="Twitter Logo" style={{ height: '30px', width: '30px', borderRadius: '25%', marginLeft: '10px', marginRight: '5px', cursor: 'pointer' }} onClick={() => {history.push('/')}}/>
  <Dropdown overlay={items} trigger={['click']} onVisibleChange={e => setDropdown(e)} className="ml-2 mt-1 flex">
  <DownOutlined className='text-black duration-[50ms]' style={dropdown ? { transform: 'rotate(-180deg)', transition: '300ms ease-in' } : { transform: 'rotate(0)', transition: '300ms ease-in' }} />
  </Dropdown>
  <ul className="menu cursor-pointer" style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0, marginLeft: '10px', marginTop:'3px', userSelect: 'none' }}>
    {MenuItems.map(item => (
      (!notCarltonOrg || (notCarltonOrg && item.key !== 8)) && (
        <li key={item.key} onClick={() => menuClick(item)} style={{ margin: '0 10px' }}>
          <div>
            <div className={navbarPage.pageKey === item.key ? 'text-center py-[3px] border-b-black border-b-[2px] w-[75px] border-transparent border-b-[2px]': 'text-center hover:text-gray-600 py-[3px] w-[75px] border-b-[2px] hover:border-gray-600 border-transparent'}>{item.label}</div>
          </div>
        </li>
      )
    ))}
  </ul>
</nav>
)
);
};

export default NavBar;
