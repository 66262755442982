
import React, { useContext, useState, useEffect } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Divider, Spin } from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { LoadingOutlined} from '@ant-design/icons';
import { EditorView } from "@codemirror/view";
import { EditorContext } from '../../../context/EditorProvider';

export default () => {
  const {
    nodes,
    selectedNode,
    getSelectedNode,
    handleNodeRename,
    getDbtModelFile
  } = useContext(TableGraphContext);

  const {
    openDbtFileFromNode
  } = useContext(EditorContext)

  const [editedName, setEditedName] = useState();
  
  let node = getSelectedNode();

  const [loading, setLoading] = useState(false);

  const [dbtModelContent, setDbtModelContent] = useState('');

  useEffect(() => {
    const fetchDBTModelFile = async () => {
      if (selectedNode && !selectedNode.dbt && selectedNode.data) {
        setLoading(true);
        try {
          const response = await getDbtModelFile(node.data.model_id); // Assuming `selectedNode.id` is needed for the API call
          if (response) {
            setDbtModelContent(response.data); // Assuming the response is the content you need
          }
        } catch (error) {
          console.error("Failed to fetch DBT model file:", error);
          setDbtModelContent(''); // Reset content on error
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDBTModelFile();
  }, [selectedNode, selectedNode.data?.model_id]); 

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  
  if (loading) {
    return (
      <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }

  const editName = e => {
    setEditedName(e.target.value);
  };

  const saveNameEdit = async () => {
    const old_name = node.data.model_name
    let response = await handleNodeRename(editedName, old_name)
    if (response && response.status === 'error') {
      setEditedName(old_name)
    } else {
      setEditedName()
    }
  }

  const cancelNameEdit = () => {
    setEditedName()
  }

  const openModel = () => {
    openDbtFileFromNode(nodes.find(item => item.id === selectedNode.id))
  }

  return (
      <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
        <div className="flex justify-center">
          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Model Name
            </div>
            <div className='flex mt-[0px]'>
              <input className="border-[1px] text-ellipsis border-gray-200 rounded-lg py-[2px] px-[5px] flex-1 h-[40px] w-[full] pr-[50px] font-[400] text-[14px] pl-[20px] relative top-[8px] overflow-hidden" value={editedName != null ? editedName : node.data.model_name} onChange={(e) => editName(e)}/>
              <div className='relative'>
              <div className={editedName != null ? 'absolute right-[5px] top-[17px] flex transition-all transition-[100ms] ease-in bg-white' : 'absolute right-[5px] top-[17px] flex hidden'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={editedName ? "w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" : "w-[22px] h-[22px] p-[1px] text-white"} onClick={() => { if (editedName) saveNameEdit() }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" onClick={() => cancelNameEdit()}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              </div>
            </div>
          </div>
        </div>
        <Divider className="my-[20px]" />
        <div className='relative'>
          <div className="mt-[10px] rounded-md grow overflow-y-auto mb-[80px] pb-[0px] bg-[#f0f0f0]">
            <CodeMirror
                    id="sql-code"
                    className='relative'
                    width="100%"
                    extensions={[
                      sql(),
                      EditorView.lineWrapping,
                      EditorView.theme({
                        ".cm-activeLineGutter": {
                          color: 'black',
                          fontWeight: '500',
                          backgroundColor: 'transparent',
                        },
                        ".cm-content": {
                          paddingRight: '0px !important'
                        },
                        ".cm-scroller": {
                          paddingBottom: '30px !important',
                          backgroundColor: '#f0f0f0'
                        },
                        ".cm-line.cm-activeLine": {
                          backgroundColor: '#e8e8e8',
                          borderRadius: '4px',
                        },
                        ".cm-gutters": {
                          backgroundColor: "#f0f0f0",
                        },
                      }),
                    ]}
                    value={selectedNode.dbt ? selectedNode.dbt : dbtModelContent}
                    editable={false}
                  >
                </CodeMirror>
                <button onClick={() => openModel()} className='absolute bottom-[85px] right-[10px] z-50 bg-white rounded-md px-[10px] py-[3px] border-gray-300 border-[1px] rounded-md text-[12px] hover:bg-gray-100'> Edit </button>
            </div>
        </div>
      </div>
    
  );
};
