import './style.scss';
import { useContext, useEffect, useState } from 'react';
import { Spin, Result } from 'antd';

import { ConnectorContext } from '../../../../../../context/ConnectorProvider';
import { LoadingOutlined } from '@ant-design/icons';

const SuccessResult = ({setResultStatus}) => {
  const { createConnectorData, getConnectorLink, editLink, setEditLink } = useContext(ConnectorContext);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [redirectComplete, setRedirectComplete] = useState(false)
  useEffect(() => {
    if (createConnectorData !== null) {
      if(editLink === null){
        getConnectorLink(createConnectorData.data.id).then(link => {
          window.open(link, '_blank')
          setRedirectComplete(true)
        }).catch()
      }
    }
  }, [createConnectorData]);

  return (
    <div className="result-container">
      <Result
        className="result-status"
        status="success"
        title="Successfully Added Connector"
        subTitle={`Status: ${createConnectorData ? createConnectorData.data.status.setup_state : 'N/A'}`}
        extra={[<p key={1}>{!redirectComplete ? "Redirecting..." : ""}</p>, <Spin spinning={!redirectComplete} key={2} indicator={loadingIcon} />]}
      />
    </div>
  );
};

export default SuccessResult;
