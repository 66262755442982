import React, {useContext, useState, useEffect} from 'react';
import { SqlEditorContext } from '../../../../context/SqlEditorProvider';
import { EditorContext } from '../../../../context/EditorProvider';



const SqlSavedMenu = ({ menuItem, setMenuItem, setMenuPos }) => {
    const {
        setNewName,
        setInitialName,
        setIsEditing,
        deleteSavedQuery,
    } = useContext(SqlEditorContext);

    const {
        handleItemDelete
    } = useContext(EditorContext)

    const handleDelete = async (e) => {
        e.stopPropagation()
        await deleteSavedQuery(menuItem._id)
        handleItemDelete(menuItem, 'sql')
        setMenuPos()
        setMenuItem()
    }


    const handleRename = (e) => {
        e.stopPropagation()
        setIsEditing(menuItem._id)
        setNewName(menuItem.name)
        setInitialName(menuItem.name)
        setMenuPos()
        setMenuItem()
    }

    return (
        <div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleDelete(e)}>Delete Query</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleRename(e)}>Rename Query</div>
            </div>
        </div>
    )
}

export default SqlSavedMenu
