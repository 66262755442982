import React, { useContext, useState, useRef, useEffect } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Divider, Spin, Menu, Dropdown} from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import fullJoin from '../joinIcons/fulljoin.png';
import innerJoin from '../joinIcons/innerjoin.png';
import rightJoin from '../joinIcons/rightjoin.png';
import leftJoin from '../joinIcons/leftjoin.png';
import { DownOutlined, LoadingOutlined, EllipsisOutlined } from '@ant-design/icons';
import { EditorView } from "@codemirror/view";
import { EditorContext } from '../../../context/EditorProvider';
import Filters from '../../../components/Workflows/AggregateHelper/Filters';
import Joins from '../../../components/Workflows/AggregateHelper/Joins';
import Grouping from '../../../components/Workflows/AggregateHelper/Groupings';
import Ordering from '../../../components/Workflows/AggregateHelper/Ordering';
import Col from '../../../components/Workflows/AggregateHelper/Col';
import Func from '../../../components/Workflows/AggregateHelper/Function';
import CustomSchema from '../../../components/Workflows/CustomSchema';
import CustomMaterialization from '../../../components/Workflows/Materialization';

export default () => {
  const {
    nodes,
    selectedNode,
    setSelectedNode,
    updateNodes,
    getSelectedNode,
    getParents,
    mode,
    setMode,
    handleNodeRename,
    fetchSelectedNodeInfo,
    workflowAI,
    getDbtModelFile,
    setSelectedNodeEdited,
  } = useContext(TableGraphContext);

  if(selectedNode.loading){return <div></div>}
  const {
    openDbtFileFromNode
  } = useContext(EditorContext)

  const [editedName, setEditedName] = useState();
  
  const [joinMenusVisible, setJoinMenusVisible] = useState([]);
  const [selectColumnOne, setSelectColumnOne] = useState(false);
  const [selectColumnTwo, setSelectColumnTwo] = useState(false);
  const [showCols, setShowCols] = useState({})
  const [inputMessage, setInputMessage] = useState('')
  const textRef = useRef();
  const [dbtModelContent, setDbtModelContent] = useState('');
  const [loading, setLoading] = useState(false);

  let node = getSelectedNode();
  
  const editName = e => {
    setEditedName(e.target.value);
  };

  const saveNameEdit = async () => {
    const old_name = node.data.model_name
    let response = await handleNodeRename(editedName, old_name)
    if (response && response.status === 'error') {
      setEditedName(old_name)
    } else {
      setEditedName()
    }
  }

  const cancelNameEdit = () => {
    setEditedName()
  }

   useEffect(() => {
        if (selectedNode.data?.joins) {
            setJoinMenusVisible(selectedNode.data.joins.map(() => false))
        }
  }, [selectedNode.data?.joins])

  useEffect(() => {
    if(mode != false){
      fetchDBTModelFile();
    }
  },[mode, selectedNode.data?.model_id])


  const fetchDBTModelFile = async () => {
    setLoading(true);
    try {
      const response = await getDbtModelFile(selectedNode.data.model_id); // Assuming `selectedNode.id` is needed for the API call
      if (response) {
        setDbtModelContent(response.data); // Assuming the response is the content you need
      }
    } catch (error) {
      console.error("Failed to fetch DBT model file:", error);
      setDbtModelContent(''); // Reset content on error
    } finally {
      setLoading(false);
    }
  };

  
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const modeChange = async val => {
    setMode(val);
  };

  const onChangeHandler = (e) => {
    const target = e.target;
    textRef.current.style.height = "35px";
    textRef.current.style.height = `${target.scrollHeight + 2}px`;
  };

  const handeKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (inputMessage) {
        setSelectedNode(prev => {return {...prev, loading: true}})
        workflowAI(inputMessage)
      }
    }
  }

  const handleInputChange = (event) => {
    // console.log('event:', event)
    setInputMessage(event.target.value);
  }
  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setSelectedNode(prev => {return {...prev, data: {...prev.data, included_columns: []}}})
    } else if (e.key === 'add') {
      
      const columns = selectedNode?.data?.columns
      const updated_included_columns = [...selectedNode?.data?.included_columns]
      const included_cols_set = new Set();
      selectedNode?.data?.included_columns.forEach(col => {
        included_cols_set.add(col.model_id + '.' + col.column_name)
      })
     for (const model_id in columns){
        for(const column_name of columns[model_id].column_list){
          if (included_cols_set.has(model_id + '.' + column_name)) {
            continue
          } else { 
            updated_included_columns.push({model_id: model_id, column_name: column_name, aggregate_function: null,
            extra_arguments: null,
            alias: null,
            require_function: (selectedNode.data.groupings.length > 0 && !selectedNode.data.groupings.find(grouping => grouping.column_name === column_name && grouping.model_id === model_id)),
            model_name: columns[model_id].model_name})
          }
        }}
      setSelectedNode(prev => {return {...prev, data: {...prev.data, included_columns: updated_included_columns}}})
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="delete">Delete All Columns</Menu.Item>
      <Menu.Item key="add">Add All Columns</Menu.Item>
    </Menu>
  );

  const setJoinMenu = (visible, i) => {
    setJoinMenusVisible(prev => prev.map((item, index) => index === i ? visible : item))
  }

  if (selectedNode.error) {
    return (
      <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
        <div className="flex justify-center">
          <div className='w-full relative'>
            {selectedNode.errorMessage}
          </div>
        </div>
      </div>
    )
  }
  const JoinType = (index) => {

    if (selectedNode.data.joins && selectedNode.data.joins[index].join_type && selectedNode.data.joins[index].join_type == 'FULL OUTER')

      return (
        <div className="flex text-black bg-gray-200 pl-[5px] hover:bg-gray-300 rounded" onClick={() => {setJoinMenu(true, index)}}>
          <img src={fullJoin} className="w-[25px] h-[25px]" />
          <div className="relative top-[4px] left-[5px]">Outer join</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="relative my-[5px] ml-[10px] mr-[5px] w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      );

    else if (selectedNode.data.joins && selectedNode.data.joins[index].join_type && selectedNode.data.joins[index].join_type == 'INNER' || selectedNode.data.joins[index].join_type == 'JOIN')

      return (
        <div className="flex text-black bg-gray-200 pl-[5px] hover:bg-gray-300 rounded" onClick={() => {setJoinMenu(true, index)}}>
          <img src={innerJoin} className="w-[25px] h-[25px]" />
          <div className="relative top-[4px] left-[5px]">Inner join</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="relative my-[5px] ml-[10px] mr-[5px] w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      );
    else if (selectedNode.data.joins && selectedNode.data.joins[index].join_type && selectedNode.data.joins[index].join_type == 'LEFT')
      return (
        <div className="flex text-black bg-gray-200 pl-[5px] hover:bg-gray-300 rounded" onClick={() => {setJoinMenu(true, index)}}>
          <img src={leftJoin} className="w-[25px] h-[25px]" />
          <div className="relative top-[4px] left-[5px]">Left join</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="relative my-[5px] ml-[10px] mr-[5px] w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      );
    else
      return (
        <div className="flex text-black bg-gray-200 pl-[5px] hover:bg-gray-300 rounded" onClick={() => {setJoinMenu(true, index)}}>
          <img src={rightJoin} className="w-[25px] h-[25px]" />
          <div className="relative top-[4px] left-[5px]">Right join</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="relative my-[5px] ml-[10px] mr-[5px] w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      );
  };
  const openModel = () => {
    openDbtFileFromNode(nodes.find(item => item.id === selectedNode.id))
  }
  return (
      <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
        <div className="flex justify-center">
          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Model Name
            </div>
            <div className='flex mt-[0px]'>
              <input className="border-[1px] text-ellipsis border-gray-200 rounded-lg py-[2px] px-[5px] flex-1 h-[40px] w-[full] pr-[50px] font-[400] text-[14px] pl-[20px] relative top-[8px] overflow-hidden" value={editedName != null ? editedName : node.data.model_name} onChange={(e) => editName(e)}/>
              <div className='relative'>
              <div className={editedName != null ? 'absolute right-[5px] top-[17px] flex transition-all transition-[100ms] ease-in bg-white' : 'absolute right-[5px] top-[17px] flex hidden'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={editedName ? "w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" : "w-[22px] h-[22px] p-[1px] text-white"} onClick={() => { if (editedName) saveNameEdit() }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" onClick={() => cancelNameEdit()}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              </div>
            </div>
          </div>
        </div>

        <CustomSchema node={selectedNode}/>
        <CustomMaterialization node={selectedNode}/>

        <Divider className="my-[20px]" />
          <div className="flex justify-center w-full mb-[10px]">
              <label htmlFor="Toggle3" className="inline-flex items-center rounded-md cursor-pointer w-[80%]">
                <input id="Toggle3" type="checkbox" className="hidden peer" />
                <span
                  className={
                    mode
                      ? 'py-2 w-1/2 text-center rounded-l-md bg-gray-200'
                      : 'py-2 w-1/2 text-center rounded-l-md bg-gray-300'
                  }
                  onClick={() => modeChange(false)}
                >
                  Automatic
                </span>
                <span
                  className={
                    mode
                      ? 'py-2 w-1/2 text-center rounded-r-md bg-gray-300'
                      : 'py-2 w-1/2 text-center rounded-r-md bg-gray-200'
                  }
                  onClick={() => modeChange(true)}
                >
                  dbt
                </span>
              </label>
            </div>
          <div className='flex flex-col grow overflow-auto'>
            { loading ?         
             <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
               <Spin indicator={antIcon} />
             </div> 
            : mode ? (
              <div className='relative '>
              <div className="mt-[10px] overflow-y-auto rounded-md pb-[0px] bg-[#f0f0f0]">
          <CodeMirror
                  id="sql-code"
                  className=' '
                  width="100%"
                  extensions={[
                    sql(),
                    EditorView.lineWrapping,
                    EditorView.theme({
                      ".cm-activeLineGutter": {
                        color: 'black',
                        fontWeight: '500',
                        backgroundColor: 'transparent',
                      },
                      ".cm-content": {
                        paddingRight: '0px !important'
                      },
                      ".cm-scroller": {
                        paddingBottom: '30px !important',
                        backgroundColor: '#f0f0f0'
                      },
                      ".cm-line.cm-activeLine": {
                        backgroundColor: '#e8e8e8',
                        borderRadius: '4px',
                      },
                      ".cm-gutters": {
                        backgroundColor: "#f0f0f0",
                      },
                    }),
                  ]}
                  value={dbtModelContent}
                  editable={false}
                />
                </div>
              <button onClick={() => openModel()} className='absolute bottom-[10px] right-[10px] z-50 bg-white rounded-md px-[10px] py-[3px] border-gray-300 border-[1px] text-[12px] hover:bg-gray-100'> Edit </button>
            </div>     
        ) : (
          /*
           * start of the automatic join sidebar mode
           */
            <>
              <div className="join-scroll-sidebar mt-[10px] text-[12px] cursor-pointer">
                <div className="">
                <div className='flex w-full justify-between'>
                      <div className="text-[12px] text-gray-500 inline-block select-none break-all">
                        Included columns
                      </div>
                      <div>
                      <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link mr-3" onClick={e => e.preventDefault()}>
                        <EllipsisOutlined style={{ fontSize: '20px' }} />
                      </a>
                      </Dropdown>
                      </div>
                    </div>
                    <Col selectedNode={selectedNode} setSelectedNode={setSelectedNode}/>
                </div>
              <div className="text-[12px] text-gray-500 mt-2">Joins</div>
              {selectedNode.data.joins && selectedNode.data.joins.length > 0 ? (
                selectedNode.data.joins.map((join, index) => (
              <div className="mt-2 border border-1 p-2">
              <div className='relative'>
                <div className='flex gap-2'>
                  <div className="flex items-center text-black border border-1 pl-[5px] pr-[5px] rounded">
                    <div className="text-[12px] text-black ">{selectedNode.data.columns[selectedNode.data.base_parent_model_id].model_name}</div>
                  </div>
                  <div>
                    {JoinType(index)}
                    {joinMenusVisible[index] && (
                      <div>
                        <div
                          className="absolute top-[0px] left-[0px] w-full h-full z-[10]"
                          onClick={() => setJoinMenu(false, index)}
                        />
                        <div
                          id="dropdown"
                          className="cursor-pointer z-[99] border-gray-300 bg-white border-[1px] rounded-lg p-[5px] divide-gray-300 w-[130px] shadow absolute"
                        >
                          <ul className="">
                            {['FULL OUTER', 'INNER', 'LEFT', 'RIGHT'].map((joinType, join_type_index) => (
                            <li key={join_type_index}>
                              <div
                                className="flex text-black hover:bg-gray-100"
                                onClick={() => {
                                  setSelectedNode(prev => {
                                    const updatedJoins = prev.data.joins.map((join, i) => {
                                      if (i == index) {
                                        return {
                                          ...join,
                                          join_type: joinType.toUpperCase(),
                                        };
                                      }
                                      return join
                                    });

                                    return { ...prev, data: { ...prev.data, joins: updatedJoins } };
                                  });
                                  setSelectedNodeEdited(true);
                                  setJoinMenu(false, index);
                                }}
                              >
                                <img src={joinType === 'FULL OUTER' ? fullJoin : joinType === 'INNER' ? innerJoin : joinType === 'LEFT' ? leftJoin : rightJoin} className="w-[25px] h-[25px]" />
                                <div className="relative top-[4px] left-[5px]">{`${joinType} JOIN`}</div>
                              </div>
                            </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center text-black border border-1 pl-[5px] pr-[5px] rounded">
                    <div className="text-[12px] text-black ">{join.model_name}</div>
                  </div>
                </div>
              </div>
              <Joins content={join.column_pairs} columns={selectedNode.data.columns} setSelectedNode={setSelectedNode} baseParentModelId={selectedNode.data.base_parent_model_id} joinParentModelId={join.model_id} baseParentModelName={selectedNode.data.columns[selectedNode.data.base_parent_model_id].model_name} joinParentModelName={join.model_name} joinsIndex={index} />
              </div>
                ))) : ( <div></div> )}

                <div className="text-[12px] mt-2 text-gray-500">Filters</div>
                <div className='relative'>
                    <div className=''>
                        <Filters columnList={selectedNode.data.columns} content={selectedNode.data.filters}setSelectedNode={setSelectedNode}/>
                    </div>
                </div>
                <div className="text-[12px] mt-2 text-gray-500">Group By</div>
                <div className='relative'>
                    <div className=''>
                        <Grouping columnList={selectedNode.data.columns} content={selectedNode.data.groupings} setSelectedNode={setSelectedNode}/>
                    </div>
                </div>
                <div className="text-[12px] mt-2 text-gray-500">Order By</div>
                <div className='relative'>
                    <div className=''>
                        <Ordering columnList={selectedNode.data.included_columns} content={selectedNode.data.orderings} setSelectedNode={setSelectedNode}/>
                    </div>
                </div>
              </div>
              <div className="relative grow w-full content-end grid grid-cols-1 mt-2">
                  {/* <div className='border-t-[1px] border-gray-200 pt-2 flex'>
                    <div className='h-full content-end grid grid-cols-1 pb-[12px] mr-[10px]'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" className='w-6 h-6'>
                        <rect width="35" height="35" rx="2.8" fill="transparent"/>
                        <circle cx="10.22" cy="10.6396" r="3.5" fill="#4CB043"/>
                        <rect x="24.7682" y="7.13965" width="7" height="21" rx="3.5" transform="rotate(38.0791 24.7682 7.13965)" className='fill-inherit'/>
                      </svg>
                    </div>
                    <form className=" w-full" onSubmit={(e) => console.log(e, 'submit')}>
                    <div className="w-full">
                      <textarea
                        ref={textRef}
                        id="textarea"
                        className="w-full outline-none overflow-auto h-[37px] max-h-[150px] p-2 resize-none rounded-lg"
                        value={inputMessage}
                        onKeyDown={handeKeyDown}
                        onChange={handleInputChange}
                        onInput={onChangeHandler}
                        placeholder="Change model to..."
                        // disabled={isLoading}
                        autoFocus={true}
                      />
                    </div>
                  </form>
                  </div> */}
                  
                </div>
              </>
            )}
            
          </div>
      </div>
    
  );
};
