import React, { useContext, useState, useRef, useEffect} from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Divider, Spin, Switch, Space, Dropdown, Tooltip, Popconfirm, Menu} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { DownOutlined, CloseOutlined, LoadingOutlined, EllipsisOutlined } from '@ant-design/icons';
import { EditorView } from "@codemirror/view";
import { EditorContext } from '../../../context/EditorProvider';
import Filters from '../../../components/Workflows/AggregateHelper/Filters';
import Grouping from '../../../components/Workflows/AggregateHelper/Groupings';
import Ordering from '../../../components/Workflows/AggregateHelper/Ordering';
import Col from '../../../components/Workflows/AggregateHelper/Col';
import CustomSchema from '../../../components/Workflows/CustomSchema';
import CustomMaterialization from '../../../components/Workflows/Materialization';

export default () => {
    const {
        nodes,
        selectedNode,
        updateNodes,
        getDbtModelFile,
        getSelectedNode,
        mode,
        setMode,
        handleNodeRename,
        fetchSelectedNodeInfo,
        setSelectedNode,
        workflowAI,
        setSelectedNodeEdited,
      } = useContext(TableGraphContext);

    const {
        openDbtFileFromNode
    } = useContext(EditorContext)

    const [editedName, setEditedName] = useState();
    const [showCols, setShowCols] = useState(true)
    const [inputMessage, setInputMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const [dbtModelContent, setDbtModelContent] = useState('');

    useEffect(() => {
      
        fetchDBTModelFile();
      
    },[mode, selectedNode.data?.model_id])


    const fetchDBTModelFile = async () => {
        setLoading(true);
        try {
          const response = await getDbtModelFile(selectedNode.data.model_id); // Assuming `selectedNode.id` is needed for the API call
          if (response) {
            setDbtModelContent(response.data); // Assuming the response is the content you need
          }
        } catch (error) {
          console.error("Failed to fetch DBT model file:", error);
          setDbtModelContent(''); // Reset content on error
        } finally {
          setLoading(false);
        }
    };

    const antIcon = (
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    );

    let node = getSelectedNode();

    const editName = e => {
        setEditedName(e.target.value);
    };
    
    const saveNameEdit = async () => {
      const old_name = node.data.model_name
      let response = await handleNodeRename(editedName, old_name)
      if (response && response.status === 'error') {
        setEditedName(old_name)
      } else {
        setEditedName()
      }
    }

    const cancelNameEdit = () => {
        setEditedName()
    }

    const modeChange = async val => {
      setMode(val);

    };
  
    const openModel = () => {
        openDbtFileFromNode(nodes.find(item => item.id === selectedNode.id))
    }
    
    const textRef = useRef();

    const onChangeHandler = (e) => {
      const target = e.target;
      textRef.current.style.height = "35px";
      textRef.current.style.height = `${target.scrollHeight + 2}px`;
    };
  
    const handeKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        if (inputMessage) {
          setSelectedNode(prev => {return {...prev, loading: true}})
          workflowAI(inputMessage)

        }
      }
    }
  
    const handleInputChange = (event) => {
      setInputMessage(event.target.value);
    }

    const handleMenuClick = (e) => {
      if (e.key === 'delete') {
        setSelectedNode(prev => {return {...prev, data: {...prev.data, included_columns: []}}})
      } else if (e.key === 'add') {
        
        const columns = selectedNode?.data?.columns
        const updated_included_columns = [...selectedNode?.data?.included_columns]
        const included_cols_set = new Set();
        selectedNode?.data?.included_columns.forEach(col => {
          included_cols_set.add(col.model_id + '.' + col.column_name)
        })
       for (const model_id in columns){
          for(const column_name of columns[model_id].column_list){
            if (included_cols_set.has(model_id + '.' + column_name)) {
              continue
            } else { 
              updated_included_columns.push({model_id: model_id, column_name: column_name, aggregate_function: null,
              extra_arguments: null,
              alias: null,
              require_function: (selectedNode.data.groupings.length > 0 && !selectedNode.data.groupings.find(grouping => grouping.column_name === column_name && grouping.model_id === model_id)),
              model_name: columns[model_id].model_name})
            }
          }}
        setSelectedNode(prev => {return {...prev, data: {...prev.data, included_columns: updated_included_columns}}})
      }
    };
  
    const menu = (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="delete">Delete All Columns</Menu.Item>
        <Menu.Item key="add">Add All Columns</Menu.Item>
      </Menu>
    );

    if (selectedNode.error) {
      return (
        <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
          <div className="flex justify-center">
            <div className='w-full relative'>
              {selectedNode.errorMessage}
            </div>
          </div>
        </div>
      )
    }
  
    return (
      <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
        <div className="flex justify-center">
          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Model Name
            </div>
            <div className='flex mt-[0px]'>
              <input className="border-[1px] text-ellipsis border-gray-200 rounded-lg py-[2px] px-[5px] flex-1 h-[40px] w-[full] pr-[50px] font-[400] text-[14px] pl-[20px] relative top-[8px] overflow-hidden" value={editedName != null ? editedName : node.data.model_name} onChange={(e) => editName(e)}/>
              <div className='relative'>
              <div className={editedName != null ? 'absolute right-[5px] top-[17px] flex transition-all transition-[100ms] ease-in bg-white' : 'absolute right-[5px] top-[17px] flex hidden'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={editedName ? "w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" : "w-[22px] h-[22px] p-[1px] text-white"} onClick={() => { if (editedName) saveNameEdit() }}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[22px] h-[22px] p-[1px] hover:bg-gray-200 rounded-md" onClick={() => cancelNameEdit()}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              </div>
            </div>
          </div>
        </div>

        <CustomSchema node={selectedNode}/>
        <CustomMaterialization node={selectedNode}/>
        <Divider className="my-[20px]" />
           <div className="flex justify-center w-full mb-[10px]">
              <label htmlFor="Toggle3" className="inline-flex items-center rounded-md cursor-pointer w-[80%]">
                <input id="Toggle3" type="checkbox" className="hidden peer" />
                <span
                  className={
                    mode
                      ? 'py-2 w-1/2 text-center rounded-l-md bg-gray-200'
                      : 'py-2 w-1/2 text-center rounded-l-md bg-gray-300'
                  }
                  onClick={() => modeChange(false)}
                >
                  Automatic
                </span>
                <span
                  className={
                    mode
                      ? 'py-2 w-1/2 text-center rounded-r-md bg-gray-300'
                      : 'py-2 w-1/2 text-center rounded-r-md bg-gray-200'
                  }
                  onClick={() => modeChange(true)}
                >
                  dbt
                </span>
              </label>
          </div>
          <div className='grow flex flex-col overflow-auto'>
            {
            loading ?         
             <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
               <Spin indicator={antIcon} />
             </div> 
            : mode ? 
             (
              <div>
                {!dbtModelContent ?            
                <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
                  <Spin indicator={antIcon} />
                </div> 
                : 
                <div className='relative'>
                <div className="mt-[10px] rounded-md grow overflow-y-auto mb-[80px] pb-[0px] bg-[#f0f0f0]">
                  <CodeMirror
                          id="sql-code"
                          className='overflow-y-auto'
                          width="100%"
                          extensions={[
                            sql(),
                            EditorView.lineWrapping,
                            EditorView.theme({
                              ".cm-activeLineGutter": {
                                color: 'black',
                                fontWeight: '500',
                                backgroundColor: 'transparent',
                              },
                              ".cm-content": {
                                paddingRight: '0px !important'
                              },
                              ".cm-scroller": {
                                paddingBottom: '30px !important',
                                backgroundColor: '#f0f0f0'
                              },
                              ".cm-line.cm-activeLine": {
                                backgroundColor: '#e8e8e8',
                                borderRadius: '4px',
                              },
                              ".cm-gutters": {
                                backgroundColor: "#f0f0f0",
                              },
                            }),
                          ]}
                          value={selectedNode.dbt ? selectedNode.dbt : dbtModelContent}
                          editable={false}
                        >
                      </CodeMirror>
                      <button onClick={() => openModel()} className='absolute bottom-[10px] right-[10px] z-50 bg-white px-[10px] py-[3px] border-gray-300 border-[1px] rounded-md text-[12px] hover:bg-gray-100'> Edit </button>
                  </div>
              </div>
                }
            </div>                
          ) : (
              <>
              <div className="join-scroll-sidebar mt-[10px] text-[12px]">
                <div className="">

                 <div className='flex w-full justify-between'>
                        <div className="text-[12px] text-gray-500 inline-block select-none break-all">
                          Included columns
                      </div>
                      <div>
                      <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link mr-3" onClick={e => e.preventDefault()}>
                        <EllipsisOutlined style={{ fontSize: '20px' }} />
                      </a>
                      </Dropdown>
                      </div>
                    </div>
                      {
                        showCols && selectedNode.data?.included_columns && (
                          <div>
                            <div className='relative'>
                                <Col selectedNode={selectedNode} setSelectedNode={setSelectedNode}/>
                            </div>
                           </div>
                          )
                        }

                  </div>
                  { showCols && selectedNode.data?.included_columns && ( 
                  <div>
                    <div className="text-[12px] mt-2 text-gray-500">Filters</div>
                    <div className='relative'>
                          <Filters columnList={selectedNode.data.columns} content={selectedNode.data.filters} setSelectedNode={setSelectedNode}/>
                    </div>
                    <div className="text-[12px] mt-2 text-gray-500">Group By</div>
                    <div className='relative'>
                          <Grouping columnList={selectedNode.data.columns} content={selectedNode.data.groupings} setSelectedNode={setSelectedNode}/>
                    </div>
                    <div className="text-[12px] mt-2 text-gray-500">Order By</div>
                    <div className='relative'>
                          <Ordering columnList={selectedNode.data.included_columns} content={selectedNode.data.orderings} setSelectedNode={setSelectedNode}/>
                    </div>
                  </div>
                  )}
              </div>
              </>
            )}
          
          </div>
      </div>
    );
};
