import { createContext, useState, useEffect } from 'react';

import LetoClient from './LetoClient';
import { useAuthInfo } from '@propelauth/react';

export const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
    const [purchaseCreditModalVisible, setPurchaseCreditModalVisible] = useState(false)

    const create_payment_intent = async (amount) => {
        const response = await LetoClient.create_payment_intent(amount)
        if (response.error) {
            console.log("error!")
            console.log(response.error)
        }
        else {
            window.open(response.redirect_url, '_self')
        }
    }

    return (
        <StripeContext.Provider
            value={{
                create_payment_intent,
                purchaseCreditModalVisible,
                setPurchaseCreditModalVisible,
            }}>
            {children}
        </StripeContext.Provider>
    )
}
