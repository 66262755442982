import React, { useState, useContext } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import { EditorContext } from '../../context/EditorProvider';

const EditorToolbar = ({ tab, toolbarFunctions }) => {

  const {
    limitActive,
    setLimitActive
  } = useContext(EditorContext)


  const [openLimit, setOpenLimit] = useState()
  
  const thisIcon = (index) => {
    switch (index) {
      case 0:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" />
          </svg>
        );
      case 1:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className='w-6 h-6 bg-transparent fill-transparent'>
            <circle cx="8" cy="10.6396" r="4" strokeWidth={2} stroke="currentColor" />
            <rect x="22.7682" y="8.13965" width="7" height="21" rx="3.5" strokeWidth={2} stroke="currentColor" transform="rotate(38.0791 24.7682 7.13965)"/>
          </svg>
        );
      case 2:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
          </svg>
        );
      case 3:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
        )
      default:
        return <div/>;
    }
  }

  const get_toolbar_component = (toolbar_component_name, toolbar_component_function, index) => {
    switch(toolbar_component_name) {
      case "save":
        return (
          <div className='flex cursor-pointer'>
            <Tooltip message='Save (⌘ + S)' side='left'>
              <div 
                key={0}
                className={`px-2 py-1 flex transition-[0.3s] ease h-[40px] text-[#686868] ${index === 0 ? 'rounded-l-lg' : ''} hover:bg-gray-200 items-center justify-center border-r-[1px]`}
                onClick={() => toolbar_component_function(tab.id)}   
              >
                {thisIcon(0)}
              </div>
            </Tooltip>
          </div>
        )
      case "copilot":
        return (
          <div className='flex cursor-pointer'>
            <Tooltip message='Copilot (⌘ + Space)' side='left'>
              <div 
                key={1}
                className={`px-2 py-1 flex transition-[0.3s] ease h-[40px] text-[#686868] ${index === 0 ? 'rounded-l-lg' : ''} hover:bg-gray-200 items-center justify-center border-r-[1px]`}
                onClick={() => toolbar_component_function(tab.id)}   
              >
                {thisIcon(1)}
              </div>
            </Tooltip>
          </div>
        )
      case "run":
        return (
          <div className='flex cursor-pointer'>
            <Tooltip message='Run (⌘ + Enter)' side='left'>
              <div 
                key={2}
                className={`px-2 py-1 flex  transition-[0.3s] ease h-[40px] text-[#686868] ${index === 0 ? 'rounded-l-lg' : ''} hover:bg-gray-200 items-center justify-center`}
                onClick={() => toolbar_component_function(tab.id)}   
              >
                {thisIcon(2)}
              </div>
            </Tooltip>
          </div>
        )
      case "run_limit":
        return (
          <div className='flex cursor-pointer relative'>
            { openLimit && 
              <div className='absolute top-[-70px] right-0 h-[60px] w-[130px] bg-white border border-1 z-10 px-3 py-2 border-gray-200 rounded-lg cursor-default'>
                <div className='font-[500]'>
                  Run Options
                </div>
                <div className="flex flex gap-2 items-center cursor-pointer" onClick={() => { setLimitActive(prev => !prev); setOpenLimit(false) }}>
                  <label className="relative flex items-center rounded-full" htmlFor="checkbox">
                    <input type="checkbox"
                      className="peer relative h-5 w-5 appearance-none cursor-pointer rounded-md border border-blue-gray-200 transition-all checked:border-gray-900 checked:bg-gray-900 "
                      id="checkbox" checked={limitActive} onChange={() => { return }}
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                      </svg>
                    </span>
                  </label>
                  Limit 100
                  </div>
              </div>
            }
            <Tooltip message='Run (⌘ + Enter)' side='left'>
              <button 
                key={2}
                className={`px-2 py-1 flex  transition-[0.3s] ease h-[40px] text-[#686868] hover:bg-gray-200 items-center justify-center border-r-[1px] ${openLimit && 'cursor-not-allowed'}`}
                onClick={() => toolbar_component_function(tab.id)} 
                disabled={openLimit}
                
              >
                {thisIcon(2)}
                {limitActive && '(Limit 100)'}
              </button>
            </Tooltip>
            <div className='flex px-1 items-center hover:bg-gray-200 rounded-r-lg' onClick={() => setOpenLimit(prev => !prev)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg>
            </div>
          </div>
        )
      case "preview":
        return (
          <div className='flex cursor-pointer'>
            <Tooltip message='Preview (⌘ + P)' side='left'>
              <div 
                key={3}
                className={`px-2 py-1 flex  transition-[0.3s] ease h-[40px] text-[#686868] ${index === 0 ? 'rounded-l-lg' : ''} hover:bg-gray-200 items-center justify-center border-r-[1px]`}
                onClick={() => toolbar_component_function(tab.id)}   
              >
                {thisIcon(3)}
              </div>
            </Tooltip>
          </div>
        )
    }
  }


  return (
    <div className="bg-white border-1 w-fit m-2 z-10 border-[1px] border-gray-200 rounded-lg">
      <div className="flex items-center justify-center">
        {Object.entries(toolbarFunctions).map(([toolbar_component_name, toolbar_component_function], index) => {
           return get_toolbar_component(toolbar_component_name, toolbar_component_function, index) 
        })}
      </div>
    </div>
  )
}

export default EditorToolbar;

