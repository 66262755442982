import './style.scss';

const ActionLink = ({ text, onClick }) => {
  return (
    <p className="action-link" onClick={onClick}>
      {text}
    </p>
  );
};

export default ActionLink;
