import { createContext, useState, useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';

export const UserContext = createContext();

export const UserProvider = ({ warehouseType, children }) => {
  const authInfo = useAuthInfo();
  const [user, setUser] = useState();
  const [noWarehouse, setNoWarehouse] = useState(null)

  useEffect(() => {
    if (authInfo.user) {
      setUser({
        userId: authInfo.user.userId,
        name: authInfo.user.firstName + ' ' + authInfo.user.lastName,
        first_name: authInfo.user.firstName,
        email: authInfo.user.email,
        pictureUrl: authInfo.user.pictureUrl,
        currentOrg: authInfo.orgHelper.getOrgIds(),
        accessToken: authInfo.accessToken,
      })
    }

  }, [authInfo.user])

  useEffect(() => {
    if (warehouseType !== null) {
      setNoWarehouse(!warehouseType)
    }
  }, [warehouseType])


  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        noWarehouse,
        setNoWarehouse,
      }}
    >
      {user && children}
    </UserContext.Provider>
  );
};
