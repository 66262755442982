import React, { useState, useContext } from 'react';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import { Spin } from 'antd';

const CSVUploadModal = ({ isOpen, onClose }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const {
        uploadSeedCSV, setIsCSVModalOpen,
        isCSVModalLoading, setIsCSVModalLoading
    } = useContext(ModelEditorContext);

    const handleUpload = async () => {
        setIsCSVModalLoading(true)
        await uploadSeedCSV(selectedFile);
        onClose(true)
    };

    const getTruncatedFileName = (file) => {
        if (!file || !file.name) return "seeds/";
        return 'seeds/' +  (file.name.length > 25 ? file.name.substring(0, 22) + "..." : file.name)
    };

    const isFileSizeTooLarge = (file) => {
        // Assuming around 2MB for a CSV with 10k rows
        return file && file.size > 2 * 1024 * 1024;
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">

      <Spin spinning={isCSVModalLoading}>
            <div className="text-center bg-gray-200 rounded-lg shadow py-5 w-[25vw] min-w-[300px] px-5">
                <h3><b>Upload CSV File</b></h3>
                    <div className='mx-5 my-5 justify-center'>
                        <input className='w-[200px] ml-6' type="file" onChange={handleFileChange} />
                        <div className='truncate grow'>
                            {!selectedFile || !selectedFile.name ? 'seeds/' : 'seeds/' + selectedFile.name}
                        </div>
                    </div>
                  {isFileSizeTooLarge(selectedFile) && <div className="text-red-500 my-2">Large file size detected... expecting long upload time</div>}
                
                <div className='mt-2'>
                    <button 
                className="border border-gray-600 px-1 rounded-sm mr-3 justify-center items-center bg-[#F7F7F7]"
      onClick={handleUpload}>Upload</button>
                    <button 
                className="border border-gray-600 px-1 rounded-sm justify-center items-center bg-[#F7F7F7]"

      onClick={onClose}>Cancel</button>
                </div>
            </div>
      </Spin>
        </div>
    );
};

export default CSVUploadModal;

