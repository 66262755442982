import { useEffect, useState, useContext } from 'react';
import Dropdown from './Dropdown';
import { Popover, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TableGraphContext } from '../../../context/TableGraphProvider';

const Grouping = ({ columnList, content, setSelectedNode, selectedNode  }) => {
    const {setSelectedNodeEdited} = useContext(TableGraphContext);
    const [dropdownColumnList, setDropdownColumnlist] = useState()
    const [columnSelected, setColumnSelected] = useState()
    const [visible, setVisible] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState([])

    useEffect(() => {
        const optionsArray = Object.entries(columnList).flatMap(([id, { model_name, column_list }]) =>
        column_list.map((columnName) => ({
            value: columnName,
            label: `${model_name}.${columnName}`, 
            model_name: model_name,
            model_id: id,
        }))
        );
        setDropdownColumnlist(optionsArray) 
    
    }, [columnList])

    useEffect(() => {
        if (content) {
            setFiltersVisible(content.map(() => false))
        }
        handleVisibleChange(false)
    }, [content])

    const handleVisibleChange = (visible, i = null) => {
        if (i === null) {
            if (visible) {
                setColumnSelected(null)
            }
            setVisible(visible)
        } else {
            if (visible) {
                setColumnSelected({'value' : content[i].column_name, 'model_id': content[i].model_id, 'model_name': content[i].model_name, 'label': content[i].model_name + '.' + content[i].column_name})
            }
            setFiltersVisible(prev => prev.map((item, index) => index === i ? visible : item))
        }
    }

    const handleVisibleChangeWithIndex = (i) => (visible) => {
        handleVisibleChange(visible, i)
    }
    
    const handleClose = (i = null) => {
        if (i === null) {
            setVisible(false)
        }
        else {
            setFiltersVisible(prev =>  prev.map((item, index) => index === i ? false : item))
        }
    }

    const handleSave = (i = null) => {
        setSelectedNode(prev => {
            if (i === null) {
                return { ...prev, data:{...prev.data, groupings: content ? [...content, { column_name: columnSelected.value, model_id: columnSelected.model_id, model_name: columnSelected.model_name}] : [{ column_name: columnSelected.value, model_id: columnSelected.model_id, model_name: columnSelected.model_name}]}}
            }
            return {...prev, data: {...prev.data, groupings: content.map((item, index) => index === i ? { column_name: columnSelected.value, model_id: columnSelected.model_id , model_name: columnSelected.model_name} : item)}}
        })
        setSelectedNodeEdited(true)
        handleClose(i)
    }

    const handleDelete = (i) => {
        setSelectedNode(prev => {
            return {...prev, data: {...prev.data, groupings: content.filter((item, index) => index !== i)}}
        })
        setFiltersVisible(prev => prev.filter((item, index) => index !== i ))
    }

    const filter = (i = null) => {
        return(
            <div style={{ width: '300px' }}>
                <div style={{ 'marginBottom': '10px' }}>
                    <Dropdown
                        selected={columnSelected ? columnSelected : null}
                        dropdownList={dropdownColumnList}
                        setSelected={(e) => setColumnSelected(e)}
                        loading={false}
                        disabled={false}
                        clearable={true}
                        placeholder='Select column...'
                    />
                </div>
                <div style={{ 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={columnSelected ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!columnSelected} onClick={() => handleSave(i)} block>
                        Accept
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose(i)} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }
    return(
        <div>
            { content && content.map((item, i) => {
                return(
                    <div key={i}>
                        <Popover placement="rightBottom" content={filter(i)} trigger="click" visible={filtersVisible[i]} onVisibleChange={handleVisibleChangeWithIndex(i)}>
                            <button className='rounded-[4px] w-full py-[5px] bg-transparent px-[15px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                                <div className='flex align-center h-full w-full' >
                                    <div className=''>{`${item.model_name}.${item.column_name}`}</div>
                                    <div className='grow '>
                                        <Tooltip title='Delete'>
                                            <button className='relative float-right hover:text-red-400 relative top-[-3px]' onClick={() => handleDelete(i)}>
                                                <DeleteOutlined/>
                                            </button>
                                        </Tooltip>
                                    </div>    
                                </div>
                            </button>
                        </Popover>
                    </div>
                )
            })}
            <Popover placement="rightBottom" content={filter()} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
                <div className='bg-gray-200 hover:bg-gray-300 rounded p-1 flex justify-center mt-[5px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                    </svg>
                    add grouping
                </div>
            </Popover>
        </div>
    )
};

export default Grouping;
