import React, { useEffect, useContext, useRef, useState} from 'react';
import { TableGraphContext } from '../../context/TableGraphProvider';
import './styles.scss';
import { useHistory } from "react-router-dom";
import { Popconfirm, Tooltip } from 'antd';
import { ApartmentOutlined } from '@ant-design/icons';

function Toolbox({dag_id}) {
  const {
    nodes,
    setNodes,
    setOpenSidebar,
    setSelectedNode,
    setForceDagSave,
    setEdges,
    executeDag,
    SSE_executeDag,
    SSE_executeDagNode,
    saveNodeChanges,
    selectedNode,
    saveDAG,
    setEditedNodes,
    setSelectedNodeEdited,
    sortDag,
    reactFlowInstance,
    reactFlowSize,
    previewDagNode,
    openConsole,
    setOpenConsole,
  } = useContext(TableGraphContext);
  const history = useHistory()
  const saveNodeChangesRef = useRef(saveNodeChanges);

  useEffect(() => {
    saveNodeChangesRef.current = saveNodeChanges;
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        saveNodeChangesRef.current(); 
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []); 


  const runDag = () => {
    SSE_executeDag(dag_id)
  }

  const organizeDag = async () => {
    try {
      const response = await sortDag();
      if (response.status == "success") {
        await saveDAG();
        reactFlowInstance.fitView();
      }
    } catch (error) {
      console.error('Error organizing DAG:', error);
    }
  };
  

  const runNode = () => {
    SSE_executeDagNode(nodes.find((val) => val.id === selectedNode.id))
  }

  const confirmDelete = () => {
    clearDag()
  };

  const confirmDeleteNode = () => {
    setNodes(nodes.filter((val) => val.id !== selectedNode.id))
    setSelectedNode(null)
    saveDAG()
  }

  const clearDag = () => {
    setNodes([])
    setEdges([])
    setForceDagSave(true)
  }

  const saveNode = () => {
    saveNodeChanges()
  }

  const handleIconClick = async (iconIndex) => {
    if (iconIndex == 2){
        history.push(`/chat/${dag_id}`);
        return
    }

    if(iconIndex == 4){
      await organizeDag()
      return
    }

    if (iconIndex == 3) {
      setOpenConsole(!openConsole)
      return
    }
    const { x, y, zoom } = reactFlowInstance.toObject().viewport
    const newPosition = { x: -(x - reactFlowSize.width/2) / zoom, y: -(y - reactFlowSize.height/2) / zoom }

    const nodeType = (iconIndex === 0) ? 'source' : (iconIndex === 1) ? 'logic' : 'chatApp';

    // Create the new node
    let node_id = Date.now().toString()
    const newNode = {
      id: node_id, // For simplicity, we're using a timestamp as a unique id
      type: nodeType,
      position: newPosition,
      data: { type: nodeType, columns: [], model_id: null, last_executed : null}
    };

    if (nodes)
      await setNodes((prevNodes) => [...prevNodes, newNode]);
    else
      await setNodes([newNode]);
    selectedNode && selectedNode.edited && setEditedNodes(prev => ({...prev, [selectedNode.id]: selectedNode}));
    await setSelectedNode({ id: node_id })
    setOpenSidebar(true)
    setForceDagSave(true)
  }


  const thisIcon = (index) => {
    if (index == 0) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
        </svg>
      )
    }
    if (index == 1) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
        </svg>

      )
    }
    if (index == 2) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" className='w-6 h-6'>
          <rect width="35" height="35" rx="2.8" fill="none" stroke="none"/>
          <circle cx="10.22" cy="10.6396" r="3.5" fill="#4CB043"/>
          <rect x="24.7682" y="7.13965" width="7" height="21" rx="3.5" transform="rotate(38.0791 24.7682 7.13965)"/>
        </svg>
      )
    }
    if (index == 4){
      return(
        <div>
          <ApartmentOutlined/>
        </div>
      )
    }
    if (index == 3) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
        </svg>
      
      )
    }


    return (
      <div/>
    )
  }

  return (
    <div className="p-1 z-10 pr-6">
        <div className="w-full flex flex-row justify-end gap-x-1">
          {['source', 'logic', 'chat', 'console', 'sort'].map((tool, index) => (
            <div 
              key={index} 
              className="toolbox-icons flex flex-col items-center cursor-pointer"
              onClick={() => handleIconClick(index)}   
              >
              {thisIcon(index)}
              <span
                className="toolbox-text text-xs"
                style={{fontSize: "11.5px"}}
              >{tool}</span>
            </div>


          ))}
          <div className='border-l-[1.7px] border-[#A3A3A3] border-opacity-30 my-[5px]' /> 
          {
            selectedNode ? 
              <>
                <div className="run-toolbox-icons flex flex-col items-center ml-[4px]" onClick={() => runNode()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}  className="justify-center mb-[-2px]">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                  <span className='toolbox-text text-xs' style={{fontSize: "11.8px"}}>node</span>
                </div> 
                <div className="toolbox-icons flex flex-col items-center" onClick={() => saveNode()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="justify-center w-[24px] h-[24px] mb-[-2px]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                  </svg>
                  <span className='toolbox-text text-xs' style={{fontSize: "11.8px"}}>save</span>
                </div>
                <Popconfirm
                  title={`Are you sure you want to delete ${selectedNode && selectedNode.data && selectedNode.data.model_name ? selectedNode.data.model_name : "this node"}?`}
                  onConfirm={confirmDeleteNode}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomRight"
                  okButtonProps={{ 
                    className: 'bg-[#5A7554] focus:bg-[#3D6343] hover:bg-[#34563A] hover:shadow-md ',
                    style: {width: '60px', borderRadius: '5px'} }} 
                  cancelButtonProps={{
                    className: 'border-solid border-[1.5px] border-[#5A7554] hover:border-[#34563A] hover:shadow-md rounded-sm hover:bg-white focus:bg-white text-[#5A7554] focus:text-[#34563A] hover:text-[#34563A]',
                    style: {width: '60px',  borderRadius: '5px'}
                  }}
                  >
                  <div className="delete-toolbox-icons flex flex-col items-center">
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-6 h-6'>
                      <path fill="currentColor" strokeWidth="1" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
                    </svg>
                    <span className='toolbox-text text-xs' style={{fontSize: "11.8px"}}>delete</span>
                  </div>
                </Popconfirm>
                
              </>
              :
              <>
                <div className="run-toolbox-icons flex flex-col items-center ml-[4px]" onClick={() => runDag()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}  className="justify-center mb-[-2px]">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                  <span className='toolbox-text text-xs' style={{fontSize: "11.8px"}}>run</span>
                </div> 
                <Popconfirm
                  title="Are you sure you want to clear the workflow?"
                  onConfirm={confirmDelete}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomRight"
                  okButtonProps={{ 
                    className: 'bg-[#5A7554] focus:bg-[#3D6343] hover:bg-[#34563A] hover:shadow-md ',
                    style: {width: '60px', borderRadius: '5px'} }} 
                  cancelButtonProps={{
                    className: 'border-solid border-[1.5px] border-[#5A7554] hover:border-[#34563A] hover:shadow-md rounded-sm hover:bg-white focus:bg-white text-[#5A7554] focus:text-[#34563A] hover:text-[#34563A]',
                    style: {width: '60px',  borderRadius: '5px'}
                  }}
                  >
                  <div className="delete-toolbox-icons flex flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="justify-center w-[24px] h-[24px] mb-[-2px]">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <span className='toolbox-text text-xs' style={{fontSize: "11.8px"}}>clear</span>
                  </div>
                </Popconfirm>
              </>
          }
        </div>
    </div>
  );
}

export default Toolbox;
