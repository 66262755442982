import { createContext, useState, useEffect } from 'react';
import LetoClient from './LetoClient';

export const LandingContext = createContext();

export const LandingProvider = ({ children }) => {
  
    const [recentWorkflows, setRecentWorkflows] = useState()
    const [recentChartboards, setRecentChartboards] = useState()
    const [recentModels, setRecentModels] = useState()


    useEffect(() => {
        getActivity()
    }, [])

    const getActivity = async (models=false) => {
      if (models) {
        let response = await LetoClient.getUserActivity(models)
        setRecentModels(response.models)
      }
      else {
        let response = await LetoClient.getUserActivity()
        setRecentWorkflows(response.workflows)
        setRecentChartboards(response.analytics)
      }
        
    }

  return (
    <LandingContext.Provider
        value={{
            recentWorkflows,
            setRecentWorkflows,
            recentChartboards,
            setRecentChartboards,
            getActivity,
            recentModels, 
            setRecentModels,
        }}
    >
      {children}
    </LandingContext.Provider>
  );
};
