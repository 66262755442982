import { createContext, useState, useEffect } from 'react';

import FivetranApi from './FivetranAPI';
import LetoClient from './LetoClient';
import { useAuthInfo } from '@propelauth/react';

export const ConnectorContext = createContext();

export const ConnectorProvider = ({ children }) => {
  const [currentWorkspace, setCurrentWorkSpace] = useState('All');
  const [workspaces, setWorkspaces] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [connectorsMeta, setConnectorsMeta] = useState([]);
  //connector modal
  const [visible, setVisible] = useState(false);
  //loading for create connector
  const [loading, setLoading] = useState(false);
  const [createConnectorData, setCreateConnectorData] = useState(null);
  //status for results after adding connector
  const [resultStatus, setResultStatus] = useState(false);
  //fivetran link
  const [editLink, setEditLink] = useState(null);
  //loading changes for edit connector
  const [applyLoading, setApplyLoading] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [pauseAllLoading, setPauseAllLoading] = useState(false)

  //connector schema
  const [schema, setSchema] = useState(null);
  const [connectorWorkspaces, setConnectorWorkspaces] = useState({});
  const authInfo = useAuthInfo();

  // Retrieve list of all Fivetran connectors
  const getMetadata = async () => {
    try {
      const data = await LetoClient.getMetadata();

      setConnectorsMeta(data);
    } catch (err) {
      console.log('Unable to retrieve connectors metadata');
      console.log(err);
      setConnectorsMeta([]);
    }
  };

  // Retrieve list of connectors for a specific group
  //	pass a 'group_id' parameter to the API handler
  const getConnectors = async () => {
    try {
      if (LetoClient.getGroupId() != '') {
        const data = await LetoClient.getConnectorsInGroup();
        setConnectors(data);
      }
    } catch (err) {
      console.log('Unable to retrieve connectors');
      console.log(err);
      //setConnectors([])
    }
  };

  const createConnectors = async (service, name) => {
    try {
      setLoading(true);
      const connector_ft_name = name.replace(/ /g, '_').toLowerCase();
      for (var num in connectors) {
        if (connectors[num].schema.includes(name)) {
          throw { 'code': 'Name Error', 'message': 'Name is a substring of another connector. Please input a name that is not a substring of another connector.' }
        }

      }
      const temp_check = await LetoClient.tempCheck(connector_ft_name)
      if (temp_check === 'conflict') {
        throw { 'code': 'Name Error', 'message': 'Name unavaliable. Please choose a new name' }
      }
      const request_body = {
        service: service,
        paused: false,
        sync_frequency: 1440,
        config: {
          schema: connector_ft_name, schema_prefix: connector_ft_name, table: connector_ft_name,
        }
      }
      const data = await LetoClient.createFTConnector(request_body);
      setCreateConnectorData(data);
      if (data.code === "Success") {
        getConnectors(); //update connectors list
        LetoClient.createConnector(data.data.id, name, data.data.service, data.data.created_at);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('Unable to create connectors');
      console.log(err);
      setCreateConnectorData(err);
    }
  };

  const getConnectorLink = async id => {
    try {
      const host = `http://app.artemisdata.io`;
      // const host = `http://localhost:3000`
      const token = await FivetranApi.getConnectCardTokenForConnector(id);
      const link = `https://fivetran.com/connect-card/setup?auth=${token}&redirect_uri=${host}/data`;
      return link
    } catch (err) {
      console.log('Unable to get connector link');
      console.log(err);
    }
  };

  const deleteConnectors = async id => {
    try {
      const data = await FivetranApi.deleteConnector(id);
      getConnectors(); //update connectors list
    } catch (err) {
      console.log(`Unable to delete connector ${id}`);
      console.log(err);
    }
  };

  const syncConnector = async (connector_id) => {
    setSyncLoading(true)
    const response = await LetoClient.syncConnector(connector_id)
    setSyncLoading(false)
    return response
  }

  const pauseAllConnectors = async (id, body) => {
    setPauseAllLoading(true)
    try {
      const data = await FivetranApi.patchAllConnectors(id, body);
      getConnectors();
      setPauseAllLoading(false)
    }
    catch (err) {
      console.log(`Unable to pause/enable all connectors`);
      console.log(err);
      setPauseAllLoading(false)
    }
  }

  const editConnectors = async (id, body, type) => {
    if (type === "pause") {
      console.log("pause!")
      setPauseLoading(true);
    }
    else {
      setApplyLoading(true);
    }
    try {
      const data = await FivetranApi.patchConnector(id, body);
      getConnectors(); //update connectors list
      setPauseLoading(false)
      setApplyLoading(false);
    } catch (err) {
      console.log(`Unable to edit connector ${id}`);
      console.log(err);
      setPauseLoading(false)
      setApplyLoading(false);
    }
  };

  const editConnectorSchema = async (id, body) => {
    setApplyLoading(true);
    try {
      const data = await LetoClient.patchConnectorSchema(id, body);
      getConnectors(); //update connectors list
      setApplyLoading(false);
    } catch (err) {
      console.log(`Unable to edit connector ${id} schema ${schema}`);
      console.log(err);
      setApplyLoading(false);
    }
  };

  const getConnectorSchema = async id => {
    try {
      const data = await FivetranApi.getConnectorSchema(id);
      setSchema(data);
    } catch (err) {
      console.log('Unable to retrieve connectors');
      console.log(err);
      setSchema(null);
    }
  };

  const getConnectorSchemaColumns = async (id, schema, table) => {
    try {
      const data = await FivetranApi.getConnectorSchemaColumns(id, schema, table);
      return data;
    } catch (err) {
      console.log('Unable to retrieve connectors');
      console.log(err);
      return null;
    }
  };

  useEffect(() => {
    LetoClient.setToken(authInfo.accessToken);
  }, []);

  return (
    <ConnectorContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        currentWorkspace,
        setCurrentWorkSpace,
        connectors,
        setConnectors,
        connectorsMeta,
        getMetadata,
        visible,
        setVisible,
        loading,
        setLoading,
        createConnectors,
        createConnectorData,
        setCreateConnectorData,
        getConnectorLink,
        resultStatus,
        setResultStatus,
        editLink,
        setEditLink,
        deleteConnectors,
        getConnectors,
        syncConnector,
        editConnectors,
        editConnectorSchema,
        applyLoading,
        syncLoading,
        pauseLoading,
        schema,
        setSchema,
        getConnectorSchema,
        getConnectorSchemaColumns,
        connectorWorkspaces,
        pauseAllLoading,
        setPauseAllLoading,
        pauseAllConnectors,
      }}
    >
      {children}
    </ConnectorContext.Provider>
  );
};
