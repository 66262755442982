import { useContext, useEffect, useRef, useState } from 'react';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Card from '../Card';
import { SubscriptionContext } from '../../context/SubscriptionProvider';

import './style.scss';

const StorageSpace = () => {
  const {
    costData,
    getCostHistory,
  } = useContext(SubscriptionContext)
  // const storageRef = useRef();

  useEffect(() => {
    getCostHistory()
  }, [])


  // const [dimensions, setDimensions] = useState({
  //   height: window.innerHeight,
  //   width: '60%',
  //   flex: 'row',
  // });


  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const handleResize = () => {
  //   // console.log("width",storageRef.current.clientWidth)
  //   const h = storageRef.current.clientHeight;
  //   const w = storageRef.current.clientWidth;

  //   setDimensions({
  //     height: h,
  //     width: w < 370 ? 300 : w * 0.6,
  //     flex: w < 370 ? 'column' : 'row',
  //   });
  // };


  console.log(costData)
  return (
    <Card title="Weekly MAR Accumulation" info="daily totals" className="history-card">
      <div className='history-chart'>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={costData}
          margin={{ top: 5, left: 5, right: 5, bottom: 5 }}
          style={{overflow: "hidden"}}
        >
        <CartesianGrid strokeDasharray="2 2"/>
        <XAxis height={20} dataKey="name" />
        <YAxis width={40} />
        <Tooltip />
        <Bar dataKey="query" maxBarSize={50} stackId="a" fill="#537650" />
        <Bar dataKey="sync" maxBarSize={50} stackId="a" fill="#1f422a" />
      </BarChart>
        </ResponsiveContainer>
        </div>
        </Card>
  );


  // return (
    //this is the real card. preserved the wording
    // <Card title="Storage Space" info="Storage breakdown by workspace" contentClass="storage-card">
    // <Card title="" info="" contentClass="storage-card">

    //   <div className='coming-soon'>
    //     <h3>
    //     Coming Soon

    //     </h3>
    //     </div>
      {/* <div className="storage-space" ref={storageRef} style={{ flexDirection: dimensions.flex }}>
        <ResponsiveContainer width={dimensions.width} height={300}>
          <PieChart>
            <Pie data={storage} dataKey="value" cx="50%" cy="50%" fill="#8884d8" innerRadius={75} outerRadius={100}>
              {storage.map((entry, i) => (
                <Cell key={`cell-${i}`} fill={getColor(entry, i)} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <div className="storage-legend">
          {storage.map((entry, i) => (
            <div className="legend-item" key={`item-${i}`}>
              <div className="label">
                <div className="dot" style={{ backgroundColor: getColor(entry, i) }} />
                {entry.name}
              </div>
              <div className="value">{entry.value} GB</div>
            </div>
          ))}
        </div>
      </div> */}
  //   </Card>
  // );
};

export default StorageSpace;
