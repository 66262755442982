
import React, { useContext, useState, useRef, useEffect } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { Divider, Spin } from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import { DownOutlined, LoadingOutlined} from '@ant-design/icons';
import { EditorView } from "@codemirror/view";
import { ModelEditorContext } from '../../../context/ModelEditorProvider';

export default () => {
  const {
      getCopilotSelectedNode,
      setCopilotNodes,
      selectedNode
  } = useContext(TableGraphContext);
    
  const descriptionTextAreaRef = useRef(null);
  const sourceTextAreaRef = useRef(null);
  const inputsTextAreaRef = useRef(null);
  const outputsTextAreaRef = useRef(null);

  let node = getCopilotSelectedNode();

  useEffect(() => {
    resizeTextarea();
}, [selectedNode]);

    const handleDescriptionChange = (e) => {
        setCopilotNodes(prev => prev.map(item => item.id === node.id ? {...item, data: {...item.data, node_plan: e.target.value}} : item))
        resizeTextarea()
    };

  const resizeTextarea = () => {
    if (descriptionTextAreaRef.current) {
      descriptionTextAreaRef.current.style.height = "auto"; // Reset height to recalculate
      descriptionTextAreaRef.current.style.height = `${descriptionTextAreaRef.current.scrollHeight}px`;
    }
  };

  const handleSourceChange = (e) => {
    let [schema, table] = e.target.value.split('.')
    setCopilotNodes(prev => prev.map(item => item.id === node.id ? { ...item, data: { ...item.data, table_ref: { table_schema: schema, table_name: table } } } : item))
    resizeSourceTextarea()
  };

  const resizeSourceTextarea = () => {
    if (sourceTextAreaRef.current) {
      sourceTextAreaRef.current.style.height = "auto"; // Reset height to recalculate
      sourceTextAreaRef.current.style.height = `${sourceTextAreaRef.current.scrollHeight}px`;
    }
  };

  const handleInputsChange = (e) => {
    setCopilotNodes(prev => prev.map(item => item.id === node.id ? {...item, data: {...item.data, input_columns: e.target.value.split(', ')}} : item))
    resizeInputTextarea()
  };

  const resizeInputTextarea = () => {
    if (inputsTextAreaRef.current) {
      inputsTextAreaRef.current.style.height = "auto"; // Reset height to recalculate
      inputsTextAreaRef.current.style.height = `${inputsTextAreaRef.current.scrollHeight}px`;
    }
  };

  const handleOuputsChange = (e) => {
    setCopilotNodes(prev => prev.map(item => item.id === node.id ? {...item, data: {...item.data, output_columns: e.target.value.split(', ')}} : item))
    resizeOutputTextarea()
  };

  const resizeOutputTextarea = () => {
    if (outputsTextAreaRef.current) {
      outputsTextAreaRef.current.style.height = "auto"; // Reset height to recalculate
      outputsTextAreaRef.current.style.height = `${outputsTextAreaRef.current.scrollHeight}px`;
    }
  };
    
    let output_columns = node.data.output_columns
    let input_columns = []
    if (node.data.input_columns) {
        input_columns = node.data.input_columns
    }
    else {
      let unique_columns = new Set()
      for (let parent of node.data.parent_models) {
        parent.parent_columns.forEach(col => unique_columns.add(col))
      }
        input_columns = [...unique_columns]
    }

  return (
      <div className="w-full tablegraphs-sider-style flex flex-col relative bg-[#f7f7f7]">
        <div className="flex justify-center">
          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Model Name
            </div>
            <div className='flex mt-[0px]'>
            <div className="border-[1px] text-ellipsis border-gray-200 rounded-lg px-[5px] flex-1 h-[40px] w-[full] pr-[50px] content-center font-[400] text-[14px] pl-[20px] relative top-[15px] overflow-hidden">
                {node.data.model_name}
                </div>
            </div>
          </div>
        </div>
          <Divider className="my-[20px]" />
          <div className='w-full relative mb-[10px]'>
          <div className='mb-[0px] font-[500]'>
              Description of node:
          </div>
          <textarea
            ref={descriptionTextAreaRef}
            value={node.data.node_plan}
            onChange={handleDescriptionChange}
            className="align-middle leading-normal box-border w-full py-1 px-2 rounded-lg text-base border-[1px] text-ellipsis border-gray-200 resize-none overflow-hidden text-[14px] font-[300]"
            style={{ minHeight: '40px' }} // Minimum height
              />
            </div>
            {node.data.type === 'model' &&
                <div className='w-full relative mb-[20px]'>
                    <div className='mb-[0px] font-[500]'>
                        Source Table
                    </div>
                    <input
                      value={`${node.data.table_ref.table_schema}.${node.data.table_ref.table_name}`}
                      onChange={handleSourceChange}
                      className="w-full p-2 rounded-lg text-base border-[1px] text-ellipsis border-gray-200 resize-none overflow-hidden text-[14px] font-[300]"
                      />
                </div>
              
          }
          {node.data.type === 'logic' &&
                <div className='w-full relative mb-[20px]'>
                    <div className='mb-[0px] font-[500]'>
                        Parent Models
                    </div>
                    <div className='mt-[0px]'>
                        {
                          node.data.parent_models.map(item => 
                              <div>
                                {item.parent_model_name}
                            </div>  
                        )
                        }
                    </div>
                </div>
              
          }

          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Input columns
            </div>
              {input_columns.join(', ')}
          </div>
          <div className='w-full relative mb-[20px]'>
            <div className='mb-[0px] font-[500]'>
              Output columns
            </div>
            <div className='flex mt-[0px] flex-wrap'>
                <textarea
                  ref={outputsTextAreaRef}
                  value={output_columns.join(', ')}
                  onChange={handleOuputsChange}
                  className="align-middle leading-tight box-border w-full py-1 px-2 rounded-lg text-base border-[1px] text-ellipsis border-gray-200 resize-none overflow-hidden text-[14px] font-[300]"
                />
            </div>
          </div>

      </div>
    
  );
};
