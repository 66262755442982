import { createContext, useContext, useState, useEffect } from 'react';
import { ConnectorContext } from './ConnectorProvider';
import LetoClient from './LetoClient';
import { useAuthInfo } from '@propelauth/react';

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [credits, setCredits] = useState()
    const [subscriptionType, setSubscriptionType] = useState()
    const [connectorCount, setConnectorCount] = useState()
    const [userCount, setUserCount] = useState()
    const [noCreditModalVisible, setNoCreditModalVisible] = useState(false)
    const [costData, setCostData] = useState([])

    const {
        connectors,
        pauseAllConnectors,
    } = useContext(ConnectorContext)

    const get_subscription_info = async () => {
        const response = await LetoClient.get_subscription_info()
        setCredits(response.credit_count)
    }

    const checkCredits = () => {
        console.log(credits)
        return credits <= 0
    }
  
  const getCostHistory = async () => {
    const response = await LetoClient.costHistory()
    setCostData(response.credit_history)
    }

    const manageConnectors = (key) => {
        if (key == 1) {
          const connector_list = []
          for (var num in connectors) {
            if (!connectors[num].paused) {
              connector_list.push(connectors[num].id)
            }
          }
          pauseAllConnectors(connector_list, {paused: true})
        }
        else {
          if (checkCredits()) {
            setNoCreditModalVisible(true)
          }
          else {
            const connector_list = []
            for (var num in connectors) {
              if (connectors[num].paused) {
                connector_list.push(connectors[num].id)
              }
            }
            pauseAllConnectors(connector_list, { paused: false })
          }
        }
      };


    return (
        <SubscriptionContext.Provider
            value={{
                credits,
                setCredits,
                subscriptionType,
                setSubscriptionType,
                connectorCount,
                setConnectorCount,
                userCount,
                setUserCount,
                get_subscription_info,
                noCreditModalVisible,
                setNoCreditModalVisible,
                checkCredits,
                manageConnectors,
                getCostHistory,
                costData, 
                setCostData,
            }}>
            {children}
        </SubscriptionContext.Provider>
    )

}

