import { useContext, useState } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import AnalyticsHome from './AnalyticsHome';
import { DownOutlined } from '@ant-design/icons';
import Page from '../../components/Page';
import { Radio } from 'antd';
import { AppstoreAddOutlined, BarChartOutlined } from '@ant-design/icons';
import Sidebar from '../../components/Sidebar';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';
import PageSetup from '../../components/Onboarding/PageSetup';

const Analytics = () => {
    const {
        loadChart,
        favorites,
        recents,
    } = useContext(AnalyticsContext)
  
    const {
      noWarehouse
    } = useContext(UserContext)
  
    const history = useHistory();
    const [selected, setSelected ] = useState('charts')

    const [sidebarData, setSidebarData] = useState({});
    const [openSidebar, setOpenSidebar] = useState(true)

    const [showFavorites, setShowFavorites] = useState(true)
  const [showRecents, setShowRecents] = useState(true)
  
  const MenuChange = (val) => {
    if (val == 'charts') {
      history.push('/analytics')
    }
    else {
      history.push('analytics/dashboard')
    }
  }

    

    const open = (chart) => {
        loadChart(chart["_id"])
        //setCurrentPage('chart')
    }
  
  const sidebarContent = () => {
    return (
      <div className=''>
        <Radio.Group className='w-[93%] mb-[10px]' value={'charts'} onChange={(e) => MenuChange(e.target.value)}>
          <Radio.Button className='w-1/2' value='dashboards' style={{ borderRadius: '12px 0 0 12px', padding: '20px 30px 20px 30px', background: selected === 'dashboards' ? 'black' : 'white', color: selected === 'dashboards' ? 'white' : 'black'}}>
              <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                  <AppstoreAddOutlined style={{ transform: 'rotate(270deg)', fontSize: '22px'}}/>
                  <p style={{ fontSize: '15px', userSelect: 'none' }}>Dashboards</p>
              </div>
          </Radio.Button>
          <Radio.Button className='w-1/2' value='charts' style={{ borderRadius: '0 12px 12px 0', padding: '20px 30px 20px 30px', background: selected === 'charts' ? 'black' : 'white', color: selected === 'charts' ? 'white' : 'black'}}>
              <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                  <BarChartOutlined style={{ fontSize: '22px' }}/>
                  <p style={{ fontSize: '15px', userSelect: 'none' }}>Charts</p>
              </div>
          </Radio.Button>
        </Radio.Group>
        <div onClick={() => {setShowFavorites(!showFavorites) }} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
            <DownOutlined className='duration-[50ms]' style={showFavorites ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', top:'-2px', position: 'relative', fontSize:'12px'}}/>
            Favorites
        </div>
        {showFavorites && (
            <div>
                { favorites.map((chart) => {
                    return(
                        <div onClick={() => open(chart)} className='cursor-pointer' style={{userSelect: 'none', marginLeft: 30}}>{chart.chart_name}</div>
                    )
                })}
            </div>
        )}
        <div onClick={() => {setShowRecents(!showRecents) }} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
            <DownOutlined className='duration-[50ms]' style={showRecents ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px',top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', top:'-2px', position: 'relative', fontSize:'12px'}}/>
            Recents
        </div>
        {showRecents && (
            <div>
                { recents.map((chart) => {
                    return(
                        <div onClick={() => open(chart)} className='cursor-pointer' style={{userSelect: 'none', marginLeft: '15px'}}>{chart.chart_name}</div>
                    )
                })}
            </div>
          )}
      </div>
    )
  }

  if (noWarehouse) {
    return (
      <Page>
        <PageSetup databaseRequired={true} type={'Analytics'}/>
      </Page>
    )
  }

    return (
        <Page>
        <Sidebar setSidebarData={setSidebarData} sidebarContent={sidebarContent()} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} hideButtonWhenClosed={false}>   
            <AnalyticsHome/>
          </Sidebar>
        </Page>
    )
};

export default Analytics;
