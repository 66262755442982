import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SqlEditorContext } from './SqlEditorProvider';
import { ModelEditorContext } from './ModelEditorProvider';
import GraphClient from './GraphClient.js';
import { useHistory } from 'react-router-dom';


export const EditorContext = createContext();

export const EditorProvider = ({ children }) => {

    const {
        sqlTabs,
        setSqlTabs,
        addSqlTab,
        openSqlTab,
        deleteSqlTab,
        executeQuery,
        savedQueries,
        sqlEditorToolbox,
        sqlActiveTabId,
        setSqlActiveTabId,
        addTableAsQuery
    } = useContext(SqlEditorContext);

    const {
        onItemClicked,
        dbtTabs,
        addDbtTab,
        deleteDbtTab,
        executeFile,
        dbtEditorToolbox,
        getFileContent,
        dbtActiveTabId,
        setDbtActiveTabId,
    } = useContext(ModelEditorContext); 

    const sidebarRef = useRef(null)

    const [sidebarOpen, setSidebarOpen] = useState(true)
    const [editorMode, setEditorMode] = useState(0)
    const [sidebarWidth, setSidebarWidth] = useState('330px');
    const [menuPos, setMenuPos] = useState()
    const [menuItem, setMenuItem] = useState()
    const [contextMenuType, setContextMenuType] = useState()
    const [activeTab, setActiveTab] = useState(0);
    const [previousTab, setPreviousTab] = useState(0)
    const [editorHeight, setEditorHeight] = useState(300)
    const [limitActive, setLimitActive] = useState(true)

    /*
     * Tablist is an array of objects that represent the tabs in the editor.
     */
    // the index of the active tab in the list of tabs - NOT UUID
    const [tabList, setTablist] = useState([]);

    const history = useHistory()

    useEffect(() => {
        if (tabList.length === 0) {
            addTab('New Query', 'sql');
            setActiveTab(0);
        }
    }, []);

    useEffect(() => {
        if (activeTab && tabList[activeTab]) {
            switch (tabList[activeTab].type) {
                case 'sql':
                    setSqlActiveTabId(tabList[activeTab].id)
                    setDbtActiveTabId()
                    break
                case 'dbt':
                    setDbtActiveTabId(tabList[activeTab].id)
                    setSqlActiveTabId()
                    break
                default:
                    setDbtActiveTabId()
                    setSqlActiveTabId()
                    break
            }
        }
    }, [tabList])

    useEffect(() => {
        //handling renames to sql queries. Only runs when saved queries list changes
        for (let key in sqlTabs) {
            if (sqlTabs.hasOwnProperty(key) && sqlTabs[key].type == 'saved') {
                const item = savedQueries.find(query => query._id == sqlTabs[key].query_id)
                if (item && item.name !== sqlTabs[key].title) {
                    setSqlTabs(prev => ({
                        ...prev,
                        [key]: {
                            ...prev[key],
                            title: item.name
                        }
                    }))
                }
            }
        }
    }, [savedQueries])

    const openDbtFile = async (item) => {
        const response = onItemClicked(item)
        let dbtTab = Object.entries(dbtTabs).find(([key, value]) => value.path == item.path)
        if (!dbtTab || !activateOpenedTab(dbtTab[0], 'dbt')) {
            const response = await getFileContent(item.path)
            let fileContent = response.data
            openTab(item, 'dbt', fileContent);
        }
    }
    
    const openDbtFileFromNode = async (node) => {
        if (node && node.data.model_id) {
            try {
                let response = await GraphClient.getDbtModel(node.data.model_id)
                openDbtFile({ path: response.path, name: response.name + '.sql' })
            }
            catch {
                console.log('unable to get specific file')
            }
            history.push('/editor')
        }
    }

    const activateOpenedTab = (tabId, type) => {
        const tabIndex = tabList.findIndex(item => item.id == tabId && item.type == type)
        if (tabIndex !== -1) {
            handleTabChange(tabIndex)
            return true
        }
    }

    const handleTabChange = (idx, setPrev = true) => {
        if (setPrev) {
            setPreviousTab(activeTab)
        }
        setActiveTab(idx)
        if (tabList[idx]) {
            switch (tabList[idx].type) {
                case 'sql':
                    setSqlActiveTabId(tabList[idx].id)
                    setDbtActiveTabId()
                    break
                case 'dbt':
                    setDbtActiveTabId(tabList[idx].id)
                    setSqlActiveTabId()
                    break
            }
        }
    }
    

    const openTab = (item, type, content='') => {
        let len = tabList.length
        const newTab = { id: uuidv4(), type: type };
        setTablist(prev => [...prev, newTab]); 
        if (type === 'sql') {
            openSqlTab(newTab, item._id, item.name, item.type);
        } else if (type === 'dbt') {
            addDbtTab(newTab, item.path, item.name, content);
        } else {
            console.log('Unknown tab type');
        }

        handleTabChange(len); // Set active tab to the new tab
    }

    const handleItemDelete = (item, type) => {
        let idx;
        if (type == 'sql') {
            idx = tabList.findIndex(tab => tab.type == 'sql' && sqlTabs[tab.id].query_id == item._id)
        }
        else if (type == 'dbt') {
            idx = tabList.findIndex(tab => tab.type == 'dbt' && dbtTabs[tab.id].path == item.path)
        }
        if (idx !== -1) {
            deleteTab(idx)
        }
    }
    
    const addTab = (title, type) => {
        let len = tabList.length
        const newTab = { id: uuidv4(), type: type };
        setTablist(prev => [...prev, newTab]);

        if (type === 'sql') {
            addSqlTab(newTab, title);
        } else if (type === 'dbt'){
            // addDbtTab(newTab, item.path, content);
        } else {
            console.log('Unknown tab type');
        }

        handleTabChange(len); // Set active tab to the new tab
    }

    // TODO remove tab from sqlTab and dbtTab list
    const deleteTab = (index) => {
        if (previousTab != null && index == activeTab) {
            if (previousTab > index) {
                handleTabChange(previousTab - 1, false)
            }
            else {
                handleTabChange(previousTab, false)
            }
        }
        else if (activeTab == tabList.length - 1 && activeTab > 0 || activeTab > index) {
            handleTabChange(activeTab - 1, false)
        }

        setPreviousTab()

        const deleted_tab = tabList[index]
        if (deleted_tab.type == 'sql') {
            deleteSqlTab(deleted_tab.id)
        }
        else if (deleted_tab.type == 'dbt') {
            deleteDbtTab(deleted_tab.id)
        }

        setTablist(prev => prev.filter((_, i) => i !== index));
    }
    
    const getToolbarFunctions = (type) => {
        switch (type) {
            case 'sql':
                return sqlEditorToolbox
            case 'dbt':
                return dbtEditorToolbox
            default:
                return []
        }
    }


    const getEditorResults = (tab) => {
        if (tab) {
            switch (tab.type) {
                case 'sql':
                    return sqlTabs[tab.id]?.results
                case 'dbt':
                    return dbtTabs[tab.id]?.results
                default:
                    return [];
            }
        }
    }

    const openTableAsQuery = (item) => {

        let len = tabList.length
        const newTab = { id: uuidv4(), type: 'sql' };
        setTablist(prev => [...prev, newTab]);

        addTableAsQuery(newTab, item);

        handleTabChange(len); // Set active tab to the new tab
    }

    return (
        <EditorContext.Provider
            value={{
                sidebarOpen,
                setSidebarOpen,
                editorMode,
                setEditorMode,
                sidebarWidth,
                setSidebarWidth,
                menuPos,
                setMenuPos,
                menuItem,
                setMenuItem,
                contextMenuType,
                setContextMenuType,
                tabList,
                setTablist,
                addTab,
                openDbtFile,
                openDbtFileFromNode,
                deleteTab,
                activeTab,
                setActiveTab,
                getToolbarFunctions,
                getEditorResults,
                sidebarRef,
                openTab,
                handleItemDelete,
                handleTabChange,
                activateOpenedTab,
                editorHeight,
                setEditorHeight,
                limitActive,
                setLimitActive,
                openTableAsQuery,
            }}
        >
        {children}
        </EditorContext.Provider>
    );
};

