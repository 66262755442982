export function trackFeatureUsed(feature, userId, orgId) {
  window.analytics.track("Feature Used", {
    feature: feature,
    user: userId,
    org: orgId
  })
}

export function trackQuery(feature, userId, orgId, executionTime) {
  window.analytics.track("Query Run", {
    feature: feature,
    user: userId,
    org: orgId,
    executionTime: executionTime
  })
}

export function trackOnboardingFeature(behaviour, userId, orgId) {
  window.analytics.track("Onboarding", {
    feature: behaviour,
    user: userId,
    org: orgId
  })
}