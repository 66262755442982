import { createContext, useState, useEffect } from 'react';

export const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [pageKey, setPageKey] = useState();

  return (
    <NavbarContext.Provider
      value={{
        pageKey,
        setPageKey,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
