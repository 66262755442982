import { React, useState, useEffect } from 'react';
import DataGrid from 'react-data-grid';

const SqlResults = ({ tab, results }) => {
    if (!results || !results.log || !results.log.execution_time) {
        return <div className="p-4 text-gray-500"></div>;
    }

    //TODO : add safety
    const log = results.log ? results.log : [];
    const result = results.result ? results.result : {};


    const TimeIcon = (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>)

    // const DownloadIcon = (
    //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    //       <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
    //     </svg>);
    // const FullScreenIcon = (
    //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    //       <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
    //     </svg>);




    const renderResultTopbar = (log) => {

        const created_at = new Date(log.created_at).toLocaleString().replace(',', ''); 
        
        const execution_time = log.execution_time.toFixed(2);
        const result_rows = log.result_rows;


        switch (log.result_status) {
            case 'Success':
                return (
                    <div className="flex justify-between">

                        {/* left details */}
                        <div className='flex'>
                        <div className='border border-1 border-green-400 rounded h-fit p-1 bg-green-200 text-green-700'> {log.result_status} </div>
                        <div className="p-1">
                            {result_rows} rows in {execution_time}s
                        </div>

                        <div className='border border-1 flex p-1'><div className='mr-1'>{TimeIcon}</div>{created_at}</div>
                        </div>

                        {/* right toolbar */}


                        <div className='flex gap-2 rounded'>
                            <div className='border border-2 p-1 px-2 rounded hover:scale-105 hover:bg-gray-200 hover:text-black'><button>Expand</button> </div>
                            <div className='border border-2 p-1 px-2 rounded hover:scale-105 hover:bg-gray-200 hover:text-black'><button>SQL</button> </div>
                            <div className='border border-2 p-1 px-2 rounded hover:scale-105 hover:bg-gray-200 hover:text-black'><button> Export </button> </div>
                        </div>
                    </div>                
                );
            case 'Failed':
                return (
                    <div className="flex">
                        <div className='border border-1 border-red-400 rounded p-1 bg-red-200 text-red-700'> {log.result_status} </div>
                        <div className="p-1">
                            in {execution_time}s
                        </div>

                        <div className='border border-1 flex p-1'><div className='mr-1'>{TimeIcon}</div>{created_at}</div>
                    </div>
                );
            default:
                return (
                    <div className="flex">
                        <div> {log.result_status} </div>
                        <div className="">
                            {log.result_rows} rows in {execution_time}s
                        </div>
                    </div>
                );
        }
    }

    const renderResult = (log, result) => {
        switch (log.result_status) {
            case 'Success':
                return (
                    <div className=''>
                        <DataGrid
                            className='h-full overflow-auto'
                            rows={result.rows}
                            columns={result.columns}
                          /> 

                    </div>
                );
            case 'Failed':
                return (
                    <div className='border-1 border p-2'>
                        <pre>{result.error}</pre>
                    </div>
                );
            default:
                return (
                    <div>
                        <pre>{JSON.stringify(result, null, 2)}</pre>
                    </div>
                );
            }
    }
 
    return (
        <div className="p-4 h-full flex flex-col">
            {renderResultTopbar(log)} 

            <div className="mt-2 grow overflow-auto">
                {renderResult(log, result)}
            </div>
        </div>
    );
}

export default SqlResults;
