import { createContext, useState, useCallback, useEffect, useContext, useRef } from 'react';
import GraphClient from './GraphClient.js';
import { v4 as uuidv4 } from 'uuid';
import { GitContext } from './GitProvider'
import { useHistory, useLocation } from 'react-router-dom';
import LetoClient from './LetoClient.js';

export const ModelEditorContext = createContext();

export const ModelEditorProvider = ({ children }) => {  

  const [dbtTabs, setDbtTabs] = useState({})

  // const openDbtFileTab = async (tab, filePath) => {
  //   let response = await GraphClient.getDbtFile(filePath)
  //   if (response.data) {
  //     setDbtTabs(prevTabs => {
  //       return {
  //         ...prevTabs,
  //         [tab.id]: {
  //           content: response.data,
  //           path: filePath,
  //           results: []
  //         }
  //       }
  //     })
  //   }
  // }
  
  /*
   *
   * UN REFACTORED METHODS AND SYMBOLS BELOW
   *
   *
   * NEED TO MOVE openedFiles to be object and tab.id based!!
   *
   * reference SqlProvider.js for the new schema for the new editor
   *
   * to do this we'll need to untangle // refactor the onItemClicked, findItemClick, and getFileContent methods
   *
   */

  const [searchValue, setSearchValue] = useState('')
  const [dbtActiveTabId, setDbtActiveTabId] = useState();
  const [fileTree, setFileTree] = useState({})
  const [modelList, setModelList] = useState([])
  const [seedList, setSeedList] = useState([])
  const [sourceList, setSourceList] = useState()
  const [testState, setTestState] = useState(false)
  const [consoleMessages, setConsoleMessages] = useState([])
  const [suggestionText, setSuggestionText] = useState()
  const history = useHistory();
  const [diffView, setDiffView] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [urlValue, setUrlValue] = useState('')
  const [patValue, setPatValue] = useState('')
  const [connectGit, setConnectGit] = useState(false)
  const git = useContext(GitContext);
  const [fileStructure, setFileStructure] = useState({'.': true})
  const [loadingSuggestion, setLoadingSuggestion] = useState(false)
  const sugText = useRef()
  const controlState = useRef()
  const [newName, setNewName] = useState("");
  const [initialName, setInitialName] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [testingRepo, setTestingRepo] = useState(false)
  const [filteredModelList, setFilteredModelList] = useState()
  const [connectGitError, setConnectGitError] = useState()
  const [filteredFiles, setFilteredFiles] = useState();
  const [fileContentList, setFileContentList] = useState();
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false);
  const [isCSVModalLoading, setIsCSVModalLoading] = useState(false);
  const [csvUploadError, setCsvUploadError] = useState()
  const [dbtResultAdded, setDbtResultAdded] = useState(false) // Dummy state to update EditorResults

  const handleEdit = (item) => {
    setNewName(item.name);
    setInitialName(item.name);
    setIsEditing(item.path);
  };

  useEffect(() => {
    if (consoleMessages.length > 8) {
      setConsoleMessages(msgs => {
        const [head, ...tail] = msgs
        return tail
      })
    }
  }, [consoleMessages])
  
 /* *
   *  TODO: refactor to seperate context provider
   * */

  //git context provider

  useEffect(() => {
    // if (Object.keys(fileTree).length === 0) {
      getFileTree(showAll);
    // }
  }, []); 

  useEffect(() => {
    if (fileContentList && !(searchValue && searchValue.length >= 3)) {
      setFileContentList()
    }
  }, [searchValue, fileContentList])

  const findFileContent = async (search_string, all, showAll) => {
    let response = await GraphClient.findFileContent(search_string, all, showAll)
      setFileContentList(response)    
  }

  const findItemClick =  (child, item, reload) => {
    if (child.children) {
      if (child.path == item.path) {
        //clicked on this folder, so update its opened / closed state in the file tree
        setFileStructure(prev => {
          return {
            ...prev,
            [item.path]: !prev[item.path]
          }
        })

        return child
      }
      return {
        ...child,
        children: child.children.map((child) => findItemClick(child, item, reload))
      }
    }
    return child
  }

  const getDiffContent = async () => {
  //   let id = uuidv4()
  //   setConsoleMessages(prev => [...prev, { id: id, message: `Fetching diff...` }])

  // let response = await GraphClient.getCurrentBranchDiff()
  // if (response.data) {
  //     setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))

  //   return new Promise((resolve) => {
  //     setOpenedFiles(prevFiles => {
  //       // Check if 'git diff' tab is already open
  //       let existingDiffTab = prevFiles.find(file => file.id === 'git.diff');
        
  //       let newFiles;
  //       if (existingDiffTab) {
  //         // If 'git diff' tab already exists, update its content
  //         newFiles = prevFiles.map(file => 
  //           file.id === 'git.diff'
  //             ? {...file, content: response.data}
  //             : file
  //         )
  //       } else {
  //         // If 'git diff' tab doesn't exist, add a new one
  //         newFiles = [...prevFiles, {
  //           id: 'git.diff',
  //           name: 'git.diff',
  //           content: response.data
  //         }];
  //       }

  //       resolve(newFiles);
  //       return newFiles;
  //     })
  //   });
  // }
  // else {
  //   setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' no changes found' } : file))
  // }
  // return response
  }

const getFiles = (fileTree) => {
  if (!fileTree) {
    return []; 
  }

  if (!fileTree.children) {
    return [fileTree];
  }

  return fileTree.children.reduce((acc, child) => {
    return [...acc, ...getFiles(child)];
  }, []);
}

const getFileDiffContent = async (file) => {
  //   let id = uuidv4()
  //   setConsoleMessages(prev => [...prev, { id: id, message: `Fetching diff...` }])
  // let response = await GraphClient.getCurrentFileDiff(file)
  // if (response.data) {
  //     setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))

  //   return new Promise((resolve) => {
  //     setOpenedFiles(prevFiles => {
  //       // Check if 'git diff' tab is already open
  //       let existingDiffTab = prevFiles.find(file => file.id === 'git.diff');
        
  //       let newFiles;
  //       if (existingDiffTab) {
  //         // If 'git diff' tab already exists, update its content
  //         newFiles = prevFiles.map(file => 
  //           file.id === 'git.diff'
  //             ? {...file, content: response.data}
  //             : file
  //         )
  //       } else {
  //         // If 'git diff' tab doesn't exist, add a new one
  //         newFiles = [...prevFiles, {
  //           id: 'git.diff',
  //           name: 'git.diff',
  //           content: response.data
  //         }];
  //       }

  //       resolve(newFiles);
  //       return newFiles;
  //     })
  //   });
  // }
  // return response
}

const openDiffTab = async (file = null) => {
  if (file){
    const newFiles = await getFileDiffContent(file);
    // setDbtActiveTabId(newFiles.findIndex(file => file.id === 'git.diff'));
  }
  else{
    const newFiles = await getDiffContent();
    // setDbtActiveTabId(newFiles.findIndex(file => file.id === 'git.diff'));

  }
}

  const onItemClicked = (item, reload=false) => {
    setFileTree((prevTree) => {
      if (prevTree.path == item.path) {
        setFileStructure(prev => {
          return {
            ...prev,
            [item.path]: !prev[item.path]
          }
        })
        return prevTree
      }
      return {
        ...prevTree,
        children: prevTree.children.map((child) => findItemClick(child, item, reload))
      }
      
    })
    return item
  };


  const getFileTree = async (all = false) => {
    let response = await GraphClient.getFileTree(all)
    if (response.data) {
      setFileTree(response.data)
      setModelList(response.model_list)
      setSeedList(response.seeds)
      setFilteredModelList(response.model_list)
      setSourceList(response.sources)
    }
    return response
  }

  const uploadSeedCSV = async (file) => {
    setIsCSVModalLoading(true)
    try {
      const response = await GraphClient.uploadSeedCSV(file)
      if (response.error) {
        setIsCSVModalLoading(false)
        setCsvUploadError(response.error)
      }
      else if (response.data) {
        setIsCSVModalLoading(false)
        setIsCSVModalOpen(false)
        setFileTree(response.data)
        setModelList(response.model_list)
        setSeedList(response.seeds)
        setFilteredModelList(response.model_list)
        return true
      }
    }
    catch (error) {
      setIsCSVModalLoading(false)
      setCsvUploadError('Error: server connection error while handling upload')
    }
  }

  const createNewFolder = async (menuItem) => {
    let response = await GraphClient.createNewFolder(menuItem.path)
    if (response.data) {
      setFileTree(response.data)
      setModelList(response.model_list)
      setFilteredModelList(response.model_list)
    }
    return response
  }

  const createNewFile = async (menuItem) => {
    let response = await GraphClient.createNewFile(menuItem.path)
    if (response.data) {
      setFileTree(response.data)
      setModelList(response.model_list)
      setFilteredModelList(response.model_list)
    }
    return response
  }

  const deleteItem = async (menuItem) => {
    let id = uuidv4()
    setConsoleMessages(prev => [...prev, {id: id, message: `Deleting model...`}])
    try {
      let response = await GraphClient.deleteItem(menuItem.path)
      if (response.data) {
        setFileTree(response.data)
        setModelList(response.model_list)
        setFilteredModelList(response.model_list)
      }
      setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
      return response
    }
    catch {
      setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Server error' } : file))

    }
    git.fetchCurrentBranchContext();
  }

  const duplicateItem = async (menuItem) => {
    let response = await GraphClient.duplicateItem(menuItem.path)
    if (response.data) {
      setFileTree(response.data)
      setModelList(response.model_list)
      setFilteredModelList(response.model_list)
    }
    return response
  }

const getFileContent = async (filePath) => {
    let response = await GraphClient.getFileContent(filePath)
    LetoClient.trackUserActivity(filePath, 'model', filePath)
    return response
  } 

  const generateSuggestion = async () => {
    let item = dbtTabs[dbtActiveTabId]
    let id = uuidv4()
    setConsoleMessages(prev => [...prev, { id: id, message: `Generating suggestion...` }])
    setLoadingSuggestion(true)
    try {
      let response = await GraphClient.generateSuggestion({ file: item.edits ? item.edits : item.content })
      setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
      setLoadingSuggestion(false)
      if (response.data) {
        setDiffView(prev => [...prev, item.id])
        // setOpenedFiles(prev => prev.map((file, index) => 
        //   index === dbtActiveTabId ? 
        //     {
        //       ...file,
        //       base: file.edits ? file.edits : file.content,
        //       diff: response.data,
        //     }
        //     :
        //     file
        //   ))
          setSuggestionText()
        // setSuggestionText(response.data)
        return response.data
      }
    }
    catch {
      setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Server error' } : file))
      setLoadingSuggestion(false)
    }
    
  }

  const updateDbtFileName = async (path, name) => {
    let id = uuidv4()
    setConsoleMessages(prev => [...prev, { id: id, message: `Renaming file to ${name}...` }])
    try {
      let response = await GraphClient.updateDbtFileName(path, name)
      setFileTree(prevTree => rename_file(prevTree, path, response.new_path, name))
      setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Done' } : file))
      setDbtTabs(prev => {
        for (let key in prev) {
          if (prev.hasOwnProperty(key)) {
            if (prev[key].path == path) {
              prev[key] = {
                ...prev[key],
                path: response.new_path,
                title: name,
                file_name: name,
              }
            }
          }
        }
        return {
          ...prev
        }
      })
    }
    catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Error: ' + error.response.data.detail } : file))
      } else {
        setConsoleMessages(prev => prev.map((file) => file.id === id ? { ...file, message: file.message + ' Server Error' } : file))
      }
    }
    git.fetchCurrentBranchContext();
  }

  const rename_file = (tree, path, new_path, new_name) => {
    if (tree.children) {
      return tree.path == path ?
        {
          ...tree,
          path: new_path,
          name: new_name,
        }
        :
        {
          ...tree,
          children: tree.children.map((elem) => rename_file(elem, path, new_path, new_name))
        }
    }
    if (tree.path == path) {
      // setOpenedFiles(prev => 
      //   prev.map(elem => 
      //     elem.id == path ? 
      //       {
      //         ...elem,
      //         name: new_name,
      //         id: new_path,
      //       }
      //       :
      //       elem
      //     )
      //   )
    }
    return tree.path == path ?
      {
        path: new_path,
        name: new_name,
      }
      :
      tree
  }

  const deleteDbtTab = (id) => {
    setDbtTabs(prev => {
      const { [id]: _, ...rest } = prev
      return rest
    })
  }

  const onContentChange = (tabId, content) => {
    setDbtTabs((prev) => {
        return {
            ...prev,
            [tabId]: {
                ...prev[tabId],
              'content': content,
              'edited': true
            }
        }
    })
}

  const addDbtTab = (tab, path, title, content) => {
      setDbtTabs((prev) => {
          return {
              ...prev,
              [tab.id]: {
                  'file_name': title,
                  'path': path,
                  'content': content, 
                  'results': {},
                  'title': title,
              }
          }
      })
  }

  const addResult = (tabId, resultId, type) => {
    setDbtTabs(prev => ({
      ...prev,
      [tabId]: {
        ...prev[tabId],
        results: {[resultId]: {type: type, response: ''}, ...prev[tabId].results}
      }
    }))
  }

  const updateResult = (tabId, resultId, newResult) => {
    setDbtTabs(prev => ({
      ...prev,
      [tabId]: {
        ...prev[tabId],
        results: {
          ...prev[tabId].results, [resultId]: {type: prev[tabId].results[resultId].type, ...newResult}
        }
      }
    }))
  }

  const executeFile = async (tabId) => {
    let resultId = uuidv4()
    addResult(tabId, resultId, 'dbt_execute')
    setDbtResultAdded(!dbtResultAdded) // Dummy state
    updateResult(tabId, resultId, {response: `Executing ${dbtTabs[tabId].file_name}...`})
    try {
      let messageIterator = await GraphClient.executeFileSSE(dbtTabs[tabId].file_name)
      for await (let message of messageIterator) {
        const filteredMessage = {log: message.data.log, response: message.data.response, error: message.data.error}
        updateResult(tabId, resultId, filteredMessage)
      }
    }
    catch (error) {
      updateResult(tabId, resultId, {error: 'Error'})
    }
  }

  const saveFile = async (tabId) => {
    if (dbtTabs[tabId].edited) {
      let response = await GraphClient.saveModelEdits(dbtTabs[tabId].path, dbtTabs[tabId].content)

      setDbtTabs((prev) => {
          return {
              ...prev,
              [tabId]: {
                  ...prev[tabId],
                  'edited': false,
              }
          }
      })
  
      // refresh git context
      git.fetchCurrentBranchContext();
    }
  }

  const openCopilot = (tabId) => {

    if(dbtTabs[tabId].copilotResultId) {
      return
    } else {
    
    const resultId = uuidv4()
    addResult(tabId, resultId, 'copilot')
    updateResult(tabId, resultId, { chat: [] })
      setDbtTabs((prev) => {
          return {
              ...prev,
              [tabId]: {
                  ...prev[tabId],
                  'copilotResultId': resultId,
              }
          }
      })
    setDbtResultAdded(!dbtResultAdded) // Dummy state
    }
  }

  const preview = async (tabId) => {
    let resultId = uuidv4()
    addResult(tabId, resultId, 'dbt_preview')
    setDbtResultAdded(!dbtResultAdded) // Dummy state
    let response = await GraphClient.previewModel(dbtTabs[tabId].file_name)
    updateResult(tabId, resultId, response)
  }

  const dbtEditorToolbox = {
    'save': saveFile,
    'copilot': openCopilot,
    'preview': preview,
    'run': executeFile,
  }

  const fetchPrompt = async (item, prompt) => {
    let id = uuidv4()
    setConsoleMessages(prev => [...prev, { id: id, message: `Generating suggestion...` }])
    try {
      if (prompt) {
        setLoadingSuggestion(true)
        let response = await GraphClient.generateSuggestion({file: item.diff, prompt: prompt})
        setConsoleMessages(prev => prev.map((file) => file.id === id ? {...file, message: file.message + ' Done'} : file))
        if (response.data) {
          // setOpenedFiles(prev => prev.map((elem) => 
          //   elem.id === item.id ?
          //     { ...elem, base: elem.diff, diff: response.data, history: elem.base }
          //     :
          //     elem
          // ))
        }
      }
      else {
        setLoadingSuggestion(true)
        let response = await GraphClient.generateSuggestion({file: item.base})
        setConsoleMessages(prev => prev.map((file) => file.id === id ? {...file, message: file.message + ' Done'} : file))
        if (response.data) {
          // setOpenedFiles(prev => prev.map((elem) => 
          //   elem.id === item.id ?
          //     { ...elem, diff: `${elem.base}\n${response.data}`, history: elem.diff}
          //     :
          //     elem
          // ))
        }
      }
    }
    catch {
      setConsoleMessages(prev => prev.map((file) => file.id === id ? {...file, message: file.message + ' Server error'} : file))
    }
    
    setLoadingSuggestion(false)
  }

  const CloneRepo = async () => {
    setTestingRepo(true)
    try {
      let response = await GraphClient.cloneRepo({ repo_url: urlValue, pat: patValue })
      if (response.error) {
        setConnectGitError(response.error)
      }
      if (response.status) {
        git.getBranchData()
        git.setNoGitConnection(false)
        getFileTree(false)
        setConnectGit(false)
      }
    }
    catch {

    }
    setTestingRepo(false)
  }
  
  return (
    <ModelEditorContext.Provider
      value={{

        // openDbtFileTab,

        getFileTree,
        getFileContent,
        // openedFiles,
        // setOpenedFiles,
        dbtActiveTabId,
        setDbtActiveTabId,
        fileTree,
        setFileTree,
        uploadSeedCSV,
        seedList,
        setSeedList,
        modelList,
        setModelList,
        createNewFolder,
        createNewFile,
        deleteItem,
        duplicateItem,
        sourceList,
        setSourceList,
        onItemClicked,
        generateSuggestion,
        openDiffTab,
        testState,
        setTestState,
        git,
        consoleMessages,
        setConsoleMessages,
        suggestionText,
        setSuggestionText,
        executeFile,
        updateDbtFileName,
        newName,
        setNewName,
        initialName,
        setInitialName,
        isEditing,
        setIsEditing,
        handleEdit,
        diffView,
        setDiffView,
        fetchPrompt,
        showAll,
        setShowAll,
        sugText,
        controlState,
        urlValue,
        setUrlValue,
        patValue,
        setPatValue,
        CloneRepo,
        testingRepo,
        setTestingRepo,
        connectGit,
        setConnectGit,
        setFilteredModelList,
        filteredModelList,
        fileStructure,
        setFileStructure,
        loadingSuggestion,
        setLoadingSuggestion,
        connectGitError,
        setConnectGitError,
        filteredFiles,
        setFilteredFiles,
        getFiles,
        findFileContent,
        setFileContentList,
        fileContentList,
        searchValue,
        setSearchValue,
        isCSVModalOpen,
        setIsCSVModalOpen,
        isCSVModalLoading,
        setIsCSVModalLoading,
        csvUploadError,
        setCsvUploadError,
        dbtEditorToolbox,
        onContentChange,
        addDbtTab,
        dbtTabs,
        setDbtTabs,
        deleteDbtTab,
        dbtResultAdded
      }}
    >
      {children}
    </ModelEditorContext.Provider>
  );
};
