import React, { useContext } from 'react';
import { TableManagementContext } from '../../../../context/TableManagementProvider';
import { DownOutlined, DatabaseOutlined, TableOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './styles.scss'
import { EditorContext } from '../../../../context/EditorProvider';

const Tree = ({ data, setFileTree, first}) => {
    const {
        getTableSchema
    } = useContext(TableManagementContext);

    const {
        setMenuItem,
        setMenuPos,
        setContextMenuType
    } = useContext(EditorContext)

    const onContextMenu = (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuItem(item)
        setMenuPos([event.clientX, event.clientY])
        switch (item.type) {
            case 'table':
                setContextMenuType(5)
                break
            default:
                setContextMenuType(6)

        }
    };

    const handleClick = async (data, e) => {
        e.stopPropagation(); 
        console.log(data)
        setFileTree(prev => prev.map(item => 
            item.key == data.key ?
                { ...item, toggled: !item.toggled }
                :
                item.children ?
                    { ...item, children: item.children.map(child => recursiveTree(child, data)) }
                    :
                    item
        ))
    }

    const recursiveTree = (item, data) => {
        if (item.key == data.key) {
            if (item.type == 'table') {
                if (!item.children) {
                    getTableColumns(item.key)
                    return { ...item, toggled: !item.toggled, loading: true }
                }
                return { ...item, toggled: !item.toggled }
            }
            else if (item.type == 'column') {
                return item
            }
        }
        return item
    }

    const getTableColumns = async (tableKey) => {
        const response = await getTableSchema(tableKey);
        const columns = response[0].columns.map(([name, type]) => ({
            name: name,
            col_type: type,
            key: `${tableKey}.${name}`,
            type: 'column',
        }));

        setFileTree(prevData => prevData.map(schemaNode => ({
            ...schemaNode,
            children: schemaNode.children.map(tableNode => {
                if (tableNode.key === tableKey) {
                    return { ...tableNode, children: columns, loading: false };
                }
                return tableNode;
            })
        })));
    }

    if (data) {
        if (data.type == 'schema' || data.type == 'table')
            return (
                <ul
                className={!first ? "text-gray-600 select-none cursor-pointer border-l-[1px] border-l-gray-300  ml-3 pl-1 pt-0  mb-0 mt-0 pb-0 text-[15px]": "text-gray-600 py-2 pt-0 mb-0 mt-0 pb-0 text-[15px]"}
                >
                    <li
                    key={data.name}
                    onContextMenu={(e) => onContextMenu(e, data)}
                    onClick={(e) => handleClick(data, e)}
                    className='select-none cursor-pointer'
                    >
                        <div className={`flex select-none items-center cursor-pointer w-full hover:bg-gray-200 rounded-md transition pl-[5px]`} >
                            <div className='flex items-center flex-none'>
                                {
                                    data.toggled ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                }
                                {data.type == 'schema' ?
                                    <DatabaseOutlined className='mr-1' />
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-1">
                                        <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                                        <line x1="3" x2="21" y1="9" y2="9" />
                                        <line x1="3" x2="21" y1="15" y2="15" />
                                        <line x1="9" x2="9" y1="9" y2="21" />
                                        <line x1="15" x2="15" y1="9" y2="21" />
                                    </svg>
                                }
                            </div>
                            <div className={`grow truncate relative ${data.loading && 'opacity-[0.5]'}`}>
                                    {data.name}
                                {data.loading && <div className="table-column-animation"></div>}
                            </div>
                        </div>
                        {data.toggled && data.children && data.children.map((item) => 
                            <Tree data={item} setFileTree={setFileTree} first={false}/>
                        )}
                        {/* {data.loading &&
                            <Spin spin></Spin>

                        } */}
                    </li>
                </ul>
            );
                
        return (
        <ul
        className={!first ? "text-gray-600 select-none cursor-pointer border-l-[1px] border-l-gray-300  ml-3 pl-1 pt-0  mb-0 mt-0 pb-0 text-[15px]": " text-gray-600 py-2 pt-0 mb-0 mt-0 pb-0 text-[15px] "}
        >
            <li
            key={data.name}
            onClick={(e) => handleClick(data, e)}
            onContextMenu={(e) => onContextMenu(e, data)}
            className={`flex select-none cursor-pointer w-full hover:bg-gray-200 rounded-md transition pl-3`}
            >
                <div className='truncate '>
                    {`${data.name} (${data.col_type})`} 
                </div>
            </li>
            </ul>
            );      
    }
    return (
        <>
        </>
    )
}

export default Tree
