import { useState, useContext, useEffect } from 'react';
import AnswerCard from '../../components/Onboarding/AnswerCard';
import { Card, Row, Col } from 'antd'
import "./styles.scss"
import A_Green from "../../components/NavBar/logo_png/A_Green.png"
import WarehouseInput from '../../components/Onboarding/WarehouseInput';
import { OnboardContext } from '../../context/OnboardProvider';
import SetOrganizationName from '../../components/Onboarding/OrgName';
import WarehouseCards from '../../components/Onboarding/WarehouseCards';
import EnterPromo from '../../components/Onboarding/EnterPromo';
import { useHistory } from 'react-router-dom';
import { trackOnboardingFeature } from '../../utils/SegmentTracker';
import { useAuthInfo } from '@propelauth/react';
import GitSetup from '../../components/Onboarding/GitSetup';

export const infraData = [
  {
    name: 'Yes',
  },
  {
    name: 'No',
  },
];

export const companyStageData = [
  {
    name: 'Private',
    img_url:
      'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/uhsr0uetxgjuq84ksent',
  },
  {
    name: 'Seed/Pre-seed',
    img_url:
      'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/uhsr0uetxgjuq84ksent',
  },
  {
    name: 'Series A-B',
    img_url:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Power_bi_logo_black.svg/2048px-Power_bi_logo_black.svg.png',
  },
  {
    name: 'Series C+',
    img_url: 'https://cdn.worldvectorlogo.com/logos/tableau-software.svg',
  },
];

export const toolsData = [
  {
    name: 'Yes',
  },
  {
    name: 'No',
  },
];

const Onboarding = ({ setUser }) => {
  const authInfo = useAuthInfo();
  const [selectedCard, setSelectedCard] = useState();
  const history = useHistory();

  const {
    setWarehouseType,
    setRequiredFields,
    nextPage,
    setCurrentPage,
    previousPage,
    currentPage,
    completeOnboard,
  } = useContext(OnboardContext)

  const ShowPage = () => {
    //already has an org, means we pick off where they left off for onboarding
    if (currentPage === 5 && authInfo.orgHelper.getOrgIds()[0]) {
      setCurrentPage(6)
    }
    if (currentPage === 1) return infraQuestionnaire;
    if (currentPage === 2) return companyStageQuestionnaire;
    if (currentPage === 3) return toolsQuestionnaire;
    if (currentPage === 5) return OrganizationName;
    // if (currentPage === 5) return PromoCode;
    if (currentPage === 6) return warehouseType;
    if (currentPage === 7) return warehouseInfo;
    if (currentPage === 8) return <GitSetup/>;
    if (currentPage === 9) return allSet;
  }

  //TODO: "Is this your first infra"
  const infraQuestionnaire = (
    <div className="flex flex-col justify-center">
      {/* Titles */}
      <div className="w-full text-4xl text-center mb-8">Is this your first data infrastructure tool?</div>
      <div className="w-full text-2xl text-gray-400 text-center mb-24">This information helps us serve you better</div>

      {/* Card Containers */}
      <div className="w-full justify-center flex-wrap flex">
        <div className="flex flex-wrap w-2/3 justify-center mb-16">
          {infraData.map(data => (
            <div className="outline-1 outline-green-50" onClick={() => console.log('working')}>
              <AnswerCard extension={data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  //TODO: Switch this to a dropdown instead of selecting a card
  // Take inspiration from the SQLEditor Sidebar InfoPreview
  const companyStageQuestionnaire = (
    <div className="flex flex-col justify-center">
      {/* Titles */}
      <div className="w-full text-4xl text-center mb-8">What stage is your company at?</div>
      <div className="w-full text-2xl text-gray-400 text-center mb-24">This information helps us serve you better</div>

      {/* Card Containers */}
      <div className="w-full justify-center flex-wrap flex">
        <div className="flex flex-wrap w-full justify-center mb-12">
          {companyStageData.map(data => (
            <AnswerCard extension={data} />
          ))}
        </div>
      </div>
    </div>
  );

  // TODO: What tools do you already use?
  const toolsQuestionnaire = (
    <div className="flex flex-col justify-center">
      {/* Titles */}
      <div className="w-full text-4xl text-center mb-8">What tools have you previously used?</div>
      <div className="w-full text-2xl text-gray-400 text-center mb-24">This information helps us serve you better</div>

      {/* Card Containers */}
      <div className="w-full justify-center flex-wrap flex">
        <div className="flex flex-wrap w-full justify-center mb-12">
          {toolsData.map(data => (
            <AnswerCard extension={data} />
          ))}
        </div>
      </div>
    </div>
  );

  const OrganizationName = (
    <SetOrganizationName />
  )

  const PromoCode = (
    <EnterPromo />
  )

  const warehouseType = (
    <WarehouseCards />
  );

  const warehouseInfo = (
    <WarehouseInput setUser={setUser} />

  );



  const allSet = (
    <div className="flex flex-col justify-center">
      {/* Titles */}
      <div className="w-full text-4xl text-center mb-8">You are all set... time to unlock your data powers!</div>
      <div className="w-full text-4xl text-center items-center mb-8">
        <button
          className="shadow bg-[#1F422A] hover:bg-[#537650] text-white text-[20px] mt-[10vh] py-2 px-6 rounded"
          onClick={() => {
            completeOnboard()
            trackOnboardingFeature("Onboarding Completed", authInfo.user.userId, authInfo.orgHelper.getOrgIds()[0])
            window.location.reload(false);
          }}
        >
          Go to Artemis
        </button>

      </div>


    </div>
  )



  return (
    <div className="h-screen flex flex-col justify-center">
      {ShowPage()}
      {currentPage <= 3 &&
        <div className="flex w-1/2 justify-end space-x-4 fixed bottom-12 right-24">
          <button type="button" className="flex p-4 hover:bg-gray-200 hover:cursor-pointer" onClick={() => nextPage()}>
            Skip
          </button>
          <button
            className="flex p-4 bg-[#306440] text-white hover:cursor-pointer disabled:bg-gray-400"
            onClick={() => nextPage()}
          >
            Continue
          </button>
        </div>
      }
    </div>
  );
};

export default Onboarding;
