import { useContext, useEffect, useState } from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { ModelEditorContext } from '../../../context/ModelEditorProvider';
import JoinSidebar from './JoinSidebar';
import AggregateSidebar from './AggregateSidebar';
import ModelSidebar from './ModelSidebar';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ErrorModelSidebar from './ErrorModelSidebar';

export default () => {
    const {
        selectedNode,
        retryNodeParse,
        retryParseLoading
    } = useContext(TableGraphContext);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(retryParseLoading)
    }, [retryParseLoading])

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
    );
  
    if (loading) {
        return (
          <div className="tablegraphs-sider-style relative flex flex-col items-center justify-center">
            <Spin indicator={antIcon} />
          </div>
        );
    }

    if (selectedNode && selectedNode.data && selectedNode.data.status && selectedNode.data.status.code == 'error') {
        return(
          <div>
            <ErrorModelSidebar/>
          </div>
        )
    }
  
    let isModelType = selectedNode && selectedNode.data && selectedNode.data.type === 'model_logic';

    if (isModelType){
        return <ModelSidebar/>
    }
    if (selectedNode && selectedNode.data && selectedNode.data.type === 'join_logic') {
        return <JoinSidebar/>
    }
    if (selectedNode && selectedNode.data && selectedNode.data.type === 'aggregate_logic') {
        return <AggregateSidebar/>
    }
    return (
        <div className='mt-[15vh] w-full '>
          <div className='text-center text-black font-[600]'>
            Invalid Logic Node
          </div>
          <div className=' text-center text-gray-500 font-[400] '>
            A Logic node must have at least one input connection
          </div>
          
        </div>
    )
};
