import { createContext, useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LetoClient from './LetoClient'


export const SqlEditorContext = createContext();

export const SqlEditorProvider = ({ children }) => {
    const [sqlTabs, setSqlTabs] = useState({}) 
    const [savedQueries, setSavedQueries] = useState()
    const [recentQueries, setRecentQueries] = useState()
    const [newName, setNewName] = useState()
    const [initialname, setInitialName] = useState()
    const [isEditing, setIsEditing] = useState()
    const [sqlResultAdded, setSqlResultAdded] = useState(false) // Dummy state to update EditorResults
    const [sqlActiveTabId, setSqlActiveTabId] = useState()
    
    const handleEdit = (item) => {
        setNewName(item.name);
        setInitialName(item.name);
        setIsEditing(item.path);
    };


    useEffect(() => {
        getSidebarQueries()
    }, [])

    const getSidebarQueries = async () => {
        const response = await LetoClient.getSidebarQueries();
        setSavedQueries(response.saved_queries)
        setRecentQueries(response.recent_queries)
    }

    const updateQueryName = async (query_id, name) => {
        try {
            const response = await LetoClient.updateQueryName(query_id = query_id, name = name);
            setSavedQueries(prev => prev.map(item => 
                item._id == query_id ?
                    response
                    :
                    item
            ))
            setSqlTabs(prev => {
                for (let key in prev) {
                    if (prev.hasOwnProperty(key)) {
                        if (prev[key].query_id == query_id) {
                            prev[key] = {
                                ...prev[key],
                                title: name,
                            }
                        }
                    }
                }
                return {
                  ...prev
                }
            })
        }
        finally {
            setIsEditing(null)
        }
    }

    const updateQuery = async (query_id, query_string) => {
        try {
            const response = await LetoClient.updateQuery(query_id = query_id, query_string = query_string);
            setSavedQueries(prev => prev.map(item => 
                item.query_id == query_id ?
                    response
                    :
                    item
            ))
        }
        finally {
            setIsEditing(null)
        }
    }

    const deleteSavedQuery = async (query_id) => {
        const response = await LetoClient.deleteSavedQuery(query_id = query_id);
        setSavedQueries(prev => prev.filter(item => item._id !== query_id))
    }

    const deleteRecentQuery = async (query_id) => {
        const response = await LetoClient.deleteRecentQuery(query_id = query_id);
        setRecentQueries(prev => prev.filter(item => item._id !== query_id))
    }

    const deleteAllRecentQueries = async () => {
        const response = await LetoClient.deleteAllRecentQueries();
        setRecentQueries([])
    }

    const saveQuery = async (query_string, query_name) => {
        const response = await LetoClient.saveQuery(query_string, query_name)
        setSavedQueries(prev => [...prev, response])
    }

    const deleteSqlTab = (id) => {
        setSqlTabs(prev => {
            const { [id]: _, ...rest } = prev
            return rest
        })
    }

    const saveRecentQuery = async (query_id) => {
        const response = await LetoClient.saveRecentQuery(query_id)
        setSavedQueries(prev => [...prev, response])
        setSqlTabs(prev => {
            for (let key in prev) {
                if (prev.hasOwnProperty(key) && prev[key].query_id == query_id) {
                    prev[key].query_id = response._id
                    prev[key].type = 'saved'
                }
            }
            return {...prev}
        })
    }

    const openSqlTab = async (tab, query_id, title, type) => {
        setSqlTabs((prev) => {
            return {
                ...prev,
                [tab.id]: {
                    query_id: query_id,
                    loading: true,
                    results: {},
                    type: type,
                    title: title,
                }
            }
        })
        try {
            let response;
            if (type == 'saved')
                response = await LetoClient.getQuerySavedData(query_id)
            else
                response = await LetoClient.getQueryHistoryData(query_id)
            setSqlTabs((prev) => {
                return {
                    ...prev,
                    [tab.id]: {
                        query_id: query_id,
                        query: response?.query, 
                        results: {},
                        type: type,
                        title: title,
                    }
                }
            })
        }
        catch {
            setSqlTabs((prev) => {
                return {
                    ...prev,
                    [tab.id]: {
                        query_id: query_id,
                        error: 'error retrieving query', 
                        results: {},
                        type: type,
                        title: title,
                    }
                }
            })
        }
    }

    const addSqlTab = async (tab, title) => {
        setSqlTabs((prev) => {
            return {
                ...prev,
                [tab.id]: {
                    query: 'SELECT * FROM ...', 
                    results: {},
                    type: 'recent',
                    title: title,
                }
            }
        })
    }

    const addTableAsQuery = (tab, item) => {
        console.log(item)
        let query = ''
        if (item.children) {
            query = `SELECT\n\t${item.children.map(col => col.name).join(',\n\t')}\nFROM ${item.key}`
        }
        else {
            query = `SELECT * FROM ${item.key}`
        }
        setSqlTabs((prev) => {
            return {
                ...prev,
                [tab.id]: {
                    query: query, 
                    results: {},
                    type: 'recent',
                    title: `${item.name} Query`,
                }
            }
        })
    }

    const onQueryChange = (tabId, query) => {
        setSqlTabs((prev) => {
            return {
                ...prev,
                [tabId]: {
                    ...prev[tabId],
                    query: query,
                    edited: prev[tabId].type == 'saved' ? true : false,
                }
            }
        })
    };

    const addResult = (tabId, resultId, type) => {
        setSqlTabs(prev => ({
            ...prev,
            [tabId]: {
                ...prev[tabId],
                results: {[resultId]: {type: type, response: ''}, ...prev[tabId].results}
            }
        }))
        setSqlResultAdded(!sqlResultAdded) // Dummy state
    }

    const updateResult = (tabId, resultId, newResult) => {
        setSqlTabs(prev => ({
                ...prev,
                [tabId]: {
                    ...prev[tabId],
                    results: {
                        ...prev[tabId].results, [resultId]: newResult
                    }
                }
        }))
    }

    const executeQuery = async (tabId) => {
        let resultId = uuidv4()
        const query = sqlTabs[tabId].query
        addResult(tabId, resultId, 'sql_execute')
        const response = await LetoClient.postQuery(query)
        updateResult(tabId, resultId, {type: 'sql_execute',  ...response})
    }

    const toolbarSaveQuery = async (tab_id) => {
        console.log(tab_id)
    }


    const openCopilot = (tabId) => { 
        
        if(sqlTabs[tabId].copilotResultId) {
          return
        } else {
        
        const resultId = uuidv4()
        addResult(tabId, resultId, 'copilot')
        updateResult(tabId, resultId, { type: 'copilot', chat: [] })
          setSqlTabs((prev) => {
              return {
                  ...prev,
                  [tabId]: {
                      ...prev[tabId],
                      'copilotResultId': resultId,
                  }
              }
          })

            setSqlResultAdded(!sqlResultAdded) // Dummy state
        }
    }
    

    const sqlEditorToolbox = {
        'save': toolbarSaveQuery,
        'copilot': openCopilot,
        'run_limit': executeQuery,
    }

    return(
        <SqlEditorContext.Provider
            value={{
                sqlTabs,
                setSqlTabs,
                addSqlTab,
                onQueryChange,
                executeQuery,
                sqlEditorToolbox,
                savedQueries, 
                setSavedQueries,
                recentQueries,
                setRecentQueries,
                updateQueryName,
                updateQuery,
                getSidebarQueries,
                openSqlTab,
                saveQuery,
                saveRecentQuery,
                newName,
                setNewName,
                initialname,
                setInitialName,
                isEditing,
                setIsEditing,
                handleEdit,
                deleteSavedQuery,
                deleteRecentQuery,
                deleteAllRecentQueries,
                deleteSqlTab,
                sqlResultAdded,
                sqlActiveTabId,
                setSqlActiveTabId,
                addTableAsQuery,
            }}>
            {children}
        </SqlEditorContext.Provider>
    );
};

export default SqlEditorProvider;    

