import { Card, Row, Col } from 'antd'
import { useState, useContext, useEffect } from 'react';
import "./styles.scss"
import { OnboardContext } from '../../context/OnboardProvider';
import A_Green from "../../components/NavBar/logo_png/A_Green.png"
import { trackOnboardingFeature } from '../../utils/SegmentTracker';
import { useAuthInfo } from '@propelauth/react';



const WarehouseCards = () => {
  const authInfo = useAuthInfo();
  const [artemis, setArtemis] = useState(false)
  const [databricks, setDatabricks] = useState(false)
  const [snowflake, setSnowflake] = useState(false)
  const [postgres, setPostgres] = useState(false)
  const [GBQ, setGBQ] = useState(false)
  const [redshift, setRedshift] = useState(false)


  const {
    setWarehouseType,
    setRequiredFields,
    nextPage,
    previousPage,
    currentPage,
    setCurrentPage,
    setupWarehouse,
    setSetupWarehouse,
  } = useContext(OnboardContext)

  const setWarehouse = (warehouseType) => {
    trackOnboardingFeature("Warehouse Selected " + warehouseType, authInfo.user.userId, authInfo.orgHelper.getOrgIds()[0])
    console.log(warehouseType)
    setWarehouseType(warehouseType)
    nextPage(warehouseType)
  }


  if (!setupWarehouse) {
    return (
      <div className="flex flex-col justify-center">
        <div className="w-full text-4xl text-center mb-[10px]">Would you like to connect a Database?</div>
        <div className="w-full text-md text-gray-500 text-center mb-[50px]">Connecting a database is required to view, manage, and dive into your data within Artemis. If you skip for now, a database can be connected later.</div>
        <div className='flex w-full justify-center'>
            <button onClick={() => setSetupWarehouse(true)} className='mt-[20px] px-4 py-3 text-[20px] rounded-lg border-gray-300 border-[1px] hover:bg-gray-200 hover:border-gray-400 mr-[30px]'> Connect a Database </button> 
            <button onClick={() => setCurrentPage(currentPage + 2)} className='mt-[20px] px-4 py-3 text-[20px] rounded-lg border-gray-300 border-[1px] hover:bg-gray-200 hover:border-gray-400'> Skip for now </button> 
        </div>   
      </div>
    )
  }


  return (
    <div className="flex flex-col justify-center">
      <svg onClick={() => setSetupWarehouse(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-[100px] mb-[8vh] w-9 h-9 cursor-pointer p-[6px] hover:bg-gray-200 rounded-lg">
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
      </svg>
      <div className="w-full text-4xl text-center mb-[10px]">Choose a data source</div>

      <div style={{ display: "flex", flexWrap: "wrap", placeContent: "center", overflowY: "hidden", margin: "auto" }}>
        <Card className="warehouse-container text-center p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setArtemis(true)} onMouseLeave={() => setArtemis(false)} onClick={() => setWarehouse("artemis")}>
          <div className={artemis ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px', alignContent: "center" }}>
            <img src={A_Green} alt="Databricks logo" />
            <h1 className="align-middle"> Artemis Managed</h1>
            <h2>Don't have a data warehouse? Let us handle that</h2>
          </div>
        </Card>

        <Card className="warehouse-container text-center  p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setDatabricks(true)} onMouseLeave={() => setDatabricks(false)} onClick={() => setWarehouse("databricks")}>
          <div className={databricks ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px' }}>
            <img src="https://s3.us-east-1.amazonaws.com/accredible_temp_credential_images/16002836894132567677717491881160.png" alt="Databricks logo" />
            <h1 className="align-middle">Databricks</h1>
            <h2>Connect Artemis to your pre-existing Databricks instance</h2>
          </div>
        </Card>
        <Card className="warehouse-container text-center p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setSnowflake(true)} onMouseLeave={() => setSnowflake(false)} onClick={() => setWarehouse("snowflake")}>
          <div className={snowflake ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px' }}>
            <img src="https://companieslogo.com/img/orig/SNOW-35164165.png?t=1634190631" alt="Snowflake logo" />
            <h1 className="align-middle">Snowflake</h1>
            <h2>Connect Artemis to your pre-existing Snowflake instance</h2>
          </div>
        </Card>
        <Card className="warehouse-container text-center p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setPostgres(true)} onMouseLeave={() => setPostgres(false)} onClick={() => setWarehouse("postgres")}>
          <div className={postgres ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px' }}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1985px-Postgresql_elephant.svg.png" atl="PostgreSQL logo" />
            <h1 className="align-middle">PostgreSQL</h1>
            <h2>Connect Artemis to your pre-existing PostgreSQL instance</h2>
          </div>
        </Card>
        <Card className="warehouse-container text-center p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setRedshift(true)} onMouseLeave={() => setRedshift(false)} onClick={() => setWarehouse("redshift")}>
          <div className={redshift ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px' }}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Amazon-Redshift-Logo.svg/1200px-Amazon-Redshift-Logo.svg.png" atl="Redshift logo" />
            <h1 className="align-middle">Redshift</h1>
            <h2>Connect Artemis to your pre-existing Redshift instance</h2>
          </div>
        </Card>
        <Card className="warehouse-container text-center p-0" bodyStyle={{ padding: "0px" }} hoverable onMouseEnter={() => setGBQ(true)} onMouseLeave={() => setGBQ(false)} onClick={() => setWarehouse("bigquery")}>
          <div className={GBQ ? "fade-out" : "fade-in"} style={{ fontSize: '20px', width: '280px', height: '122px' }}>
            <img src="https://cdn.worldvectorlogo.com/logos/google-bigquery-logo-1.svg" atl="GBQ logo" />
            <h1 className="align-middle">Google Big Query</h1>
            <h2>Connect Artemis to your pre-existing Google Big Query instance</h2>
          </div>
        </Card>
      </div>
      
    </div>
  )
}

export default WarehouseCards;