export const MenuItems = [
  {
    key: '4',
    label: 'Workflows',
    link: '/workflows'
  },
  // {
  //   key: '11',
  //   label: 'Arty',
  //   link: '/artyai',
  // },
  {
    key: '12',
    label: 'Editor',
    link: '/editor',
  },
  {
    key: '8',
    label: 'Agents',
    link: '/contexts'
  }, 
  {
    key: '9',
    label: 'Diana',
    link: '/diana'
  }, 


];
