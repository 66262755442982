import React, {useContext, useEffect, useState}  from 'react';
import Page from '../../components/Page';
import { ReactFlowProvider } from 'reactflow';
import { AgentContext } from '../../context/AgentProvider';
import { BsSearch } from 'react-icons/bs';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useHistory } from "react-router-dom";

const WORKFLOWS_DOCS_URL = 'https://artemisdata.notion.site/Arty-Agents-Platform-BETA-e70b12e429184a6ca6edaa36e0f4fca8?pvs=4'

const ContextHome = () => {
    const { 
      fetchContexts,
      createContext,
        updateContext,
        updateContextName,
      contexts,
        deleteContextById
    } = useContext(AgentContext);

    const [filteredDags, setFilteredDags] = useState([]);
    
    console.log(contexts)

    const [newName, setNewName] = useState("");
    const [initialName, setInitialName] = useState("");
    const [isEditing, setIsEditing] = useState(null);
    const [hoveredWorkflow, setHoveredWorkflow] = useState(false)
    const history = useHistory();


    useEffect(() => {
      fetchContexts();
    }, []);


  const onCreate = async () => {
    await createContext();
  };


    const deleteContext = async (contextId, e) => {
        e.stopPropagation();
        await deleteContextById(contextId); // Assuming the type is 'contexts'
        fetchContexts(); // Re-fetch contexts to update the list
    };


    const createdAt = (isoTimeString) => {
      const date = new Date(isoTimeString);
      return date.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    }


    const lastUpdated = (time) => {
        console.log(time)
        if (Math.round((Date.now() - time) / (8.64 * 10 ** 7)) > 30) {
            return String(Math.round((Date.now() - time) / (30 * 8.64 * 10 ** 7))) + ' months ago'
        }
        if ((Date.now() - time) / (8.64 * 10 ** 7) > 30) {
            return String(Math.round((Date.now() - time) / (30 * 8.64 * 10 ** 7))) + ' month ago'
        }
        if (Math.round((Date.now() - time) / (8.64 * 10 ** 7)) > 1) {
            return String(Math.round((Date.now() - time) / (8.64 * 10 ** 7))) + ' days ago'
        }
        if ((Date.now() - time) / (8.64 * 10 ** 7) > 1) {
            return String(Math.round((Date.now() - time) / (8.64 * 10 ** 7))) + ' day ago'
        }
        else {
            return String(Math.round((Date.now() - time) / (3.6 * 10 ** 6))) + ' hours ago'
        }
    }

    const searchChange = () => {
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault()
    }



    const handleEdit = (item, e) => {
        e.stopPropagation();
        setNewName(item.name);
        setInitialName(item.name);
        setIsEditing(item._id);
    };

    const handleSave = async (contextId) => {
        if (isEditing !== null) {
            await updateContextName(contextId, newName);
            setIsEditing(null);
        }
    };

    const handleCancel = () => {
        setNewName(initialName);
        setIsEditing(null);
    };

    const handleKeyDown = async (e, contextId) => {
        if (e.key === "Enter") {
            await handleSave(contextId);
        }
    };




    const onEditorClick = async (context_id) => {
        history.push(`/contexts/${context_id}`);
    }


    const onChatClick = async (dag_id) => {
    } 


    return (
        <Page>
            <div className="flex flex-col w-full h-full overflow-y-auto bg-white">
                <div className='w-full px-6 my-2'>
                    <div className = "py-4 rounded-lg flex items-center w-full">  
                        <h1 className="text-2xl font-bold select-none my-auto ml-0 px-2">Agents [BETA]</h1>
                        <div className = "flex flex-row ml-auto mr-0 gap-4">
                            <form className="searchbar-styling flex items-center my-[5px]" onSubmit={handleSearchSubmit}>   
                                <div className="relative w-full">
                                    <input type="search" className="bg-gray-50 border w-full rounded-[5px] border-gray-300 text-gray-900 text-sm block pl-10 px-2.5 py-[9.5px]" placeholder="Search" onChange={(e) => searchChange(e.target.value)} />
                                    <BsSearch className="absolute left-3 top-3 text-gray-400"></BsSearch>
                                </div>
                            </form>
                            <button className="border border-gray-300 w-[200px] my-1 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200" onClick = {() => {onCreate()}}>
                                <a className= "font-bold select-none">Create new</a>
                            </button>
                            <button className="border border-gray-300 my-1 px-4 mr-2 rounded-lg flex justify-center items-center bg-[#F7F7F7] hover:bg-gray-200"
                                onClick = {() => {
                                    window.open(WORKFLOWS_DOCS_URL, '_blank');
                                }}>
                                <a className="font-bold select-none">Docs</a>
                            </button> 
                        </div>
                    </div>

                </div>
                <div className='flex mx-2 flex-wrap px-6'>

                {contexts ? contexts.length ? contexts.map((item, index) => (

                    
                    <div key={index} className="border-[1px] border-gray-300 py-4 px-4 rounded-lg hover:scale-105 my-5 mx-3 h-[200px] w-[200px]"
                        onMouseEnter={() => setHoveredWorkflow(item)}
                        onMouseLeave={() => setHoveredWorkflow()}
                    >

                        <div 
                        key={index}
                        className="py-4 pl-5 rounded-lg items-center cursor-pointer"

                        onClick={()=>{if(isEditing !== item._id)onEditorClick(item._id)}}
                        >



                            <div className='min-w-[25%] justify-start'>
                                {isEditing === item._id ? (
                                    <div className="flex items-center space-x-2">
                                        <input 
                                            className="border border-gray-900 focus:border-black p-1 text-xl font-bold"
                                            type="text" 
                                            value={newName} 
                                            onChange={(e) => setNewName(e.target.value)} 
                                            onBlur={handleCancel}
                                            onKeyDown={(e) => handleKeyDown(e, item._id)}
                                            autoFocus
                                        />
                                        <CheckCircleOutlined className='cursor-pointer' onMouseDown={()=>handleSave(item._id)}/>
                                        <CloseCircleOutlined onClick={handleCancel}/>
                                    </div>
                                ) : (
                                <div className='min-w-[25%] justify-start flex items-center'>
                                <h1 
                                    className="text-xl font-bold select-none cursor-text"
                                    onClick={(e) => handleEdit(item, e)}
                                >
                                    {item.name}
                                </h1>
                                
                            </div>
                                )}
                                
                            </div>
                            <div className='justify-center mt-1'>

                                <p className="text-xs text-gray-500 mr-[30px] select-none">{item?.data?.sources?.length ? item.data.sources.length : 0} sources</p>
                                <p className="text-xs text-gray-500 mr-[30px] select-none">{item?.data?.functions?.length ? item.data.functions.length : 0} functions</p>



                                <p className="text-xs text-gray-500 mr-[30px] select-none">Created: {createdAt(item.created_at)}</p>

                            </div>

                            <div className='flex'>
                                <Tooltip title='Delete'>
                                        <div 
                                            className=" hover:bg-gray-200 w-fit text-black font-bold py-1 px-1 rounded select-none cursor-pointer"
                                            onClick={(e) => {deleteContext(item._id, e)}}
                                        >
                                            <div className={hoveredWorkflow === item ? 'rounded-lg opacity-1 ' : 'rounded-lg opacity-0'}>
                                                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" className='w-5 h-5'>
                                                    <path fill="currentColor" strokeWidth="1" d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </Tooltip>

                                {hoveredWorkflow === item ? 
                                    <Tooltip title='Editor'>
                                        <div 
                                            className="hover:bg-gray-200 text-black font-bold py-1 px-1 ml-2 rounded select-none cursor-pointer"
                                        >
                                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </div>
                                    </Tooltip> : <div></div>
                                }
                                </div>


                                                               
                            </div>
                    </div>))

                    :
                    <div className='mt-[75px] text-center text-gray-500 text-[24px] font-600'>
                            No contexts found...
                    </div>
                    :
                    <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center'>
                        <div className='flex flex-col items-center'>
                            <div className='w-[100px] h-[100px]'>
                                <div className="page-loading-container">
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div> 
                            </div>
                        </div>
                    </div>
                }
            </div>

            </div>
        </Page>
    );
};

export default ContextHome;

