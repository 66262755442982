import axios from 'axios';

const API_URL = 'https://api.fivetran.com/v1';
const API_KEY = '8kax8Y35AtKIGxVv';
const API_SECRET = 'Z9lcES1uUMa32psgb0tcPjfTAZTovY0G';

class FivetranAPI {
  constructor() {
    this.auth = { username: API_KEY, password: API_SECRET };
    this.baseUrl = API_URL;
    this.groupId = '';
  }
  getGroupId() {
    return this.groupId;
  }

  setGroupId(id) {
    this.groupId = id;
  }

  async getMetadata() {
    return await this.executePaged(`get`, `/metadata/connectors`, 200);
  }

  async getListOfConnectorsInGroup(group = this.groupId) {
    return await this.executePaged(`get`, `/groups/${group}/connectors`, 1000);
  }

  async getConnector(id) {
    const responseData = await this.execute(`get`, `/connectors/${id}`, null);
    return responseData.data;
  }

  async getConnectorSchema(id) {
    const responseData = await this.execute(`get`, `/connectors/${id}/schemas`, null);
    return responseData.data;
  }

  async getConnectorSchemaColumns(id, schema, table) {
    const responseData = await this.execute(`get`, `/connectors/${id}/schemas/${schema}/tables/${table}/columns`, null);
    return responseData.data;
  }

  async createConnector(service, name, group = this.groupId) {
    const connector_ft_name = name.replace(/ /g, '_').toLowerCase();
    var responseData
    if(service = 'google_analytics'){
      console.log('google_analytics!')
      responseData = await this.execute(`post`, `/connectors`, {
        service: service,
        group_id: group,
        config: {
          schema: connector_ft_name,
          schema_prefix: connector_ft_name,
          reports : [
          {
            table: connector_ft_name,
            dimensions: ['date']
          }]
        },
        });
    }
    else{
      responseData = await this.execute(`post`, `/connectors`, {
        service: service,
        group_id: group,
        config: {
          schema: connector_ft_name,
          table: connector_ft_name,
          schema_prefix: connector_ft_name,
        },
        });
    }
    let result = responseData;
    result.data.display_name = name;
    return result;
  }

  async getConnectCardTokenForConnector(id) {
    const responseData = await this.execute(`post`, `/connectors/${id}/connect-card-token`, {});
    return responseData.token;
  }

  async deleteConnector(id) {
    const responseData = await this.execute(`delete`, `/connectors/${id}`, {});
    return responseData.token;
  }

  async patchConnector(id, body) {
    const responseData = await this.execute(`patch`, `/connectors/${id}`, body);
    return responseData.data;
  }

  async patchAllConnectors(id, body) {
    const responseData = []
    for (var connector in id) {
      const data = await this.execute(`patch`, `/connectors/${id[connector]}`, body);
      responseData.push(data.data)
    }
    return responseData
  }

  async execute(method, path, data) {
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      auth: this.auth,
      data: data,
    });
    if (response.status >= 400) throw Error(response.data);

    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      auth: this.auth,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      console.log(`${method}, ${path}, ${cursor}`);
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        auth: this.auth,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        console.log(response.data);
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }
}

const FivetranApi = new FivetranAPI();
export default FivetranApi;
