const DatabaseMenu = ({ menuItem, setMenuItem, setMenuPos }) => {

    const handleCopy = async (e) => {
        e.stopPropagation()
        await navigator.clipboard.writeText(menuItem.name);
        setMenuPos()
        setMenuItem()
    }



    return (
        <div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleCopy(e)}>Copy Name</div>
            </div>
        </div>
    )   
}

export default DatabaseMenu
