import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const WarehouseIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
</svg>;

const BigQueryIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
</svg>

const JSONIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10"> 
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
</svg>;


const ComingSoonIcon = 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M4.867 19.125h.008v.008h-.008v-.008Z" />
</svg>
;


const CodeIcon = 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
</svg>;

const MetabaseIcon = 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
</svg>;

const ArtyIcon = 
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
          <rect width="35" height="35" rx="2.8" fill="white"/>
          <circle cx="10.22" cy="10.6396" r="3.5" fill="#4CB043"/>
          <rect x="24.7682" y="7.13965" width="7" height="21" rx="3.5" transform="rotate(38.0791 24.7682 7.13965)" className='fill-inherit'/>
        </svg>;

const DBTIcon = 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>;

const WorkflowsIcon = 
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className="w-10 h-10">

  <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
</svg>;

export default function AddModalNode({ open, onClose, type, onSubmit, fetchSuggestion, loadingSuggestion }) {
  const sourceOptions = ["Leto", "Warehouse", "Workflows", "Text", "URL", "JSON", "API", "PDF"];
  const functionOptions = ["Code", "Copilot", 'DBT', 'Workflows'];

  const options = type === 'source' ? sourceOptions : functionOptions;

  const [selectedOption, setSelectedOption] = useState(null);
  const [modalStep, setModalStep] = useState(1); 
  const [copilotInput, setCopilotInput] = useState(''); 

const [metabaseDetails, setMetabaseDetails] = useState({
  url: '',
  username: '',
  password: '',
});
const handleMetabaseInputChange = (e) => {
  const { name, value } = e.target;
  setMetabaseDetails(prevDetails => ({
    ...prevDetails,
    [name]: value
  }));
};

const handleSubmitMetabase = () => {

  const { url, username, password } = metabaseDetails;
  const formatInput = (input) => {
    return input.startsWith('$') ? input.slice(1) : `'${input}'`;
  };

  const updatedCode = `URL=${formatInput(url)}\nUSER=${formatInput(username)}\nPASS=${formatInput(password)}\n` +
                      "MetabaseClient.set_auth(URL, USER, PASS)\n" +
                      "id = MetabaseClient.get_database_id(database_name)\n" +
                      "url = MetabaseClient.create_public_metabase_table_card_with_query(sql_query, id)\n" +
                      "result = {'content': url, 'type': 'url'}";

  const functionObject = {
    id: "1705880743215",
    name: "create_metabase_card",
    description: "takes in a sql query and a database_name and generates a metabase card",
    parameters: [
      {
        "value": "sql_query",
        "description": "sql query that returns data from database"
      },
      {
        "value": "database_name",
        "description": "name of the database"
      }
    ],
    code: updatedCode,
    type: "function"
  };

  onSubmit(functionObject);
    
 setModalStep(1);
  onClose();
};
  const handleCopilotInputChange = (e) => {
    setCopilotInput(e.target.value);
  };
  const icons = {
    Leto: WarehouseIcon,
    Warehouse: BigQueryIcon,
    Text: JSONIcon,
    URL: JSONIcon,
    JSON: JSONIcon,
    API: ComingSoonIcon, 
    CSV: ComingSoonIcon,
    PDF: ComingSoonIcon,
    Code: CodeIcon, 
    Metabase: MetabaseIcon, Copilot: ArtyIcon, DBT: DBTIcon, Workflows: WorkflowsIcon
  };
const createNewNode = (type, data) => {
  return {
    id: String(Date.now()), // Unique ID based on timestamp
    type: type,
    dateCreated: new Date().toISOString(),
    lastUpdated: new Date().toISOString(),
    data
  };
};
const handleSelect = (option) => {
  let nodeData;
  if (type === 'source') {
    // Define the structure for different source types
    switch (option) {
      case 'Warehouse':
        nodeData = createNewNode('source', {'type': 'Warehouse', 'tables': []})        
        break;
      case 'Metabase':
        nodeData = createNewNode('source', {'type': 'Metabase',  'data': {'metabase_config': {}, 'context':{}}})        
        break;
      case 'Text':
        nodeData = createNewNode('source', {'type': 'Text',  'data': {}})  
        break;
      case 'URL':
        nodeData = createNewNode('source', {'type': 'URL', 'data':{}})
        break;
    }
    
      if(nodeData){
            onSubmit(nodeData); // Pass the created node data back
      }
    onClose();
  } else if (type === 'function') {
      switch (option) {
        case 'Code':

    const defaultFunctionData = {
      name: 'foo',
      code: ``,
      description: 'function that does x',
      parameters: [] 
    };
            
          onSubmit(createNewNode('function', defaultFunctionData));
          onClose();
          break;
        case 'Copilot':
            setSelectedOption(option);
            setModalStep(2)
        case 'Metabase':
          setSelectedOption(option);
          setModalStep(2);
          break;
        // ... handle other function types
      }
  
  }


};

  const handleSubmitCopilot = async () => {
      await fetchSuggestion(copilotInput); 
        setModalStep(1);
      onClose();
  };

  const renderStep2Content = () => {
    switch (selectedOption) {
      case 'Copilot':
        return <div className="p-4 flex">

          <input 
            type="text"
            value={copilotInput}
            onChange={handleCopilotInputChange}
            className="w-full p-2 border border-gray-400 rounded"
            placeholder="Enter Copilot text..."
          />
          <Spin indicator={antIcon} spinning={loadingSuggestion}>

          <button
            onClick={handleSubmitCopilot}
            className="mt-2 hover:scale-105 text-white font-bold py-2 px-4 rounded"
          >
        <svg xmlns="http://www.w3.org/2000/svg" className='mb-1' width="35" height="35" viewBox="0 0 35 35">
          <rect width="35" height="35" rx="2.8" fill="white"/>
          <circle cx="10.22" cy="10.6396" r="3.5" fill="#4CB043"/>
          <rect x="24.7682" y="7.13965" width="7" height="21" rx="3.5" transform="rotate(38.0791 24.7682 7.13965)" className='fill-inherit'/>
        </svg>
          </button>
                </Spin>


        </div>;    
        case 'Metabase':
      return (
        <div className="p-4">
          <input 
            type="text"
            name="url"
            value={metabaseDetails.url}
            onChange={handleMetabaseInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
            placeholder="Metabase URL"
          />
          <input 
            type="text"
            name="username"
            value={metabaseDetails.username}
            onChange={handleMetabaseInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
            placeholder="Username"
          />
          <input 
            type="text"
            name="password"
            value={metabaseDetails.password}
            onChange={handleMetabaseInputChange}
            className="w-full p-2 border border-gray-300 rounded mb-2"
            placeholder="Password"
          />
          <button
            onClick={handleSubmitMetabase}
            className="bg-white-500 hover:scale-105 border border-2 py-2 px-4 rounded w-full"
          >
            Submit
          </button>
        </div>
      );
      default:
        return <div>Default Content</div>;
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={()=>{setModalStep(1); setSelectedOption(''); onClose()}}>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="border border-1 relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 w-1/2 h-full">
                {modalStep === 1 && (
                <div className="grid grid-cols-4 gap-5 p-5 m-5 overflow-y-auto overflow-x-hidden">
                  {options.map((option, index) => (
                        <div 
      key={index} 
      className="flex flex-col items-center justify-center cursor-pointer" 
      onClick={() => handleSelect(option)}
    >                      {icons[option]}
                      <p className="text-center mt-2 p-2">{option}</p>
                    </div>
                  ))}
                </div>
                )}
                {modalStep === 2 && renderStep2Content()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

