import React, { useState, useEffect, useRef} from 'react';

const CollapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="w-6 h-6 relative top-[10px] right-[3px] hover:bg-gray-200 rounded-md p-[2px] transition ease-in-out">
  <path className='color-gray-100 ' strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
</svg>

);

const ExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" className="w-6 h-6 relative top-[10px] left-[3px] hover:bg-gray-200 rounded-md p-[2px] transition ease-in-out">
  <path className='color-gray-100 ' strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
</svg>

);

const Component = ({ children, setSidebarData, sidebarContent, openSidebar, setOpenSidebar, hideButtonWhenClosed, removePadding, onSidebarCollapse=null, sidebarWidth, setSidebarWidth, providedRef=null, hideButtonWhenOpen=null}) => {
  
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  
  const startResizing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(true);
    document.body.classList.add('body-cursor-resize-ew');
  };

  const resize = (e) => {
    if (isResizing) {
      const newWidth = Math.min(Math.max(e.clientX, 330), window.innerWidth * 11/16); // Ensure minimum width
      setSidebarWidth(`${newWidth}px`);
    }
  };

  const stopResizing = () => {
    setIsResizing(false);
    document.body.classList.remove('body-cursor-resize-ew');
  };

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);

    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing]);

    
  return (
    <div className="flex h-full w-full max-h-full" style = {{overflow: 'visible', position: 'relative'}}>
      <div
        ref={sidebarRef}
        className={`${
          openSidebar ? 'min-w-[330px] relative' : !openSidebar && !hideButtonWhenClosed ? 'w-[30px]' : 'w-0'
        } bg-[#F7F7F7] ${openSidebar ? '' : 'transition-all duration-100 ease-in-out'} border-r-[1px] shadow-sm`}
        style={{ width: openSidebar ? sidebarWidth ? sidebarWidth : '330px' : '', overflow: "auto", position: 'relative' }}
      >
        <button 
          className={`${
            openSidebar ? 'absolute top-0 right-2' : 'absolute top-0 left-0'
            } ${(hideButtonWhenClosed && !openSidebar) || (hideButtonWhenOpen && openSidebar) ? 'invisible' : ''}`
          }
          onClick={() => {
            if (openSidebar && onSidebarCollapse) {
              onSidebarCollapse()
            }
            setOpenSidebar(!openSidebar)

          }}
        >
   
      {openSidebar? CollapseIcon() : ExpandIcon()}
    </button>

        {/* Sidebar content */}
        <div className={openSidebar ? 'duration-0 h-full' : 'hidden duration-0 h-full'}>

          <div ref={providedRef} className={removePadding ? "h-full w-full overflow-auto" : "h-full w-full p-4 overflow-auto"}>{sidebarContent}</div>
        </div>
      </div>
      {openSidebar && 
        <div
          onMouseDown={startResizing}
          className={`cursor-ew-resize h-full border-r-[6px] bg-gray-200 focus:border-gray-400 hover:border-gray-400 transition-[2000ms] ease-in-out ${isResizing && 'border-gray-400'}`}
        style={{ zIndex: 0}}
      ></div>
      }

      <div
        className={`${
          openSidebar ? 'flex-grow' : 'w-full'
        } bg-white overflow-auto`}
        style={{maxWidth: openSidebar ? `${window.innerWidth - parseInt(sidebarWidth)}px`: ''}}
      >
        <div className={removePadding ? "h-full" : "h-full pl-5"} >{children}</div>
      </div>
    </div >
  );
};

export default Component;

