import React, { useState, useEffect, useContext } from 'react';
import { AgentContext } from '../../../context/AgentProvider';
import Page from '../../../components/Page';

import { format, parseISO } from 'date-fns';

const SecretsPage = () => {
  const [secrets, setSecrets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newSecret, setNewSecret] = useState({ key: '', value: '' });
  const [error, setError] = useState('');
  const { getSecrets, createSecret, deleteSecret } = useContext(AgentContext);

  useEffect(() => {
    fetchSecrets();
  }, []);
  const handleDirectAddSecret = async () => {
    if (!isValidKey(newSecret.key)) {
      setError('Key must only contain letters and underscores, and cannot start with a number.');
      return;
    }
    setError('');
    await createSecret(newSecret.key, newSecret.value);
    setNewSecret({ key: '', value: '' });
    fetchSecrets();
  };
  const fetchSecrets = async () => {
    const fetchedSecrets = await getSecrets();
    setSecrets(fetchedSecrets || []);
  };
  const handleDeleteSecret = async (key) => {
    await deleteSecret(key);
    fetchSecrets(); // Refresh the list after deletion
  };
  const isValidKey = (key) => {
    return /^[A-Za-z_]+[A-Za-z0-9_]*$/.test(key);
  };

  const handleAddSecret = async () => {
    if (!isValidKey(newSecret.key)) {
      setError('Key must only contain letters and underscores, and cannot start with a number.');
      return;
    }
    setError('');
    await createSecret(newSecret.key, newSecret.value);
    setNewSecret({ key: '', value: '' });
    setShowModal(false);
    fetchSecrets();
  };

  return (
    <Page>
      <div className="p-4 text-black mx-15 my-10">
        <h1 className="text-2xl font-semibold mb-4">Secrets - encrypted key value pairs injected directly into agents</h1>

        <div className="overflow-x-auto relative sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-700">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              <tr>
                <th scope="col" className="py-3 px-6">Key</th>
                <th scope="col" className="py-3 px-6">Value</th>
                <th scope="col" className="py-3 px-6">Created At</th>
                <th scope="col" className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
             <tr className="bg-white border-b">
                <td className="py-4 px-6">
                  <input
                    type="text"
                    className="shadow-sm py-3 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter Secret Key"
                    value={newSecret.key}
                    onChange={(e) => setNewSecret({ ...newSecret, key: e.target.value })}
                  />
                </td>
                <td className="py-4 px-6">
                  <input
                    type="text"
                    className="shadow-sm py-3 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter Secret Value"
                    value={newSecret.value}
                    onChange={(e) => setNewSecret({ ...newSecret, value: e.target.value })}
                  />
                </td>
                <td className="py-4 px-6"></td>
                <td className="py-4 px-6">
                  <button
                    className="text-black-500 hover:text-black-700"

                    onClick={handleDirectAddSecret}
                  >
                    Add
                  </button>
                </td>
              </tr>
              {secrets.map((secret, index) => (
                <tr key={index} className="bg-white border-b">
                  <td className="py-4 px-6">{secret.key}</td>
                  <td className="py-4 px-6">{secret.value}</td>
                  <td className="py-4 px-6">
                    {secret.created_at ? format(parseISO(secret.created_at), 'PPP') : 'N/A'}
                  </td>
                  <td className="py-4 px-6">
                    <button
                      onClick={() => handleDeleteSecret(secret.key)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};export default SecretsPage;

