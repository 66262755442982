import {useContext, React, useState, useEffect} from 'react';
import { Handle, Position } from 'reactflow';
import { ArtyAIContext } from '../../context/ArtyAIProvider';
import { useHistory } from 'react-router-dom';
import { TableGraphContext } from '../../context/TableGraphProvider';
import "./styles.scss"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
    <div className='flex flex-col items-center'>
    <div className='w-[50px] h-[50px]'>
    <div className="loading-container">
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
            </div>
        </div>
        </div>
);


const CopilotNode = (node) => {
    const {
        selectedNode,
        acceptCopilotNode,
        nodes,
        setCopilotNodes,
        copilotEdges,
        copilotNodes,
        setCopilotEdges,
        copilotNodesLoading,
    } = useContext(TableGraphContext);

    const [targetEdges, setTargetEdges] = useState([])
    const [acceptable, setAcceptable] = useState(false)

    let initDescription = node.data && node.data.description ? node.data.description : '';

    useEffect(() => {
        setTargetEdges(copilotEdges.filter(edge => edge.target === node.id))
    }, [copilotEdges, copilotNodes, nodes])

    useEffect(() => {
        setAcceptable(targetEdges.every(edge => nodes.find(n => n.id === edge.source)))
    }, [targetEdges])

    const getIcon = () => {
        return node.data.type == 'source' ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
        </svg>
        :
        node.data.type == 'model' ? 
        <svg  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M22 4v3H10V4H2v8h8V9h7.023A4.946 4.946 0 0 0 16 12v8a3.004 3.004 0 0 1-3 3h-3v-3H2v8h8v-3h3a5.006 5.006 0 0 0 5-5v-8a2.998 2.998 0 0 1 2.997-3H22v3h8V4ZM8 10H4V6h4Zm0 16H4v-4h4Zm20-16h-4V6h4Z"/>
        </svg>
        :
        node.data.type == 'seed' ? 
        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000000" d="M48 180c0 11 7.18 20 16 20a14.24 14.24 0 0 0 10.22-4.66a8 8 0 0 1 11.56 11.06A30.06 30.06 0 0 1 64 216c-17.65 0-32-16.15-32-36s14.35-36 32-36a30.06 30.06 0 0 1 21.78 9.6a8 8 0 0 1-11.56 11.06A14.24 14.24 0 0 0 64 160c-8.82 0-16 9-16 20Zm79.6-8.69c-4-1.16-8.14-2.35-10.45-3.84c-1.25-.81-1.23-1-1.12-1.9a4.57 4.57 0 0 1 2-3.67c4.6-3.12 15.34-1.73 19.82-.56a8 8 0 0 0 4.15-15.48c-2.12-.55-21-5.22-32.84 2.76a20.58 20.58 0 0 0-9 14.95c-2 15.88 13.65 20.41 23 23.11c12.06 3.49 13.12 4.92 12.78 7.59c-.31 2.41-1.26 3.34-2.14 3.93c-4.6 3.06-15.17 1.56-19.55.36a8 8 0 0 0-4.31 15.44a61.34 61.34 0 0 0 15.19 2c5.82 0 12.3-1 17.49-4.46a20.82 20.82 0 0 0 9.19-15.23c2.19-17.31-14.32-22.14-24.21-25Zm83.09-26.84a8 8 0 0 0-10.23 4.84L188 184.21l-12.47-34.9a8 8 0 0 0-15.07 5.38l20 56a8 8 0 0 0 15.07 0l20-56a8 8 0 0 0-4.84-10.22ZM216 88v24a8 8 0 0 1-16 0V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-27.31-8L160 51.31V80Z"/>
        </svg>
        :
        node.data.type == 'logic' || node.data.type == 'join_logic' || node.data.type == 'aggregate_logic' || node.data.type == "model_logic" ?
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
        </svg>
    }

    const Title = () => {
        return (
        <div className='w-full font-sans font-[600] text-[10px] truncate'>
                {node.data.model_name}
                {/* Suggested Node */}
        </div>
        )
    }

    let nodeColor = 'bg-[#F7F7F7]';


    const border = (selectedNode && selectedNode.id && node.id === selectedNode.id)? 'border-[#4B613D] border-2 ' : '';

    const handleAccept = async (e) => {
        e.stopPropagation()
        let accepted_node = copilotNodes.find(item => item.id === node.id)
        await acceptCopilotNode(accepted_node, targetEdges)

    }

    const handleDelete = (e) => {
        e.stopPropagation()
        setCopilotNodes(prev => prev.filter(item => item.id !== node.id))
        setCopilotEdges(prev => prev.filter(item => item.source !== node.id && item.target !== node.id))
    }

    return (
        <div className='relative'>
            <Spin indicator={antIcon} spinning={copilotNodesLoading[node['id']] == true}>

                {
                    node.data.type !== "model" &&
                <Handle isConnectable={false} type="target" position="left" id="a" style={{ background: '#555' }} />
                }

            <div className='absolute top-[4px] right-[4px] flex'>
                { acceptable && <div onClick={(e)=>handleAccept(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:stroke-2 w-3 h-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                </div>
                }
                <div onClick={(e) => handleDelete(e)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:stroke-2 w-3 h-3">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                </div>

            
            </div>

        <div className={`h-[125px] w-[150px] ${nodeColor} border border-1  ${border}  rounded px-[5px] py-[5px]`}>

            <div className='mb-[1px] flex items-center'>
            <div className='pl-1 w-full font-sans text-[10px] font-[600] overfow-hidden'>
                {`Copilot ${node.data.type}`}
            </div>

            </div>
            <div className='w-full border border-1 bg-white h-[25px] rounded flex mb-[5px] pl-[5px] font-sans text-[10px] '>
            <div className='w-[16px] h-[16px] mt-[4px]'>
                {getIcon()}
            </div>
            
            <div className='mt-[5px] ml-[3px] truncate'>
                {Title()}
            </div>
            </div>

            <div className='w-full border border-1 bg-white h-[67px] rounded flex flex-wrap items-start justify-start' >
            <div className='font-sans text-[8px] font-[500] p-[5px] flex flex-wrap h-[68px] w-full overflow-hidden'>
                <div className='w-[125px] h-full '>
                    <h1 className='h-full leading-3 break-words text-start resize-none '>
                    { initDescription}
                    </h1>
                </div>
            </div>
            </div>
        </div>
        {node.data.type != 'chatApp' && 
            <Handle isConnectable={false} type="source" position="right" id="b" style={{ background: '#555' }} />
        }
        </Spin>
        </div>
    );
};

export default CopilotNode;

