import React, {useContext, useState, useEffect} from 'react';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';
import { UserContext } from '../../../../context/UserProvider';
import { SqlEditorContext } from '../../../../context/SqlEditorProvider';
import { EditorContext } from '../../../../context/EditorProvider';



const TableMenu = ({ menuItem, setMenuItem, setMenuPos }) => {
    const {
        openTableAsQuery,
    } = useContext(EditorContext);

    const handleOpenQuery = (e) => {
        e.stopPropagation()
        openTableAsQuery(menuItem)
        setMenuPos()
        setMenuItem()
    }

    const handleCopy = async (e) => {
        e.stopPropagation()
        await navigator.clipboard.writeText(menuItem.name);
        setMenuPos()
        setMenuItem()
    }



    return (
        <div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleCopy(e)}>Copy Name</div>
            </div>
            <div className="hover:bg-gray-100 py-1 px-2 rounded cursor-pointer select-none">
                <div className=" text-gray-900" onClick={(e) => handleOpenQuery(e)}>Open as Query</div>
            </div>
        </div>
    )   
}

export default TableMenu
