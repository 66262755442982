import { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from '../../context/AnalyticsProvider';
import AnalyticsDropdown from './AnalyticsDropdown';
import { Button, Popover, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AnalyticsChartFilters from './AnalyticsChartFilters';

const AggregateTypes = [
    {"value" : "AVG", "label" : "AVG"},
    {"value" : "COUNT", "label" : "COUNT"},
    {"value" : "MAX", "label" : "MAX"},
    {"value" : "MIN", "label" : "MIN"},
    {"value" : "SUM", "label" : "SUM"},
]

const AnalyticsChartParameters = () => {
    const {
        chartTypeSelected,
        metricsSelected,
        setMetricsSelected,
        analyticsColumnList,
        setAnalyticsColumnList,
        analyticsColumnLoading,
        analyticsTableSelected,
        getTableColumns,
        setReadyToGraph,
        groupsSelected,
        setGroupsSelected,
        groupVisible,
        setGroupVisible,
        groupsVisible,
        setGroupsVisible,
        metricsVisible,
        setMetricsVisible,
        visible,
        setVisible,
        dummyRefreshState,
        setDummyRefreshState,
    } = useContext(AnalyticsContext)

    
    const [columnSelected, setColumnSelected] = useState()
    const [groupColumnSelected, setGroupColumnSelected] = useState()
    const [aggregateSelected, setAggregateSelected] = useState()
    

    useEffect(() => {
        // TODO reduce pie chart to one metric
        setAnalyticsColumnList([])
        if (analyticsTableSelected && analyticsTableSelected.value) {
            getTableColumns(analyticsTableSelected.value)
        }
    }, [analyticsTableSelected])

    const handleGroupVisibleChange = (visible, i = null) => {
        if (i === null) {
            if (visible) {
                setGroupColumnSelected(null)
            }
            setGroupVisible(visible)
        } else {
            if (visible) {
                setGroupColumnSelected(groupsSelected[i])
            }
            setGroupsVisible((prevState) => {
                prevState[i] = visible
                return prevState
            }
            )
        }
        setDummyRefreshState(!dummyRefreshState)
        
    }

    const handleVisibleChange = (visible, i = null) => {
        if (i === null) {
            if (visible) {
                setColumnSelected(null)
                setAggregateSelected(null)
            }
            setVisible(visible)
        } else {
            if (visible) {
                setColumnSelected(metricsSelected[i].column)
                setAggregateSelected(metricsSelected[i].aggregate)
            }
            var data = metricsVisible
            data[i] = visible
            setMetricsVisible(data)
        }
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleVisibleChangeWithIndex = (i) => (visible) => {
        handleVisibleChange(visible, i)

    }

    const handleGroupVisibleChangeWithIndex = (i) => (visible) => {
        handleGroupVisibleChange(visible, i)
    }

    const handleGroupClose = (i = null) => {
        if (i === null) {
            setGroupVisible(false)
        } else {

            setGroupsVisible((prevState) => {
                prevState[i] = false
                return prevState
            })
        }
        setDummyRefreshState(!dummyRefreshState)
    }

    
    const handleClose = (i = null) => {
        if (i === null) {
            setVisible(false)
        } else {
            var data = metricsVisible
            data[i] = false
            setMetricsVisible(data)
        }
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleGroupSave = (i = null) => {
        if (i === null) {
            setGroupsVisible([...groupsVisible, false])
            setGroupsSelected([...groupsSelected, groupColumnSelected])
        } else {
            setGroupsSelected((prevState) => {
                prevState[i] = groupColumnSelected
                return prevState
            })
            setDummyRefreshState(!dummyRefreshState)
        }
        handleGroupClose(i)
    }

    const handleSave = (i = null) => {
        var data = metricsSelected
        if (i === null) {
            data.push({'column': columnSelected, 'aggregate': aggregateSelected})
        } else {
            data[i] = {'column': columnSelected, 'aggregate': aggregateSelected}
        }
        setMetricsSelected(data)
        handleClose(i)
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleGroupDelete = (i) => {
        var data = groupsSelected
        data.splice(i, 1)
        setGroupsSelected(data)
        setDummyRefreshState(!dummyRefreshState)
    }

    const handleDelete = (i) => {
        var data = metricsSelected
        data.splice(i, 1)
        setMetricsSelected(data)
        setDummyRefreshState(!dummyRefreshState)
    }

    const group = (i = null) => {
        return(
            <div style={{ width: '300px' }} className='text-[13px]'>
                <div style={{ 'marginBottom': '10px' }}>
                    <AnalyticsDropdown
                        selected={groupColumnSelected}
                        dropdownList={analyticsColumnList}
                        setSelected={setGroupColumnSelected}
                        loading={analyticsColumnLoading}
                        disabled={false}
                        clearable={true}
                        placeholder='Column...'
                    />
                </div>
                <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={groupColumnSelected ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!groupColumnSelected} onClick={() => handleGroupSave(i)} block>
                        Save
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleGroupClose(i)} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }

    const metric = (i = null) => {
        return(
            <div style={{ width: '300px' }} className='text-[13px]'>
                <div style={{ 'marginBottom': '10px' }}>
                    <AnalyticsDropdown
                        selected={aggregateSelected}
                        dropdownList={AggregateTypes}
                        setSelected={setAggregateSelected}
                        loading={analyticsColumnLoading}
                        disabled={false}
                        clearable={true}
                        placeholder='Aggregate...'
                    />
                </div>
                <div style={{ 'marginBottom': '10px' }}>
                    <AnalyticsDropdown
                        selected={columnSelected}
                        dropdownList={analyticsColumnList}
                        setSelected={setColumnSelected}
                        loading={analyticsColumnLoading}
                        disabled={false}
                        clearable={true}
                        placeholder='Column...'
                    />
                </div>
                <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '10px',  height: '100%', width: '100%'}}>
                    <button className={columnSelected && aggregateSelected ? 'bg-[#5e7452] w-full text-white py-[5px] border-[#4b613d] border-[1px] rounded-[4px] hover:bg-[#4b613d]' : 'bg-gray-100 w-full py-[5px] border-gray-300 border-[1px] rounded-[4px] hover:cursor-not-allowed'} disabled={!columnSelected || !aggregateSelected} onClick={() => handleSave(i)} block>
                        Save
                    </button>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100' onClick={() => handleClose(i)} block>
                        Close
                    </button>
                </div>
            </div>
        )
    }

    if (chartTypeSelected && chartTypeSelected.value) {
        return (
            <div className='text-[13px]'>
                <div style={{ paddingTop: 5 }}>Dimensions</div>
                { groupsSelected && groupsSelected.map((selected, i) => 
                        <div className='text-[13px]'>
                            <Popover placement="right" content={group(i)} trigger="click" visible={groupsVisible[i]} onVisibleChange={handleGroupVisibleChangeWithIndex(i)}>
                            <button className='rounded-[4px] w-full py-[5px] bg-transparent px-[15px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                                    <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'left', 'gap': '5px',  height: '100%', width: '100%'}}>
                                        <div className='text-[13px]'>{selected.value}</div>
                                        <div className='w-full'>
                                            <Tooltip title='Delete'>
                                                <button className='relative top-[-3px] float-right hover:text-red-400' onClick={() => handleGroupDelete(i)}>
                                                    <DeleteOutlined/>
                                                </button>
                                            </Tooltip>
                                        </div>    
                                    </div>
                                </button>
                            </Popover>
                        </div>
                    
                )}
                <Popover placement="right" content={group()} trigger="click" visible={groupVisible} onVisibleChange={handleGroupVisibleChange}>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                        <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                            <div>Add Dimension</div>
                        </div>
                    </button>
                </Popover>
                <div style={{paddingTop: 10, paddingBottom : 5}}>Metrics</div>
                { metricsSelected && metricsSelected.map((selected, i) => {
                    return(
                        <div>
                            <Popover placement="left" content={metric(i)} trigger="click" visible={metricsVisible[i]} onVisibleChange={handleVisibleChangeWithIndex(i)}>
                            <button className='rounded-[4px] w-full py-[5px] bg-transparent px-[15px] border-gray-300 border-[1px] border-dashed hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                                    <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'left', 'gap': '5px',  height: '100%', width: '100%'}}>
                                        <div>{selected['aggregate'].value + "(" + selected['column'].value + ")"}</div>
                                        <div className='w-full'>
                                            <Tooltip title='Delete'>
                                                <button className='relative top-[-3px] float-right hover:text-red-400' onClick={() => handleDelete(i)}>
                                                    <DeleteOutlined/>
                                                </button>
                                            </Tooltip>
                                        </div>    
                                    </div>
                                </button>
                            </Popover>
                        </div>
                    )
                })}
                <Popover placement="left" content={metric()} trigger="click" visible={visible} onVisibleChange={handleVisibleChange}>
                    <button className='rounded-[4px] w-full py-[5px] bg-white border-gray-300 border-[1px] hover:bg-gray-100'  style={{ 'marginTop' : '5px'  }} block>
                        <div style={{ 'display': 'flex', 'align-items': 'center', 'justify-content': 'center', 'gap': '5px',  height: '100%', width: '100%'}}>
                            <div>Add Metric</div>
                        </div>
                    </button>
                </Popover>
                <div style={{paddingTop: 5}}>Filters</div>
                <AnalyticsChartFilters />
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
};

export default AnalyticsChartParameters;