import { useEffect, useState } from 'react';
import Dropdown from './Dropdown';

const functions = [
    {"value" : "DATE_TRUNC", "label" : "DATE_TRUNK( )"},
    {"value" : "DATE_PART", "label" : "DATE_PART( )"},
    {"value" : "SUM", "label" : "Sum( )"},
    {"value" : "COUNT", "label" : "Count( )"},
    {"value" : "AVG", "label" : "Avg( )"},
    {"value" : "MAX", "label" : "Max( )"},
    {"value" : "MIN", "label" : "Min( )"},
]

const timeframeOptions = [
    { "value": "year", "label": "year" },
    { "value": "quarter", "label": "quarter" },
    { "value": "month", "label": "month" },
    { "value": "week", "label": "week" },
    { "value": "day", "label": "day" },

]

const Func = ({ funcSelected, setFuncSelected, dateFuncArg, setDateFuncArg, currentFunction, currentDateFuncArg }) => {
    const DATEFUNC_FUNCTIONS = ['DATE_PART', 'DATE_TRUNC']

    const [localCurrentFunction, setLocalCurrentFunction] = useState(currentFunction)

    const handleFunctionChange = (e) => {
        const val = e && e.value ? e.value : null
        setFuncSelected(val)
        setLocalCurrentFunction()
        if (!DATEFUNC_FUNCTIONS.includes(val)) {
            setDateFuncArg(null)
        }
    }

    const handleDateFuncArgChange = (e) => {
        const val = e && e.value ? e.value : null
        setDateFuncArg(val)
    }

    const ArgumentBehaviour = () => {
        if (DATEFUNC_FUNCTIONS.includes(funcSelected)) {
            return <DateFunc dateFuncArg={dateFuncArg} handleDateFuncArgChange={handleDateFuncArgChange} currentDateFuncArg={currentDateFuncArg} />
        }
    }


    const filter = (i = null) => {
        return(
            <div style={{ width: '300px' }}>
                <div style={{ 'marginBottom': '10px' }}>
                    <Dropdown
                        selected={funcSelected ? {value: funcSelected, label: `${funcSelected}( )`} : localCurrentFunction ? {value: localCurrentFunction, label: `${localCurrentFunction}( )`} : null}
                        dropdownList={functions}
                        setSelected={(e) => handleFunctionChange(e)}
                        loading={false}
                        disabled={false}
                        clearable={true}
                        placeholder='Select function...'
                    />
                </div>
                {ArgumentBehaviour()}
            </div>
        )
    }

    return(
        <div>
            {filter()}
        </div>
    )
};

export default Func;



const DateFunc = ({ dateFuncArg, handleDateFuncArgChange, currentDateFuncArg }) => {
    return (
        <div>
            <div style={{ 'marginBottom': '10px' }}>
                <Dropdown
                    selected={ dateFuncArg ? {value: dateFuncArg, label: dateFuncArg } : currentDateFuncArg ? {value: currentDateFuncArg, label: currentDateFuncArg} : timeframeOptions[0]}
                    dropdownList={timeframeOptions}
                    setSelected={(e) => handleDateFuncArgChange(e)}
                    loading={false}
                    disabled={false}
                    clearable={false}
                    placeholder='Select timeframe...'
                />
            </div>
        </div>
    )
}
