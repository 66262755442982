import ArtemisGreen from "../../components/NavBar/logo_png/Artemis_Green.png"
import './styles.scss'
const InvalidUser = () => {
  return (
    <div style={{position: "fixed", height: "100%", width: "100%", backgroundColor: "#fcfcff", display: "block", textAlign: "center"}}>
          <img className="artemis-invalid" src={ArtemisGreen} alt="Artemis" />
          <br />
          <br/>
      Maximum number of users reached for subscription type. Contact organization owner to upgrade subscription.
    </div>
  )
}

export default InvalidUser;

