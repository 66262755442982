import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({active, payload, label, yAxisTitle}) => {
  const tooltipStyle = {
    border: "1px solid #CCCC",
    backgroundColor: "white",
    padding: "8px",
  };

  if (active && payload && payload.length) {
    const formattedValue = payload[0].value.toLocaleString(); 
    return (
      <div className="custom-tooltip"
        style={tooltipStyle}
        >
        <p className="label">{`${label}`}</p>
        <p className="value" style = {{fontSize: "13px", color: "#1f422a"}}>{`${yAxisTitle}: ${formattedValue}`}</p>
      </div>
    );
  }

  return null;
};

export const BarGraph = ({chart_data, rows}) => {
    console.log(chart_data)
    console.log(rows)

    return (
      <ResponsiveContainer width="100%" height="85%">
            
              <BarChart
                width={500}
                height={300}
                data={rows}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={chart_data.x_axis} />
                <YAxis name={chart_data.y_axis} />
                <Tooltip content={<CustomTooltip yAxisTitle={chart_data.y_axis}/>}/>
                <Legend />
                <Bar dataKey={chart_data.y_axis} fill="#1f422a" />
              </BarChart>
  
      </ResponsiveContainer>
      
      
      )


}
