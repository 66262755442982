import { createContext, useState } from 'react';

const MOCK_STORAGE = [
  { name: 'Finance', value: 14 },
  { name: 'Advertising', value: 12 },
  { name: 'Sales', value: 11 },
  { name: 'R&D', value: 6 },
  { name: 'Other', value: 7 },
  { name: 'Remaining', color: '#c6c4d0', value: 12 },
];

export const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
  const [storage, setStorage] = useState(MOCK_STORAGE);

  return (
    <StorageContext.Provider
      value={{
        storage,
        setStorage,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};
