import React, { useState, useEffect, useContext } from 'react';
import { ModelEditorContext } from '../../context/ModelEditorProvider';
import Sidebar, {SidebarRight} from '../../components/Sidebar';
import Page from '../../components/Page';
import CodeMirror from '@uiw/react-codemirror';
import CodeMirrorMerge from 'react-codemirror-merge';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { StandardSQL  } from '@codemirror/lang-sql';
import { EditorView } from "@codemirror/view";
import './style.scss'
import '../TablePage/TabList.css'
import { langs } from '@uiw/codemirror-extensions-langs';
import ContextMenu from './ContextMenu';
import { InlineSuggestion } from '../../components/EditorAutofil/extension';
import { searchKeymap } from "@codemirror/search";
import ReactTerminal from './Terminal';
import ModelEditorSidebar from './ModelEditorSidebar';
import ModelEditorCopilot from './ModelEditorCopilot';
import ModelEditorToolbar from './ModelEditorToolbar';
import DiffToolbar from './DiffToolbar';
import { IoClose } from "react-icons/io5";
import {Tooltip} from 'antd';
import ConnectGit from '../../components/ConnectionPages/ConnectGit';
import PageSetup from '../../components/Onboarding/PageSetup';


searchKeymap.splice(2,1)
  
function MyEditor() {
  const Original = CodeMirrorMerge.Original;
  const Modified = CodeMirrorMerge.Modified;
  const [dags, setDags] = useState({});
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openRightCopilotSidebar, setOpenRightCopilotSidebar] = useState(false);

  const {
    openedFiles,
    setOpenedFiles,
    activeTab,
    setActiveTab,
    fileTree,
    setFileTree,
    modelList,
    saveModelEdits,
    sourceList,
    generateSuggestion,
    setSuggestionText,
    diffView,
    git,
    getFileTree,
    loadingSuggestion,
  } = useContext(ModelEditorContext);

  useEffect(() => {
    if (Object.keys(fileTree).length === 0) {
      getFileTree();
    }
  }, []);

  const fetchSuggestion = async (state = null) => {
    const code = await generateSuggestion(openedFiles[activeTab])
    return code;
  }

  //tabs management
  function myCompletions(language) {
    let type = language.name.split('.')
    type = type[type.length - 1]
    switch (type) {
      case ('sql'):
        return [
          StandardSQL.language.data.of({
            autocomplete: function myCompletions(context) {
              const word = context.matchBefore(/(\w|\.){3,}$/);
              if (!word || (word.from === word.to && !context.explicit)) return null;
              const options = []
              for (let name of modelList) {
                options.push({ label: name, type: 'keyword', detail: 'model name' })
                options.push({ label: name, apply: `{{ ref('${name}') }}`, detail: 'reference'})
              }
              for (let source of sourceList) {
                options.push({ label: `${source[0]}.${source[1]}`, apply: `{{ source('${source[0]}', '${source[1]}') }}`, detail: 'source'})
              }
              return {
                from: word.from,
                options: [...options,
                  {
                    label: 'source',
                    apply: '{{ source( schema_name, table_name ) }}',
                    detail: 'macro'
                  }
                ]
              };
            },
          }),
          langs.sql({upperCaseKeywords: true}),
          EditorView.lineWrapping,
          EditorView.theme({
            "&": {
                border: "none"
            },
            ".cm-activeLineGutter": {
              color: 'black',
              fontWeight: '500',
              backgroundColor: 'transparent',
            },
            ".cm-line.cm-activeLine": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: '#E5E7EB',
              backgroundColor: 'transparent'
            },
            ".cm-line": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: 'white',
            },
            "&.cm-editor.cm-focused": {
                outline: "none"
            },
            ".cm-gutters": {
              backgroundColor: "white",
              borderRightWidth: '0px',
              borderLeftColor: '#D1D5DB',
            },
          }),
          InlineSuggestion({
            fetchFn: fetchSuggestion,
            delay: 1000,
          }),
        ];
      case 'yml':
        return [
          langs.yaml(),
          EditorView.lineWrapping,
          EditorView.theme({
            "&": {
                border: "none"
            },
            ".cm-activeLineGutter": {
              color: 'black',
              fontWeight: '500',
              backgroundColor: 'transparent',
            },
            ".cm-line.cm-activeLine": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: '#E5E7EB',
              backgroundColor: 'transparent'
            },
            ".cm-line": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: 'white',
            },
            "&.cm-editor.cm-focused": {
                outline: "none"
            },
            ".cm-gutters": {
              backgroundColor: "white",
              borderRightWidth: '0px',
              borderLeftColor: '#D1D5DB',
            }
          }),
        ]
      case 'diff':
        return [
          langs.diff(),
          EditorView.lineWrapping,
          EditorView.theme({
            "&": {
                border: "none"
            },
            ".cm-activeLineGutter": {
              color: 'black',
              fontWeight: '500',
              backgroundColor: 'transparent',
            },
            ".cm-line.cm-activeLine": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: '#E5E7EB',
              backgroundColor: 'transparent'
            },
            ".cm-line": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: 'white',
            },
            "&.cm-editor.cm-focused": {
                outline: "none"
            },
            ".cm-gutters": {
              backgroundColor: "white",
              borderRightWidth: '0px',
              borderLeftColor: '#D1D5DB',
            }
          }),
        ]
      case 'json':
        return [
          langs.json(),
          EditorView.lineWrapping,
          EditorView.theme({
            "&": {
                border: "none"
            },
            ".cm-activeLineGutter": {
              color: 'black',
              fontWeight: '500',
              backgroundColor: 'transparent',
            },
            ".cm-line.cm-activeLine": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: '#E5E7EB',
              backgroundColor: 'transparent'
            },
            ".cm-line": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: 'white',
            },
            "&.cm-editor.cm-focused": {
                outline: "none"
            },
            ".cm-gutters": {
              backgroundColor: "white",
              borderRightWidth: '0px',
              borderLeftColor: '#D1D5DB',
            }
          }),
        ]
      default:
        return [
          langs.markdown(),
          EditorView.lineWrapping,
          EditorView.theme({
            "&": {
                border: "none"
            },
            ".cm-activeLineGutter": {
              color: 'black',
              fontWeight: '500',
              backgroundColor: 'transparent',
            },
            ".cm-line.cm-activeLine": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: '#E5E7EB',
              backgroundColor: 'transparent'
            },
            ".cm-line": {
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              borderColor: 'white',
            },
            "&.cm-editor.cm-focused": {
                outline: "none"
            },
            ".cm-gutters": {
              backgroundColor: "white",
              borderRightWidth: '0px',
              borderLeftColor: '#D1D5DB',
            }
          }),
        ]
    }
  }

  const handleFileContentChange = (fileId, newContent) => {
    setOpenedFiles((prevState) => prevState.map(file => {
      if (file.id === fileId) {
        if (newContent != file.content) {
          return { ...file, edits: newContent }
        }
        return {id: file.id, name: file.name, content: file.content}
      }
      return file
    }
    ));
  };

  const diffHandleContentChange = (fileId, newContent) => {
    setOpenedFiles((prevState) => prevState.map(file => 
      file.id === fileId ?
        { ...file, diff: newContent }
        :
        file
    ));
  };

  
  const removeTab = async (e, num) => {
    e.stopPropagation()

    if (activeTab >= num && openedFiles.length > 1 && activeTab > 0) {
      await setActiveTab(activeTab - 1)
    }
    await setOpenedFiles(prevFiles => prevFiles.filter((file, index) => index !== num))

    if (activeTab < num) {
      await setActiveTab(activeTab)
    }  
  }

  const handleClick = () => {
    setSuggestionText()
  }

  const handleKeyPress = (event, item) => {
    if (event.key === 's' && event.metaKey) {
      event.preventDefault()
      event.stopPropagation()
      if (item.edits) {
        saveModelEdits(item)
      }
      return
    }
  }
  if (git.noGitConnection) {
    //git is not initialized, so we show connect git page to user instead of model editor page
    return (
      <Page>
        <PageSetup gitRequired={true} type={'Models'}/>
      </Page>
    )
}

  return (
    <Page>
      <ContextMenu/>
      <Sidebar setSidebarData = {()=>{}} sidebarContent={
          <ModelEditorSidebar fileTree={fileTree} setFileTree={setFileTree} /> 
            } openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} hideButtonWhenClosed={false} removePadding={true}>
              <div className='h-full p-0'>
          {openedFiles.length > 0 && (
            <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)} className='h-full'>
              <PanelGroup direction="vertical" className=''>
                <Panel className=' flex flex-col relative' defaultSize={75} order={1} id={1}>
                <TabList className=' flex overflow-x-auto w-full cursor-pointer overflow-y-hidden border-b-[2px] mb-[10px]' style={{userSelect: 'none', scrollbarWidth: 'thin'}}>
            {openedFiles.map((file, index) => 
              <Tab 
                key={index}
                className={activeTab === index ? 'selected-tab-styling' : 'tab-styling'}>
                <Tooltip title = {file.name} placement='bottom' mouseEnterDelay='1.5' align={{ offset:[0, -12]}}>
                  <div className='tab-outer-wrapper'>
                    <div className='tab-inner-wrapper'>
                      {file.name}
                      { index !== 0 && 
                        ('edits' in file ?
                          <div className={activeTab === index ? 'flex font-[1000] bg-black w-[6px] h-[6px] rounded-full ml-[5px] text-[28px] ' : 'flex bg-gray-600 ml-[5px] w-[6px] h-[6px] rounded-full'}></div>
                          :
                          <div className="w-[6px] h-[6px] ml-[5px]"></div>
                        )
                      }
                      
                    </div>
                    {index !== 0 &&
                      <div className="delete-button-wrapper">
                        <button onClick={(e) => { removeTab(e, index) }}>
                          <div>
                            <IoClose className="delete-button" />
                          </div>
                        </button>
                      </div>
                    }
                  </div>
                </Tooltip>
                </Tab>
            )}
        </TabList>
        {openedFiles.map((file, index) => 

          <TabPanel key={index} className={index === activeTab ? 'overflow-y-auto tab-panel-height' : ''}>
            
            { diffView.includes(file.id) ?
              <div className='flex flex-col h-full'>
                <div className='grow overflow-auto'>
                  <div className='h-full'>
                  <CodeMirrorMerge
                    orientation="a-b"
                    className='break-all max-w-full h-full max-h-full overflow-auto'
                    height="100%"
                    collapseUnchanged={true}
                    highlightChanges={false}
                    >
                    <Original
                      key='123'
                      width="100%"
                      className='break-all h-full max-h-full mr-[10px] overflow-auto'
                      height='100%'
                      extensions={myCompletions(file)}
                      value={file.base}
                      readOnly={true}
                      collapseUnchanged={true}
                      />
                    <Modified
                      key='1234'
                      width="100%"
                      className='break-all h-full max-h-full overflow-auto'
                      height='100%'
                      onChange={(e) => {
                        diffHandleContentChange(file.id, e);
                      }}
                      readOnly={true}
                      autoFocus={true}
                      value={file.diff}
                      extensions={myCompletions(file)}
                      collapseUnchanged={true}
                    />
                    </CodeMirrorMerge>
                    </div>
                </div>
                <DiffToolbar file={file} fetchSuggestion={fetchSuggestion} />
              </div>
              
              :
              <div className='h-full' onBlur={e => setSuggestionText()}>
         <SidebarRight setSidebarData = {()=>{}} sidebarContent={
            <ModelEditorCopilot/>
            } openSidebar={openRightCopilotSidebar} setOpenSidebar={setOpenRightCopilotSidebar} hideButtonWhenClosed={true} removePadding={true}>

                <div className='h-full ' id={'test_id'}>
                  <CodeMirror
                    // ref={inputRef}
                    key='codemirrorbox'
                    width="100%"
                    className='break-all max-w-full h-full max-h-full overflow-auto'
                    height='100%'
                    value={'edits' in file ? file.edits : file.content}
                    onChange={(e) => {
                      handleFileContentChange(file.id, e);
                    }}
                    // extensions={[sql(), EditorView.lineWrapping]}
                    extensions={myCompletions(file)}
                    onKeyDown={(event) => handleKeyPress(event, file)}
                    autoFocus={true}
                    onClick={handleClick}
                    readOnly={index === 0 || loadingSuggestion}
                                     
                  />
                </div>
                <div className='absolute bottom-0 right-0'>
                <ModelEditorToolbar file={file} fetchSuggestion={fetchSuggestion} setOpenRightCopilotSidebar={setOpenRightCopilotSidebar}/>
                </div>
                </SidebarRight>
              </div>
              
            }
          </TabPanel>
        )}
        </Panel>
        <PanelResizeHandle
            className='flex-[0_0_3px] bg-gray-200 focus:bg-gray-400 hover:bg-gray-400 transition-[2000ms] ease-in-out'
            id={0}
        />
        <Panel className='' defaultSize={25} maxSize={50} minSize={10} collapsible={false} order={2}>
        <div className='overflow-auto h-full w-full '>
          
          <ReactTerminal/>
          
        </div>
        </Panel>
        </PanelGroup>
    </Tabs>
)}


      </div>
    </Sidebar>
  </Page> 
  );
}

export default MyEditor;


