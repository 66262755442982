import React, { useState, useEffect, useContext } from 'react';
import { OnboardContext } from '../../context/OnboardProvider';


const GitSetup = () => {
    const {
        setWarehouseType,
        setRequiredFields,
        nextPage,
        setCurrentPage,
        previousPage,
        currentPage,
        setConnectGit,
        connectGit,
        saveAndTest,
        urlValue,
        setUrlValue,
        patValue,
        setPatValue,
        testingRepo,
        setTestingRepo,
        connectGitError,
    } = useContext(OnboardContext);


    
    // const saveAndTest = () => {
        // CloneRepo()
    
    // }

    if (!connectGit) {
        return (
            <div className="flex flex-col justify-center">
                <div className="w-full text-4xl text-center mb-[10px]">Would you like to connect to Github?</div>
                <div className="w-full text-md text-gray-500 text-center mb-[50px]">Connecting a dbt project repository is required to use dbt features in Artemis. If you skip for now, a repository can be connected later.</div>
                <div className='flex w-full justify-center'>
                    <button onClick={() => setConnectGit(true)} className='mt-[20px] px-4 py-3 text-[20px] rounded-lg border-gray-300 border-[1px] hover:bg-gray-200 hover:border-gray-400 mr-[30px]'> Connect to Github </button> 
                    <button onClick={() => setCurrentPage(currentPage + 1)} className='mt-[20px] px-4 py-3 text-[20px] rounded-lg border-gray-300 border-[1px] hover:bg-gray-200 hover:border-gray-400'> Skip for now </button> 
                </div>
            </div>
        )
    }
    

    return (
        (
            testingRepo ?
            <div className='w-full h-full bg-white'>
                <div className='pt-[25vh] text-center w-full'>
                    Attempting to clone repository...
                </div>
            </div>
            :
            <div className='w-full  h-full flex'>
                <div className='w-1/2 h-full bg-white pt-[10vh] relative'>
                    <svg onClick={() => setConnectGit(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-10 mb-[8vh] w-9 h-9 cursor-pointer p-[6px] hover:bg-gray-100 rounded-lg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>

                  <div className='mx-10'>
                    { connectGitError && 
                      <div className='text-[16px] font-[500] px-[5vw] text-red-400'>
                        Unable to clone repository, responded with error: {connectGitError}
                      </div>
                    }
                  
                    <h2>
                      Repository URL
                    </h2>
                    <input value={urlValue} onChange={(e) => setUrlValue(e.target.value)} placeholder='https://github...' className='border-[1px] rounded-lg border-gray-200 p-2 w-full mt-[10px]'/>
  
                    <h2 className='mt-[30px]'>
                      Personal Access Token
                    </h2>
                    <input value={patValue} onChange={(e) => setPatValue(e.target.value)} placeholder='token...' className='border-[1px] rounded-lg border-gray-200 p-2 w-full mt-[10px]' />
                  </div>
                  <br></br>
                  <div className='w-full flex justify-center absolute bottom-0 pb-[8vh]'>
                    <button className='cursor-pointer px-4 py-3 border-gray-200 border-[1px] rounded-lg hover:bg-gray-100 hover:border-gray-300' disabled={!patValue || !urlValue} onClick={() => saveAndTest()}>
                      Save & Test
                    </button>
                  </div>  
                </div>
                <div className='w-1/2 pt-[50px] px-10 h-[100%]'>
                  <h1 className='mb-[15px]'> How to set up your Github Repository</h1>
                  <h5>
                    <h3>Retrieving repository url</h3>
                    <div> 1. On <a className="text-blue-400 hover:text-blue-700" href="https://github.com/settings/profile" target="_blank"> Github</a>, navigate to the repository of your dbt project. </div>
                    <div className=''> 2. Click the <span className='bg-gray-300 rounded-md px-[5px]'>{' <> Code'}</span> button in the top right corner. Copy the HTTPS url and paste it into the repository url input.</div>
                    <h3 className='mt-[15px]'>Generating Personal Access Token</h3>
                    1. Navigate to <a className="text-blue-400 hover:text-blue-700" href="https://github.com/settings/profile" target="_blank"> Github </a> and open your profile settings. This can be done on any page by clicking on your profile icon in the top right corner, then clicking Settings
                    <br/>
                    2. Click {'<>'} Developer Settings from the bottom of the sidebar, then open Fine-grained tokens in the Personal access tokens section
                    <br />
                    4. Generate a new token. Input name and set expiry date <div className='text-[10px]'> Note: New token will have to be generated and provided after expiry date to continue service.</div> 
                    <br />
                    <div className='pl-[15px]'>
                      For repository access, allow 'Only select repositories' and select the repo which your dbt project is tied to.
                    </div>
                    <div className='pl-[15px]'>
                      For permissions, allow the token to read and write to repository contents. To do this, open the 'Repository permissions' section and change the access to the Contents option to 'Read and Write'
                    </div>
                    <br />
                    5. 
                    <br/>
                  </h5>
                </div>
            </div>
            )
    )
}

export default GitSetup