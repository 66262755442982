import React, { useContext } from 'react';
import { EditorContext } from '../../../../context/EditorProvider';
import { TableManagementContext } from '../../../../context/TableManagementProvider';
import { DatabaseOutlined } from '@ant-design/icons';
import FileContentListItem from '../FileContentListItem';
import { ModelEditorContext } from '../../../../context/ModelEditorProvider';


const SearchTree = ({ data, setFileTree, setFilteredTree, first, mode, searchValue}) => {
    const {
        onItemClicked,
        updateDbtFileName,
        newName,
        setNewName,
        initialName,
        isEditing,
        setIsEditing,
        fileStructure,
        dbtActiveTabId,
        dbtTabs
    } = useContext(ModelEditorContext);
    
    const {
        setMenuPos,
        menuItem,
        setMenuItem,
        setContextMenuType,
        openDbtFile,
        openSqlQuery,
    } = useContext(EditorContext)

    const onContextMenu = (event, item) => {
        event.stopPropagation();
        event.preventDefault();
        setMenuItem(item)
        setMenuPos([event.clientX, event.clientY])
        setContextMenuType(mode)
    };

    const handleSave = async (path) => {
        if (isEditing !== null) {
          // Call your API to update item's name
            if (newName != initialName) {
                await updateDbtFileName(path, newName)
            }
            setIsEditing(null)
        }
    };
    
    const handleCancel = () => {
        setNewName(initialName);
        setIsEditing(null);
    };
    
    const handleKeyDown = async (e, path) => {
        if (e.key === "Enter") {
            handleSave(path)
        }
        if (e.key === "Escape") {
            handleCancel()
        }
    };
    
    const handleClick = async (data, e) => {
        e.stopPropagation();
    
        if (mode === 0 && !data.children) {
          //dbt mode
            openDbtFile(data);
        }
        else if (mode === 2) {
          //sql mode
            openSqlQuery(data)
        }
        else {
          //database mode
            onItemClicked(data)
        }    
    }

    if (data) {
        if (data.children)
          return (
            <ul
            className={!first ? "text-gray-600 select-none cursor-pointer border-l-[1px] border-l-gray-300  ml-3 pl-1 pt-0  mb-0 mt-0 pb-0 text-[15px]": "text-gray-600 py-2 pt-0 mb-0 mt-0 pb-0 text-[15px]"}
            >
            <li
              key={data.name}
              onContextMenu={(e) => onContextMenu(e, data)}
              onClick={(e) => handleClick(data, e)}
              className='select-none cursor-pointer'
            >
                <div className={`flex w-full hover:bg-gray-200 rounded-md pl-[5px] ${menuItem && menuItem.path === data.path ? 'bg-gray-200' : ''}`} >
                  {fileStructure[data.path] ?
                    <div className='flex items-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                      </svg>
    
                      <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                        <path d="M32,208V64a8,8,0,0,1,8-8H93.3a8.1,8.1,0,0,1,4.8,1.6l27.8,20.8a8.1,8.1,0,0,0,4.8,1.6H200a8,8,0,0,1,8,8v24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                        <path d="M32,208l30-75a7.9,7.9,0,0,1,7.4-5h48.2a8.4,8.4,0,0,0,4.4-1.3l20-13.4a8.4,8.4,0,0,1,4.4-1.3h82.5a8,8,0,0,1,7.6,10.5L208,208Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                      </svg>
                    </div>
                    
                    :
                    <div className='flex items-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                      </svg>
    
                      <svg height="20" viewBox="0 0 21 21" width="20" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 4)">
                          <path d="m.5 1.5v9c0 1.1045695.8954305 2 2 2h10c1.1045695 0 2-.8954305 2-2v-6.00280762c.0007656-1.05436179-.8150774-1.91816512-1.8499357-1.99451426l-.1500643-.00468356-5 .00200544-2-2h-4c-.55228475 0-1 .44771525-1 1z" />
                          <path d="m.5 2.5h7" />
                        </g>
                      </svg>
                      </div>
                  
                  }
                <div className='grow'>
                  {
                    isEditing === data.path ?
                    <div className="w-full" onClick={(e) => e.stopPropagation()} onBlur={() => handleCancel()}
                    >
                      <div className='flex items-center box-border box-content'>
                        
                        <input
                          className=" px-1 input-width grow"
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => handleKeyDown(e, data.path)}
                          autoFocus
                        />
                      </div>
                    </div>
                    :
                    <div className='w-full break-all'>
                        <FileContentListItem
                            originalString={data.name}
                            substringToHighlight={searchValue}
                        />
                    </div>
                  }
                  
                </div>
              </div>
                {fileStructure[data.path] &&
                  data.children.map((item) => 
                      <SearchTree data={item} setFileTree={setFileTree} setFilteredTree={setFilteredTree} first={false} mode={mode} searchValue={searchValue}/>
                  )
                
              }
            
            </li>
            </ul>
          );
                    
        return (
          <ul
          className={!first ? "text-gray-600 select-none cursor-pointer border-l-[1px] border-l-gray-300  ml-3 pl-1 pt-0  mb-0 mt-0 pb-0 text-[15px]": " text-gray-600 py-2 pt-0 mb-0 mt-0 pb-0 text-[15px] "}
          >
            <li
              key={data.name}
              onClick={(e) => handleClick(data, e)}
              onContextMenu={(e) => onContextMenu(e, data)}
              className={dbtActiveTabId && dbtTabs[dbtActiveTabId] && dbtTabs[dbtActiveTabId].path === data.path ? 'select-none cursor-pointer w-full flex bg-gray-300 rounded-md transition pl-[5px]' : `flex select-none cursor-pointer w-full hover:bg-gray-200 rounded-md transition pl-[5px] ${menuItem && menuItem.path === data.path ? 'bg-gray-200' : ''}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="relative top-[3px] mr-[5px] w-[16px] h-[16px] min-w-[16px] min-h-[16px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              {
                isEditing === data.path ?
                  <div className="w-full" onClick={(e) => e.stopPropagation()} onBlur={() => handleCancel()}
                  >
                    <div className='items-center box-border box-content'>
                        
                        <input
                          className=" px-1 input-width grow"
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => handleKeyDown(e, data.path)}
                          autoFocus
                        />
                      </div>
                  </div>
                  :
                  <div className='truncate '>
                    <FileContentListItem
                        originalString={data.name}
                        substringToHighlight={searchValue}
                    /> 
                  </div>
                  }
                
            </li>
            </ul>
        );      
    }
    return (
        <>
        </>
    )  


  
  
}

export default SearchTree
