import React, { useState, useEffect, useRef, useContext} from 'react';
import { ArtyChatContext } from '../../context/ArtyChatProvider';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../context/UserProvider';
import { TableGraphContext } from '../../context/TableGraphProvider';

const antIcon = (
    <div className="text-dot-loading-container">
        <div className="text-dot" />
    </div>
);

const WorkflowCopilot = () => {
    const { 
        copilotChat, 
        promptArtyCopilot, 
        fetchingChat,
    } = useContext(ArtyChatContext);

    const {
        dagId
    } = useContext(TableGraphContext)

    const { user } = useContext(UserContext) 

    const [isLoading, setIsLoading] = useState(false);
    const [inputMessage, setInputMessage] = useState('');

    useEffect(() => {
        scrollToBottom({ behavior: "smooth" })
    }, [copilotChat])
    
    useEffect(() => {
        scrollToBottom()
    }, [])

    const handeKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            // event.stopPropagation()
            handleSubmit(event)
        }
    }

    const adjustTextareaHeight = () => {
        const textarea = document.getElementById("textarea");
        textarea.style.height = "40px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        // handle user message submission here
        // and start loading stat
        setIsLoading(true);
        setInputMessage('');
        await promptArtyCopilot(dagId, inputMessage)
        adjustTextareaHeight()
        setIsLoading(false);
    }
    const messagesEndRef = useRef(null);

    const scrollToBottom = (behavior = {}) => {
        messagesEndRef.current?.scrollIntoView(behavior)
    } 

    const renderMessage = (message, index) => {
        if (!message || !message.response) {
            return (
                <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                    <div className='text-gray-500 mr-[10px] select-none'>
                        {user.first_name ? user.first_name : 'User'}:
                    </div>
                    <div className="text-left whitespace-pre-line">
                    </div>
                </div>
            );
        }
        switch (message.response_type) {
            case -1:
                return (
                    <div key={index + "error"} className="flex w-full text-[#ff6963] text-left bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='mr-[10px]'>
                            Error:
                        </div>
                        <div className="whitespace-pre-line">
                            {message.response}
                        </div>
                    </div>
                );

            case 0:
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {user.first_name ? user.first_name : 'User'}:
                        </div>
                        <div className="text-left whitespace-pre-line">

                            {message.response}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div key={index + "user"} className="flex w-full bg-[#F7F6F6] py-[15px] border-b-[1px] border-gray-300 px-[30px]">
                        <div className='text-gray-500 mr-[10px] select-none'>
                            {'Copilot'}:
                        </div>
                        <div className="text-left whitespace-pre-line">

                            {message.response}
                        </div>
                    </div>
                )
            default:
                return null;
        }
    }

    return (
        <div className='h-full w-full'>
        <div className="flex relative flex-col justify-between h-full bg-[#F7F7F7]">
            <div className="overflow-y-auto py-2 grow overflow-auto">
                    {copilotChat && copilotChat.data && copilotChat.data.length > 0 &&
                        <div className='max-h-[60%]'>
                            {copilotChat.data.map(renderMessage)}
                            <div ref={messagesEndRef} />
                        </div>}
            </div>
            <div className="border-t-2 border-gray-200 py-2 content-end">
                <Spin indicator={antIcon} spinning={isLoading}>
                    <form onSubmit={(e) => handleSubmit(e)} className="mt-1 flex w-full justify-content">
                        <div className="grow">
                            <textarea
                                id="textarea"
                                className="w-full h-[40px] max-h-[120px] outline-black rounded-lg p-2 resize-none"
                                value={inputMessage}
                                onKeyDown={handeKeyDown}
                                onChange={handleInputChange}
                                onInput={adjustTextareaHeight}
                                placeholder="Edit workflow to..."
                                disabled={isLoading}
                                autoFocus={true}
                            />
                        </div>
                        <input
                            className="bg-black h-[40px] ml-2 text-white p-2 rounded-lg cursor-pointer"
                            type="submit"
                            value="Send"
                            disabled={isLoading}
                        />
                    </form>
                </Spin>
            </div>
        </div>
        </div>
    )
}

export default WorkflowCopilot