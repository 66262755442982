import { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AddConnector from '../../components/Connectors/AddConnector/AddConnector';
import ConnectorCard from '../../components/Connectors/ConnectorCard/ConnectorCard';
import { ConnectorContext } from '../../context/ConnectorProvider';
import ConnectorModal from '../../components/Connectors/ConnectorsModal';
import WorkSpace from '../../components/Connectors/WorkSpace/WorkSpace';
import FivetranApi from '../../context/FivetranAPI';
import { Button, Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SubscriptionContext } from '../../context/SubscriptionProvider';
import Sidebar from '../../components/Sidebar';
import { useAuthInfo } from '@propelauth/react';
import Page from '../../components/Page';
import ConnectorDetailsDrawer from '../../components/Connectors/ConnectorDetailsDrawer/ConnectorDetailsDrawer';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import ConnectDatabase from '../../components/ConnectionPages/ConnectDatabase';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Space } from 'antd';
import { UserContext } from '../../context/UserProvider';
import PageSetup from '../../components/Onboarding/PageSetup';
import LetoClient from '../../context/GraphClient';


const Connectors = () => {
  const {
    connectors,
    connectorsMeta,
    getMetadata,
    connectorWorkspaces,
    currentWorkspace,
    workspaces,
    getConnectors,
  } = useContext(ConnectorContext);

  const {
    noWarehouse
  } = useContext(UserContext)

  const { pathname } = useLocation();

  const [connectorFilter, setConnectorFilter] = useState(connectors);

  const [retrievedConnectors, setRetrievedConnectors] = useState(false);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({}); 

  const authInfo = useAuthInfo();

  useEffect(() => {
    if (/\/data/.test(pathname)) {
      if (FivetranApi.getGroupId() !== '') {
        getConnectors();
        getMetadata();
      }
    }
  }, [pathname, FivetranApi.getGroupId()]);

  useEffect(() => {
    if (connectors.length) {
      handleFilter(currentWorkspace);
    }
  }, [connectors, currentWorkspace, workspaces]);

  const handleFilter = workspace => {
    if (workspace === 'All') {
      setConnectorFilter(connectors);
      return;
    }
    const connector_list = [];

    for (let k = 0; k < connectors.length; k++) {
      for (var key in connectorWorkspaces) {
        if (connectors[k].id === key) {
          for (let n = 0; n < connectorWorkspaces[key].length; n++) {
            if (connectorWorkspaces[key][n] === workspace) {
              connector_list.push(connectors[k]);
            }
          }
        }
      }
    }
    setConnectorFilter(connector_list);
    return;
  };

  const handleSearchFilter = value => {
    console.log(connectors)
    let connector_list = connectors.filter(
      (element) => {
        console.log(element.service.includes(value))  
        return element.service.includes(value) || element.schema.includes(value)
      }
    )
    console.log(connector_list)
    setConnectorFilter(connector_list)
  }

  const connectorDetailsSidebar = () => {
    
    console.log(sidebarData)
    if(!sidebarData.cardDetails || !sidebarData.connector){
      return (<div></div>) 

    }


    return (
    <div>
      <ConnectorDetailsDrawer cardDetails = {sidebarData.cardDetails}
        metaData={sidebarData.metaData}
        visible = {openSidebar}
        onClose={() => { setSidebarData({}); setOpenSidebar(false) }}
        connector = {sidebarData.connector}
        selectedWorkspaces = {sidebarData.selectedWorkspaces}
        setSelectedWorkspaces = {sidebarData.setSelectedWorkspaces}
        paused = {sidebarData.paused}/>
    </div>
    );
  }
  
  if (noWarehouse) {
    return (
      <Page>
        <PageSetup databaseRequired={true} type={'Data'} />
      </Page>
    )
  }

  return (
  <Page>
    <Sidebar setSidebarData = {setSidebarData} sidebarContent = {connectorDetailsSidebar()} openSidebar = {openSidebar} setOpenSidebar = {setOpenSidebar} hideButtonWhenClosed={true}>
    <div className="pb-10 mt-5">
        {/* <snippyly-comments /> */}
        <ConnectorModal />


        <div className = "relative mt-2 rounded-md ml-2 w-1/5"> 
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400  hover:bg-gray-100" aria-hidden="true" />
        </div>
            <input className="block w-full rounded-md border-1 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm outline-none mb-2"
              onChange={(e) => { handleSearchFilter(e.target.value) }}
              placeholder='Connector name'
            /> 


      </div> 

  {/* <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            <Dropdown overlay={menu}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Manage Connectors
                  <DownOutlined />
                </Space>
                </a>
            </Dropdown>
          </div>
        </div> */}

        <div
          className={openSidebar?"mb-[20px] overflow-auto flex flex-wrap transition-all duration-300 ease-in-out": "flex flex-wrap mb-[20px] transition-all duration-300  ease-in-out"}>
          <AddConnector />
          {connectorsMeta.length !== 0 &&
            connectorFilter.map((connector, i) => (
              <ConnectorCard
                setSidebarData = {setSidebarData}
                setOpenSidebar = {setOpenSidebar}
                key={i}
                connector={connector}
                name={connector.schema}
                metaData={connectorsMeta.filter(s => s.id === connector.service)[0]}
                status={connector.status.setup_state}
              />
            ))}
        </div>
      </div>
    </Sidebar>
    </Page>
  );
};

export default Connectors;
