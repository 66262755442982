import {useContext}  from 'react';
import ConnectGit from '../../components/ConnectionPages/ConnectGit';
import { ModelEditorContext } from '../../context/ModelEditorProvider';
import { UserContext } from '../../context/UserProvider';
import { OnboardContext } from '../../context/OnboardProvider';
import ConnectDatabase from '../ConnectionPages/ConnectDatabase';
import { TableGraphContext } from '../../context/TableGraphProvider';
const CompleteSetup = () => {
    const { git } = useContext(TableGraphContext);

    const {
        connectGit,
        setConnectGit,
    } = useContext(ModelEditorContext);

    const {
        noWarehouse
    } = useContext(UserContext)
    const {
        setupWarehouse,
        setSetupWarehouse,
    } = useContext(OnboardContext)

    if (connectGit) {
        return <ConnectGit/>
    }

    if (setupWarehouse) {
        return <ConnectDatabase/>
    }


    return (
        <div className="min-h-full flex pt-[25vh] justify-center bg-white cursor-default">
            <div className="text-center">
                <p className="mt-4 text-[24px] font-[500] text-gray-900">Connection Required</p>
                <p className="mt-2 text-lg text-gray-900">Artemis requires a connection to a database and a Github repository to use various parts of the platform</p>
                <p className="mt-2 text-lg text-gray-900 flex justify-center">
                    Database status:
                    {
                        noWarehouse ? 
                        <div className='flex text-yellow-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <button className='px-2 border-yellow-500 border-[1px] rounded-lg hover:text-yellow-600 hover:border-yellow-600' onClick={() => setSetupWarehouse(true)}>
                                get started
                            </button>
                        </div>
                        :
                        <div className='flex text-green-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            completed
                        </div>
                    }
                </p>
                <p className="mt-2 text-lg text-gray-900 flex justify-center">
                    Github status:
                    {
                        git.noGitConnection ? 
                        <div className='flex text-yellow-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <button className='px-2 border-yellow-500 border-[1px] rounded-lg hover:text-yellow-600 hover:border-yellow-600' onClick={() => setConnectGit(true)}>
                                get started
                            </button>
                        </div>
                        :
                        <div className='flex text-green-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 relative top-[3px] mx-[5px]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            completed
                        </div>
                    }
                </p>  
            </div>
        </div>
    )
}

export default CompleteSetup