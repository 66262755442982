import { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';

import ActionLink from '../ActionLink';
import Card from '../Card';
import CreditCard from './CreditCard';

import './style.scss';
import PaymentDrawer from './PaymentDrawer';

const PaymentMethod = () => {
  const [visible, setVisible] = useState(false);
  const [cardDetails, setCardDetails] = useState({
    brand: 'Mastercard',
    digits: '1234',
    expiry: '06/2024',
    cardholder: 'Josh Gray',
    email: 'josh@artemis.ca',
  });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Card title="" info="" contentClass="payment-content">
      <div className='coming-soon'>
        <h3>
        Coming Soon

        </h3>
        </div>
        {/* <CreditCard cardDetails={cardDetails} />

        <div className="card-summary">
          <p className="summary-digits">
            {cardDetails.brand} ending in {cardDetails.digits}
          </p>
          <p className="summary-expiry">Expiry {cardDetails.expiry}</p>
          <p className="summary-email">
            <MailOutlined /> {cardDetails.email}
          </p>
        </div>

        <div className="action">
          <ActionLink text="Change method" onClick={showDrawer} />
        </div>

        <PaymentDrawer cardDetails={cardDetails} visible={visible} onClose={onClose} /> */}


        {/* <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M38.4 42 25.85 29.45l2.85-2.85 12.55 12.55ZM9.35 42 6.5 39.15 21 24.65l-5.35-5.35-1.15 1.15-2.2-2.2v4.25l-1.2 1.2L5 17.6l1.2-1.2h4.3L8.1 14l6.55-6.55q.85-.85 1.85-1.15 1-.3 2.2-.3 1.2 0 2.2.425 1 .425 1.85 1.275l-5.35 5.35 2.4 2.4-1.2 1.2 5.2 5.2 6.1-6.1q-.4-.65-.625-1.5-.225-.85-.225-1.8 0-2.65 1.925-4.575Q32.9 5.95 35.55 5.95q.75 0 1.275.15.525.15.875.4l-4.25 4.25 3.75 3.75 4.25-4.25q.25.4.425.975t.175 1.325q0 2.65-1.925 4.575Q38.2 19.05 35.55 19.05q-.9 0-1.55-.125t-1.2-.375Z"/></svg>
        Under Construction
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M38.4 42 25.85 29.45l2.85-2.85 12.55 12.55ZM9.35 42 6.5 39.15 21 24.65l-5.35-5.35-1.15 1.15-2.2-2.2v4.25l-1.2 1.2L5 17.6l1.2-1.2h4.3L8.1 14l6.55-6.55q.85-.85 1.85-1.15 1-.3 2.2-.3 1.2 0 2.2.425 1 .425 1.85 1.275l-5.35 5.35 2.4 2.4-1.2 1.2 5.2 5.2 6.1-6.1q-.4-.65-.625-1.5-.225-.85-.225-1.8 0-2.65 1.925-4.575Q32.9 5.95 35.55 5.95q.75 0 1.275.15.525.15.875.4l-4.25 4.25 3.75 3.75 4.25-4.25q.25.4.425.975t.175 1.325q0 2.65-1.925 4.575Q38.2 19.05 35.55 19.05q-.9 0-1.55-.125t-1.2-.375Z" /></svg> */}
    </Card>
  );
};

export default PaymentMethod;
