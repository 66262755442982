import React , {useContext, useState, useEffect}from 'react';
import { TableGraphContext } from '../../../context/TableGraphProvider';
import { TableManagementContext } from '../../../context/TableManagementProvider';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import { List, Spin, Pagination} from 'antd'
import './styles.scss'
import { ModelEditorContext } from '../../../context/ModelEditorProvider';
import CSVUploadModal from '../../ModelEditor/CSVUploadModal';
const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default () => {
  const {
    initSelectedNode,
    initSelectedModel,
    initSelectedSeed,
    setShowDB,
    showDB,
    showModels,
    setShowModels,
    blankLoading,
  } = useContext(TableGraphContext);
  
  const {
    uploadSeedCSV,
    seedList,
    modelList,
    setFilteredModelList,
    filteredModelList,
  } = useContext(ModelEditorContext)

  const {
    tableList,
    filteredTableList,
    setFilteredTableList,
    tableListLoading,
    getAllTables,
  } = useContext(TableManagementContext)

  //TODO: refactor other show dropdowns to the component level state 
  const [showSeeds, setShowSeeds] = useState(false)
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  useEffect(() => {
    if (!tableList) {
      getAllTables()
    }
  }, [])


  const handleUpload = async () => {

    // if (!file) {
    //   alert('Please select a file first.');
    //   return;
    // } 

    uploadSeedCSV(file) 
  }

  const [dbPage, setDbPage] = useState(1)
  const [modelPage, setModelPage] = useState(1)
  const [seedPage, setSeedPage] = useState(1)
  const [isCSVModalOpen, setIsCSVModalOpen] = useState(false)

  const SelectModel = (item) => {
    initSelectedModel("models/" + item + ".sql") 
    // TODO: change once we have model files for multiple models
  }
  
  const SelectSeed = (item) => {
    initSelectedSeed(item)
  }
  
  const SelectTable = (item) => {
    if (item.schema) {
      initSelectedNode(item.schema + '.' + item.table)
    }
    else {
      initSelectedNode(item.table)
    }
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
  }


const searchChange = (value) => {
    const searchTerms = value.toLowerCase().trim().split(' ');

    if (tableList && tableList.length > 0) {
        const filteredTables = tableList.filter(item => 
            searchTerms.every(term => 
                item.table.toLowerCase().includes(term) || 
                (item.schema && item.schema.toLowerCase().includes(term))
            )
        );
        setFilteredTableList(filteredTables);
    }

    if (modelList && modelList.length > 0) {
        const filteredModels = modelList.filter(model => 
            searchTerms.every(term => model.toLowerCase().includes(term))
        );
        setFilteredModelList(filteredModels);
    }

    setDbPage(1);
    setModelPage(1);
};



  const dbPagination = (list) => {
    if (list.error){
      return;
    } 
    if (list)
      return list.slice((dbPage - 1) * 15, (dbPage - 1) * 15 + 14)
  }

  const modelPagination = (list) => {
    if (list?.error){
      return;
    } 
    if (list)
      return list.slice((modelPage - 1) * 15, (modelPage - 1) * 15 + 14)
  }
  return (
    <div className='overflow-hidden h-full flex flex-col'>
      
            <CSVUploadModal isOpen={isCSVModalOpen} onClose={() => setIsCSVModalOpen(false)} />
      <form className="searchbar-styling flex items-center my-[5px]" onSubmit={handleSearchSubmit}>   
            <div className="relative w-full" >
              <Spin spinning={tableListLoading} key={2} indicator={loadingIcon}>
              <input type="search" className="bg-gray-50 border w-full rounded-lg border-gray-300 text-gray-900 text-sm focus:ring-none focus:border-none block  pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none" placeholder="Find source..." onChange={(e) => searchChange(e.target.value)} />
              </Spin>
            </div>
      </form>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={blankLoading}>
      <div className='grow overflow-auto mb-[25px]'>
        <div onClick={() => {setShowDB(!showDB) }} className='text-gray-500 cursor-pointer' style={{userSelect: 'none'}}>
          <DownOutlined className='duration-[50ms]' style={showDB ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
          Database
        </div>
        {showDB && 
          <>
        <div
        id="scrollableDiv"
        className={'blank-source-scrollable'}
        >
          
          { tableList && 
          <>
          <List
            dataSource={dbPagination(filteredTableList)}
            renderItem={(item) => (
              <List.Item key={item.schema ? item.schema + '.' + item.table : item.table}  style={{height: '45px', userSelect: 'none'}} onClick={() => SelectTable(item)} >
                <List.Item.Meta
                  avatar={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M.99 5.24A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25l.01 9.5A2.25 2.25 0 0116.76 17H3.26A2.267 2.267 0 011 14.74l-.01-9.5zm8.26 9.52v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 00.627-.74zm1.5 0a.75.75 0 00.627.74h5.373a.75.75 0 00.75-.75v-.615a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625zm6.75-3.63v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75zM17.5 7.5v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75z" clipRule="evenodd" />
                          </svg>
                  }
                  title={<div className='text-[13px] truncate mb-[0px]'>{item.table}</div>}
                  description={<div className='text-[10px] truncate'>{item.schema}</div>}
                  
                />
              </List.Item>
            )}
          >
          </List>
          </>
          }
        </div>
          <div className='relative justify-center flex mt-[5px] mb-[20px]'>
            { filteredTableList && 
            <Pagination className='' current={dbPage} pageSize={15} total={filteredTableList.length} onChange={(e) => setDbPage(e)} hideOnSinglePage={true} showSizeChanger={false} showLessItems={true} size={'small'} />
            }
          </div>
          </>
        }

      {/*MODELSLIST*/}
        <div onClick={() => {setShowModels(!showModels) }} className='text-gray-500 cursor-pointer  mt-1.5' style={{userSelect: 'none'}}>
          <DownOutlined className='duration-[50ms]' style={showModels ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
          Models
        </div>
        {
          showModels && 
          <>
      <div
        id="scrollableDiv"
        className={'blank-source-scrollable'}
        >
          
          { modelList && 
          <>
          <List
            dataSource={modelPagination(filteredModelList)}
            renderItem={(item) => (
              <List.Item style={{height: '45px', userSelect: 'none'}} onClick={() => SelectModel(item)} >
                <div className='flex justify-start cursor-pointer w-full overflow-x-hidden'>
                  <div className='w-4 h-4 mr-[5px] relative top-[3px]'>
                  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill='black' className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M22 4v3H10V4H2v8h8V9h7.023A4.946 4.946 0 0 0 16 12v8a3.004 3.004 0 0 1-3 3h-3v-3H2v8h8v-3h3a5.006 5.006 0 0 0 5-5v-8a2.998 2.998 0 0 1 2.997-3H22v3h8V4ZM8 10H4V6h4Zm0 16H4v-4h4Zm20-16h-4V6h4Z"/>
                  </svg>
                  </div>
                  

                  <div className='text-[13px] truncate mb-[0px]'>{item}</div>
                </div>
                

              </List.Item>
            )}
          >
          </List>
          </>
          }
        </div>
        <div className='relative justify-center flex mt-[5px]'>
          {filteredModelList &&
            <Pagination className='' current={modelPage} pageSize={15} total={filteredModelList.length} onChange={(e) => setModelPage(e)} hideOnSinglePage={true} showSizeChanger={false} showLessItems={true} size={'small'} />
          }
        </div>
            </>
        }

      {/*SEEDS LIST*/}
       <div onClick={() => {setShowSeeds(!showSeeds) }} className='text-gray-500 cursor-pointer mt-1.5' style={{userSelect: 'none'}}>
          <DownOutlined className='duration-[50ms]' style={showSeeds ? { transform: 'rotate(0deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'} : { transform: 'rotate(-90deg)', transition: '200ms ease-in', marginRight: '10px', marginLeft:'0px' ,top:'-2px', position: 'relative', fontSize:'12px'}}/>
          Seeds [CSV]
        </div>
        {
          showSeeds && 
          <>
      <div
        id="scrollableDiv"
        className={'blank-source-scrollable'}
        >
          
          { seedList && 
          <>
          <List
            dataSource={seedList}
            renderItem={(item) => (
              <List.Item style={{height: '45px', userSelect: 'none'}} onClick={() => SelectSeed(item)} >
                <div className='flex justify-start cursor-pointer w-full overflow-x-hidden'>
                  <div className='w-4 h-4 mr-[5px] relative top-[3px]'>


<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
    <path fill="#000000" d="M48 180c0 11 7.18 20 16 20a14.24 14.24 0 0 0 10.22-4.66a8 8 0 0 1 11.56 11.06A30.06 30.06 0 0 1 64 216c-17.65 0-32-16.15-32-36s14.35-36 32-36a30.06 30.06 0 0 1 21.78 9.6a8 8 0 0 1-11.56 11.06A14.24 14.24 0 0 0 64 160c-8.82 0-16 9-16 20Zm79.6-8.69c-4-1.16-8.14-2.35-10.45-3.84c-1.25-.81-1.23-1-1.12-1.9a4.57 4.57 0 0 1 2-3.67c4.6-3.12 15.34-1.73 19.82-.56a8 8 0 0 0 4.15-15.48c-2.12-.55-21-5.22-32.84 2.76a20.58 20.58 0 0 0-9 14.95c-2 15.88 13.65 20.41 23 23.11c12.06 3.49 13.12 4.92 12.78 7.59c-.31 2.41-1.26 3.34-2.14 3.93c-4.6 3.06-15.17 1.56-19.55.36a8 8 0 0 0-4.31 15.44a61.34 61.34 0 0 0 15.19 2c5.82 0 12.3-1 17.49-4.46a20.82 20.82 0 0 0 9.19-15.23c2.19-17.31-14.32-22.14-24.21-25Zm83.09-26.84a8 8 0 0 0-10.23 4.84L188 184.21l-12.47-34.9a8 8 0 0 0-15.07 5.38l20 56a8 8 0 0 0 15.07 0l20-56a8 8 0 0 0-4.84-10.22ZM216 88v24a8 8 0 0 1-16 0V96h-48a8 8 0 0 1-8-8V40H56v72a8 8 0 0 1-16 0V40a16 16 0 0 1 16-16h96a8 8 0 0 1 5.66 2.34l56 56A8 8 0 0 1 216 88Zm-27.31-8L160 51.31V80Z"/>
</svg>

                  </div>
                  

                  <div className='text-[13px] truncate mb-[0px]'>{item}</div>
                </div>
                

              </List.Item>
            )}
          >
          </List>
          </>
          }
        </div>
        <div className='relative justify-center flex mt-[5px]'>
          {seedList &&
            <Pagination className='' current={seedPage} pageSize={15} total={seedList.length} onChange={(e) => setSeedPage(e)} hideOnSinglePage={true} showSizeChanger={false} showLessItems={true} size={'small'} />
          }
        </div>

         <div className='flex justify-start cursor-pointer w-full overflow-x-hidden'>
                  <div className='w-4 h-4 mr-[5px] relative top-[3px]'>


<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="#000000" d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16h-2Zm-5 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z"/>
</svg>
                  </div>

    <button
            onClick={()=> setIsCSVModalOpen(true)}
                className="border border-gray-300 px-1 rounded-md flex justify-center items-center bg-[#F7F7F7]">
Upload</button>                

      
                </div>
            </>
        }
        </div>
        </Spin>
    </div>
  )
};

