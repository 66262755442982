import { TableGraphContext } from "../../context/TableGraphProvider";
import Spreadsheet from "react-spreadsheet";
import "./styles.scss"
import { useContext, useState, useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 34,
    }}
    spin
  />
);



const DataPreview = () => {
    const {
        previewCachedData,
        selectedNode,
        nodes
    } = useContext(TableGraphContext)
    const [mode, setMode] = useState(0)
    const [oldData, setOldData] = useState(false)

    
    useEffect(() => {
        if (selectedNode) {
            let curr_node = nodes.find(node => node.id === selectedNode.id)
            if (previewCachedData[selectedNode.id]) {
                if (previewCachedData[selectedNode.id].loading) {
                    setMode(1)
                }
                else if (previewCachedData[selectedNode.id].error) {
                    setMode(2)
                }
                else {
                    setMode(3)
                }
            }
            else {
                setMode(4)
            }

            if (curr_node && curr_node.data.dbt_file_last_updated * 1000 > curr_node.data.last_executed) {
                setOldData(true)
            }
            else {
                setOldData(false)
            }
        }
        else {
            setMode(0)
        }

    }, [nodes, selectedNode, previewCachedData])

    return (
        <div>
            {mode === 1 && 
                <div className="w-full h-full relative">
                    <Spin indicator={antIcon} spinning={true} className="w-full justify-center pt-[5%]" />
                </div>
            }
            {selectedNode && mode === 2 &&
                <div className='inline text-[#ff6963]' style={{whiteSpace: 'pre'}}>
                    {previewCachedData[selectedNode.id] ? previewCachedData[selectedNode.id].error : ""}
                </div>
            }
            {selectedNode && mode === 3 &&
                <div className="overflow-auto">
                    {oldData &&
                        <div className=" text-gray-500 mb-2">
                            Warning: You have currently saved changes which have not been executed. Preview data is taken from the last time the node was executed.
                        </div>
                    
                    }
                    {previewCachedData[selectedNode.id] &&
                        <Spreadsheet
                        data={previewCachedData[selectedNode.id].rows}
                        columnLabels={previewCachedData[selectedNode.id].columns}
                    />
                    }
                </div>

            }
            {mode === 4 &&
                <div>
                    No preview data found
                </div>
            }
            {!selectedNode || mode === 0 &&
                <div>
                    No node selected
                </div>
            }
        </div>

    )

};

export default DataPreview;
