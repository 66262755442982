import { createContext, useState, useEffect, useContext } from 'react';
import GraphClient from './GraphClient.js';
import { TableGraphContext } from './TableGraphProvider.js';

export const ArtyChatContext = createContext();

export const ArtyChatProvider = ({ children }) => {

  const {dagId, nodes, setNodes, edges, setEdges, setCopilotNodes, setCopilotEdges} = useContext(TableGraphContext)

  const [chat, setChat] = useState({})
  const [copilotChat, setCopilotChat] = useState({})
  const [chatId, setChatId] = useState('')
  const [contextMessage, setContextMessage] = useState('')
  const [chatList, setChatList] = useState([{}])
  const [pinnedChatList, setPinnedChatList] = useState([{}])
  const [fetchingChat, setFetchingChat] = useState(false)
  const [newChatName, setNewChatName] = useState("");
  const [initialChatName, setInitialChatName] = useState("");
  const [isEditing, setIsEditing] = useState(null);

  useEffect(() => {
    setCopilotChat({})
  }, [dagId])
     
  const handleEdit = async (item) => {
      if (item.chat_name) {
          setNewChatName(item.chat_name);
          setInitialChatName(item.chat_name);
      }
      else {
          setNewChatName("Undefined Chat");
          setInitialChatName("Undefined Chat");
      }
      setIsEditing(item._id);
  };

  const handleSave = (dag_id, chat_id) => {
      if (isEditing !== null) {
          updateChatName(dag_id, chat_id, newChatName);
          setIsEditing(null);
      }
  };

  const handleCancel = () => {
      setNewChatName(initialChatName);
      setIsEditing(null);
  };

    const createChat = async (dag_id) => {
      setFetchingChat(true)
      try {
        const response = await GraphClient.createArtyChat(dag_id)
        setChatList(response)
        setChatId(response[response.length - 1]['_id'])
        setContextMessage(response[response.length - 1]['data'])
        setChat(response[response.length - 1])
      }
      catch {
        console.log('server error')
      }
      setFetchingChat(false)
    }

    const getAllChats = async (dag_id) => {
      const response = await GraphClient.getAllChats(dag_id);
      setChatList(response)
      return response
    }

    const getPinnedChats = async (dag_id) => {
      const response = await GraphClient.getPinnedChats(dag_id)
      setPinnedChatList(response)
    }

    const createPinnedChat = async (chat_id, dag_id) => {
      const response = await GraphClient.createPinnedChat(chat_id)
      getPinnedChats(dag_id)
    }

    const deletePinnedChat = async (chat_id, dag_id) => {
      const response = await GraphClient.deletePinnedChat(chat_id)
      getPinnedChats(dag_id)
    }

    const updateChatName = async (dag_id, chat_id, chat_name) => {
      let response = await GraphClient.updateChatName(chat_id, chat_name)
      getAllChats(dag_id)
      getPinnedChats(dag_id)
    }

    const deleteChat = async (chat_id, dag_id) => {
      await GraphClient.deleteChat(chat_id)
      const response = await getAllChats(dag_id)
      getPinnedChats(dag_id)
      return response
    }

  const convertPipelineToDAG = async (pipeline) => {
    try{
      let response = await GraphClient.convertPipelineToDAG({pipeline: pipeline})
      if (nodes)
        setNodes((prevNodes) => [...prevNodes, ...response.nodes]);
      else
        setNodes(response.nodes);

      if(edges)
        setEdges((prevEdges) => [...prevEdges, ...response.edges]);
      else
        setEdges(response.edges);
      return response
    } catch (error){
    }
  }

  const promptArtySql = async (dag_id, sql, index) => {
  try {
      setChat((currentChat) => {
        // Make a shallow copy of the current chat data to maintain immutability
        let updatedChatData = [...currentChat.data];
        
        // Check if the index is within the bounds of the chat data array
        if (index >= 0 && index < updatedChatData.length) {
          // Create a new object with the existing chat data and add the 'results' field
          let updatedChatObject = {
            ...updatedChatData[index],
            loading: true// Insert the response data into the 'results' field
          };

          // Replace the object at the given index with the updated object
          updatedChatData[index] = updatedChatObject;
        }

        // Return the updated chat state
        return {
          ...currentChat,
          data: updatedChatData
        };
      });


    let response = await GraphClient.promptArtyChatSql(dag_id, chatId, { 'sql': sql });

    // Check if the response is valid and contains data
    if (response && response.data) {
      // Update the chat object at the given index with the results
      setChat((currentChat) => {
        // Make a shallow copy of the current chat data to maintain immutability
        let updatedChatData = [...currentChat.data];
        
        // Check if the index is within the bounds of the chat data array
        if (index >= 0 && index < updatedChatData.length) {
          // Create a new object with the existing chat data and add the 'results' field
          let updatedChatObject = {
            ...updatedChatData[index],
            results: response.data // Insert the response data into the 'results' field
          };

          // Replace the object at the given index with the updated object
          updatedChatData[index] = updatedChatObject;
        }

        // Return the updated chat state
        return {
          ...currentChat,
          data: updatedChatData
        };
      });
    }
  } catch (error) {
    // Handle error by updating the chat object with an error message
    setChat((currentChat) => {
      let updatedChatData = [...currentChat.data];

      if (index >= 0 && index < updatedChatData.length) {
        let updatedChatObject = {
          ...updatedChatData[index],
          results: { response_type: -1, response: 'A problem occurred when connecting/communicating with the server.' }
        };

        updatedChatData[index] = updatedChatObject;
      }

      return {
        ...currentChat,
        data: updatedChatData
      };
    });
  }
  }
  
  const promptArtyCopilot = async (dag_id, text) => {
      setCopilotChat(chat => ({
        ...chat, 
        data: chat.data ? [...chat.data, { response_type: 0, response: text }] : [{ response_type: 0, response: text }]
      }))
      try {
        let eventSource = await GraphClient.promptArtyCopilot(dag_id, { 'prompt': text, 'chat': copilotChat.data })
        const decoder = new TextDecoder('utf-8')
        const reader = eventSource.body.getReader()
        var { value, done }  = await reader.read();
        let buffer = '';
        let match;
        while (!done) {
          buffer += decoder.decode(value, { stream: true });
          const pattern = /data:\s({[\s\S]*?})\s*\n\s*\n/g;
          let lastIndex = 0 
          while ((match = pattern.exec(buffer)) !== null) {
            const jsonString = match[1];
            try {
              const message = JSON.parse(jsonString);
              lastIndex = pattern.lastIndex
              if (message.type === 0) {
                setCopilotChat(prev => {
                  if (prev.data) {
                    let front = prev.data.slice(0, -1)
                    let last = { response_type: 1, response: message.data.message }
                    return { ...prev, data: [...front, last] }  
                  }
                  return { ...prev, data: [{ response_type: 1, response: message.data.message }] }
                })
              }
              else if (message.type === 1) {
                setCopilotChat(prev => (prev.data ?
                  { ...prev, data: [...prev.data, { response_type: 1, response: message.data.message }] }
                  :
                  { ...prev, data: [{ response_type: 1, response: message.data.message }]}
                ))
              }
              else if (message.type === 2) {
                setCopilotNodes(prev => prev ? [...prev, ...message.data.new_nodes] : message.data.new_nodes)
                setCopilotEdges(prev => prev ? [...prev, ...message.data.new_edges] : message.data.new_edges)
              }
            }
            catch (error){
              console.log(error)
            }
          }
          buffer = buffer.slice(lastIndex);
          ({ value, done } = await reader.read())
        }
      } catch {
      setChat({
        ...chat, 
        data: chat.data ? [...chat.data, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }] : [{ response_type: 0, response: text }, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }]
      })
    }
    }

    const promptArty = async (dag_id, text) => {
      console.log("text:", text)
      setChat(prev => ({
        ...prev, 
        data: prev.data ? [...prev.data, { response_type: 0, response: text }, {response_type: 1, response: ''}] : [{ response_type: 0, response: text }, {response_type: 1, response: ''}]
      }))
      try {
        let messageIterator = await GraphClient.promptArtyChatSSE(dag_id, chatId, { 'prompt': text })
        for await (let message of messageIterator) {
          if (message.error) {
            setChat(prev => {
              if (prev.data) {
                prev.data[prev.data.length - 1] = {response_type: -1, response: message.error} 
              }
              else {
                prev.data = [{response_type: -1, response: message.error}]
              }
              return prev
            })
            break;
          }
          else if (message.type == 0) {
            setChat(prev => {
              if (prev.data) {
                prev.data[prev.data.length - 1] = { response_type: 1, response: message.data.explanation }
              }
              else {
                prev.data = [{ response_type: 1, response: message.data.explanation }]
              }
              return {...prev}
            })
          }
          else if (message.type == 2) {
            let existingItem = chatList.find(item => item._id === message.data._id);
            if (existingItem && !existingItem.chat_name) {
                let name = await GraphClient.getChatName({ data: text });
                if (name) {
                    updateChatName(dag_id, message.data._id, name.replace(/"/g, ''));
                }
            } 
            setChatList(prev => prev.map((item) => item._id == message.data._id ? message.data : item))
            setChat(message.data)
          }
        }
      } catch {
      setChat(prev => ({
        ...prev, 
        data: prev.data ? [...prev.data, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }] : [{ response_type: 0, response: text }, { response_type: -1, response: 'A problem occured when connecting/communicating with the server.' }]
      }))
    }
  }

  const fetchChat = async (id) => {
    setFetchingChat(true)
    try {
      const response = await GraphClient.fetchChat(id)
      if (response.data) {
        setChat(response.data)
        setChatId(id)
      }
      else if (response.error) {
        console.log('invalid response', response.error)
      }
    }
    catch {
      console.log('server error')
    }
    setFetchingChat(false)
  }

  return (
    <ArtyChatContext.Provider
      value={{
          chat, 
          setChat,
          chatId,
          setChatId,
          createChat,
          convertPipelineToDAG,
          promptArty, 
          promptArtySql,
          contextMessage, 
          setContextMessage,
          getAllChats,
          setChatList,
          chatList,
          getPinnedChats,
          pinnedChatList,
          createPinnedChat,
          deletePinnedChat,
          deleteChat,
          fetchingChat, 
          setFetchingChat,
          fetchChat,
          updateChatName,
          newChatName,
          setNewChatName,
          initialChatName,
          setInitialChatName,
          isEditing,
          setIsEditing,    
          handleEdit,
          handleSave,
          handleCancel,
          promptArtyCopilot,
          copilotChat,
          setCopilotChat,
      }}
    >
      {children}
    </ArtyChatContext.Provider>
  )
}

