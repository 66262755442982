import React , {useContext, useState, useEffect }from 'react';
import { TableGraphProvider, TableGraphContext } from '../../../context/TableGraphProvider';
import { TableManagementContext } from '../../../context/TableManagementProvider';
import { LoadingOutlined, DownOutlined, HeartOutlined, RetweetOutlined } from '@ant-design/icons';
import { Row, Col as Column, Card, List, Spin, Button, Col, Pagination} from 'antd'
import '../styles.scss'


const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default () => {
  const {
    initSelectedNode,
    dagId,
    dags,
  } = useContext(TableGraphContext);
    
  const [created, setCreated] = useState()
  const [lastUpdated, setLastUpdated] = useState()
  const [numNodes, setNumNodes] = useState(0)
  const [dagName, setDagName] = useState('')
  
    
    
  useEffect(() => {
    for (let dag of dags) {
        if (dagId == dag._id) {
            setDagName(dag.name)
            setCreated(dag.created_at)
            setLastUpdated(dag.last_updated)
            setNumNodes(dag.nodes.length)
        }
    }
  }, [dagId])
  

  const {
    tableList,
    filteredTableList,
    setFilteredTableList,
    tableListLoading,
  } = useContext(TableManagementContext)

  const [page, setPage] = useState(1)

  const changePage = (value) => {
    setPage(value)
  }


  const selectTable = async(table_name) => {
      await initSelectedNode(table_name).then(() => {

      })}

  const SelectTable = (item) => {
    if (item.schema) {
      initSelectedNode(item.schema + '.' + item.table)
    }
    else {
      initSelectedNode(item.table)
    }
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
  }

  const searchChange = (value) => {
    if (value.length > 3) {
      setFilteredTableList(tableList.filter(item => item.table.includes(value) || item.schema.includes(value)))
      setPage(1)
    }
    else
    setFilteredTableList(tableList)
  }

  const tableNameLength = (tableName) => {
    return (tableName.length > 28) ? <a style={{fontSize: '13px', marginBottom:'0px'}}> {tableName.substring(0, 26) + "..."}</a> : <a style={{fontSize: '13px'}} > {tableName} </a>
  }

  const schemaNameLength = (schemaName) => {
    return (schemaName.length > 33) ? <a style={{fontSize: '10px'}}> {schemaName.substring(0, 32) + "..."}</a> : <a style={{fontSize: '10px'}} > {schemaName} </a>
  }

  const pagination = (list) => {
    return list.slice((page - 1)*15, (page - 1)*15 + 14)
  }
    
  const createdAt = (time) => {
    let date = new Date(parseInt(time))
    return date.toLocaleString().split(',')[0]
  }
    
  const lastUpdate = (time) => {
    
    if (Math.round((Date.now() - Date.parse(time)) / (8.64 * 10 ** 7)) > 30) {
        return String(Math.round((Date.now() - time)/(30*8.64*10**7))) + ' months ago'
    }
    if (Math.round((Date.now() - Date.parse(time)) / (8.64 * 10 ** 7)) > 1) {
        return String(Math.round((Date.now() - time)/(8.64*10**7))) + ' days ago'
    }
    else {
        return String(Math.round((Date.now() - time)/(3.6*10**6))) + ' hours ago'
      }
  }

  


  return (
    <div className='overflow-hidden'>
        <div className='sider-style'>       
            {dagName && 
                <div className='w-full justify-center'>
                    <div className='flex justify-center text-center text-[18px] mb-[10px]'>
                        <div className='mr-[5px] font-[500]'>
                            {dagName}
                        </div>
                            Workflow
                    </div>
                </div>
            }
            {created && 
                <div className='w-full justify-center'>
                    <div>
                        Created on: {createdAt(created)}
                    </div>
                </div>
            
            
            }
            {lastUpdated && 
                <div className='w-full justify-center'>
                    <div>
                        Last Updated: {lastUpdate(lastUpdated)} 
                    </div>
                </div>
            
            
            }
            {lastUpdated && 
                <div className='w-full justify-center'>
                    <div>
                        Last Updated: {lastUpdate(lastUpdated)} 
                    </div>
                </div>
            
            
            }
      
        </div> 
      </div>
  )
};

